import React, {useEffect, useState} from "react";
import {Drawer, Skeleton} from "antd";
import {CloseOutlined} from "@ant-design/icons";

export default function ChatHistory({currentThreadID, openChatHistory, setOpenChatHistory}) {
  
  const [loadingChatList, setLoadingChatList] = useState(false);
  const [chatList, setChatList] = useState([]);

  const getChatList = async () => {
    setLoadingChatList(true);
    let token = sessionStorage.getItem("ZeitroA");
    try {
      const response = await fetch("/los/mortgagegpt/chatlist", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
      });

      if (!response.ok) {
        setLoadingChatList(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setLoadingChatList(false);
      setChatList(data.chat_list);
    } catch (error) {
      setLoadingChatList(false);
      console.error("Fetch Error:", error);
    }
  };

  useEffect(() => {
    getChatList();
  }, []);


  const renderChatList = () => {

    const processedData = chatList.reduce((acc, item) => {
      const [thread, dateString, titleFallback, title] = item;
      const formattedDate = new Date(dateString).toLocaleDateString();
    
      const finalTitle = title || titleFallback;
      if (!finalTitle) return acc;
    
      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push({ title: finalTitle, threadId: thread });
    
      return acc;
    }, {});

    const handleClick = (threadId) => {
      window.location.href = `/app/mortgagegpt/${threadId}`;
    };
  
    return (
      <div className="text-left">
        {Object.entries(processedData).map(([date, items]) => (
          <div key={date}>
            <div className="title-5 py-3 px-4">{date === new Date().toLocaleDateString() ? 'TODAY' : date}</div>
            {items.map((item, index) => (
              <div key={index} className={item.threadId === currentThreadID ? "chatHistoryTitleCurrent py-2 px-4 " : "chatHistoryTitle py-2 px-4 "} onClick={() => handleClick(item.threadId)}>
                {item.title}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }



  return (
    <Drawer
      className="chatListDrawer"
      title="Basic Drawer"
      placement="right"
      closable={true}
      onClose={() => setOpenChatHistory(false)}
      open={openChatHistory}
      getContainer={false}>
      <div className="bottomBorder">
        <div className="pt-4 pb-3 px-4 d-flex justify-content-between">
          <div className="title-2">Chat history</div>
          <CloseOutlined
            className="rowLink"
            style={{fontSize: 20}}
            onClick={() => setOpenChatHistory(false)}
          />
        </div>
      </div>

      <div className="chatListWrap mt-2">{loadingChatList ? <Skeleton active /> : renderChatList()}</div>

    </Drawer>
  );
}
