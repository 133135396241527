import React, { useState, useEffect } from 'react';
import { 
  Input,
  InputNumber,
  Select,
  message,
  Switch,
  Row,
  Col,
  Spin
} from 'antd';
import "./Common.css";
import "./Overview.css";
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import * as com from "../../Common"
import { Link } from 'react-router-dom'


const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        hardmoney: state.application.hardmoneyloan,
        progress: state.progress,
        borrower: state.borrower
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
      dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    }
  });

export function CalcHardMoneyLoanRate(props)  {
let result = {
    unqualified: false,
    unqualifiedReason: "", 
    floorRate: 0,
    point: 0,
    monthlyPayment: 0,
    closingCost: 0,

}
let propertyType = props.application.property.propertytype
let scre = props.application.hardmoneyloan.estimatedfico
let borrowerExperience = props.application.hardmoneyloan.timesofflippingaproperty + props.application.hardmoneyloan.timesofpurchasingarentalproperty
let loanamount = ""
let ltv = 0
if (props.application.property.salesprice==="") {
    ltv = 0
} else {
    switch (props.application.property.purpose) {
    case "purchase":
        loanamount = props.application.hardmoneyloan.initialloanamount
        break;
    case "refinance":
        loanamount = props.application.hardmoneyloan.refinanceloanamount
        break;
    case "construction":
        loanamount = props.application.hardmoneyloan.constructionloanamount
        break;
    }
    ltv = 100 * com.safeParseInt(loanamount) / com.safeParseInt(props.application.property.salesprice)
}

let floorRate = 8
let point = 1

if (ltv>60 && ltv <=70) {
    floorRate += 0.5
} else if (ltv>70 && ltv <=80) {
    floorRate += 1
} else if (ltv > 80) {
    result.unqualified = true
    result.unqualifiedReason = "The maximum LTV is 80%, while current LTV is "+ Math.round(ltv) + "%."
}

if (scre >= 650) {
    if (scre >= 700 && scre < 730) {
    floorRate += 0.5
    }
    if (scre >= 650 && scre < 700) {
    floorRate += 0.5
    point += 0.5
    }
} else {
    result.unqualified = true
    result.unqualifiedReason = "The minimum FICO score is 650, whereas the FICO score you have provided is " + scre + "."
}

if (borrowerExperience>=10 && borrowerExperience <= 20) {
    floorRate += 0.5
} else if (borrowerExperience>=3 && borrowerExperience <10) {
    floorRate += 1
} else if (borrowerExperience < 3) {
    floorRate += 1.5
    point += 0.5
}

if (propertyType === "commercial" || propertyType === "land") {
    floorRate += 3
    point += 1
}
if (propertyType === "condominium" || propertyType === "mixeduse") { 
    floorRate += 2
    point += 1
}


let mp = Math.round(com.MonthlyPayment(0.01 * floorRate/12, com.safeParseInt(loanamount), props.application.hardmoneyloan.mortgagetermmonths))
let cc = Math.round(com.safeParseInt(loanamount) * point * 0.01)

result.floorRate = floorRate
result.point = point
result.monthlyPayment = mp
result.closingCost = cc
return result
}

export function calcLoanAmount(props)  {
    let lm = ""
    if (props.property.purpose === "purchase") {
        lm = props.hardmoney.initialloanamount
    }
    if (props.property.purpose === "refinance") {
        lm = props.hardmoney.refinanceloanamount
    }   
    if (props.property.purpose === "construction") {
        lm = props.hardmoney.constructionloanamount
    }        
    return lm
}
export function getLoanAmount(props)  {
    let lm = calcLoanAmount(props)
    if (lm !== "") {
        return com.commaize(lm)
    }
    return "-"
}

export function getOriginationPoint(props)  {
    let lm = calcLoanAmount(props)
    if (lm !== "") {
        return com.commaize(parseInt(lm) / 100)
    }
    return "-"
}

export function getLTV(props) {
    if (props.property.salesprice !== "") {
        let sp = com.safeParseInt(props.property.salesprice)
        let lm = com.safeParseInt(calcLoanAmount(props))
        return Math.round(100 * lm/sp)
    }
    return "-"
}

export default function Overview(props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [showContact, setShowContact] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [contactMessage, setContactMessage] = useState("")
    const [teamList, setTeamList] = useState([])
    const { TextArea } = Input;
    // const statusList = [
    //     {
    //         value: 'Application Draft Started',
    //         label: 'Application Draft Started',
    //         operate: [<a onClick={()=>props.checkInterview()}>Edit application draft</a>]
    //     },
    //     {
    //         value: 'Initial Application Submitted',
    //         label: 'Initial Application Submitted',
    //         operate: []
    //     },
    //     {
    //         value: 'Processing',
    //         label: 'Processing',
    //         operate: []
    //     },
    //     {
    //         value: 'Underwriting',
    //         label: 'Underwriting',
    //         operate: []
    //     },
    //     {
    //         value: 'UW Approved',
    //         label: 'UW Approved',
    //         operate: []
    //     },
    //     {
    //         value: 'Docs drawn/out',
    //         label: 'Docs drawn/out',
    //         operate: []
    //     },
    //     {
    //         value: 'Funded',
    //         label: 'Funded',
    //         operate: []
    //     },
    //     {
    //         value: 'Recorded',
    //         label: 'Recorded',
    //         operate: []
    //     }
    // ]

    useEffect(()=>{
        getLoanProcessor()
    }, [])

    const statusList = () => {
        const options = Object.entries(com.loanStatusMap).map(([value, label]) => ({ value, label }))
        return options
    }

    const getAsIsValue = () => {
        if (props.property.salesprice !== "") {
            return com.commaize(props.property.salesprice)
        }
        return "-"
    }

    const formItemMode = (formItem, fields, type) => {
        if (formItem && editMode) {
            return formItem
        } else {
            if (fields && fields !== '') {
                return <span>{type === 'money' && <span>$</span>}{fields}{type === 'months' && <span> Months</span>}{type === 'percentage' && <span>%</span>}</span>
            } else {
                return '--'
            }
        }
    }

    let rateResult = CalcHardMoneyLoanRate(props)

    const loanTermOptions = [
        {
          value: 12,
          label: '12 Months'
        },
        {
          value: 24,
          label: '24 Months'
        },
        {
          value: 36,
          label: '36 Months'
        },
        {
          value: 48,
          label: '48 Months'
        },
        {
          value: 60,
          label: '60 Months'
        }
    ]
    const modeChange = (checked) => {
        console.log(`switch to ${checked}`);
        setEditMode(checked)
    }
    const getLoanPurpose = () => {
        let state = JSON.parse(sessionStorage.getItem('state'))
        if (state.application.product.mortgageapplied === "hardmoneyloan") {
            return "Hard money loan"
        }
        if (state.application.property.purpose === "purchase" && state.application.property.hascontract===false) {
            return "Pre-qualification"
        }
        if (this.props.application.property.purpose === "purchase") {
            return "Purchase"
        } else if (this.props.application.property.purpose === "cashoutrefinance") {
            return "Cash-out refinance"
        } else {
            return "Refinance"
        }
    }
    const loanType = (type) => {
        const typeMap = {
            'fixflip': 'Fix / Flip',
            'fixrent': 'Fix / Rent',
            'bridge': 'Bridge',
            'groundup': 'Ground Up',
            'rental': 'Rental'
        }
        return typeMap[type]
    }
    const PropertyType = (type) => {
        const typeMap = {
            'singlefamilyattached': 'Single Family Residence (Attached)',
            'singlefamilydetached': 'Single Family Residence',
            'twofamily': '2 Unit',
            'threefamily': '3 Unit',
            'fourfamily': '4 Unit',
            'multistory': '5+ Multi',
            'condominium': 'Condo / PUD',
            'townhouse': 'Townhouse',
            'commercial': 'Commercial',
            'mixeduse': 'Mixed Use',
        }
        return typeMap[type]
    }
    
    const getLoanProcessor = () => {
        setLoading(true)
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/');
        fetch('/los/getloanprocessor', {
          body: JSON.stringify({LoanID: paths[paths.length - 1]}),
          method: 'POST',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          }
        }).then(
          response => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                setLoading(false)
              return;
            }
            response.json().then(js => {
                setLoading(false)
                let data = js
                let team = [
                    {
                        name: `${data.CurrentLO.FirstName} ${data.CurrentLO.LastName}`,
                        role: 'Loan Officer',
                        avatar: ''
                    },
                    {
                        name: `${data.CurrentLP.FirstName} ${data.CurrentLP.LastName}`,
                        role: 'Processor',
                        avatar: ''
                    },
                    {
                        name: `${data.CurrentUnderwriter.FirstName} ${data.CurrentUnderwriter.LastName}`,
                        role: 'Underwriter',
                        avatar: ''
                    },
                    {
                        name: `${data.CurrentCloser.FirstName} ${data.CurrentCloser.LastName}`,
                        role: 'Closer',
                        avatar: ''
                    }
                ]
                console.log(team)
                setTeamList(team)
            })
          }
        )
          .catch(function (err) {
            setLoading(false)
            console.log('Fetch Error :-S', err)
          })
    }
    const handleStatusChange = (e) => {
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/');
        let body = { LoanID: paths[paths.length - 1], Key: "status", Value: e, IsAdmin: true }
        fetch('/los/updatepipeline', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        }
        }).then(response => {
        if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
            response.status);
            return;
        }
        })
        .catch(function (err) {
            console.log('Fetch Error :-S', err)
        })
    }
    return (
        <div className="over-view text-left">
            <Spin spinning={loading}>
                <div className='over-view-address'>{props.property.address}</div>
                <Row className='over-view-topInfo'>
                    {/* <Col><span>Loan ID</span><span>0124-12-0035</span></Col>
                    <Col><span>Target Closing Date</span><span>08/01/2023</span></Col> */}
                    <Col>
                        <Select
                            className='status-select'
                            defaultValue={props.application.status}
                            onChange={e => handleStatusChange(e)}
                            options={statusList()}
                        />
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col xl={12} span={24}>
                        <div className='general'>
                            <div className='module-title'>General</div>
                            <div>
                                <div className='general-row'>
                                    <div>
                                        <div>Loan Purpose</div>
                                        <div>{getLoanPurpose()}</div>
                                    </div>
                                    <div>
                                        <div>Loan Type</div>
                                        <div>{loanType(props.application.hardmoneyloan.loantype)}</div>
                                    </div>
                                </div>
                                <div className='general-row'>
                                    <div>
                                        <div>Property Type</div>
                                        <div>{PropertyType(props.application.property.propertytype)}</div>
                                    </div>
                                    <div>
                                        <div>Rehab Loan</div>
                                        <div>{props.application.hardmoneyloan.rehabloan ? 'Yes' : 'No'}</div>
                                    </div>
                                </div>
                                <div className='general-row'>
                                    <div>
                                        <div>Primary Borrower</div>
                                        <div style={{color: '#326EEB', fontWeight: 600}}>{props.borrower.fullname}</div>
                                    </div>
                                    <div>
                                        <div>Co-Borrower</div>
                                        <div>{props.application.coborrower.firstname !== "" ? `${props.application.coborrower.firstname} ${props.application.coborrower.lastname}` : '-'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='team'>
                            <div className='module-title'>Yeelee Capital Team</div>
                            <div className='role-list'>
                                {
                                    teamList.map((item, index) => (
                                        <div className='role-list-item'>
                                            <div className='role-avatar'><img src='/images/default-avatar.svg' /></div>
                                            <div><div className='role'>{item.role}</div><div className='name'>{item.name !== ' ' ? item.name : 'Unassigned'}</div></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </Col>
                    <Col xl={10} span={24}>
                        <div className='loan-terms'>
                            <div className='confirm-loan-title'><span>Loan Terms</span><span className='status'>Confirmed</span></div>
                            <div className='loan-terms-card'>
                                <div className='loan-terms-item top'>
                                    <div>
                                        <span>Loan Amount</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        prefix="$"
                                                        defaultValue={getLoanAmount(props)}
                                                        min={0}
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , getLoanAmount(props), 'money')
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <span>As-Is-Value</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        prefix="$"
                                                        defaultValue={getAsIsValue()}
                                                        min={0}
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , getAsIsValue(), 'money')
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <span>Loan-to-Value</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        defaultValue={getLTV(props)}
                                                        min={0}
                                                        max={100}
                                                        formatter={(value) => `${value}%`}
                                                        parser={(value) => value.replace('%', '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , getLTV(props), 'percentage')
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='loan-terms-item middle'>
                                    <div>
                                        <span>Interest Rate</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        defaultValue={rateResult.floorRate}
                                                        min={0}
                                                        max={100}
                                                        formatter={(value) => `${value}%`}
                                                        parser={(value) => value.replace('%', '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , rateResult.floorRate, 'percentage')
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <span>Monthly Payment</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        prefix="$"
                                                        defaultValue={com.commaize(rateResult.monthlyPayment)}
                                                        min={0}
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , com.commaize(rateResult.monthlyPayment), 'money')
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <span>Loan Term</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <Select
                                                        defaultValue={props.hardmoney.mortgagetermmonths}
                                                        allowClear
                                                        onChange={e => console.log(e)}
                                                        options={loanTermOptions}
                                                        size="small"
                                                        style={{width: '90px'}}
                                                    />
                                                , props.hardmoney.mortgagetermmonths, 'months')
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='loan-terms-item bottom'>
                                    <div>
                                        <span>Processing Fee</span>
                                        <div>
                                            {
                                                formItemMode(
                                                    <InputNumber
                                                        prefix="$"
                                                        defaultValue={500}
                                                        min={0}
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                                                        onChange={e => console.log(e)}
                                                        size="small"
                                                    />
                                                , 500, 'money')
                                            }
                                        </div>
                                    </div>
                                    <div><span>Origination Point</span><div><span>${getOriginationPoint(props)}</span><span className='explain'>(1%Loan Amount)</span></div></div>
                                </div>
                            </div>
                            <div className='loan-terms-mode'><Switch checked={editMode} onChange={modeChange} /><span style={{marginLeft: 10}}>Edit mode</span></div>
                        </div>
                    </Col>
                </Row>
                {contextHolder}
                {/* <Modal centered title="Contact my loan officer" 
                    open={showContact}  
                    onCancel={()=>setShowContact(false)}
                    footer={[
                        <Button key="back" onClick={()=>setShowContact(false)}>
                        Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={sendMail}>
                        Send
                        </Button>,
                    ]} >
                <div>
                    <TextArea rows={4} placeholder="Leave your message here" onChange={e=>setContactMessage(e.target.value)}/>
                </div>
                </Modal> */}
                {/* <div className='over-view-content'>
                    <div className='application-status'>
                        <div className='status-title' onClick={()=>setShowContact(true)}>Application Status </div>
                        <div className='status-list'>
                            {
                                statusList.map((status, statusIndex) => 
                                    <div className='status-item' key={statusIndex}> 
                                        <div style={{color: props.step > statusIndex ? '#1F3988' : '#8895BC'}}>{status.name}</div>
                                        {
                                            status.operate.length > 0 && (
                                                <div className='operate' style={{color: props.step > statusIndex ? '#0F62FE' : ''}}>
                                                    {
                                                        status.operate.map((o, index) => o)
                                                    }
                                                </div>
                                            )
                                        }
                                        <div className='status-tag' style={{backgroundColor: props.step > statusIndex ? '#0F62FE' : '#DDE1E6'}}></div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className='my-loan-terms'>
                        <div className='loan-terms-title'><span>My Loan Terms</span><span>Estimated {props.hardmoney.mortgagetermmonths} months</span></div>
                        <div className='loan-terms'>
                            <div className='loan-terms-item'><span>Loan Amount</span><span>{getLoanAmount(props)}</span></div>
                            <div className='loan-terms-item'><span>As-Is-Value</span><span>{getAsIsValue()}</span></div>
                            <div className='loan-terms-item'><span>Loan-to-Value</span><span>{getLTV(props)}</span></div>
                        </div>
                        <div className='loan-terms'>
                            <div className='loan-terms-item'><span>Estimated Interest Rate</span><span>{rateResult.floorRate}%</span></div>
                            <div className='loan-terms-item'><span>Estimated Monthly Payment</span><span>${com.commaize(rateResult.monthlyPayment)}</span></div>
                            <div className='loan-terms-item'><span>Estimated Closing Costs</span><span>${com.commaize(rateResult.closingCost)}</span></div>
                        </div>
                        <div className='loan-terms'>
                            <div className='loan-terms-item'><span>Target Closing Date</span><span>{props.hardmoney.targetclosingdate!=="" ? props.hardmoney.targetclosingdate : "-"}</span></div>

                        </div>
                    </div>
                </div> */}
            </Spin>
        </div>
    )
}

Overview = connect(mapStateToProps, mapDispatchToProps)(Overview);
