import React, { useState, useEffect } from 'react';
import './App.css';
import './Admin.css';
import Error from "./Error"
import AuthenticatedApp from "./AuthenticatedApp"
import LoginPage from "./LoginPage"
import Signup from './signup'
import NewAccount from './newAccount'
import SignUpPage from './SignUpPage'
import LogoutPage from './LogoutPage'
import Customer from './Customer'
import LandingPage from './LandingPage/LandingPage';
import NewLandingPage from './LandingPage/NewLandingPage';
import LandingPreview from './LandingPage/LandingPreview';
import Loading from './Loading';
import BorrowerSignUpPage from "./BorrowerSignUpPage";
import BorrowerLoginPage from "./BorrowerLoginPage";
import BrandNewLandingPage from './LandingPage/BrandNewLandingPage/index'
import Validation from "./Validation";
import BorrowerForgotPasswordPage from "./BorrowerForgotPasswordPage";
// import './fonts/gilroy-regular-3-webfont.woff2'
// import './fonts/gilroy-medium-2-webfont.woff2'
// import './fonts/gilroy-bold-4-webfont.woff2'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Helmet } from 'react-helmet'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { insertCssColors } from './Common';
import { Skeleton, Spin } from 'antd';
import ShareChat from './MortgageGPT/ShareChat';
import CalcGoogain from './CalcGoogain';
import CelebrityQualifier, { GMCCForeigners, GMCCSpecials, GMCCUniversePage, UniverseQualifier } from './GMCCCalculator/GMCCCalculator';
import { GMCCDisclaimer } from './GMCCCalculator/Disclaimer';
import ActivateLOAccount from './ActivateLOAccount';
import { useSelector, useDispatch } from 'react-redux'
let stylesNotApplied = true;

if (stylesNotApplied) {
  const customerID = localStorage.getItem('customerid');
  insertCssColors(customerID)
  stylesNotApplied = false;
}

function App() {
  const [loaded, setLoaded] = useState(false);
  const [PageLoaded, setPageLoaded] = useState(false);
  const [landingPages, setLandingPages] = useState([]);
  const [landingUrls, setLandingUrls] = useState([]);
  const dispatch = useDispatch()
  const subscription = useSelector(state => {

    return state.subscriptionReducer.currentInfo
  })
  const getInfo = async () => {
  
    if (subscription.loading&&subscription.loading!==undefined) return
    setPageLoaded(true)
    let token = sessionStorage.getItem("ZeitroA")

    const response = await fetch('/los/getcurrentsubscription', {
      method: 'get',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    })
    const DataInfo = await response.json()
    dispatch({ type: "SUBSCRIPTION_INFO", data: { ...DataInfo, loading: true } })
    setPageLoaded(false)
    // sessionStorage.setItem("subscription", JSON.stringify(DataInfo))
  }
  const generateRealEstateLanding = () => {
    setLoaded(false);
    fetch("/los/getlandingurls", {
      method: "GET",
      headers: {
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          setLoaded(true);
          return;
        }
        response.json().then((js) => {
          setLandingUrls(js)
          let tmp = [];
          for (let j of js) {
            if (j.CustomerID !== "gmcc" && (JSON.parse(j.ToolstoDisplay).includes("celebrity15K") || JSON.parse(j.ToolstoDisplay).includes("celebrityNoratio"))) {
              j.ToolstoDisplay = JSON.stringify(JSON.parse(j.ToolstoDisplay).filter(tool => tool !== "celebrity15K" && tool !== "celebrityNoratio"))
            }
            // if (j.Domain !== "") {
            //   tmp.push(
            //     <Route exact path={"/"}>
            //       {j.SiteVersion=== 2 ? <LandingPage customerid={j.CustomerID} loid={j.LoID}  url={j.Url} /> : <NewLandingPage customerid={j.CustomerID} loid={j.LoID}  url={j.Url} appurl={j.AppUrl} toolstoDisplay={j.ToolstoDisplay} />}
            //     </Route>
            //   );
            // }

            tmp.push(
              <Route exact path={j.Url}>
                {/* {j.SiteVersion=== 2 ? <LandingPage customerid={j.CustomerID} loid={j.LoID}  url={j.Url} /> : <NewLandingPage customerid={j.CustomerID} loid={j.LoID}  url={j.Url} appurl={j.AppUrl} toolstoDisplay={j.ToolstoDisplay} />} */}
                <BrandNewLandingPage customerid={j.CustomerID} loid={j.LoID} url={j.Url} appurl={j.AppUrl} toolstoDisplay={j.ToolstoDisplay} />

              </Route>
            );
            tmp.push(
              <Route exact path={j.Url + "/services/signin"}>
                <BorrowerLoginPage appUrl={j.AppUrl} customerid={j.CustomerID} loid={j.LoID} url={j.Url} />
              </Route>
            );
            tmp.push(
              <Route exact path={j.Url + "/services/signup/"}>
                <BorrowerSignUpPage view="signup" customerid={j.CustomerID} loid={j.LoID} url={j.Url} />
              </Route>
            );
            if (j.Url.includes("/gmcc/")) {
              tmp.push(
                <Route exact path={j.Url + "/special"}>
                  <GMCCSpecials fromLanding={false} view="signup" customerid={j.CustomerID} loid={j.LoID} url={j.Url} />
                </Route>
              )
              tmp.push(
                <Route exact path={j.Url + "/ForeignNationals"}>
                  <GMCCForeigners fromLanding={false} view='signup' customerid={j.CustomerID} loid={j.LoID} url={j.Url} />
                </Route>
              )
            }
            tmp.push(
              <Route exact path="/services/forgotpassword">
                <BorrowerForgotPasswordPage appUrl={j.AppUrl} customerid={j.CustomerID} />
              </Route>
            );
            // tmp.push(
            //   <Route  exact path="/services/resetpassword">
            //     <BorrowerResetPasswordPage appUrl={j.AppUrl} customerid={j.CustomerID} />
            //   </Route>
            // );
            // tmp.push(
            //   <Route exact path="/handoff">
            //     <BorrowerHandoffPage url={url} />
            //   </Route>
            // );
            tmp.push(
              <Route exact path="/services/new-landing-page">
                <NewLandingPage customerid={j.CustomerID} loid={j.LoID} />
              </Route>
            );
            tmp.push(
              <Route exact path="/services/landing-review">
                <LandingPreview customerid={j.CustomerID} loid={j.LoID} />
              </Route>
            );
          }
          // tmp.push(
          //   <Route  exact path="/validated/:loid/:borrowerid">
          //     <Validate />
          //   </Route>
          // );

          setLandingPages(tmp);
          setLoaded(true);
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :", err);
        setLoaded(true);
      });
  };

  useEffect(() => {
    let token = sessionStorage.getItem("ZeitroA")
    if (token) {
      getInfo()

    }
    generateRealEstateLanding();
  }, []);

  const DefaultRoute = () => {
    if (!loaded) {
      return <Route>
        <div className='p-5'>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      </Route>
    }

    let domainOccupied = false
    let cID, lID, url, appUrl, toolstoDisplay
    for (let j of landingUrls) {
      if (j.Domain !== "") {
        if (window.location.host.includes(j.Domain)) {
          domainOccupied = true
          cID = j.CustomerID
          lID = j.LoID
          url = j.Url
          appUrl = j.AppUrl
          toolstoDisplay = j.ToolstoDisplay
        }
      }
    }
    if (window.location.host.includes("special.gmccloan.com")) {
      return (
        <Route exact path="/">
          <GMCCSpecials view="signup" customerid="gmcc" loid="gmcc" url="www.gmccloan.com" />
        </Route>
      )
    }
    if (window.location.host.includes("15kgrant.gmccloan.com")) {
      return (
        <Route exact path="/">
          <div>
            <div className="title-2 text-center mt-3 mb-2">GMCC - Celebrity</div>
            <div className="text-center mb-1">Designed to support underserved borrowers and Communities</div>
            <CelebrityQualifier customerid="gmcc" loid="gmcc" url="www.gmccloan.com"
              loEmail="info@gmccloan.com" loPhone="6509961982" loName="" loNMLS="254895" />
            <div className='p-5'><GMCCDisclaimer /></div>
          </div>
        </Route>
      )
    }
    // foreigner.gmccloan.com
    if (window.location.host.includes("foreigner.gmccloan.com")) {
      return (
        <Route exact path="/">
          <div>
            <div className="title-2 text-center mt-4 mb-2">GMCC - Foreign Nationals</div>
            <div className="text-center mb-1">Designed to support borrowers who are foreign nationals</div>
            <UniverseQualifier customerid="gmcc" loid="gmcc" type="foreigners" url="www.gmccloan.com"
              loEmail="info@gmccloan.com" loPhone="6509961982" loName="" loNMLS="254895" />
            <div className='p-5'><GMCCDisclaimer /></div>
          </div>
        </Route>
      )
    }
    if (window.location.host.includes("universe.gmccloan.com")) {
      return (
        <Route exact path="/">
          <div>
            <GMCCUniversePage customerid="gmcc" loid="gmcc" url="www.gmccloan.com"
              loEmail="info@gmccloan.com" loPhone="6509961982" loName="" loNMLS="254895" />
          </div>
        </Route>
      )
    }

    if (!domainOccupied) {
      return (
        <Route exact path="/">
          <LoginPage />
        </Route>
      );
    } else {
      return <Route exact path="/">
        <BrandNewLandingPage customerid={cID} loid={lID} url={url} appurl={appUrl} toolstoDisplay={toolstoDisplay} />
      </Route>
    }
  };

  return (
    <Spin spinning={PageLoaded}>
      <Router >
        <div className="App py-0 my-0">

          <Helmet>
            <title></title>
          </Helmet>

          <Switch>
            {DefaultRoute()}
            <Route exact path="/newloacount/:hex?">
              <ActivateLOAccount />
            </Route>
            <Route exact path="/login/:customerid?">
              <LoginPage />
            </Route>
            <Route exact path="/testcalgoogain">
              <CalcGoogain />
            </Route>
            <Route exact path="/signup/:customerid">
              <SignUpPage />
            </Route>
            <Route path="/app">
              <AuthenticatedApp />
            </Route>
            <Route path="/signup">
              <Signup />

            </Route>
            <Route path="/newAccount">
              <NewAccount></NewAccount>

            </Route>
            <Route exact path="/services/logout">
              <LogoutPage />
            </Route>
            <Route exact path="/mortgagegpt/sharechat/:threadid">
              <Helmet>
                <title>GuidelineGPT - Share chat</title>
              </Helmet>
              <ShareChat />
            </Route>
            {loaded ? (
              landingPages
            ) : (
              <Route>
                <Loading />
              </Route>
            )}
            <Route exact path="/services/validate">
              <Validation />
            </Route>
            <Route >
              <Error code={404} />
            </Route>
          </Switch>

        </div>
      </Router>
    </Spin>
  );
}

export default App;


