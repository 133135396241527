import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Button, Container } from "react-bootstrap";
import { Col, Row, Tabs, Drawer, QRCode, Skeleton, Collapse, message } from 'antd';
import BasicInfo from "./components/BasicInfo";
import AboutMe from "./components/AboutMe";
import Company from './components/Company'
import Mobile from "./components/mobile";
import CustomerReview from "../CustomerReview"
import Tools, { LandingAffordability, LandingRateQuoter } from "../Tools";
import CallBack from "../CallBack";
import Helmet from 'react-helmet'
import "./index.css";
import { GMCCDisclaimer } from "../../GMCCCalculator/Disclaimer";
import { GMCCSpecials as GMCC } from "../../GMCCCalculator/GMCCCalculator";
import { withRouter } from "react-router-dom";
import Programs from './components/programs'
import Faq from "./components/Faq";
import Request from "./components/request";
import { BrowserRouter as Router } from "react-router-dom";
import { checkAccess } from "../../Auth";
import { UR_Owner } from "../../State";
import smartlookClient from "smartlook-client";

const backgroundColor = "#F7F8FC";
const rateQuoteDisabledLoIDs=["16c7cbfe-fe33-405f-9600-ad7308c52f49","0c80c06c-1922-4c15-bd74-f6ff57e724bb"]
const rateQuoteDisabledCompanyIDs = ["gmcc", "changehomemortgage"]
const LandingPage = (props) => {
  return <LoLandingPage {...props} />;
};

const LoLandingPage = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nmls, setNMLS] = useState("");
  const [phone, setPhone] = useState("");
  const [basicInfo, setBasicInfo] = useState({});
  const [aboutInfo, setAboutInfo] = useState({});
  const [reviewList, setReviewList] = useState([]);
  const [loading, setLoading] = useState(true)
  const [showContactmeModal, setShowContactmeModal] = useState(false);
  const [companyLogoUrl, setCompanyLogoUrl] = useState("")
  const refAffordability = useRef(null);
  const refRateQuoter = useRef(null);
  const refCalculators = useRef(null);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const isOwner = checkAccess([UR_Owner]);
  const [showFAQ, setShowFAQ] = useState(true)
  const [showTestimonial, setShowTestimonial] = useState(true)
  const [showCTA, setShowCTA] = useState(true)
  const [showAffordability, setShowAffordability] = useState(true)
  const [showMortgage, setShowMortgage] = useState(true)
  const [showCompanyInfo, setShowCompanyInfo] = useState(true)
  const handleClickCalculators = (tab) => {
    if (tab === "ratequoter") {
      if (refRateQuoter.current)
        setNavDrawer(false)
      refRateQuoter.current.scrollIntoView({ behavior: "smooth" });
    } else if (tab === "affordability") {
      if (refAffordability.current)
        setNavDrawer(false)
      refAffordability.current.scrollIntoView({ behavior: "smooth" });
    } else if (tab === "special") {
      if (refCalculators.current)
        setNavDrawer(false)
      refCalculators.current.scrollIntoView({ behavior: "smooth" });
    }
  }
  const [landingUrl, setLandingUrl] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [scale, setScale] = useState(0.8);
  const [pageWidth, setPageWidth] = useState(125);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    adjustZoom()
  };
  const adjustZoom = () => {
    const baseWidth = 1980
    const baseScale = 0.6
    const baseContainerWidth = 167
    const currentWidth = window.innerWidth
    const scaleRatio = currentWidth / baseWidth
    const newScale = baseScale * scaleRatio
    const newWidth = baseContainerWidth / scaleRatio
    setScale(newScale)
    setPageWidth(`${newWidth}%`)
  }
  useEffect(() => {
    getProfile()
    adjustZoom()
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);

  }, []);
  const initialPropsRef = useRef({ basicInfo: props.basicInfo, aboutInfo: props.aboutInfo });
  useEffect(() => {
    const { basicInfo, aboutInfo } = initialPropsRef.current;

    const shouldUpdateBasicInfo = JSON.stringify(basicInfo) !== JSON.stringify(props.basicInfo);
    const shouldUpdateAboutInfo = JSON.stringify(aboutInfo) !== JSON.stringify(props.aboutInfo);
    if (props.mode === 'preview') {
      if (shouldUpdateBasicInfo) {
        setBasicInfo(basicInfo => ({
          ...basicInfo,
          file: props.basicInfo.file.length > 0 ? [
            {
              url: props.basicInfo.file[0].url
            }
          ] : undefined,
          firstname: props.basicInfo.firstname,
          lastname: props.basicInfo.lastname,
          nmls: props.basicInfo.nmls,
          states: props.basicInfo.states,
          tagsList: props.basicInfo.tagsList,
          popularTags: ['I speak Mandarin', 'Top 10 Loan offier', "Bay Area for 10+ years"],
          google: props.basicInfo.google,
          yelp: props.basicInfo.yelp,
          linkedin: props.basicInfo.linkedin,
          twitter: props.basicInfo.twitter,
        }))
      }
      if (shouldUpdateAboutInfo) {
        setAboutInfo(aboutInfo => ({
          ...aboutInfo,
          introduction: props.aboutInfo.introduction,
          phone: props.aboutInfo.phone,
          email: props.aboutInfo.email,
          companyWebsite: props.aboutInfo.companyWebsite,
          tagline: props.aboutInfo.tagline,
  
          companyIntroduction: props.aboutInfo.companyIntroduction,
          companyLink: props.aboutInfo.companyLink,
          banner: props.aboutInfo.banner,
        }))
      }

      if (shouldUpdateBasicInfo || shouldUpdateAboutInfo) {
        initialPropsRef.current = { basicInfo: props.basicInfo, aboutInfo: props.aboutInfo };
      }
    }
  }, [props.basicInfo, props.aboutInfo]);
  useEffect(() => {
    if (props.mode === 'preview') {
      setShowFAQ(getModuleStatus(4, 'FAQs'))
      setShowTestimonial(getModuleStatus(4, 'testimonial'))
      setShowCTA(getModuleStatus(4, 'CTA'))
      setShowAffordability(getModuleStatus(3, 'affordability'))
      setShowMortgage(getModuleStatus(3, 'mortgage'))
    }
  }, [props.moduleManage])
  useEffect(() => {
    if (props.mode === 'preview') {
      setShowCompanyInfo(props.companyInfoDisable)
    }
  }, [props.companyInfoDisable])
  useEffect(() => {
    if (props.mode === 'preview') {
      console.log(props.reviewList)
      setReviewList(props.reviewList)
    }
  }, [props.reviewList])
  useEffect(() => {
    if (props.mode === 'preview') {
      setAboutInfo(aboutInfo => ({
        ...aboutInfo,
        paramsInfo: props.paramsInfo,
        faqInfo: props.faqInfo
      }))
    }
  }, [props.paramsInfo, props.faqInfo])
  
  useEffect(() => {
    if (props.sectionId) {
      const refs = {
        basic: section1Ref,
        company: section2Ref,
        widgets: section3Ref,
        website: section4Ref,
      }
      const targetRef = refs[props.sectionId]
      if (targetRef && targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [props.sectionId])
  const getModuleStatus = (num, key) => {
    return props.moduleManage.find(module => module.module === num).manage.find(item => item.key === key).status
  }
  const handleSubmit = (type) => {
    if (type === 'success') {
      setShowContactmeModal(false)
      message.success("Thanks for your submission. We will contact you shortly.")
    } else {
      message.error("Some internal error happened. Please try again later.")
    }
  }
  const getContactmeModal = () => {
    return (
      <Modal
        className="contactMeModal"
        size="lg"
        backdropClassName="contactMeModalBackdrop"
        dialogClassName="contactMeModalDialog"
        contentClassName="contactMeModalContent"
        show={showContactmeModal}
        onHide={() => setShowContactmeModal(false)}
      >
        <Modal.Dialog>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <Request withoutBanner={true} fromLanding={true} customerid={props.customerid} loid={props.loid} url={props.Url} appurl={props.appurl} basicInfo={basicInfo} aboutInfo={aboutInfo} handleSubmit={handleSubmit} />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    );
  };

  const [navDrawer, setNavDrawer] = useState(false);
  const showDrawer = () => {
    setNavDrawer(!navDrawer)
  }
  const onClose = () => {
    setNavDrawer(false)
  }
  const dynamicExternalLink = (href) => {
    if (!/^https?:\/\//i.test(href)) {
      return `https://${href}`
    }
    return href
  }
  const getPreQualificationLink = () => {
    if (props.customerid === "gmcc") {
      if(basicInfo.appLink !== ""){
        return basicInfo.appLink
      } else {
        return aboutInfo.companyLink
      }
    }
    if (basicInfo.appLink !== "") {
      return basicInfo.appLink
    }
    if (window.location.host.includes("zeitro.us")) {
      return props.appurl + "/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
    }
    if (window.location.host.includes("localhost")) {
      return props.appurl + "/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
    }
    return props.appurl + "/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
  }
  const getProfile = () => {
    setLoading(true);
    let token = "";
    fetch("/los/getprofilesetup", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-CustomerID": props.customerid,
        "X-LoID": props.loid,
      },
    }).then(response => {
      if (!response.ok) {
        console.log(response)
        setLoading(false);
      } else {
        response.json().then(js => {
          setCompanyLogoUrl(js.CompanyLogo)
          let lo = js
          let su = lo.LandingPageSetUp
          let name = lo.FirstName + " " + lo.LastName
          smartlookClient.init('09b3763f18ea03a3078dade87fcf17e136934224')
          smartlookClient.record({
            emails: true,
            forms: true,
            numbers: true,
            ips: true,
          })
          smartlookClient.identify(props.loid, {
            name: name,
            email: lo.Email
          })
          setShowAffordability(su.showAffordability !== undefined ? su.showAffordability : true)
          setShowMortgage(su.showMortgage !== undefined ? su.showMortgage : true)
          setBasicInfo({
            file: lo.Image === '' ? [] : [
              {
                url: lo.Image
              }
            ],
            firstname: lo.FirstName,
            lastname: lo.LastName,
            nmls: lo.NMLS,
            states: typeof su.States === "undefined" ? [] : su.States,
            tagsList: typeof su.TagsList === "undefined" ? [] : su.TagsList,
            popularTags: ['I speak Mandarin', 'Top 10 Loan offier', "Bay Area for 10+ years"],
            google: su.Google === undefined ? '' : su.Google,
            yelp: su.Yelp === undefined ? '' : su.Yelp,
            linkedin: su.Linkedin === undefined ? '' : su.Linkedin,
            twitter: su.Twitter === undefined ? "" : su.Twitter,
            appLink: su.appLink === undefined ? "" : su.appLink,
          })
          setName(name)
          setEmail(lo.Email)
          setPhone(lo.Phone)
          setNMLS(lo.NMLS)
          setAboutInfo({
            introduction: lo.Introduction,
            phone: lo.Phone,
            email: lo.Email,
            companyWebsite: su.CompanyWebsite === undefined ? '' : su.CompanyWebsite,
            tagline: su.tagline === undefined ? "" : su.tagline,

            companyIntroduction: su.companyIntroduction === undefined ? "" : su.companyIntroduction,
            companyLink: su.companyLink === undefined ? "" : su.companyLink,
            banner: su.banner === undefined ? "" : su.banner,
            paramsInfo: su.paramsInfo === undefined ? {
              title: '',
              paramsList: []
            } : su.paramsInfo,
            faqInfo: su.faqInfo === undefined ? [] : su.faqInfo
          })
          setReviewList(su.ReviewsList === undefined ? [] : su.ReviewsList)
          setLandingUrl(window.location.hostname.includes("localhost") ? "http://localhost:3001" + js.LandingUrl : "https://" + window.location.hostname + js.LandingUrl)
          setShowFAQ(su.showFAQ !== undefined ? su.showFAQ : true)
          setShowTestimonial(su.showTestimonial !== undefined ? su.showTestimonial : false)
          setShowCTA(su.showCTA !== undefined ? su.showCTA : true)
          setShowCompanyInfo(su.showCompanyInfo !== undefined ? su.showCompanyInfo : true)
          setLoading(false);

        })
      }
    }).catch(error => {
      console.log("Revalidate catch error " + error)
    })
  };
  if (loading) return <div className="p-4">
    <Skeleton active />
    <Skeleton active />
    <Skeleton active />
  </div>

    let avatarSrc = "/images/avatar.png"
  
    const displayLogo = () => {
      if (props.customerid === "star") return <img src='/images/StarFinancial_logo.png' style={{ height: 40, marginRight: 20 }} />
      if (props.customerid === "gmcc") return <img src='/images/gmccLogo.png' style={{ height: 40, marginRight: 20 }} />
      if (props.customerid === "siliconcitymortgage") return <img src='/images/scmLogo.png' style={{ height: 70, marginRight: 20 }} />
      if (props.customerid === "zeitro") return <img src='/bigLogo.png' className="landing-page-logo" />


      return <img src={companyLogoUrl ? companyLogoUrl : "/images/smalllogo.svg" } style={{maxHeight: 40, marginRight: 20}}/>
    }
  
  return (
    <div style={props.mode === 'preview' ? { transform: `scale(${scale})`, width: pageWidth, height: 0, transformOrigin: '0 0' } : {}}>
      <Helmet>
        <title>{name}'s Loan Services </title>
        <link rel="icon" href={typeof basicInfo.file !== "undefined" && basicInfo.file.length > 0 ? basicInfo.file[0].url : avatarSrc} />
      </Helmet>
      <div className="landing-nav">
        <div className={props.customerid==="gmcc"? "menu-left-gmcc": "menu-left"}>

          {displayLogo()}
          {!rateQuoteDisabledCompanyIDs.includes(props.customerid) && showMortgage && <span onClick={() => handleClickCalculators('ratequoter')}>Rate Quote</span>}
          {showAffordability && <span onClick={() => handleClickCalculators('affordability')}>Affordability</span>}
          <span onClick={()=> window.open(getPreQualificationLink(),"_blank")}>Get Prequalified</span>
          {props.customerid === "gmcc" && <span onClick={() => window.location.href = landingUrl + "/special"}>Special Programs</span>}
          {props.customerid === "gmcc" && <span onClick={() => window.location.href = landingUrl + "/ForeignNationals"}>Foreign Nationals</span>}
          {props.customerid === "gmcc" && <span onClick={() => window.open(`https://app.gmccloan.com/resources/calhfa/gmcc?loid=${props.loid}`,"_blank")}>CalHFA (Dream For All)</span>}
          {props.customerid === "siliconcitymortgage" && <span onClick={() => window.open(`https://app.zeitro.com/resources/calhfa/siliconcitymortgage?loid=${props.loid}`,"_blank")}>CalHFA (Dream For All)</span>}

        </div>
        <div className={props.customerid==="gmcc" ? "about-navList-gmcc" :"about-navList"}>
  
          <Button className='contact-btn apply-now-btn' onClick={() => window.open(getPreQualificationLink(),"_blank")}>Apply now</Button>
          {/* <Button className='contact-btn about-apply-now-btn apply-now-btn' onClick={() => setShowContactmeModal(true)}>Contact today</Button> */}
        </div>
        <img className={props.customerid==="gmcc"? "landingMeneButton-gmcc" : "landingMeneButton"} src='/images/menu.svg' onClick={showDrawer} />
      </div>
      <div>
        <div>
          <div className={showCompanyInfo ?"introductionWraper normal": "introductionWithoutCompanyWraper normal"}>
            {/* <Row gutter={20} className="d-flex">

              <Col className="gutter-row" span={24} lg={12}>
                <BasicInfo aboutInfo={aboutInfo} appurl={props.appurl} customerid={props.customerid} loid={props.loid} basicInfo={basicInfo} contact={() => setShowContactmeModal(true)} />
              </Col>

              <Col className="gutter-row" span={24} lg={12}>
                <AboutMe handleClickCalculators={handleClickCalculators} basicInfo={basicInfo} aboutInfo={aboutInfo} customerid={props.customerid} loid={props.loid} appurl={props.appurl} contact={() => setShowContactmeModal(true)} />
              </Col>
             
            </Row> */}
            {/* <img className="backgroundImage" src="/company.png" /> */}
            <div className="introContainer" style={{backgroundColor:"#fff"}}>
              <div className="basicInfoWraper" id="basic" ref={section1Ref}>
                <div className="basicInfoContainer">
                  <div className="basicInfoDiv">
                    <BasicInfo aboutInfo={aboutInfo} appurl={props.appurl} customerid={props.customerid} loid={props.loid} basicInfo={basicInfo} contact={() => setShowContactmeModal(true)} />
                  </div>
                  <div className="aboutInfoDiv">
                    <AboutMe handleClickCalculators={handleClickCalculators} basicInfo={basicInfo} aboutInfo={aboutInfo} customerid={props.customerid} loid={props.loid} appurl={props.appurl} contact={() => setShowContactmeModal(true)} />
                  </div>
                </div>
              </div>
              <div id="company" ref={section2Ref}>
                  {showCompanyInfo && <div className="companyWraper">
                    <Company aboutInfo={aboutInfo}></Company>
                    <div className="company-bg-img">
                      <img src='/circleHouse.svg'/>
                    </div>
                  </div>}
              </div>
            </div>

          </div>
          <div className="landingProfile new_mobile">
            <Row gutter={20}>



              <Col className="mobile_row" span={24} lg={14}>
                {/* <AboutMe basicInfo={basicInfo} aboutInfo={aboutInfo} customerid={props.customerid} loid={props.loid} appurl={props.appurl} contact={() => setShowContactmeModal(true)} /> */}
                <Mobile showCompanyInfo={showCompanyInfo} basicInfo={basicInfo} aboutInfo={aboutInfo} customerid={props.customerid} loid={props.loid} appurl={props.appurl} contact={() => setShowContactmeModal(true)} />
              </Col>
            </Row>

          </div>
        </div>
      </div>
      {/* <Company aboutInfo={aboutInfo}></Company> */}
      <div id="widgets" ref={section3Ref}>
        {!rateQuoteDisabledCompanyIDs.includes(props.customerid) && showMortgage && <div
          style={{ backgroundColor: backgroundColor }}
          className="landingRateQuoter"
          id="landingRateQuoter"
          ref={refRateQuoter}
        >
          <LandingRateQuoter isLandingPage customerid={props.customerid} id={props.loid} width={width} height={height} appLink={basicInfo.appLink} companyLink={aboutInfo.companyLink} {...props} />
        </div>}
        {showAffordability && <div
          style={{ backgroundColor: "white" }}
          className="landingTools pb-2"
          id="landingTools"
          ref={refAffordability}
        >
          <LandingAffordability isLandingPage customerid={props.customerid} loid={props.loid} width={width} height={height} appLink={basicInfo.appLink} companyLink={aboutInfo.companyLink}/>
        </div>}
      </div>
      {props.customerid==="gmcc" && <div ref={refCalculators} className="calculators gmccspecials">
        {/* <div className="calculators-title">Special Program Qualifiers</div> */}
        {/* <GMCCSpecials fromLanding={true} customerid={props.customerid} loid={props.loid} url={props.Url} basicInfo={basicInfo} aboutInfo={aboutInfo} /> */}
        <GMCC fromLanding={true} customerid={props.customerid} loid={props.loid} url={props.Url} basicInfo={basicInfo} aboutInfo={aboutInfo} />
      </div>}
      <div id="website" ref={section4Ref}>
        {showFAQ && <Faq faqInfo={aboutInfo.faqInfo}></Faq>}
        {showTestimonial && <CustomerReview reviewList={reviewList} />}
        {showCTA && <Programs paramsInfo={aboutInfo.paramsInfo} customerid={props.customerid} loid={props.loid} appurl={props.appurl} appLink={basicInfo.appLink} companyLink={aboutInfo.companyLink}></Programs>}
      </div>
      <Request fromLanding={true} customerid={props.customerid} loid={props.loid} url={props.Url} appurl={props.appurl} basicInfo={basicInfo} aboutInfo={aboutInfo} />

      <div className="landingFooter">
        <Row>
          <Col span={12} lg={4} className="sitemap">
            <div className="rowTitle">Sitemap</div>
            <div className="rowContent">
              <div>Home</div>
              {!rateQuoteDisabledCompanyIDs.includes(props.customerid) && <div onClick={() => handleClickCalculators('ratequoter')}>Check Current Rate</div>}
              <div onClick={() => handleClickCalculators('affordability')}>Affordability</div>
              <div onClick={()=> window.open(getPreQualificationLink(),"_blank")}>Get Prequalified</div>
              {props.customerid === "gmcc" && <div onClick={() => window.location.href = landingUrl + "/special"}>Special Programs</div>}
              {props.customerid === "gmcc" && <div onClick={() => window.location.href = landingUrl + "/ForeignNationals"}>Foreign Nationals</div>}
              {props.customerid === "gmcc" && <div onClick={() => window.open(`https://app.gmccloan.com/resources/calhfa/gmcc?loid=${props.loid}`,"_blank") }>CalHFA</div>}
              {props.customerid === "siliconcitymortgage" && <div onClick={() => window.open(`https://app.zeitro.com/resources/calhfa/siliconcitymortgage?loid=${props.loid}`,"_blank") }>CalHFA</div>}
              <div onClick={() => window.open(getPreQualificationLink(),"_blank")}>Apply now</div>
            </div>
          </Col>
          <Col span={12} lg={4} className="contact">
            <div className="rowTitle">Contact</div>
            <div className="rowContent">
              <div>{aboutInfo.phone}</div>
              <div>{aboutInfo.email}</div>
              {props.customerid === "gmcc" && <div>1350 Old Bayshore Hwy #740, Burlingame, CA 94010, United States.</div>}
            </div>
          </Col>
          <Col span={24} lg={6} className="socialMedia">
            <div className="rowTitle">Social media</div>
            <div className="rowContent">
              {basicInfo.google && <img src='/images/google.svg' alt='google' onClick={() => window.location.href = dynamicExternalLink(basicInfo.google)} />}
              {basicInfo.linkedin && <img src='/images/linkedln.svg' alt='linkedln' onClick={() => window.location.href = dynamicExternalLink(basicInfo.linkedin)} />}
              {basicInfo.twitter && <img src='/images/twitter.svg' alt='twitter' onClick={() => window.location.href = dynamicExternalLink(basicInfo.twitter)} />}
              {basicInfo.yelp && <img src='/images/yelp.svg' alt='yelp' onClick={() => window.location.href = dynamicExternalLink(basicInfo.yelp)} />}
            </div>
          </Col>
          <Col span={24} lg={6} className="security">
            <div className="rowTitle">Security</div>
            <div className="rowContent">
              The Security of your personal information is our highest priority. We use bank-level encryption (256-bit AEE) to ensure it is never compromised.
            </div>
          </Col>
          <Col span={24} lg={4} className="QRCode">
            <div>
              <div className="rowTitle" style={{ marginBottom: '4px' }}>Scan my QR code</div>
              <div className="rowContent">
                <QRCode
                  className="landing-qrcode"
                  errorLevel="H"
                  value={landingUrl}
                  size={110}
                  bordered={false}
                />
              </div>
            </div>
          </Col>
        </Row>
        <hr />

        {props.customerid !== "gmcc" ? (
          <div className="d-flex" style={{ flexDirection: "row", fontSize: "10px" }}>
            <img className="equal_housing_oppotunity" src='/images/equal_housing_oppotunity.png' style={{ marginRight: "10px", width: "10%", heigh: "10%" }} />
            <div className="CollapseMobile">
              <Collapse
                bordered={false}
                expandIconPosition='end'
                items={[
                  {

                    key: '1',
                    label: 'Equal House OPPORTUNITY',
                    children: <p >              Interest rates and annual percentage rates (APRs) are based on current market rates, are for informational purposes only, are subject to change without notice and may be subject to pricing add-ons related to property type, loan amount, loan-to-value, credit score and other variables—call for details. This is not a credit decision or a commitment to lend. Depending on loan guidelines, mortgage insurance may be required. If mortgage insurance is required, the mortgage insurance premium could increase the APR and the monthly mortgage payment. Additional loan programs may be available. APR reflects the effective cost of your loan on a yearly basis, taking into account such items as interest, most closing costs, discount points (also referred to as “points”) and loan-origination fees. One point is 1% of the mortgage amount (e.g., $1,000 on a $100,000 loan). Your monthly payment is not based on APR, but instead on the interest rate on your note. Adjustable-rate mortgage (ARM) rates assume no increase in the financial index after the initial fixed period. ARM rates and monthly payments are subject to increase after the fixed period: ARMs assume 30-year term.
                    </p>
                  }

                ]}
              />
            </div>
            <div className="Collapse">
              Interest rates and annual percentage rates (APRs) are based on current market rates, are for informational purposes only, are subject to change without notice and may be subject to pricing add-ons related to property type, loan amount, loan-to-value, credit score and other variables—call for details. This is not a credit decision or a commitment to lend. Depending on loan guidelines, mortgage insurance may be required. If mortgage insurance is required, the mortgage insurance premium could increase the APR and the monthly mortgage payment. Additional loan programs may be available. APR reflects the effective cost of your loan on a yearly basis, taking into account such items as interest, most closing costs, discount points (also referred to as “points”) and loan-origination fees. One point is 1% of the mortgage amount (e.g., $1,000 on a $100,000 loan). Your monthly payment is not based on APR, but instead on the interest rate on your note. Adjustable-rate mortgage (ARM) rates assume no increase in the financial index after the initial fixed period. ARM rates and monthly payments are subject to increase after the fixed period: ARMs assume 30-year term.

            </div>
          </div>
        ) : (
          <GMCCDisclaimer />
        )}
        {/* <Row>
          <Col span={24} className="copyright">Powered by Zeitro, Inc. All Rights Reserved {new Date().getFullYear()}.</Col>
        </Row> */}
      </div>
      {showContactmeModal && getContactmeModal()}
      <Drawer className="drawer-nav" style={{height:props.customerid==="gmcc"?"440px":""}} placement="top" onClose={onClose} open={navDrawer}>
        {!rateQuoteDisabledCompanyIDs.includes(props.customerid) && showMortgage && <div className="nav" onClick={() => handleClickCalculators('ratequoter')}>Rate Quote</div>}
        {showAffordability && <div className="nav" onClick={() => handleClickCalculators('affordability')}>Affordability</div>}
        <div className="nav" onClick={()=> window.open(getPreQualificationLink(),"_blank")}>Get Prequalified</div>
        {props.customerid === "gmcc" && <div className="nav" onClick={() => window.location.href = landingUrl + "/special"}>Special Programs</div>}
        {props.customerid === "gmcc" && <div className="nav" onClick={() => window.location.href = landingUrl + "/ForeignNationals"}>Foreign Nationals</div>}
        {props.customerid === "gmcc" && <div className="nav" onClick={() => window.open(`https://app.gmccloan.com/resources/calhfa/gmcc?loid=${props.loid}`,"_blank")}>CalHFA (Dream For All)</div>}
        {props.customerid === "siliconcitymortgage" && <div onClick={() => window.open(`https://app.zeitro.com/resources/calhfa/siliconcitymortgage?loid=${props.loid}`,"_blank") }>CalHFA (Dream For All)</div>}
        <div className="d-flex justify-content-center">
          <div style={{ textAlign: 'center', marginRight:"10px" }}><Button style={{width:"140px"}} className='contact-btn' onClick={() => window.open(getPreQualificationLink(),"_blank")}>Apply now</Button></div>
          <div style={{ textAlign: 'center' }}><Button style={{width:"140px"}} className='contact-btn' onClick={() => setShowContactmeModal(true)}>Contact today</Button></div>
        </div>
      </Drawer>
    </div>
  );
};

export default withRouter(LandingPage);
