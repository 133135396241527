import React, { useState, useEffect, useRef } from 'react'
import { useImmer } from "use-immer"
import { Dropdown, Radio, Tabs, Table, Input, Alert, Button, Menu, Col, Row, Modal, Space, Checkbox, Popover, Card, Switch, Select, Tooltip } from 'antd'
import './CreditReport.css'


import { DownOutlined, UpOutlined, ExclamationCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import FullHeightModal from "./Common/components/FullHeightModal"
import { useSelector, useDispatch } from 'react-redux'
import { checkAccess } from "./Auth"
import { UR_Owner } from './State'
const { TabPane } = Tabs


export default function NewCreditReport() {
    const application = useSelector(state => {

        return state.application
    })
    console.log(application)
    const urlParams = window.location.pathname.replace('/app/borrower/', '')

    const [user, setUser] = useImmer({
        borrower: {},
        coborrower: {},
        hascoborrower: ''
    })
    const [CheckLoading, setCheckLoading] = useImmer(false)
    const [creditLiability, setCreditLiability] = useImmer([])
    const [creditLoanType, setCreditLoanType] = useImmer([])
    const [accountStatus, setAccountStatus] = useImmer([])
    const [currentDataSource, setCurrentDataSource] = useImmer([])
    const [currentTable, setCurrentTable] = useImmer(null)
    const [isSwitch, setIsSwitch] = useImmer(false)
    const [credit, setCredit] = useImmer('')
    const [status, setState] = useImmer('')
    const [pdfString, setPdfString] = useImmer('')
    const [summary, setSummary] = useImmer([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [parties, setParties] = useImmer([])
    const [isShowSummary, setIsShowSummary] = useImmer('CreditHistory')
    const [isChecked, setIsChecked] = useState(['borrower', 'coborrower'])
    const [accountData, setAccountData] = useState([])
    const partiesReplace = [
        { time: '', value: '', title: 'ExperianFairIsaac', factors: [] },
        { time: '', value: '', title: 'FICORiskScoreClassic04', factors: [] },
        { time: '', value: '', title: 'EquifaxBeacon5.0', factors: [] }
    ]
    const [checkButton, setCheckButton] = useState(true)
    const [errorData, setErrorData] = useImmer({
        error: false,
        content: ''
    })
    const [next, setNext] = useImmer(true)
    const [creditRadio, setCreditRadio] = useImmer('SoftPull')
    const [bureauRadio, setBureauRadio] = useImmer(['Experian'])
    const [creditReports, setCreditReports] = useState([

    ])

    const currentType = {
        SoftPull: "CustomerSoftCreditReport",
        HardPull: 'CustomerHardCreditReport'
    }
    const [currentReports, setCurrentReports] = useState('')
    const Bureau = [
        {
            value: "Experian",

        },
        {
            value: "Equifax",

        },
        {
            value: "TransUnion",

        }
    ]
    const getcreditaccountconfig = async () => {
        let token = sessionStorage.getItem("ZeitroA")
        const response = await fetch('/los/getcreditaccountconfig', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        })
        const js = await response.json()
        console.log(js.Accounts.length)
        // const isNotSet = js?.Accounts?.findIndex(x => x.IsCurrent) !== -1 && js?.AutomatedSoftCreditPull
        const isNotSet = js?.Accounts.length > 0 ? true : false
        console.log(isNotSet)
        setCheckButton(isNotSet)
    }
    useEffect(() => {
        console.log(application)
        getcreditaccountconfig()
        setUser({
            borrower: application.borrower,
            coborrower: application.coborrower,
            hascoborrower: application.hascoborrower
        })
        getXml()

    }, [])
    const handleMenuClick = (item) => {
        const getCurrentReports = creditReports.find((data) => data.key.toString() === item.key)

        setCurrentReports(getCurrentReports.label)
        setXml(getCurrentReports.CreditReportXML)

    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'Remaining Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (_, record) => (
                <div style={{ color: '#222' }}>${_}</div>
            ),
        },
        {
            title: 'Monthly Payment',
            dataIndex: 'payment',
            key: 'payment',
            render: (_, record) => (
                <div style={{ color: '#222' }}>${_}</div>
            ),
        },
        {
            title: 'Monthly left to pay',
            dataIndex: 'monthly',
            key: 'monthly',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'Opened date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => {
                if (a.date < b.date) {
                    return -1
                }
                if (a.date > b.date) {
                    return 1
                }
                return 0
            },
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'Credit loan type',
            dataIndex: 'credit',
            key: 'credit',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'Account Status',
            dataIndex: 'account',
            key: 'account',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
    ]
    const columnsSummary = [
        {
            title: 'Account Type',
            dataIndex: 'Type',
            key: 'Type',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },

        {
            title: 'Balance',
            dataIndex: 'Balance',
            key: 'Balance',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'Count',
            dataIndex: 'Count',
            key: 'Count',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'Payment',
            dataIndex: 'Payment',
            key: 'Payment',

            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'PastDue',
            dataIndex: 'PastDue',
            key: 'PastDue',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
        {
            title: 'HighCredit',
            dataIndex: 'HighCredit',
            key: 'HighCredit',
            render: (_, record) => (
                <div style={{ color: '#222' }}>{_}</div>
            ),
        },
    ]
    const type = {
        "ExperianFairIsaac": "Experian",
        "EquifaxBeacon5.0": 'Equifax',
        'TransUnion': 'TransUnion',
        'FICORiskScoreClassic': 'TransUnion'
    }

    return (
        <>
            <div style={{
                padding: '18px 36px'
            }}>

                <div style={{ paddingBottom: 10 }}>
                    <span style={{ fontSize: 20, fontWeight: 500, color: '#000' }}>Credit check results</span>
                    {creditReports.length > 1 && <Dropdown menu={{ items: creditReports, onClick: handleMenuClick }} trigger={['click']}>
                        <span style={{ color: "#222", fontSize: 12, fontWeight: 500, display: 'inline-block', marginLeft: 20, minWidth: 100, borderBottom: '1px solid #000' }}>

                            {currentReports}


                        </span>
                    </Dropdown>}

                </div>

                <div >
                    <Tabs defaultActiveKey="CreditHistory">
                        <TabPane tab="Credit History" key="CreditHistory">
                            {!checkButton && <div className='setUpAccount'>
                                <div>
                                    <div style={{ color: "#222", fontSize: 16, fontWeight: 600 }}>Setup your credit account</div>
                                    <div style={{ color: "#222", fontSize: 14, fontWeight: 400 }}>The credit account has not been set up. Please contact your admin for assistance.</div>
                                </div>
                                <div>
                                    <Button className='zeitroButton' onClick={() => {

                                        if (checkAccess([UR_Owner])) {
                                            window.open('/app/management', '_self')
                                        } else {

                                            alert(' Please contact your admin to set up the credit account')
                                        }

                                    }}>Setup</Button>

                                </div>
                            </div>}


                            {errorData.error && <div style={{ color: "#CB8600", display: 'flex', alignItems: 'center', marginTop: '10px', fontWeight: 500 }}><ExclamationCircleFilled /> <span style={{ marginLeft: '10px' }}>
                                Credit check unavailable</span></div>}
                            {errorData.error && <Alert style={{ marginTop: "10px" }} message={<div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>

                                <div>{errorData.content}</div>
                                <div>
                                    <Popover placement='bottom' content={
                                        <div style={{
                                            color
                                                : "#222",
                                        }}>
                                            <div style={{
                                                minWidth: 150,
                                                fontSize: '28px',
                                                paddingBottom: 10,
                                                paddingLeft: 4,
                                                borderBottom: "1px solid #DBDCDE"
                                            }}>{user.borrower.firstname} {user.borrower.lastname}</div>

                                            <div style={{ marginTop: 10, }}>
                                                <div className='PopoverTitle'>Phone</div>
                                                <div>{user.borrower.homephone === '' ? '--' : user.borrower.homephone} </div>
                                            </div>
                                            <div style={{ marginTop: 10 }}>
                                                <div className='PopoverTitle'>Email</div>
                                                <div style={{ textDecoration: 'underline' }}>{user.borrower.email} </div>
                                            </div>

                                        </div>


                                    }>
                                        <Button className='ant-btn-default-zeitro'>Contact borrower</Button>
                                    </Popover>

                                </div>

                            </div>} type="warning" />}
                            {/* <Tabs> */}
                            {
                                parties.map((partiesItem, partiesIndex) => {
                                    const newCredit = partiesItem.credit.length ? partiesItem.credit : partiesReplace
                                    return (
                                        // <TabPane tab={partiesItem.name} key={partiesIndex}>
                                        <Space direction="vertical" size="middle" style={{ display: 'flex', marginTop: 15 }}>
                                            <div style={{
                                                display: "flex", alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}>
                                                <div style={{ color: "#222", fontSize: 16, fontWeight: 500 }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <circle cx="12" cy="12" r="12" fill="#DBDCDE" />
                                                        <circle cx="11.951" cy="8.71667" r="3.46667" fill="#6E6E70" />
                                                        <path d="M11.95 13.05C9.07812 13.05 6.75 15.3782 6.75 18.25H17.15C17.15 15.3782 14.8219 13.05 11.95 13.05Z" fill="#6E6E70" />
                                                    </svg>
                                                    <span style={{ marginLeft: 15 }}>{partiesItem.name}</span>

                                                </div>
                                                <div style={{
                                                    display: "flex", alignItems: 'center',

                                                }}>   {partiesItem?.id === '' ? <></> : <div style={{
                                                    marginRight: '10px', cursor: 'pointer', border: '1px solid #ECECEC',
                                                    padding: '5px 7px', background: "#FCFCFC",
                                                    fontSize: "18px",
                                                    borderRadius: '6px',

                                                }} onClick={() => {

                                                    const link = document.createElement("a")
                                                    link.href = `data:application/pdf;base64,${pdfString}`
                                                    link.download = 'Credit Report'
                                                    document.body.appendChild(link)
                                                    link.click()
                                                    document.body.removeChild(link)


                                                }}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4 15.8333C4 15.2811 3.55228 14.8333 3 14.8333C2.44772 14.8333 2 15.2811 2 15.8333H4ZM22.2 15.8333C22.2 15.2811 21.7523 14.8333 21.2 14.8333C20.6477 14.8333 20.2 15.2811 20.2 15.8333H22.2ZM11.3115 16.5516C10.9719 16.9871 11.0495 17.6155 11.4849 17.9552C11.9204 18.2948 12.5488 18.2172 12.8885 17.7817L11.3115 16.5516ZM18.0885 11.1151C18.4281 10.6796 18.3505 10.0512 17.9151 9.71146C17.4796 9.37187 16.8512 9.44947 16.5115 9.88493L18.0885 11.1151ZM11.3115 17.7817C11.6512 18.2172 12.2796 18.2948 12.7151 17.9552C13.1505 17.6155 13.2281 16.9871 12.8885 16.5516L11.3115 17.7817ZM7.68851 9.88493C7.34883 9.44947 6.72044 9.37187 6.28497 9.71146C5.84949 10.0512 5.77183 10.6796 6.11149 11.1151L7.68851 9.88493ZM11.1 17.1667C11.1 17.7189 11.5477 18.1667 12.1 18.1667C12.6523 18.1667 13.1 17.7189 13.1 17.1667H11.1ZM13.1 2.5C13.1 1.94772 12.6523 1.5 12.1 1.5C11.5477 1.5 11.1 1.94772 11.1 2.5H13.1ZM2 15.8333V17.1667H4V15.8333H2ZM2 17.1667C2 19.9041 4.17009 22.1667 6.9 22.1667V20.1667C5.32209 20.1667 4 18.8476 4 17.1667H2ZM6.9 22.1667H17.3V20.1667H6.9V22.1667ZM17.3 22.1667C20.0299 22.1667 22.2 19.9041 22.2 17.1667H20.2C20.2 18.8476 18.8779 20.1667 17.3 20.1667V22.1667ZM22.2 17.1667V15.8333H20.2V17.1667H22.2ZM12.8885 17.7817L18.0885 11.1151L16.5115 9.88493L11.3115 16.5516L12.8885 17.7817ZM12.8885 16.5516L7.68851 9.88493L6.11149 11.1151L11.3115 17.7817L12.8885 16.5516ZM13.1 17.1667V2.5H11.1V17.1667H13.1Z" fill="#545459" />
                                                    </svg>
                                                </div>}
                                                    {
                                                        checkButton ?

                                                            <Button style={{ height: 40 }} className='ant-btn-default-zeitro' onClick={() => {
                                                                setIsModalOpen(true)



                                                            }}>{partiesItem?.id === '' ? 'Check credit' : 'Check again'}</Button>
                                                            : <Tooltip placement="left" title={checkAccess(["Owner"]) ? 'This feature requires a credit account. Please configure your credit account in Management to unlock it.' : 'This feature requires a credit account. Please contact your account admin to configure your credit account in Management and unlock it.'}>
                                                                <Button disabled={true} style={{ height: 40 }} className='ant-btn-default-zeitro' onClick={() => {
                                                                    setIsModalOpen(true)



                                                                }}>{partiesItem?.id === '' ? 'Check credit' : 'Check again'}</Button>    </Tooltip>


                                                    }</div>
                                            </div>
                                            {newCredit.map((item, index) => {

                                                return <Card style={!checkButton ? { filter: 'blur(1px)' } : {}}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                        <div>
                                                            {getImg(item.title)}
                                                            <div className='reportScore'>Score</div>
                                                            <div className='reportValue'>{item.value === '' ? '---' : item.value}</div>


                                                        </div>
                                                        <div style={{ width: '90%' }} >
                                                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                                {/* <div style={{ color: "#6E6E70" }}>Soft credit pull (automatic) {item.time}</div> */}
                                                                <div>

                                                                    <div style={{ cursor: "pointer" }} onClick={() => {
                                                                        if (currentTable === index) {
                                                                            setCurrentTable(null)
                                                                        } else {
                                                                            setCredit('')
                                                                            setState('')
                                                                            setIsSwitch(false)
                                                                            setCurrentTable(index)
                                                                            const list = partiesItem.liabilities.filter((info => info.filter.includes(type[item.title.includes('FICORiskScoreClassic') ? 'TransUnion' : item.title])))
                                                                            setCurrentDataSource(list)
                                                                        }

                                                                    }}>
                                                                        {currentTable === index ? <UpOutlined /> : < DownOutlined />}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {item.factors.length > 0 ?
                                                                <div className='factorScore'>
                                                                    <div className='FactorsTitle'>Factors Influencing Credit Score:</div>
                                                                    <ul style={{
                                                                        paddingLeft
                                                                            : '19px', color: 'rgba(34, 34, 34, 0.90)',
                                                                        fontSize: '14px', marginTop: '5px'
                                                                    }}>
                                                                        {
                                                                            item.factors.map((factorsItem) => {

                                                                                return (
                                                                                    <li key={factorsItem}>{factorsItem}</li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div> : <div className='factorScore' style={{ textAlign: "center", minHeight: '14vh', display: "grid", alignItems: "center" }}>
                                                                    <div>
                                                                        <div style={{ color: "#222", fontWeight: "500", fontSize: "16px" }}>Credit not pulled</div>
                                                                        {/* <div style={{ color: "6e6e70", fontWeight: "400", fontSize: "14px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div> */}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* <div style={{ width: '100%', height: '1px', background: "#DBDCDE", marginTop: "10px" }}></div> */}
                                                    {

                                                        currentTable === index ? <div style={{ marginTop: "10px" }}>
                                                            <div className='CreditHistory'>Credit History</div>

                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                <div>
                                                                    <Switch value={isSwitch} onChange={(e) => {
                                                                        setIsSwitch(e)
                                                                        const list = partiesItem.liabilities.filter((info => info.filter.includes(type[item.title.includes('FICORiskScoreClassic') ? 'TransUnion' : item.title]))).filter(info => info.credit.includes(credit === undefined ? '' : credit)).filter(info => info.account.includes(status === undefined ? '' : status)).filter((info) => {
                                                                            if (e) {
                                                                                return Number(info.payment) !== 0
                                                                            } else {
                                                                                return info
                                                                            }


                                                                        })
                                                                        setCurrentDataSource(list)
                                                                    }}></Switch> <span className='exclude'>Exclude 0 monthly payment records</span>
                                                                </div>
                                                                <div>
                                                                    <Select
                                                                        placeholder='Select Credit loan type'
                                                                        allowClear
                                                                        onChange={(e) => {
                                                                            // console.log(e)
                                                                            setCredit(e)
                                                                            const list = partiesItem.liabilities.filter((info => info.filter.includes(type[item.title.includes('FICORiskScoreClassic') ? 'TransUnion' : item.title]))).filter(info => info.credit.includes(e === undefined ? '' : e)).filter(info => info.account.includes(status === undefined ? '' : status)).filter((info) => {
                                                                                if (isSwitch) {
                                                                                    return Number(info.payment) !== 0
                                                                                } else {
                                                                                    return info
                                                                                }


                                                                            })
                                                                            setCurrentDataSource(list)
                                                                        }}
                                                                        style={{ width: '250px', marginRight: '15px' }} options={creditLoanType}></Select>
                                                                    <Select
                                                                        allowClear
                                                                        onChange={(e) => {

                                                                            setState(e)
                                                                            const list = partiesItem.liabilities.filter((info => info.filter.includes(type[item.title.includes('FICORiskScoreClassic') ? 'TransUnion' : item.title]))).filter(info => info.credit.includes(credit === undefined ? '' : credit)).filter(info => info.account.includes(e === undefined ? '' : e)).filter((info) => {
                                                                                if (isSwitch) {
                                                                                    return Number(info.payment) !== 0
                                                                                } else {
                                                                                    return info
                                                                                }


                                                                            })
                                                                            setCurrentDataSource(list)
                                                                        }}
                                                                        placeholder='Select Account Status'
                                                                        style={{ width: '150px' }} options={accountStatus}></Select>

                                                                </div>

                                                            </div>

                                                            <Table style={{ marginTop: '10px' }} columns={columns} dataSource={currentDataSource}
                                                            ></Table>
                                                        </div> : <></>
                                                    }

                                                </Card>
                                            })}

                                        </Space>
                                        // </TabPane>
                                    )
                                })

                            }

                            {/* </Tabs> */}
                        </TabPane>
                        <TabPane tab="Credit Summary" key="CreditSummary">
                            <div style={{ marginTop: '10px' }}>
                                <div className='CreditHistory' >Credit Summary</div>
                                <Table
                                    style={{ marginTop: '10px' }}
                                    columns={columnsSummary}
                                    dataSource={summary}
                                ></Table>

                            </div>
                        </TabPane>

                    </Tabs>
                    {/* <div>
                        <Radio.Group value={isShowSummary} onChange={(e) => {

                            setIsShowSummary(e.target.value)
                        }} defaultValue="CreditSummary">
                            <Radio value="CreditHistory">Credit history</Radio>
                            <Radio value="CreditSummary">Credit summary</Radio>


                        </Radio.Group>

                    </div> */}


                </div>


                {/* {
                    isShowSummary === 'CreditSummary' ?
                  
                 

                } */}



            </div >
            <FullHeightModal onClose={() => {

                setIsModalOpen(false
                )
            }} footer={<div style={{ display: "flex", justifyContent: 'space-between', paddingTop: "10px", borderTop: "1px solid #ECECEC" }}>
                {next ? <div></div> : <Button type='link' onClick={() => {

                    setNext(true)
                }}> GO back</Button>}
                <Button type='primary' loading={CheckLoading} onClick={async () => {
                    if (next) {

                        let token = sessionStorage.getItem("ZeitroA")
                        const response = await fetch('/los/getcreditaccountconfig', {
                            method: 'GET',
                            headers: {
                                Authorization: "Bearer " + token,
                                Cache: "no-cache"
                            }
                        })
                        if (response.status !== 200) {
                            console.log('Looks like there was a problem. Status Code: ' +
                                response.status)

                            return
                        }
                        const js = await response.json()
                        console.log(js)
                        setAccountData(js.Accounts)
                        setNext(false)
                    } else {

                        try {
                            setCheckLoading(true)
                            let token = sessionStorage.getItem("ZeitroA")
                            const response = await fetch(`/los/customercreditreport`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    LoanID: urlParams,
                                    Type: currentType[creditRadio]
                                }),
                                headers: {
                                    Authorization: "Bearer " + token,
                                    Cache: "no-cache"
                                }
                            })
                            if (response.status !== 200) {
                                alert("Some internal error occurred. Please try again later.")
                                setNext(true)
                                setIsModalOpen(false)
                                setCheckLoading(false)
                                return
                            }

                            const responseBody = await response.text()
                            if (responseBody.includes("AuthenticationFailure")) { // Check if response contains AuthenticationFailure
                                const msg = checkAccess([UR_Owner])
                                    ? 'We were unable to retrieve the credit report because there was an authentication failure with your credit account. Please verify your credit account settings in the management portal.'
                                    : 'We were unable to retrieve the credit report because there was an authentication failure with your credit account. Please contact your admin to verify your credit account settings in the management portal.'

                                alert(msg)
                                setNext(true)
                                setIsModalOpen(false)
                                setCheckLoading(false)
                                return
                            }


                            setNext(true)
                            setIsModalOpen(false
                            )
                            getXml()
                        } catch (error) {
                            console.log(error)
                            alert('An unexpected error occurred. Please try again later.')
                            setNext(true)
                            setIsModalOpen(false)
                            setCheckLoading(false)
                        }
                    }



                }}>{!next ? 'Check Now' : 'Next'}</Button>
            </div>} title='Credit check' width={800} open={isModalOpen} content={next ? getContent() : getOrder()}>

            </FullHeightModal>

        </>

    )
    function getContent() {
        return <div className='borrowerContent'>
            <div>
                <div className='ContentBorrowers'>
                    Borrowers

                </div>
                <div className='ContentSelect'>
                    Select the borrower(s) you would like to run soft credit check for
                </div>
                <div>
                    <Checkbox.Group onChange={(e) => {

                        console.log(e)
                    }} value={isChecked} style={{
                        display: "flex", flexDirection: 'column', justifyContent: 'space-around',


                    }}>
                        <div className='ContentCheckbox' onClick={() => {

                            const borrower = isChecked.includes('borrower')
                            if (borrower) {
                                setIsChecked(isChecked.filter((item) => item !== 'borrower'))
                            } else {
                                setIsChecked([...isChecked, 'borrower'])
                            }

                        }}>
                            {/* <Checkbox value='borrower'></Checkbox> */}
                            <div className='CheckboxName'>

                                <div>  <span >{user.borrower.firstname} {user.borrower.lastname}</span> <span style={{
                                    fontWeight: 400
                                }}>· SSN {user.borrower.socialsecurity}</span></div>
                                {/* <div style={{
                                    color
                                        : "#6E6E70",
                                    fontSize: '16px',
                                    fontWeight: '500'
                                }}>
                                    Credit not pulled
                                </div> */}


                            </div>
                            <div style={{ color: "#586570", marginTop: '15px' }}>
                                <div>
                                    Date of birth: <span style={{ marginLeft: "51px", color: "#333" }}>{user.borrower.dateofbirth === '' ? '--' : user.borrower.dateofbirth}</span>
                                </div>
                                <div>
                                    Current address:<span style={{ marginLeft: "36px", color: "#333" }}>{user.borrower.presentaddressstreet === '' ? '--' : user.borrower.presentaddressstreet}</span>
                                </div>
                            </div>

                        </div>
                        {user.hascoborrower === 'withcoborrower' ? <div className='ContentCheckbox' onClick={() => {

                            const coborrower = isChecked.includes('coborrower')
                            if (coborrower) {
                                setIsChecked(isChecked.filter((item) => item !== 'coborrower'))
                            } else {
                                setIsChecked([...isChecked, 'coborrower'])
                            }

                        }}>
                            {/* <Checkbox value='coborrower' ></Checkbox> */}
                            <div className='CheckboxName'>

                                <div>
                                    <span >

                                        {user.coborrower.firstname} {user.coborrower.lastname}
                                    </span> <span style={{
                                        fontWeight: 400
                                    }}>·SSN {user.coborrower.socialsecurity}</span></div>
                                {/* <div style={{
                                    color
                                        : "#6E6E70",
                                    fontSize: '16px',
                                    fontWeight: '500'
                                }}>
                                    Credit not pulled
                                </div> */}


                            </div>
                            <div style={{ color: "#586570", marginTop: '15px' }}>
                                <div>
                                    Date of birth: <span style={{ marginLeft: "51px", color: "#333" }}>{user.coborrower.dateofbirth === '' ? '--' : user.coborrower.dateofbirth}</span>
                                </div>
                                <div>
                                    Current address:<span style={{ marginLeft: "36px", color: "#333" }}>{user.coborrower.presentaddressstreet === '' ? '--' : user.coborrower.presentaddressstreet}</span>
                                </div>
                            </div>


                        </div> : <></>}

                    </Checkbox.Group>

                </div>
            </div>


        </div>
    }
    function getUserName(type) {

        if (accountData.length === 0) return ''
        const data = accountData.find(item => item.Type === type)

        if (
            !data
        ) {
            return ''
        } else {
            return <span style={{ marginLeft: 10 }}>{data.Vendor} - {data.Username}</span>

        }
    }
    function getOrder() {
        return <div className='borrowerContent'>
            <div>
                <div className='ContentBorrowers'>
                    New credit order

                </div>
                <div className='ContentSelect'>
                    Enter the required information to order credit for your borrowers.
                </div>
                <div className='ContentSelect' style={{ marginTop: "30px" }}>
                    What type of credit order you would like to run?
                </div>
                <div style={{ marginTop: "10px" }}>
                    <Radio.Group style={{ display: "flex" }} value={creditRadio} onChange={(e) => {

                        setCreditRadio(e.target.value)

                    }}>
                        {
                            getUserName('SoftPull') !== '' && <div className='CreditRadio' style={creditRadio === 'SoftPull' ? { borderColor: "#325CEB" } : {}}>
                                <Radio value='SoftPull'>Soft credit check</Radio>
                            </div>
                        }
                        {
                            getUserName('HardPull') !== '' && <div className='CreditRadio CreditRadioLeft' style={creditRadio === 'HardPull' ? { borderColor: "#325CEB" } : {}}>
                                <Radio value='HardPull'>Hard credit check</Radio>
                            </div>
                        }
                    </Radio.Group>
                </div>
                {
                    getUserName(creditRadio) === '' ? <></> : <div>
                        <div className='ContentSelect' style={{ marginTop: "30px" }}>
                            Your credit account
                        </div>
                        <div className='creditAccount'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10.0007 9.99996C12.3018 9.99996 14.1673 8.13447 14.1673 5.83329C14.1673 3.53211 12.3018 1.66663 10.0007 1.66663C7.69947 1.66663 5.83398 3.53211 5.83398 5.83329C5.83398 8.13447 7.69947 9.99996 10.0007 9.99996Z" fill="#474849" />
                                <path d="M10.0008 12.0834C5.82578 12.0834 2.42578 14.8834 2.42578 18.3334C2.42578 18.5667 2.60911 18.75 2.84245 18.75H17.1591C17.3925 18.75 17.5758 18.5667 17.5758 18.3334C17.5758 14.8834 14.1758 12.0834 10.0008 12.0834Z" fill="#474849" />
                            </svg>
                            {getUserName(creditRadio)}
                        </div>
                    </div>
                }
                {/* <div className='ContentSelect' style={{ marginTop: "30px" }}>
                    Choose credit bureau(s)
                </div>
                <div style={{ marginTop: "10px" }}>
                    <Checkbox.Group style={{ display: "flex" }} value={bureauRadio} onChange={(e) => {
                        setBureauRadio(e)
                    }}>
                        {
                            Bureau.map((item, index) => {

                                return (
                                    <div style={creditRadio === item.value ? { borderColor: "#325CEB" } : {}} className={index === 0 ? 'BureauRadio' : 'BureauRadio CreditRadioLeft'}>
                                        <Checkbox value={item.value}>
                                            <div>
                                                {getImg(item.value)}
                                            </div>
                                            <div>{item.value}</div>

                                        </Checkbox>

                                    </div>
                                )
                            })
                        }
                    </Checkbox.Group>
                </div> */}
            </div>
            {/* <div style={{ display: "flex", justifyContent: 'space-between', paddingTop: "10px", borderTop: "1px solid #ECECEC" }}>

                <Button type='link' onClick={() => {

                    setNext(true)
                }}> GO back</Button>
                <Button type='primary' onClick={async () => {

                    try {
                        setCheckLoading(true)
                        let token = sessionStorage.getItem("ZeitroA")
                        const response = await fetch(`/los/customercreditreport`, {
                            method: 'POST',
                            body: JSON.stringify({
                                LoanID: urlParams
                            }),
                            headers: {
                                Authorization: "Bearer " + token,
                                Cache: "no-cache"
                            }
                        })
                        await response.json()
                        setNext(true)
                        setIsModalOpen(false
                        )
                        getXml()
                    } catch (error) {
                        alert('An unexpected error occurred. Please try again later.')
                        setNext(true)
                        setIsModalOpen(false
                        )
                        setCheckLoading(false)
                    }

                }} loading={CheckLoading}>Check now</Button>
            </div> */}
        </div>


    }
    function getImg(title) {
        if (title.includes('Equifax')) {
            return <img src='/images/equifax.svg'></img>

        }
        if (title.includes('TransUnion') || title.includes('FICORiskScoreClassic')) {
            return <img src='/images/transunion.svg'></img>

        }
        if (title.includes('Experian')) {
            return <img src='/images/experian.svg'></img>

        }


    }

    async function getXml() {

        const type = {
            CustomerSoftCreditReport: 'Soft credit pull',
            CustomerHardCreditReport: 'Hard credit pull'
        }
        let token = sessionStorage.getItem("ZeitroA")

        const response = await fetch(`/los/getcreditreport/${urlParams}`, {
            method: 'get',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        })
        const { Error, Status, CreditReports } = await response.json()

        console.log(Error, Status)
        // return
        if (Status === 'Error') {
            alert('An unexpected error occurred. Please try again later.')
            setParties([{
                credit: [],
                liabilities: [],
                id: "",
                name: "",
                type: ''

            },])
            return

        } else {

            setCreditReports(CreditReports.map((item, index) => {
                item.key = index
                item.label = <div>
                    {type[item.Type]} {new Date(item.RequestedTime).toLocaleDateString()}
                </div>

                return item

            }))
            if (CreditReports.length === 0) {
                setParties([{
                    credit: [],
                    liabilities: [],
                    id: "",
                    name: "",
                    type: ''

                }])
            } else if (CreditReports.length === 1) {
                setXml(CreditReports[0].CreditReportXML)


            } else {
                const type = {
                    CustomerSoftCreditReport: 'Soft credit pull',
                    CustomerHardCreditReport: 'Hard credit pull'
                }
                const string = type[CreditReports[0].Type] + " " + new Date(CreditReports[0].RequestedTime).toLocaleDateString()
                setCurrentReports(string)
                setXml(CreditReports[0].CreditReportXML)

            }

        }


        // var xhr = new XMLHttpRequest()
        // xhr.open('GET', 'http://localhost:3001/error.xml', true)
        // // 当请求完成时触发的回调函数
        // xhr.onreadystatechange = async () => {
        //     if (xhr.readyState === 4 && xhr.status === 200) {
        //         // 请求成功，开始解析XML
        //         var parser = new DOMParser()
        //         var xmlDoc = parser.parseFromString(xhr.responseText, 'text/xml')
        //         const CREDIT_SCORES = xmlDoc.getElementsByTagName("CREDIT_SCORES")
        //         const CREDIT_LIABILITIES = xmlDoc.getElementsByTagName("CREDIT_LIABILITIES")
        //         const CreditErrorMessageText = xmlDoc.getElementsByTagName('CreditErrorMessageText')
        //         const { error, content } = await getError(CreditErrorMessageText)
        //         console.log(error, content)
        //         setErrorData({
        //             error,
        //             content
        //         })
        //         let parties = xmlDoc.getElementsByTagName('PARTIES')
        //         const partiesList = await getParties(parties)
        //         const relationships = xmlDoc.getElementsByTagName('RELATIONSHIPS')[1].childNodes
        //         const shipParties = await getRelationships(relationships, partiesList)
        //         const to = CREDIT_SCORES.length > 0 ? xmlDoc.getElementsByTagName("CREDIT_SCORES")[0]?.childNodes : []
        //         const creditParties = await getCreditScores(to, shipParties)
        //         const liability = CREDIT_LIABILITIES.length > 0 ? xmlDoc.getElementsByTagName("CREDIT_LIABILITIES")[0].childNodes : []
        //         getCreditLiability(liability, creditParties)
        //         let pdfDom = xmlDoc.getElementsByTagName('FOREIGN_OBJECT')
        //         let summaryDom = xmlDoc.getElementsByTagName('CREDIT_SUMMARY')
        //         getPdfString(pdfDom)
        //         getSummary(summaryDom)


        //     } else if (xhr.status !== 200) {
        //         console.error('Error loading XML file.')
        //     }
        // }

        // // 发送请求
        // xhr.send()
    }
    async function setXml(xml) {

        var parser = new DOMParser()

        var xmlDoc = parser.parseFromString(xml, 'text/xml')
        const CREDIT_SCORES = xmlDoc.getElementsByTagName("CREDIT_SCORES")
        const CREDIT_LIABILITIES = xmlDoc.getElementsByTagName("CREDIT_LIABILITIES")
        const CreditErrorMessageText = xmlDoc.getElementsByTagName('CreditErrorMessageText')
        const { error, content } = await getError(CreditErrorMessageText)
        console.log(error, content)
        setErrorData({
            error,
            content
        })
        let parties = xmlDoc.getElementsByTagName('PARTIES')
        const partiesList = await getParties(parties)
        const relationships = xmlDoc.getElementsByTagName('RELATIONSHIPS')[1].childNodes
        const shipParties = await getRelationships(relationships, partiesList)
        const to = CREDIT_SCORES.length > 0 ? xmlDoc.getElementsByTagName("CREDIT_SCORES")[0]?.childNodes : []
        const creditParties = await getCreditScores(to, shipParties)
        const liability = CREDIT_LIABILITIES.length > 0 ? xmlDoc.getElementsByTagName("CREDIT_LIABILITIES")[0].childNodes : []
        getCreditLiability(liability, creditParties)
        let pdfDom = xmlDoc.getElementsByTagName('FOREIGN_OBJECT')
        let summaryDom = xmlDoc.getElementsByTagName('CREDIT_SUMMARY')
        getPdfString(pdfDom)
        getSummary(summaryDom)

    }
    function getError(dom) {
        return new Promise((resolve, reject) => {
            if (dom.length === 0) {
                resolve({
                    error: false
                })
            } else {
                const content = dom[0].innerHTML

                resolve({
                    error: true,
                    content: content
                })
            }
        })
    }
    function getRelationships(dom, res) {
        return new Promise((resolve, reject) => {
            const userIdList = res
            for (let i = 0; i < dom.length; i++) {
                const element = dom[i]
                // console.log(element)
                for (let j = 0; j < userIdList.length; j++) {
                    const id = userIdList[j].id
                    if (id === element.getAttribute('d7p1:to')) {
                        userIdList[j].type.push(element.getAttribute('d7p1:from'))
                    }
                }
            }
            resolve(userIdList)
            setParties(userIdList)

        })

    }
    function getParties(dom) {
        return new Promise((resolve, reject) => {
            const element = dom[1].childNodes
            const userData = Array.from({ length: element.length - 1 }, () => {
                return {
                    name: "",
                    id: '',
                    type: [],
                    credit: [],
                    liabilities: []

                }
            })
            for (let i = 0; i < element.length; i++) {
                const parties = element[i].childNodes
                for (let j = 0; j < parties.length; j++) {
                    const party = parties[j]
                    if (party.tagName === 'INDIVIDUAL' && party.children[0].tagName !== 'NAME') {
                        break
                    }
                    if (party.tagName === 'INDIVIDUAL') {
                        userData.find((item, index) => index === i - 1).name = party.children[0].children[1].innerHTML
                    }
                    if (party.tagName === 'ROLES') {
                        userData.find((item, index) => index === i - 1).id
                            = party.childNodes[0].getAttribute('d7p1:label')
                    }
                }
            }

            setParties(userData)
            resolve(userData)
        })




    }
    function getPdfString(dom) {
        // console.log(dom, 99)
        for (let i = 0; i < dom.length; i++) {
            const element = dom[i].childNodes
            // console.log(element)
            for (let j = 0; j < element.length; j++) {
                const dom = element[j]
                if (dom.tagName === 'EmbeddedContentXML') {
                    setPdfString(dom.innerHTML)
                }
                if (dom.tagName === 'MIMETypeIdentifier' && dom.innerHTML === 'application/pdf') {
                    // console.log(dom)
                    // setPdfString(dom.innerHTML)
                }


            }

        }
    }
    function getSummary(dom) {
        const list = Array.from({ length: (dom[0].childNodes[0].childNodes.length - 2) / 5 }, () => {
            return {
                Balance: "",
                Count: '',
                Payment: "",
                PastDue: '',
                HighCredit: '',
                Type: "",

            }
        })
        for (let i = 0; i < dom.length; i++) {
            const element = dom[i].childNodes
            for (let j = 0; j < element.length; j++) {
                const elementDom = element[j]
                if (elementDom.tagName === 'CREDIT_SUMMARY_DETAIL') {
                    if (elementDom.children[0].innerHTML === 'TradeSummary') {
                        const summary = element[0].childNodes
                        const data = {
                            Balance: "",
                            Count: '',
                            Payment: "",
                            PastDue: '',
                            HighCredit: '',
                            Type: "",
                        }

                        for (let l = 0; l < summary.length; l++) {
                            const elementSummary = summary[l].childNodes

                            if (elementSummary[0].innerHTML.includes("LiabilityOldest")) {
                                continue
                            }
                            if (elementSummary[0].innerHTML.includes('Mortgage')) {
                                const type = 'Mortgage'
                                list.find((item, index) => index === 0).Type = 'Mortgage'
                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 0).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 0).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 0).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 0).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 0).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Installment')) {
                                const type = 'Installment'
                                list.find((item, index) => index === 1).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 1).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 1).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 1).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 1).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 1).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('InstallmentOther')) {
                                const type = 'InstallmentOther'
                                list.find((item, index) => index === 2).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 2).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 2).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 2).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 2).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 2).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Auto')) {
                                const type = 'Auto'
                                list.find((item, index) => index === 3).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 3).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 3).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 3).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 3).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 3).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Education')) {
                                const type = 'Education'
                                list.find((item, index) => index === 4).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 4).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 4).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 4).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 4).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 4).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Revolving')) {
                                const type = 'Revolving'
                                list.find((item, index) => index === 6).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 6).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 6).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 6).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 6).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 6).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Open')) {
                                const type = 'Open'
                                list.find((item, index) => index === 5).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 5).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 5).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 5).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 5).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 5).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('Unknown')) {
                                const type = 'Unknown'
                                list.find((item, index) => index === 7).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 7).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 7).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 7).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 7).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 7).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            if (elementSummary[0].innerHTML.includes('TotalLiability')) {
                                const type = 'TotalLiability'
                                list.find((item, index) => index === 8).Type = type

                                if (elementSummary[0].innerHTML.includes(`${type}Balance`)) {
                                    list.find((item, index) => index === 8).Balance = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Count`)) {
                                    list.find((item, index) => index === 8).Count = elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}Payment`)) {
                                    list.find((item, index) => index === 8).Payment = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}PastDue`)) {
                                    list.find((item, index) => index === 8).PastDue = '$' + elementSummary[1].innerHTML
                                }
                                if (elementSummary[0].innerHTML.includes(`${type}HighCredit`)) {
                                    list.find((item, index) => index === 8).HighCredit = elementSummary[1].innerHTML
                                }

                            }
                            // console.log(elementSummary[0].innerHTML)

                        }

                    }

                }

            }


        }
        setSummary(list)
        console.log(list)


    }
    function getCreditScores(to, partiesList) {
        return new Promise((resolve, reject) => {
            const list = partiesList
            for (let i = 0; i < to.length; i++) {
                const element = to[i]
                const creditId = element.getAttribute('d7p1:label')
                for (let j = 0; j < list.length; j++) {
                    const listElement = list[j]
                    if (listElement.type.includes(creditId)) {
                        const data = {
                            time: "",
                            value: '',
                            title: "",
                            factors: []
                        }
                        for (let index = 0; index < element.childNodes.length; index++) {
                            const elementNode = element.childNodes[index]
                            if (elementNode.tagName === 'CREDIT_SCORE_DETAIL') {
                                for (let k = 0; k < elementNode.childNodes.length; k++) {
                                    const elementChildNode = elementNode.childNodes[k]

                                    if (elementChildNode.tagName === 'CreditScoreDate') {
                                        data.time = elementChildNode.innerHTML
                                    }
                                    if (elementChildNode.tagName === 'CreditScoreValue') {
                                        data.value = elementChildNode.innerHTML.replace(/^\+?0*([1-9]\d*)/, '$1')
                                    }
                                    if (elementChildNode.tagName === 'CreditScoreModelNameType') {
                                        data.title = elementChildNode.innerHTML
                                    }

                                }
                            }
                            if (elementNode.tagName === 'CREDIT_SCORE_FACTORS') {
                                for (let k = 0; k < elementNode.childNodes.length; k++) {
                                    const elementChildNode = elementNode.childNodes[k].children
                                    data.factors.push(`${elementChildNode[0].innerHTML
                                        }-${elementChildNode[1].innerHTML}`)

                                }

                            }

                        }



                        listElement.credit.push(data)
                    }

                }

            }
            setParties(list)
            resolve(list)
        })

    }
    function getCreditLiability(to, creditList) {
        const list = creditList
        const CREDIT_LIABILITY = Array.from({ length: to.length }, () => {
            return {
                name: '',
                balance: '',
                payment: "",
                monthly: '',
                date: '',
                credit: '',
                account: "",
                filter: [],
            }
        })

        for (let i = 0; i < to.length; i++) {
            const Attribute = to[i].getAttribute('d7p1:label')
            const content = to[i].childNodes
            for (let f = 0; f < list.length; f++) {
                const data = {
                    name: '',
                    balance: '',
                    payment: "",
                    monthly: '',
                    date: '',
                    credit: '',
                    account: "",
                    filter: [],
                }
                const listElement = list[f]
                if (listElement.type.includes(Attribute)) {
                    for (let j = 0; j < content.length; j++) {
                        const creditor = content[j].childNodes
                        //name
                        if (content[j].tagName === 'CREDIT_LIABILITY_CREDITOR') {
                            // console.log(content[j])

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'NAME') {
                                    data.name = creditor[k].children[0].innerHTML

                                }
                            }
                        }
                        //balance
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLiabilityUnpaidBalanceAmount') {
                                    // console.log(creditor[k]);
                                    data.balance = creditor[k].innerHTML
                                }
                            }
                        }
                        //payment
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLiabilityMonthlyPaymentAmount') {
                                    // console.log(creditor[k]);
                                    data.payment = creditor[k].innerHTML
                                }
                            }
                        }
                        //monthly
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLiabilityTermsMonthsCount') {
                                    // console.log(creditor[k]);
                                    data.monthly = creditor[k].innerHTML
                                }
                            }
                        }
                        //date
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLiabilityAccountOpenedDate') {
                                    // console.log(creditor[k]);
                                    data.date = creditor[k].innerHTML
                                }
                            }
                        }
                        //credit
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLoanType') {
                                    // console.log(creditor[k]);
                                    data.credit = creditor[k].innerHTML
                                }
                            }
                        }
                        //account
                        if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CreditLiabilityAccountStatusType') {
                                    // console.log(creditor[k]);
                                    data.account = creditor[k].innerHTML
                                }
                            }
                        }
                        //filter
                        if (content[j].tagName === 'CREDIT_REPOSITORIES') {

                            for (let k = 0; k < creditor.length; k++) {
                                if (creditor[k].tagName === 'CREDIT_REPOSITORY') {

                                    data.filter.push(
                                        creditor[k].children[0].innerHTML
                                    )

                                }
                            }
                        }

                    }


                }
                listElement.liabilities.push(data)
            }

            for (let j = 0; j < content.length; j++) {
                const creditor = content[j].childNodes
                //name
                if (content[j].tagName === 'CREDIT_LIABILITY_CREDITOR') {
                    // console.log(content[j])

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'NAME') {
                            CREDIT_LIABILITY.find((item, index) => index === i).name = creditor[k].children[0].innerHTML
                        }
                    }
                }
                //balance
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLiabilityUnpaidBalanceAmount') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).balance = creditor[k].innerHTML
                        }
                    }
                }
                //payment
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLiabilityMonthlyPaymentAmount') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).payment = creditor[k].innerHTML
                        }
                    }
                }
                //monthly
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLiabilityTermsMonthsCount') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).monthly = creditor[k].innerHTML
                        }
                    }
                }
                //date
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLiabilityAccountOpenedDate') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).date = creditor[k].innerHTML
                        }
                    }
                }
                //credit
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLoanType') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).credit = creditor[k].innerHTML
                        }
                    }
                }
                //account
                if (content[j].tagName === 'CREDIT_LIABILITY_DETAIL') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CreditLiabilityAccountStatusType') {
                            // console.log(creditor[k]);
                            CREDIT_LIABILITY.find((item, index) => index === i).account = creditor[k].innerHTML
                        }
                    }
                }
                //filter
                if (content[j].tagName === 'CREDIT_REPOSITORIES') {

                    for (let k = 0; k < creditor.length; k++) {
                        if (creditor[k].tagName === 'CREDIT_REPOSITORY') {

                            CREDIT_LIABILITY.find((item, index) => index === i).filter.push(
                                creditor[k].children[0].innerHTML
                            )

                        }
                    }
                }

            }
        }
        const creditLoanTypeList = [...new Set(CREDIT_LIABILITY.map((item => item.credit)))].map((item) => {

            return {
                value: item,
                label: item
            }
        })
        const AccountStatusList = [...new Set(CREDIT_LIABILITY.map((item => item.account)))].map((item) => {

            return {
                value: item,
                label: item
            }
        }).filter((item) => item.value !== '')
        setAccountStatus(AccountStatusList)
        setCreditLoanType(creditLoanTypeList)
        setParties(list)
        // console.log(CREDIT_LIABILITY)
        // setCreditLiability(CREDIT_LIABILITY)
        console.log(list)


    }
}