import React, { useState, useEffect } from "react"
import { Switch, Col, Row, Tooltip, Spin } from 'antd'

import './index.css'
import { checkAccess } from "../../Auth"



const Feature = () => {
    const [isSwitch, setSwitch] = useState(false)
    const [loading, setLoading] = useState(false)
    const [creditAccountNotSet, setCreditAccountNotSet] = useState(true)


    useEffect(() => {
        getautomatedcreditpull()

    }, [])
    const getList = async () => {
        let token = sessionStorage.getItem("ZeitroA")
        const response = await fetch('/los/getcreditaccountconfig', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        })
        const js = await response.json()
        setLoading(false)
        const isNotSet = js?.Accounts?.findIndex(x => x.AutomatedSoftCreditPull) !== -1 ? false : true
        setCreditAccountNotSet(isNotSet)
        if (isNotSet) {
            setSwitch(false)

            const response = await fetch(`/los/setautomatedcreditpull`, {
                method: 'POST',
                body: JSON.stringify({
                    AutomatedCreditPull: false
                }),
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            })
        }
    }
    const getautomatedcreditpull = async () => {
        setLoading(true)
        try {
            let token = sessionStorage.getItem("ZeitroA")
            const response = await fetch(`/los/getautomatedcreditpull`, {
                method: 'get',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            })
            const { AutomatedCreditPull } = await response.json()
            setSwitch(AutomatedCreditPull)
            getList()
        } catch (error) {
            setSwitch(false)
            setLoading(false)
            alert('An unexpected error occurred. Please try again later.')
        }

    }



    return (
        <Spin spinning={loading}>
            <div className='mx-3 ' style={{ padding: '0px 20px 0px 20px' }}>

                <div className='User_title mt-4 mb-3 ml-4 mr-5 Support'>
                    <div>
                        Feature Customization
                    </div>
                </div>
                <div className='Customize  ml-4'>Customize your borrowers' application experience</div>
                <Row className="feature_row">

                    <Col span={24}>
                        <div className="usage">
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                                <div className="usage_title">Automated Soft Credit Pulls</div>
                                <div>
                                    {
                                        creditAccountNotSet ? <div> <Tooltip placement="left" title={checkAccess(["Owner"]) ? 'This feature requires a credit account. Please configure your credit account in Management to unlock it.' : 'This feature requires a credit account. Please contact your account admin to configure your credit account in Management and unlock it.'}>
                                            <Switch checked={isSwitch} onChange={async (e) => {

                                                setSwitch(false)


                                            }}></Switch>
                                        </Tooltip>
                                        </div> : <Switch checked={isSwitch} onChange={async (e) => {
                                            setSwitch(e)
                                            let token = sessionStorage.getItem("ZeitroA")
                                            const response = await fetch(`/los/setautomatedcreditpull`, {
                                                method: 'POST',
                                                body: JSON.stringify({
                                                    AutomatedCreditPull: e
                                                }),
                                                headers: {
                                                    Authorization: "Bearer " + token,
                                                    Cache: "no-cache"
                                                }
                                            })
                                            if (response.status !== 200) {
                                                alert('An unexpected error occurred. Please try again later.')
                                            }




                                        }}></Switch>

                                    }


                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%", marginTop: "15px" }}>
                                <div className="usage_content">When you enable this feature, it allows your borrowers to opt-in for automated soft credit checks. Once activated, a soft credit pull is conducted when your borrower provides their Social Security Number (SSN) and consents to the credit check in the "Liability" section of the application process.</div>

                            </div>

                        </div>





                    </Col>
                    {/* <Col span={24}>
                        <div className="usage" style={{ borderTop: '1px solid #D9D9D9', borderRadius: 0 }}>
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                                <div className="usage_title">Auto generate pre-qual letter</div>
                                <div>
                                    {
                                        creditAccountNotSet ? <div> <Tooltip placement="left" title={checkAccess(["Owner"]) ? 'This feature requires a credit account. Please configure your credit account in Management to unlock it.' : 'This feature requires a credit account. Please contact your account admin to configure your credit account in Management and unlock it.'}>
                                            <Switch checked={isSwitch} onChange={async (e) => {

                                                setSwitch(false)


                                            }}></Switch>
                                        </Tooltip>
                                        </div> : <Switch checked={isSwitch} onChange={async (e) => {
                                            setSwitch(e)
                                            let token = sessionStorage.getItem("ZeitroA")
                                            const response = await fetch(`/los/setautomatedcreditpull`, {
                                                method: 'POST',
                                                body: JSON.stringify({
                                                    AutomatedCreditPull: e
                                                }),
                                                headers: {
                                                    Authorization: "Bearer " + token,
                                                    Cache: "no-cache"
                                                }
                                            })
                                            if (response.status !== 200) {
                                                alert('An unexpected error occurred. Please try again later.')
                                            }




                                        }}></Switch>

                                    }


                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%", marginTop: "15px" }}>
                                <div className="usage_content">When activated, your borrower will automatically receive a pre-qualification letter.</div>

                            </div>

                        </div>





                    </Col> */}
                </Row>




            </div>
        </Spin>
    )

}
export default Feature