import React, {useEffect, useState} from 'react';
import {withRouter, useParams} from 'react-router-dom';
import {Skeleton, Input} from "antd"
import { ProcessAnswerText } from './utils';
import './Chat.css';

function ShareChat() {
    const [loadingShare, setLoadingShare] = useState(false)
    const [chatData, setChatData] = useState([])
    const { threadid } = useParams();
    
    useEffect(()=>{
    
        setLoadingShare(true)
        let body = { ThreadID: threadid }
        fetch('/los/mortgagegpt/sharechat', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    setLoadingShare(false)  
                    alert("Something went wrong, please try again later")
                    return;
                }
                response.json().then(js => {
                    setChatData(js.chat_history)
                    setLoadingShare(false)  

                });
            }
        ).catch(function (err) {
            setLoadingShare(false)
            console.log('Fetch Error :-S', err);
        });
    },[])

    return (
        <div className='shareChatWrap'>
            {loadingShare ? <Skeleton active /> : 
        <div className="shareChatContainer">
            {chatData.map(msg => {
        if (msg.role === "user") {
            return <div className="shareChatUser">
              <img src="/images/chatbotuseravatar.png"  style={{width:30, height:30, marginRight: 10}}/>
              <div>
                <div className='bold'>You</div>
                <div>{msg.content}</div>
              </div>
            </div>
        } else {
            return <div className="shareChatBot">
              <img src="/images/chatbot.png"  style={{width:30, height:30, marginRight: 10}}/>
              <div>
                <div className='bold'>GuidelineGPT</div>
                <div>{ProcessAnswerText(msg.content)}</div>
              </div>
            </div>
        }
    })}</div>
                }
        </div>
    );
}
export default withRouter(ShareChat);