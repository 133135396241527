import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
    Table,
    DatePicker,
    Modal,
    Input,
    Button,
    Form,
    Select,
    Col,
    Row,
    Popconfirm
} from 'antd';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux'
import "./Comment.css";
import * as act from "../Store/actions"
import * as com from "../Common"
import { set } from 'lodash';
const { TextArea } = Input
const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        hardmoney: state.application.hardmoneyloan,
        progress: state.progress,
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    
})

export default function Comment(props) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [isViewfilesOpen, setIsViewfilesOpen] = useState(false)
    const [nameList, setNameList] = useState([])
    const [commentLists, setCommentLists] = useState([])
    const [deleteLoading, setDeleteLoading] = useState(false)
    const columns = [
        {
            title: 'CATEGORY',
            dataIndex: 'category',
            key: 'category',
            width: 180,
            render: (text) => <span className="table-date">{text}</span>,
        },
        {
            title: 'ASSIGNEE',
            dataIndex: 'fullName',
            key: 'fullName',
            width: 150,
            render: (text) => <span className="table-date">{text}</span>,
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'created',
            key: 'created',
            width: 150,
            render: (text) => <span className="table-date">{text}</span>,
        },
        {
            title: 'DUE DATE',
            dataIndex: 'duedate',
            key: 'duedate',
            width: 150,
            render: (text) => <span className="table-date">{text}</span>,
        },
        {
            title: 'DETAILS',
            dataIndex: 'detailes',
            key: 'detailes',
            width: 250,
            render: (text) => <span className="table-date">{text}</span>,
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            fixed: 'right',
            render: (text, record, index) => {
                return <Select
                    defaultValue={text}
                    style={{
                        width: 90,
                    }}
                    onChange={(value) => changeStatus(value, index)}
                    options={[
                        {
                            value: 1,
                            label: 'Done',
                        },
                        {
                            value: 2,
                            label: 'To-do',
                        }
                    ]}
                />
            },
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            fixed: 'right',
            render: (index) => {
                return <Popconfirm
                title="Are you sure to you want to delete this comment?"
                description="You won't be able to recover this comment later."
                onConfirm={()=>deleteCommentByIndex(index)}
                okText="Yes"
                cancelText="No"
              >
                <div style={{width:"100%", display:"flex",justifyContent:"end"}}>
                    <a style={{ color: "#EB0000", textDecoration: "underline", fontSize: 16, fontWeight: 500 }}>
                    {deleteLoading ? <Spinner animation="border" variant="danger" size="sm" /> : <img style={{height:"32px"}} src="/images/delete.png" />}
                    </a>
                </div>
              </Popconfirm>
            },
        }
    ]
    useEffect(() => {
        getComments()
        getLoanofficer()
    }, [])
    const changeStatus = (value, index) => {
        const updatedComments = [...commentLists]
        updatedComments[index].status = value
        updateComments(updatedComments)
    }
    const deleteCommentByIndex = (index) => {
        setDeleteLoading(true)
        //delete the comment by index and update the list
        const updatedComments = [...commentLists]
        updatedComments.splice(index, 1)
        updateComments(updatedComments)
    }
    const getLoanofficer = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/loanofficerlist', {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        }
        }).then(
        response => {
            if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
                response.status)
            alert("Something went wrong. Please try again later.")
            return;
            }
            response.json().then(js => {
            if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
            } else {
                let docs = js.LoanOfficers.map(item => ({value: `${item.FirstName} ${item.LastName}`, label: `${item.FirstName} ${item.LastName}`}))
                setNameList(docs)
            }})
        }).catch(function (err) {
        console.log('Fetch Error :-S', err)
      })
    }
    const getComments = () => {
        setLoading(true)
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/getloancomments', {
          body: JSON.stringify(
            {LoanID: props.loanid}
          ),
          method: 'POST',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          }
        }).then(
          response => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                response.status);
              setLoading(false)
              return;
            }
            response.json().then(js => {
              let d = JSON.parse(js)
              console.log(d)
              if (d["pipeline"] !== undefined && d["pipeline"]["comments"] !== undefined) {
                setCommentLists(d["pipeline"]["comments"])
              }
              setLoading(false)
            });
          }
        ).catch(function (err) {
            console.log('Fetch Error :-S', err);
            setLoading(false)
          });
    }


    const updateComments = (comments) => {
        let token = sessionStorage.getItem("ZeitroA")  

        fetch('/los/updateloancomments', {
          body: JSON.stringify(
            {
                LoanID: props.loanid,
                Data: JSON.stringify(comments),
            }
          ),
          method: 'POST',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          }
        }).then(
          response => {
            setLoading(false)
            setDeleteLoading(false)
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status)
                return
            }
            getComments()
          }
        ).catch(function (err) {
            console.log('Fetch Error :-S', err);
            setLoading(false)
          });
    }

    const addComments = (comment) => {
        let token = sessionStorage.getItem("ZeitroA")  
        fetch('/los/addloancomments', {
          body: JSON.stringify(
            {
                LoanID: props.loanid,
                Data: JSON.stringify(comment),
            }
          ),
          method: 'POST',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          }
        }).then(
          response => {
            setLoading(false)
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status)
                return
            }
            getComments()
          }
        ).catch(function (err) {
            console.log('Fetch Error :-S', err);
            setLoading(false)
          });
    }

    const disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    }

    const validateMessages = {
        required: '${label} is required!',
    }
    const handleValuesChange = (changedValues, allValues) => {
        
    }
    const validateForm = () => {
        form.validateFields().then((values) => {
            const comment = {
                fullName: values.name,
                category: values.category,
                created: dayjs().format('MM/DD/YYYY'),
                duedate: dayjs(values.dueDate).format('MM/DD/YYYY'),
                detailes: values.comment,
                status: 2,
            }
            addComments(comment)
            resetForm()
            setIsViewfilesOpen(false)
        })
        .catch((errorInfo) => {
            console.log('Validation failed:', errorInfo)
        })
    }
    const resetForm = () => {
        form.resetFields()
    }

    const showAddComment = () => {
        setIsViewfilesOpen(true)
        resetForm()
    }

    const handleSave = () => {
        validateForm()
    }
    const handleCancel = () => {
        setIsViewfilesOpen(false)
    }
    return (
        <div className="overview-comment text-left">
            <div className='overview-comment-title'>
                <span className='comment-title'>Comment </span>
                <Button onClick={showAddComment} key="submit" className='overview-comment-btn btn-default' type="primary">
                    Add Comment
                </Button>
            </div>
            <Table className="comment-table" loading={loading}  columns={columns} dataSource={commentLists} />
            <Modal className='viewFiles-modal' maskClassName="viewFiles-modal-mask" centered width={700} zIndex={1002} open={isViewfilesOpen} onCancel={handleCancel}
                footer={[
                    <Button key="back" className='overview-comment-btn btn-line' onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button key="submit" className='overview-comment-btn btn-default' type="primary" onClick={handleSave}>
                      Save
                    </Button>
                  ]}
            >
                <div className='comment-wrap'>
                    <Form
                        className='comment-form'
                        layout='vertical'
                        form={form}
                        onValuesChange={handleValuesChange}
                        validateMessages={validateMessages}
                        size="large"
                    >
                        <Row gutter={60}>
                                <Col span={24} sm={12} style={{marginBottom: '12px'}}>
                                    <Form.Item
                                        name="name" 
                                        label="Assign this comment to"
                                        rules={[
                                            {
                                                required: true,
                                            }
                                        ]}
                                    >
                                        <Select
                                            allowClear
                                            options={nameList}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12} style={{marginBottom: '12px'}}>
                                    <Form.Item
                                        name="dueDate" 
                                        label="Due Date"
                                        rules={[
                                            {
                                                required: true,
                                            }
                                        ]}
                                    >
                                        <DatePicker
                                            style={{width: '100%'}}
                                            className='closing-date'
                                            disabledDate={disabledDate}
                                            placeholder="MM/DD/YYYY"
                                            format="MM/DD/YYYY"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        <Form.Item 
                            name="category" 
                            label="Category"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input placeholder="Borrower Credit"  />
                        </Form.Item>
                        <Form.Item
                            name="comment"
                            label="Comment"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <TextArea
                                autoSize={{
                                    minRows: 4,
                                    maxRows: 6
                                }}
                                placeholder="Revolving balance cosistently near limits. Discuss impact on approval with borrower..."
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>

        </div>
    )
}

Comment = connect(mapStateToProps, mapDispatchToProps)(Comment);
