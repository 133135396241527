import React, { useState, useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Tabs,
  DatePicker,
  Button,
  Checkbox,
  Tooltip,
  Select,
  Switch,
  Modal,
  Divider,
  Form,
  Input,
  InputNumber,
  Slider,
  Col,
  Row
} from 'antd';
import {
    QuestionCircleOutlined
  } from '@ant-design/icons';
import moment from 'moment';
import "./Common.css";
import "./PropertyAndLoan.css";
import * as com from "../../Common"
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import GoogleAutocomplete from '../../Common/GoogleAutocomplete/GoogleAutocomplete'

const mapStateToProps = (state) => {
    return {
        property: state.application.property,
        application: state.application,
        progress: state.progress,
    }
  }

const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
      dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    },
    changeMainPropertyAttribute: (t, verb) => {
      dispatch(act.ChangeMainPropertyAttribute(t, verb));
    },
    updateHasCoborrower: (val) => {
        dispatch(act.UpdateHasCoborrower(val))
    },
    updateProduct: (v, verb) => {
      dispatch(act.UpdateProduct(v, verb))
    },
    changeBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "borrower", verb))
    },
    changeCoBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "coborrower", verb))
    },
  });

export default function PropertyAndLoan(props) {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loanPurpose, setLoanPurpose] = useState(props.application.hardmoneyloan.rehabloan && props.property.purpose==="purchase" ? "purchaserehab" : props.property.purpose);
    const [loanLimits, setLoanLimits] = useState(null)
    const [stateOptions, setStateOptions] = useState([])
    const [countyOptions, setCountyOptions] = useState([])
    const onFinish = (values) => {
        console.log(values);
        props.finish(values)
    }
    const disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    };
    const inputFormatter = (value) => {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const inputParser = (value) => {
        return value.replace(/\\s?|(,*)/g, '')
    }
    const onChangeLoanPurpose = (v) => {
        setLoanPurpose(v)
        if (v==="purchaserehab") {
            props.updateHardMoneyLoanAttribute(true, "rehabloan")
            props.changeMainPropertyAttribute("purchase", "purpose")
        } else {
            props.changeMainPropertyAttribute(v, "purpose")
        }
    }
    const getAddress = (val) => {
        console.log(val)
        let zipCode = com.getSubaddress(val.address_components, "postal_code", "long_name")
        let state = com.getSubaddress(val.address_components, "administrative_area_level_1", "long_name")
        let county = com.getSubaddress(val.address_components, "administrative_area_level_2", "long_name")
        form.setFieldsValue({
            zipCode: zipCode,
            state: state,
            county: county
        })
        props.changeMainPropertyAttribute(zipCode, "zipcode")
        props.changeMainPropertyAttribute(state, "state")
        props.changeMainPropertyAttribute(county, "county")
    }
    const fetchLoanLimits = () => {
        fetch("/data/loanlimits")
        .then((res) => {
          if (!res.ok) {
            console.log(res);
            throw Error(res.statusText);
          }
          return res.json();
        })
        .then((res) => {
          setLoanLimits(res)
          setStateOptions(Object.keys(res).map(item => ({value: com.capitalize(item), label: com.capitalize(item)})))
        })
        .catch((err) => {
          console.error(err);
        });
    }
    const onChangeState = (val) => {
        props.changeMainPropertyAttribute(val, "state")
        form.setFieldsValue({
            county: undefined,
        })
        setCountyOptions(val ? Object.keys(loanLimits[val.toUpperCase()]).map(item => ({value: com.capitalize(item), label: com.capitalize(item)})): [])
    }
    const onChangeZip = (e) => {
        let formatVal = e.target.value.replace(/\-/g,"");
        form.setFieldsValue({
            zipCode: formatVal,
        })
        props.changeMainPropertyAttribute(String(formatVal), "zipcode")
    }
    useEffect(() => {
        fetchLoanLimits()
    }, []);
    const onChangeLoanAmountRequested = (val) => {
        // add rehabloanamount?
        props.updateHardMoneyLoanAttribute(String(val), "initialloanamount")
    }
    const onChangeRehabDetails = (val, key) => {
        let r = {...props.application.hardmoneyloan.rehabdetails}
        r[key] = val
        props.updateHardMoneyLoanAttribute(r, "rehabdetails")
    }
    const onChangeRefinanceDetails = (val, key) => {
        let r = {...props.application.hardmoneyloan.refinancedetails}
        r[key] = val
        props.updateHardMoneyLoanAttribute(r, "refinancedetails")
    }
    const PropertyTypeOptions = [
        {
            value: 'singlefamilydetached',
            label: 'Single Family Residence'
        },
        {
            value: 'commercial',
            label: 'Commercial'
        },
        {
            value: 'condominium',
            label: 'Condo'
        },
        {
            value: 'Mixed Use',
            label: 'mixeduse'
        },
    ]
    const loanTypeOptions = [
        {
            value: 'fixflip',
            label: 'Fix / Flip'
        },
        {
            value: 'fixrent',
            label: 'Fix / Rent'
        },
        {
            value: 'bridge',
            label: 'Bridge'
        },
        {
            value: 'groundup',
            label: 'Ground Up'
        },
        {
            value: 'rental',
            label: 'Rental'
        }
    ]
    const loanPurposeOptions = [
        {
            value: 'purchase',
            label: 'Purchase'
        },
        {
            value: 'purchaserehab',
            label: 'Purchase and Rehab'
        },
        {
            value: 'refinance',
            label: 'Refinance'
        },
        {
            value: 'construction',
            label: 'Construction'
        }
    ]
    const loanTermOptions = [
        {
          value: 12,
          label: '12 Months'
        },
        {
          value: 24,
          label: '24 Months'
        },
        {
          value: 36,
          label: '36 Months'
        },
        {
          value: 48,
          label: '48 Months'
        },
        {
          value: 60,
          label: '60 Months'
        }
    ]
    const options = [
        {
            value: true,
            label: 'Yes'
        },
        {
            value: false,
            label: 'No'
        }
    ]
    const validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        }
    };
    const cd = props.application.hardmoneyloan.targetclosingdate ? dayjs(props.application.hardmoneyloan.targetclosingdate, "MM/DD/YYYY") : ''
    const formInitial = {
        streetAddress: props.property.address,
        state: props.property.state,
        county: props.property.county,
        zipCode: props.property.zipcode,
        propertyType: props.property.propertytype,
        loanType: props.application.hardmoneyloan.loantype,
        loanPurpose: (props.application.hardmoneyloan.rehabloan && props.property.purpose==="purchase") ? "purchaserehab" : props.property.purpose,
        purchaseprice: props.property.salesprice,
        escrowDate: cd,
        initialAdvanceRequested: props.application.hardmoneyloan.initialadvancerequested,
        rehabRequested: props.application.hardmoneyloan.rehabloanamount,
        totalLoanAmountRequested: props.application.hardmoneyloan.initialloanamount,
        loanTermRequested: props.application.hardmoneyloan.mortgagetermmonths,
        ARV: props.application.hardmoneyloan.projectedafterrepairvalue,
        assignmentFee: props.application.hardmoneyloan.assignmentfee,
        liquidity: props.application.hardmoneyloan.liquidity,
        exitStrategy: props.application.hardmoneyloan.exitstrategy,
        rehabRemaining: props.application.hardmoneyloan.rehabdetails.rehabremaining,
        rehabCostSpentToDate: props.application.hardmoneyloan.rehabdetails.rehabcostspenttodate,
        addingSqft: props.application.hardmoneyloan.rehabdetails.addingsqft,
        sqftAdded: props.application.hardmoneyloan.rehabdetails.sqftadded,
        addingUnits: props.application.hardmoneyloan.rehabdetails.addingunits,
        originalPurchasePrice: props.application.hardmoneyloan.refinancedetails.originalPurchasePrice,
        dateOfPurchase: props.application.hardmoneyloan.refinancedetails.dateOfPurchase ? dayjs(props.application.hardmoneyloan.refinancedetails.dateOfPurchase, "MM/DD/YYYY") : '',
        estimatedCurrentValue: props.application.hardmoneyloan.refinancedetails.estimatedCurrentValue,
        existingLoanAmount: props.application.hardmoneyloan.refinancedetails.existingLoanAmount,
        existingLender: props.application.hardmoneyloan.refinancedetails.existingLender,
        existingLoanRate: props.application.hardmoneyloan.refinancedetails.existingLoanRate,
        existingLoanMaturityDate: props.application.hardmoneyloan.refinancedetails.existingLoanMaturityDate ? dayjs(props.application.hardmoneyloan.refinancedetails.existingLoanMaturityDate,"MM/DD/YYYY") : '',
        subordinateDebt: props.application.hardmoneyloan.refinancedetails.subordinateDebt,
        midConstruction: props.application.hardmoneyloan.refinancedetails.midConstruction,
    }
    const formItemMode = (formItem, fields, type) => {
        if (formItem && props.editMode) {
            return formItem
        } else {
            if (type === 'Amount') {
                return <div style={{fontSize: '16px'}}>{fields && fields !=='' ? `$ ${inputFormatter(fields)}`: '--'}</div>
            } else if (type === 'Y/N') {
                return <div style={{fontSize: '16px'}}>{fields === true ? 'Yes': fields === false ? 'No' : '--'}</div>
            }
            return <div style={{fontSize: '16px'}}>{fields && fields !=='' ? fields: '--'}</div>
        }
    }
    useEffect(() => {
        form.resetFields()
    }, [props.editMode])
    return (
        <div className="borrower-form text-left">
            <Form
                className='application-form'
                layout='vertical'
                form={form}
                onFinish={onFinish}
                initialValues={formInitial}
                validateMessages={validateMessages}
                size="large"
            >
                <Row>
                    <span className='form-title'>Property Information</span>
                </Row>
                <Row gutter={30}>
                    <Col span={24}>
                        <Form.Item
                            name="streetAddress"
                            label="Property address"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            {formItemMode(<GoogleAutocomplete placeholder="Street address" getPlace={getAddress} onChange={(e)=>{props.changeMainPropertyAttribute(e, "address")}} />, props.property.address)}
                        </Form.Item>
                    </Col>
                    {/* <Col span={24}>
                        <Form.Item
                            name="Apt"
                            style={{marginBottom: '10px'}}
                        >
                            <Input placeholder="Apt, suite, etc (Optional)" />
                        </Form.Item>
                    </Col> */}
                    <Col span={24} sm={8}>
                        <Form.Item
                            name="state"
                            rules={[
                                {
                                  required: true,
                                },
                            ]}
                        >
                            {
                                formItemMode(
                                    <Select
                                        allowClear
                                        options={stateOptions}
                                        onChange={onChangeState}
                                        placeholder="Select your state"
                                    />,
                                    props.property.state
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={8}>
                        <Form.Item
                            name="county"
                            rules={[
                                {
                                  required: true,
                                },
                            ]}
                        >
                            {
                                formItemMode(
                                    <Select
                                        allowClear
                                        options={countyOptions}
                                        placeholder="Select your county"
                                        onChange={(e)=>props.changeMainPropertyAttribute(e, "county")}
                                    />,
                                    props.property.county
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={8}>
                        <Form.Item
                            name="zipCode"
                            rules={[
                                {
                                  required: true,
                                },
                            ]}
                        >
                            {
                                formItemMode(
                                    <Input
                                        type='number'
                                        style={{ width: '100%' }}
                                        placeholder="Zip Code"
                                        min={0}
                                        onChange={onChangeZip}
                                    />,
                                    props.property.zipcode
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col span={24} sm={12}>
                        <Form.Item
                            name="propertyType"
                            label="Property type"
                            rules={[
                                {
                                  required: true,
                                },
                            ]}
                            tooltip={{
                                title: 'Property Type',
                                icon: <QuestionCircleOutlined className="tips-icon" />,
                            }}
                        >
                            {
                                formItemMode(
                                    <Select
                                        allowClear
                                        options={PropertyTypeOptions}
                                        placeholder="Select Property type"
                                        onChange={(e)=>props.changeMainPropertyAttribute(e, "propertytype")}
                                    />,
                                    props.property.propertytype
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <span className='form-title'>Loan Information</span>
                </Row>
                <Row gutter={30}>
                    <Col span={24} sm={12}>
                        <Form.Item
                            name="loanType"
                            label="Loan purpose"
                            rules={[
                                {
                                  required: true,
                                },
                            ]}
                        >
                            {
                                formItemMode(
                                    <Select
                                        allowClear
                                        options={loanTypeOptions}
                                        placeholder="Select Loan type"
                                        onChange={e=>props.updateHardMoneyLoanAttribute(e, "loantype")}
                                    />,
                                    props.application.hardmoneyloan.loantype
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                        <Form.Item
                            name="loanPurpose"
                            label="Loan type"
                            rules={[
                                {
                                  required: true,
                                },
                            ]}
                        >
                            {
                                formItemMode(
                                    <Select
                                        allowClear
                                        options={loanPurposeOptions}
                                        placeholder="Select Loan purpose"
                                        onChange={onChangeLoanPurpose}
                                    />,
                                    (props.application.hardmoneyloan.rehabloan && props.property.purpose==="purchase") ? "purchaserehab" : props.property.purpose
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                {
                    loanPurpose === 'purchaserehab' && (
                        <>
                            <Divider />
                            <Row>
                                <span className='form-sub-title'>Purchase Loan Details</span>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="purchaseprice"
                                        label="Purchase price"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>props.changeMainPropertyAttribute(e, "salesprice")}
                                                />,
                                                props.property.salesprice,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="escrowDate"
                                        label="Scheduled close of escrow date"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <DatePicker
                                                    style={{width: '100%'}}
                                                    className='closing-date'
                                                    disabledDate={disabledDate}
                                                    placeholder="MM/DD/YYYY"
                                                    format="MM/DD/YYYY"
                                                    onChange={(e)=>{
                                                        let dat = new Date(e)
                                                        props.updateHardMoneyLoanAttribute(e? dayjs(dat).format('MM/DD/YYYY') : '', "targetclosingdate")
                                                    }}
                                                />,
                                                props.application.hardmoneyloan.targetclosingdate
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="initialAdvanceRequested"
                                        label="Initial advance requested"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(String(e), "initialadvancerequested")}
                                                />,
                                                props.application.hardmoneyloan.initialadvancerequested,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="rehabRequested"
                                        label="Rehab requested"
                                        rules={[
                                            {
                                              required: false,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(String(e), "rehabloanamount")}
                                                />,
                                                props.application.hardmoneyloan.rehabloanamount,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="totalLoanAmountRequested"
                                        label="Total loan amount requested"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={onChangeLoanAmountRequested}
                                                />,
                                                props.application.hardmoneyloan.initialloanamount,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="loanTermRequested"
                                        label="Loan term requested"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <Select
                                                    allowClear
                                                    options={loanTermOptions}
                                                    placeholder="Select Loan term"
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(e, "mortgagetermmonths")}
                                                />,
                                                props.application.hardmoneyloan.mortgagetermmonths
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="ARV"
                                        label="Estimated after repair value (ARV)"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(String(e), "projectedafterrepairvalue")}
                                                />,
                                                props.application.hardmoneyloan.projectedafterrepairvalue,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="assignmentFee"
                                        label="Assignment fee (Y/N)"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <Select
                                                    allowClear
                                                    options={options}
                                                    placeholder="Select Loan term"
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(e, "assignmentfee")}
                                                />,
                                                props.application.hardmoneyloan.assignmentfee,
                                                'Y/N'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item name="liquidity" label="Liquidity">
                                        {formItemMode(<Input placeholder="Optional" onChange={e=>props.updateHardMoneyLoanAttribute(e, "liquidity")}/>, props.application.hardmoneyloan.liquidity)}
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item name="exitStrategy" label="Exit strategy">
                                        {
                                            formItemMode(
                                                <Select
                                                    allowClear
                                                    options={loanTermOptions}
                                                    placeholder="Optional"
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(e, "exitstrategy")}
                                                />,
                                                props.application.hardmoneyloan.exitstrategy
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <span className='form-sub-title'>Rehab Details</span>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="rehabRemaining"
                                        label="Rehab remaining"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>onChangeRehabDetails(String(e), "rehabremaining")}
                                                />,
                                                props.application.hardmoneyloan.rehabdetails.rehabremaining,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="rehabCostSpentToDate"
                                        label="Rehab cost spent to date"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>onChangeRehabDetails(String(e), "rehabcostspenttodate")}
                                                />,
                                                props.application.hardmoneyloan.rehabdetails.rehabcostspenttodate,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="addingSqft"
                                        label="Adding sqft (Y/N)"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <Select
                                                    allowClear
                                                    options={options}
                                                    placeholder=""
                                                    onChange={e=>onChangeRehabDetails(e, "addingsqft")}
                                                />,
                                                props.application.hardmoneyloan.rehabdetails.addingsqft,
                                                'Y/N'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="sqftAdded"
                                        label="Sqft added"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {formItemMode(<Input placeholder="N/A" onChange={e=>onChangeRehabDetails(e, "sqftadded")}/>, props.application.hardmoneyloan.rehabdetails.sqftadded)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="addingUnits"
                                        label="Adding units (Y/N)"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <Select
                                                    allowClear
                                                    options={options}
                                                    placeholder=""
                                                    onChange={e=>onChangeRehabDetails(e, "addingunits")}
                                                />,
                                                props.application.hardmoneyloan.rehabdetails.addingunits,
                                                'Y/N'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )
                }
                {/* Refinance Loan Details */}
                {
                    loanPurpose === 'refinance' && (
                        <>
                            <Divider />
                            <Row>
                                <span className='form-sub-title'>Refinance Loan Details</span>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="originalPurchasePrice"
                                        label="Original purchase price"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>onChangeRefinanceDetails(String(e), "originalPurchasePrice")}
                                                />,
                                                props.application.hardmoneyloan.refinancedetails.originalPurchasePrice,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="dateOfPurchase"
                                        label="Date of purchase"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <DatePicker style={{width: '100%'}} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" onChange={e=>{
                                                    let dat = new Date(e)
                                                        onChangeRefinanceDetails(e? dayjs(dat).format('MM/DD/YYYY') : '', "dateOfPurchase")
                                                    }}
                                                />,
                                                props.application.hardmoneyloan.refinancedetails.dateOfPurchase
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="initialAdvanceRequested"
                                        label="Initial advance requested"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(String(e), "initialadvancerequested")}
                                                />,
                                                props.application.hardmoneyloan.initialadvancerequested,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="rehabRequested"
                                        label="Rehab requested"
                                        rules={[
                                            {
                                              required: false,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(String(e), "rehabloanamount")}
                                                />,
                                                props.application.hardmoneyloan.rehabloanamount,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="totalLoanAmountRequested"
                                        label="Total loan amount requested"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(String(e), "refinanceloanamount")}
                                                />,
                                                props.application.hardmoneyloan.initialloanamount,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="loanTermRequested"
                                        label="Loan term requested"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <Select
                                                    allowClear
                                                    options={loanTermOptions}
                                                    placeholder="Select Loan term"
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(e, "mortgagetermmonths")}
                                                />,
                                                props.application.hardmoneyloan.mortgagetermmonths
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="estimatedCurrentValue"
                                        label="Estimated current value (LTV)"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>onChangeRefinanceDetails(String(e), "estimatedCurrentValue")}
                                                />,
                                                props.application.hardmoneyloan.refinancedetails.estimatedCurrentValue,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="ARV"
                                        label="Estimated after repair value (ARV)"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(String(e), "projectedafterrepairvalue")}
                                                />,
                                                props.application.hardmoneyloan.projectedafterrepairvalue,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="existingLoanAmount"
                                        label="Existing loan amount (Payoff)"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '100%' }}
                                                    max={10000000}
                                                    min={0}
                                                    formatter={inputFormatter}
                                                    parser={inputParser}
                                                    onChange={e=>onChangeRefinanceDetails(String(e), "existingLoanAmount")}
                                                />,
                                                props.application.hardmoneyloan.refinancedetails.existingLoanAmount,
                                                'Amount'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="existingLender"
                                        label="Existing lender"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {formItemMode(<Input placeholder="" onChange={e=>onChangeRefinanceDetails(e.target.value, "existingLender")}/>, props.application.hardmoneyloan.refinancedetails.existingLender)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="existingLoanRate"
                                        label="Existing loan rate"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <InputNumber
                                                    addonAfter="%"
                                                    style={{ width: '100%' }}
                                                    max={100}
                                                    min={0}
                                                    onChange={e=>onChangeRefinanceDetails(String(e), "existingLoanRate")}
                                                />,
                                                props.application.hardmoneyloan.refinancedetails.existingLoanRate
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="existingLoanMaturityDate"
                                        label="Existing loan maturity date"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <DatePicker style={{width: '100%'}} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" onChange={e=>{
                                                    let dat = new Date(e)
                                                        onChangeRefinanceDetails(e? dayjs(dat).format('MM/DD/YYYY') : '', "existingLoanMaturityDate")
                                                    }}
                                                />,
                                                props.application.hardmoneyloan.refinancedetails.existingLoanMaturityDate
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="subordinateDebt"
                                        label="Subordinate debt (Y/N-$)"
                                        rules={[
                                            {
                                              required: true,
                                            },
                                        ]}
                                    >
                                        {
                                            formItemMode(
                                                <Select
                                                    allowClear
                                                    options={options}
                                                    placeholder="Select Loan term"
                                                    onChange={e=>onChangeRefinanceDetails(e, "subordinateDebt")}
                                                />,
                                                props.application.hardmoneyloan.refinancedetails.subordinateDebt,
                                                'Y/N'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item name="liquidity" label="Liquidity">
                                        {formItemMode(<Input placeholder="Optional" onChange={e=>props.updateHardMoneyLoanAttribute(e, "liquidity")}/>, props.application.hardmoneyloan.liquidity)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col span={24} sm={12}>
                                    <Form.Item name="exitStrategy" label="Exit strategy">
                                        {
                                            formItemMode(
                                                <Select
                                                    allowClear
                                                    options={loanTermOptions}
                                                    placeholder="Optional"
                                                    onChange={e=>props.updateHardMoneyLoanAttribute(e, "exitstrategy")}
                                                />,
                                                props.application.hardmoneyloan.exitstrategy
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item name="midConstruction" label="Mid construction (Y/N)">
                                        {
                                            formItemMode(
                                                <Select
                                                    allowClear
                                                    options={options}
                                                    placeholder=""
                                                    onChange={e=>onChangeRefinanceDetails(e, "midConstruction")}
                                                />,
                                                props.application.hardmoneyloan.refinancedetails.midConstruction,
                                                'Y/N'
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )
                }
                {
                    props.editMode && (
                        <Row>
                            <Form.Item>
                                <Button type="primary" className='common-btn' htmlType="submit">
                                    Continue
                                </Button>
                            </Form.Item>
                        </Row>
                    )
                }
            </Form>
        </div>
    )
}

PropertyAndLoan = connect(mapStateToProps, mapDispatchToProps)(PropertyAndLoan);
