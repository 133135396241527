import React, { useState, useEffect } from "react"
import { Switch, Col, Row, Modal, Table, Button, Checkbox, Form, Input, Select, Tooltip, Collapse, Popconfirm } from 'antd'
import { CaretDownOutlined, CaretUpOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons'
import FullHeightModal from "../Common/components/FullHeightModal"


import { checkAccess } from "../Auth"
import { useSelector } from 'react-redux'


const vendorsList = {
    'KCBCredit': 'KCB Credit',
    'AdvantageCredit': 'Advantage Credit',
    // 'AmericanReportingCompany': 'American Reporting Company',
    // 'BirchwoodCreditService': 'Birchwood Credit Service',
    // 'CICCredit': 'CIC Credit',
    // 'CreditPlus': 'Credit Plus',
    // 'LASERCreditAccess': 'LASER Credit Access',
    // 'Service1st': 'Service 1 st',
    // 'TriVerify': 'TriVerify',
    // 'UnitedOn Resources': 'United One Resources',
}


const Feature = () => {
    const subscription = useSelector(state => {

        return state.subscriptionReducer.currentInfo
    })
    const [form] = Form.useForm()
    const [currentInfo, getCurrentInfo] = useState({})

    const [isAccounTModalOpen, setAccounTModalOpen] = useState(false)



    const [CheckboxValue, getCheckboxValue] = useState([])


    const [Accounts, setAccounts] = useState([])
    const [currentAccountsInfo, setCurrentAccountsInfo] = useState({})

    const getcreditaccountconfig = async () => {

        let token = sessionStorage.getItem("ZeitroA")
        const response = await fetch('/los/getcreditaccountconfig', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        })
        if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
                response.status)

            return
        }
        const js = await response.json()
        console.log(js)
        let newArray = [


        ]
        const soft = js.Accounts.findIndex(item => item.Type == 'SoftPull')
        const Hard = js.Accounts.findIndex(item => item.Type == 'HardPull')

        if (soft === -1) {
            newArray = [...js.Accounts.map((item) => {
                item.show = true
                return item
            }), {
                Vendor: "",
                Username: "",
                Password: "",
                UseExperian: false,
                UseEquifax: false,
                UseTransUnion: false,
                AutomatedSoftCreditPull: false,
                Type: 'SoftPull',
                show: false
                ,
            }]
        }
        if (Hard === -1) {
            newArray = [...js.Accounts.map((item) => {
                item.show = true
                return item
            }), {
                Vendor: "",
                Username: "",
                Password: "",
                UseExperian: false,
                UseEquifax: false,
                UseTransUnion: false,
                AutomatedSoftCreditPull: false,
                Type: 'HardPull',
                show: false,
            }]

        }
        setAccounts(js.Accounts.length === 0 ? [
            {
                Vendor: "",
                Username: "",
                Password: "",
                UseExperian: false,
                UseEquifax: false,
                UseTransUnion: false,
                AutomatedSoftCreditPull: false,
                Type: 'SoftPull',
                show: false,
            },
            {
                Vendor: "",
                Username: "",
                Password: "",
                UseExperian: false,
                UseEquifax: false,
                UseTransUnion: false,
                AutomatedSoftCreditPull: false,
                Type: 'HardPull',
                show: false,
            },

        ] : newArray.length === 0 ? js.Accounts.map((item) => {
            item.show = true
            return item
        }) : newArray)
        setSwitch(js?.AutomatedSoftCreditPull)





    }
    const getBureaus = (item) => {
        const BureauList = []
        if (item.UseExperian) {
            BureauList.push('Experian')

        }
        if (item.UseEquifax) {
            BureauList.push('Equifax')

        }
        if (item.UseTransUnion) {
            BureauList.push('Trans Union')

        }

        return BureauList
    }
    useEffect(() => {
        getcreditaccountconfig()
        console.log(checkAccess(["Owner"]))
        const { customerID, loanOfficers, plan, stripeCustomer, recurringInterval } = subscription
        getCurrentInfo({
            customerID,
            loanOfficers,
            plan,
            stripeCustomer,
            recurringInterval

        })
        setSwitch(plan === 'starter' ? false : true)
    }, [])

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }




    const [isSwitch, setSwitch] = useState(true)
    const [isShowTable, setIsShowTable] = useState(false)
    const AccountsContent = () => {
        const onFinish = async (value) => {
            console.log(value, {
                ...currentAccountsInfo, ...value,
            }
            )
            setCurrentAccountsInfo({
                ...currentAccountsInfo, ...value,
            })
            setAccounTModalOpen(false)

        }
        return (<div className='mx-3 ' style={{ padding: '0px 20px 0px 20px', height: 'calc(100vh - 50px)', display: 'flex', flexDirection: 'column', justifyContent: "space-between" }}>
            <div>
                <div className='usage_title mt-4  Support'>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }} onClick={() => {

                        setIsShowTable(false)
                    }} >
                        <LeftOutlined style={{
                            fontSize: 12, color
                                : '#545459'
                        }} /> <span style={{ marginLeft: 15, color: '#545459' }}>  {currentAccountsInfo.Type === 'SoftPull' ? 'Soft' : 'Hard'} credit check</span>
                    </div>
                </div>
                <Row className="feature_check" >


                    <Col span={24}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <div style={{ color: '#222', fontSize: 16, fontWeight: 600 }}>
                                    Credit account
                                </div>
                                <div style={{ color: "#6E6E70", fontSize: 14 }}>
                                    Add credit account(s) so you can make faster, easier credit check with Zeitro
                                </div>
                            </div>
                            {currentAccountsInfo.Username === '' ? <Button onClick={() => {
                                setAccounTModalOpen(true)
                                console.log(121212)
                            }} style={{ width: 100, borderColor: "#325CEB", color: '#325CEB', fontWeight: "600" }}> Add</Button> : <></>}
                        </div>
                        {
                            currentAccountsInfo.Username === '' ?
                                <div style={{ color: "#586570", marginTop: 16, fontSize: 16, fontWeight: 500 }}>
                                    No account added

                                </div> :
                                <div style={{ color: "#222", marginTop: 16, fontSize: 16, fontWeight: 500, display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ marginLeft: 10 }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M9.99967 9.99984C12.3008 9.99984 14.1663 8.13435 14.1663 5.83317C14.1663 3.53199 12.3008 1.6665 9.99967 1.6665C7.69849 1.6665 5.83301 3.53199 5.83301 5.83317C5.83301 8.13435 7.69849 9.99984 9.99967 9.99984Z" fill="#474849" />
                                            <path d="M9.99984 12.0835C5.82481 12.0835 2.4248 14.8835 2.4248 18.3335C2.4248 18.5668 2.60814 18.7502 2.84147 18.7502H17.1582C17.3915 18.7502 17.5748 18.5668 17.5748 18.3335C17.5748 14.8835 14.1748 12.0835 9.99984 12.0835Z" fill="#474849" />
                                        </svg> <span>
                                            {currentAccountsInfo.Vendor} - {currentAccountsInfo.Username}
                                        </span>

                                    </div>
                                    <div>
                                        <Popconfirm
                                            title="Delete the account"
                                            description="Are you sure to delete this account?"
                                            onConfirm={async () => {
                                                let token = sessionStorage.getItem("ZeitroA")
                                                const response = await fetch('/los/deletecreditaccount', {
                                                    method: 'POST',
                                                    body: JSON.stringify({
                                                        Type: currentAccountsInfo.Type



                                                    }),
                                                    headers: {
                                                        Authorization: "Bearer " + token,
                                                        Cache: "no-cache"
                                                    }
                                                })
                                                await response.json()
                                                setCurrentAccountsInfo({
                                                    ...currentAccountsInfo,
                                                    Vendor: '',
                                                    Username: '',
                                                    Password: ''
                                                })
                                                setIsShowTable(false)
                                                getcreditaccountconfig()
                                            }}

                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button danger type='link' style={{ fontWeight: 600 }} >Delete</Button>
                                        </Popconfirm>

                                        <Button type='link' style={{ fontWeight: 600 }}
                                            onClick={() => {

                                                form.setFieldsValue(currentAccountsInfo)
                                                setAccounTModalOpen(true)
                                            }}
                                        >Edit</Button>
                                    </div>
                                </div>

                        }
                        <div className="line"></div>
                        <div style={{ marginTop: 20 }}>
                            <div style={{ color: '#222', fontSize: 16, fontWeight: 600 }}>
                                Credit bureau(s)
                            </div>
                            <Checkbox.Group value={CheckboxValue} style={{ width: '100%', marginTop: 10, justifyContent: "space-between" }} onChange={(e) => {
                                getCheckboxValue(e)

                                console.log(e.includes("UseExperian"))


                                currentAccountsInfo.UseExperian = e.includes("UseExperian")
                                currentAccountsInfo.UseEquifax = e.includes("UseEquifax")
                                currentAccountsInfo.UseTransUnion = e.includes("UseTransUnion")





                            }} >
                                <div className="CheckBoxBureau">
                                    <Checkbox value="UseExperian">
                                        <div>
                                            <img src="/images/experian.svg" />
                                        </div>
                                    </Checkbox>
                                    <div style={{ marginLeft: 27, marginTop: 12 }}>
                                        Experian
                                    </div>
                                </div>
                                <div className="CheckBoxBureau">  <Checkbox value="UseEquifax">
                                    <img src="/images/equifax.svg" />

                                </Checkbox>
                                    <div style={{ marginLeft: 27, marginTop: 20 }}>
                                        Equifax
                                    </div></div>
                                <div className="CheckBoxBureau">  <Checkbox value="UseTransUnion">
                                    <img src="/images/transunion.svg" />

                                </Checkbox>
                                    <div style={{ marginLeft: 27, marginTop: 12 }}>
                                        Trans Union
                                    </div></div>

                            </Checkbox.Group>

                        </div>
                        {currentAccountsInfo.Type === 'SoftPull' ? <div className="usage" style={{ padding: "20px 0" }}>
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                                <div className="usage_title">Automated Soft Credit Pulls</div>

                                <Switch checked={currentAccountsInfo.AutomatedSoftCreditPull} onChange={async (e) => {
                                    setCurrentAccountsInfo({ ...currentAccountsInfo, AutomatedSoftCreditPull: e })
                                    let token = sessionStorage.getItem("ZeitroA")
                                    const response = await fetch('/los/addcreditaccount', {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            ...currentAccountsInfo,
                                            AutomatedSoftCreditPull: e



                                        }),
                                        headers: {
                                            Authorization: "Bearer " + token,
                                            Cache: "no-cache"
                                        }
                                    })
                                    if (response.status !== 200) {
                                        console.log('Looks like there was a problem. Status Code: ' +
                                            response.status)

                                        return
                                    }
                                    setSwitch(e)
                                    getcreditaccountconfig()



                                }}></Switch>

                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%", marginTop: "15px" }}>
                                <div className="usage_content">Enabling this feature allows your loan officers to activate the automated soft pull functionality on their settings - feature customization page. This gives their borrowers the option to opt-in for automated soft credit checks. Upon activation, a soft credit pull occurs when a borrower provides their SSN and consents to the credit check in the "Liability" section of the application process.</div>

                            </div>

                        </div> : <></>}

                    </Col>


                </Row>
            </div>
            <Modal open={isAccounTModalOpen} title='Add credit account' footer={null} onCancel={() => {
                setAccounTModalOpen(false)
            }}>
                <Form
                    form={form}
                    layout='vertical'
                    validateMessages={validateMessages}
                    onFinish={onFinish}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="Vendor"
                                label="Credit vendor"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select style={{ height: '44px' }} options={
                                    Object.keys(vendorsList).map((item) => {
                                        return { label: vendorsList[item], value: item }
                                    })
                                } placeholder="Enter the credit vendor" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="Username"
                                label="User name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input style={{ height: '44px' }} placeholder="Enter your User name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="Password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.Password style={{ height: '44px' }} placeholder="Enter your Password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <div style={{ color: "#707070" }}>*Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut ultricies tortor. Vestibulum euismod turpis eget nisi malesuada, eu bibendum mi convallis.</div> */}
                    <div style={{ display: "flex", justifyContent: 'space-between', marginTop: "15px" }}>
                        <div><Button type="link" onClick={() => {
                            setAccounTModalOpen(false)
                        }}>Cancel</Button></div>
                        <div><Button type='primary' htmlType="submit" >Save</Button></div>

                    </div>
                </Form>

            </Modal>
            <div style={{
                display: 'flex', justifyContent: "end", width: '100%', borderTop
                    : "1px solid #D9D9D9"
            }}>
                <Button disabled={!(currentAccountsInfo.UseExperian || currentAccountsInfo.UseEquifax || currentAccountsInfo.UseTransUnion)||currentAccountsInfo.Username === ''} onClick={async () => {

                    let token = sessionStorage.getItem("ZeitroA")
                    const response = await fetch('/los/addcreditaccount', {
                        method: 'POST',
                        body: JSON.stringify({
                            ...currentAccountsInfo,



                        }),
                        headers: {
                            Authorization: "Bearer " + token,
                            Cache: "no-cache"
                        }
                    })
                    if (response.status !== 200) {
                        console.log('Looks like there was a problem. Status Code: ' +
                            response.status)

                        return
                    }
                    setIsShowTable(false)
                    getcreditaccountconfig()
                }} type='primary' style={{ height: 46, width: 122, marginTop: 15 }}>Save</Button>
            </div>
        </div>)
    }
    return (
        <>
            {isShowTable ? AccountsContent() : <div className='mx-3 ' style={{ padding: '0px 20px 0px 20px' }}>

                <div className='usage_title mt-4  Support'>
                    <div style={{ color: '#000', fontSize: 20 }}>
                        Credit report settings
                    </div>
                </div>
                <div style={{ color: "#6E6E70", fontSize: 16, marginTop: 6 }}>Customize your borrowers’ application experience</div>

                <Row className="feature_row">
                    {
                        Accounts.map((item, index) => {

                            return (
                                <Col span={24} style={{ marginTop: 18 }}>

                                    <div onClick={() => {
                                        const dataInfoList = []
                                        if (item.UseEquifax) {
                                            dataInfoList.push('UseEquifax')
                                        }
                                        if (item.UseExperian) {
                                            dataInfoList.push('UseExperian')
                                        }
                                        if (item.UseTransUnion) {
                                            dataInfoList.push('UseTransUnion')
                                        }
                                        getCheckboxValue(dataInfoList)

                                        setCurrentAccountsInfo(item)
                                        setIsShowTable(true)
                                    }} style={{ cursor: 'pointer', display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <div className="usage_title mb-2">
                                                {item.Type === 'SoftPull' ? 'Soft credit account' : 'Hard credit account'}
                                            </div>
                                            <div style={{ color: "#6E6E70", fontSize: 14 }}>

                                                {item.Type === 'SoftPull' ? ' Set up your credit account and select your preferred credit bureaus for a soft credit pull.' : 'Set up your credit account and select your preferred credit bureaus.'}
                                            </div>
                                        </div>
                                        <RightOutlined />
                                    </div>
                                    {Accounts.length - 1 === index ? <></> : <div className="line" ></div>}
                                    {
                                        item.show &&
                                        <div className="VendorDetail">
                                            <div className="detailTile">Credit  Vendor  <span style={{ fontWeight: "400", fontSize: '16px', color: '#222', marginLeft: "30px" }}> {item.Vendor}  -   {item.Username} </span>
                                                {/* <span className="detailEdit" onClick={() => {
                                                        console.log(dataInfo)
                                                        const id = dataInfo.Accounts?.find((item) => item.IsCurrent)?.id
                                                        getSelectValue(id)
                                                        setIsModalOpen(true)
                                                        const dataInfoList = []
                                                        if (item.UseEquifax) {
                                                            dataInfoList.push('UseEquifax')
                                                        }
                                                        if (item.UseExperian) {
                                                            dataInfoList.push('UseExperian')
                                                        }
                                                        if (item.UseTransUnion) {
                                                            dataInfoList.push('UseTransUnion')
                                                        }
                                                        getCheckboxValue(dataInfoList)
                                                        const data = {

                                                            UseExperian: dataInfoList.includes("UseExperian"),
                                                            UseEquifax: dataInfoList.includes("UseEquifax"),
                                                            UseTransUnion: dataInfoList.includes("UseTransUnion")
                                                        }
                                                        setCurrentChoose(data)

                                                    }}>Edit</span> */}
                                            </div>
                                            <div className="detailTile" style={{ marginTop: "15px" }}>Credit  Bureau(s)

                                                <span style={{ fontWeight: "400", fontSize: '16px', color: '#222', marginLeft: '20px' }}>
                                                    {getBureaus(item).join(' , ')}




                                                </span>
                                            </div>


                                        </div>

                                    }
                                </Col>

                            )

                        })
                    }

                </Row>







            </div>}
        </>


    )

}
export default Feature