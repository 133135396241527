import React , { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Col, Row, Divider, Carousel, Drawer} from 'antd';
import EmailVerification from './Application/EmailVerification';
import "./SignUpPage.css";
const contentStyle = {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  };
export default function SignUpPage() {
    const [form] = Form.useForm();
    const formInitial = {

    }
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    const onFinish = (val) => {
        console.log(val)
    }
    const [navDrawer, setNavDrawer] = useState(false);
    const showDrawer = () => {
        setNavDrawer(true)
    }
    const onClose = () => {
        setNavDrawer(false)
    }
    return (
        <>
            <div className="sign-up-box">
                <div className='create-account'>
                    <div className='sign-up-logo'><img src='/images/logo.svg'/></div>
                    <div className='mobile-nav'>
                        <div className='mobile-logo'><img src='/images/mobile-logo.svg' /></div>
                        <div className='mobile-menu'><img src='/images/menu.svg' onClick={showDrawer} /></div>
                    </div>
                    <div className='create-centerBox'>
                        <div className='create-title'>Create Your Account</div>
                        <Form
                            className='create-account-form'
                            layout='vertical'
                            form={form}
                            initialValues={formInitial}
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                            size="large"
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item 
                                        name="firstname" 
                                        label="First name"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input placeholder="First name"  />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item 
                                        name="lastname" 
                                        label="Last name"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Last name"  />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="email" 
                                        label="Email"
                                        rules={[
                                            {
                                                required: true,
                                                type: 'email',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Email address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button htmlType="submit" className='create-btn sign-zeitro' block>Sign up with Zeitro</Button>
                        </Form>
                        <Divider plain>or sign up with</Divider>
                        <Row gutter={8}>
                            <Col span={8}><Button size="large" style={{width: '100%'}} className='create-btn sign-google' icon={<img src='/images/google.svg' style={{width: '22px', height: '22px'}} />} >Google</Button></Col>
                            <Col span={8}><Button size="large" style={{width: '100%'}} className='create-btn sign-google' icon={<img src='/images/outlook.svg' style={{width: '22px', height: '22px'}} />} >Outlook</Button></Col>
                            <Col span={8}><Button size="large" style={{width: '100%'}} className='create-btn sign-google' icon={<img src='/images/apple.svg' style={{width: '22px', height: '22px'}} />} >Apple</Button></Col>
                        </Row>
                        <div className='signIn-text'>Already have an account? <span className='to-signIn'>Sign in</span></div>
                        {/* <EmailVerification  /> */}
                    </div>
                    <div className='create-copyright'>© {new Date().getFullYear()} Zeitro. All rights reserved.</div>
                </div>
                <div className='carousel-box'>
                    <div className='content-box'>
                        <Carousel className='create-carousel'>
                            <div>
                                <div style={contentStyle} className='create-carousel-1'>
                                    <div className='center-box'>
                                        <div>Streamlined and modern LOS system</div>
                                        <img style={{marginBottom: '32px'}} src='/images/carousel-img-1.svg' />
                                        <img style={{width: '85%', margin: '0px auto'}} src='/images/carousel-img-3.svg' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={contentStyle} className='create-carousel-2'>
                                    <div className='center-box'>
                                        <div>More loans, less time, less Effort.</div>
                                        <img style={{width: '78%', margin: '0 auto 52px auto'}} src='/images/carousel-img-4.svg' />
                                        <img style={{width: '131%', marginLeft: '-18%'}} src='/images/carousel-img-5.svg' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={contentStyle} className='create-carousel-3'>
                                    <div className='center-box'>
                                        <div>Trusted by 100+ lenders & companies</div>
                                        <div className='carousel-subtitle'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. s</div>
                                        <div style={{marginTop: '56px'}}>
                                            <img src='/images/carousel-img-6.png' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
            <Drawer className="create-drawer-nav" width="60%" placement="right" onClose={onClose} open={navDrawer}>
                <div className="nav">1</div>
                <div className="nav">2</div>
                <div className="nav">3</div>
                <div style={{textAlign: 'center'}}><Button className='drawer-btn'>button</Button></div>
            </Drawer>
        </>
    )
}