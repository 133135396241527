import React from 'react';
import { Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Menu from "./Menu.js"
import Copyright from "./Copyright.js"
import { UR_LoanOfficer, UR_LoanOfficerManager, UR_Owner } from './State';
import { checkAccess } from './Auth';
import * as com from './Common';

export default function Error({ code }) {

  let header, text, extraRender, url;

  if (code === 404) {
    header = 'Page Not Found.';
    // text = 'The application is still under construction. Please be patient!';
    text = null;
    extraRender = null;
  } else if (code === 401) {
    header = 'You Are Not Authorized To Access This Page.';
    text = null;
    if (checkAccess([UR_LoanOfficerManager, UR_Owner])) {
      url = '/app/management'
    } else if (checkAccess([UR_LoanOfficer])) {
      url = '/app/borrowers'
    }
    extraRender = <Link style={{ fontSize: '30px' }} to={url}>Go back</Link>;

  }

  return (
    <div className=" py-0 my-0">
      <Menu logoSrc={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"} />
      <div id="landing1" className="container my-5" >
        <div className="row">
          <div className="col-sm mt-5  text-center">
            <h1 className="mt-5 ">Error {code}.</h1>
            <h1>{header}</h1>
            {text && <div className="pt-5 punch">
              {text}
            </div>}
            {extraRender}
            <div className="mt-5  text-center" >


            </div>
          </div>
          <div className="col-sm ml-5">
            <img id="homepic" className="pl-5" alt="Your future home" src="/home.svg" />
          </div>
        </div>
        <div className="mt-5">&nbsp;</div>
        <div className="mt-5">&nbsp;</div>

      </div>

      <Copyright />

    </div>
  )
}
