import React,{ useState } from 'react';
import { 
    Dropdown,
    Space
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import "./Common.css";
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import "./ViewMode.css";

const mapStateToProps = (state) => {
    return {
        property: state.application.property,
        application: state.application,
        progress: state.progress,
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
      dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    },
    changeMainPropertyAttribute: (t, verb) => {
      dispatch(act.ChangeMainPropertyAttribute(t, verb));
    },
    updateHasCoborrower: (val) => {
        dispatch(act.UpdateHasCoborrower(val))
    },
    updateProduct: (v, verb) => {
      dispatch(act.UpdateProduct(v, verb))
    },
    changeBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "borrower", verb))
    },
    changeCoBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "coborrower", verb))
    },
    changeDeclaration: (val, verb) => {
        dispatch(act.ChangeDeclaration(val, "borrower", verb))
    },
  });


export default function ViewMode(props) {
    const menuItem = [
        {
          label: (
            <a
              rel="noopener noreferrer"
              onClick={() => {props.changeMode(true)}}
            >
              editing
            </a>
          ),
          key: "0",
        },
        {
          label: (
            <a
              rel="noopener noreferrer"
              onClick={() => {props.changeMode(false)}}
            >
              viewing
            </a>
          ),
          key: "1",
        },
    ]
    return (
        <div className="interview-mode">
            <div className='interview-mode-title'>Hard Money Loan Application</div>
            <div>
                You are{" "}
                <Dropdown
                    menu={{
                        items : menuItem,
                    }}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space style={{ color: "#325CEB" }}>
                            {props.editMode ? "editing" : "viewing"}
                            <DownOutlined style={{ color: "#325CEB" }} />
                        </Space>
                    </a>
                </Dropdown>
            </div>
        </div>
    )
}

ViewMode = connect(mapStateToProps, mapDispatchToProps)(ViewMode);