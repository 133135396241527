import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as com from './Common'
import "./signup.css";
import { Form, Input, Button, Col, Row, Modal, Space, Popover, Select } from 'antd';
import { UR_LoanProcessor, UR_PriceEngineUser } from './State';
import { checkAccessByToken, checkAloneAccess } from './Auth';

const ActivateLOAccount = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSameNMLS, setIsSameNMLS] = useState(false)
    const [isSameEmail, setIsSameEmail] = useState(false)
    const [isLP, setIsLP] = useState(false)
    const { hex } = useParams();
    const loinfo = com.hex2ascii(hex);
    const customerID = loinfo.split("|")[0];
    const loID = loinfo.split("|")[1];
    const [form] = Form.useForm();
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    useEffect(() => {
        getLOInfo()
    }, []);

    const onFinish = async (val) => {
        setIsLoading(true);
        setIsSameEmail(false)
        setIsSameNMLS(false)
        const response = await fetch('/los/activatelo', {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: loID,
                customerid: customerID,
                ...Object.fromEntries(
                    Object.entries(val).map(([key, value]) => [key.toLowerCase(), value])
                )

            })

        })
        if (response.status !== 200) {
            console.error("Error:", response);
            alert("Some error occured, please try again later.")
        }
        const { Text, Status, Token } = await response.json();
        if (Status === 'OK') {
            sessionStorage.setItem("ZeitroA", Token)
            sessionStorage.removeItem("state")
            sessionStorage.removeItem("originalstate")
            let path = "/app/welcome"
            if (checkAccessByToken(Token, [UR_PriceEngineUser])) {
                path = "/app/purchasecalculator";
              }
            window.location.href = path
        }
        if (Status === 'Error') {
            Text.includes('NMLS') ? setIsSameNMLS(true) : setIsSameNMLS(false)
            Text.includes('Email already') ? setIsSameEmail(true) : setIsSameEmail(false)
        }
        setIsLoading(false);
    }
    const getLOInfo = () => {
        sessionStorage.removeItem("ZeitroA")
        fetch("/los/getprofilebyid/" + customerID + "/" + loID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setIsLoading(false);
                if (data.Roles.length === 1 && data.Roles[0] === "LoanProcessor") {
                    setIsLP(true)
                }
                form.setFieldsValue(data)
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
            });
    }

    return (
        <>
            <div className="sign-up-box">
                <div className='create-account'>
                    <div className='sign-up-logo'><img style={{ width: '100px', height: "auto" }} src='/images/logo.svg' /></div>
                    <div className='mobile-nav'>
                        <div className='mobile-logo'><img src='/images/mobile-logo.svg' /></div>
                        <div className='mobile-menu'><img src='/images/menu.svg' /></div>
                    </div>
                    <Row >
                        <Col span={24}>
                            <div className='Welcome'>Welcome aboard!</div>
                            <div className='inter'>We prioritize the security and trust of our users. Link your Zeitro account to your NMLS ID, so that borrowers trust us, and trust you. </div>
                            <Form
                                className=''
                                style={{ marginTop: '20px' }}
                                layout='vertical'
                                form={form}

                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                size="large"
                            >
                                <Row>
                                    <Col span={11}>
                                        <Form.Item
                                            name="FirstName"
                                            label="First Name"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter your first name" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11} push={2}>
                                        <Form.Item
                                            name="LastName"
                                            label="Last Name"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter your last name" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name="Password"
                                            label="Password"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input.Password placeholder="Enter your Password" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name="Email"
                                            label="Email"
                                            rules={[{required: true,},]}
                                        >
                                            <Input placeholder="Enter your Email" status={isSameEmail ? 'error' : null} />
                                        </Form.Item>
                                        {isSameEmail && <div style={{ color: 'red',marginTop:-20 }}>The email address is already in use. Please use a different one.</div>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name="Nmls"
                                            label="NMLS ID"
                                            rules={[
                                                {
                                                    required: isLP? false:true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter your Individual NMLS ID" status={isSameNMLS ? 'error' : null} />
                                        </Form.Item>
                                        {isSameNMLS && <div style={{ color: 'red',marginTop:-20 }}>The NMLS number is already in use. Please use a different one.</div>}
                                    </Col>
                                </Row>
                                <Row style={{display:"none"}}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="Roles"
                                            label="Your role"

                                        >
                                            <Select placeholder="Select Role" options={[
                                                {
                                                    label: "Account owner",
                                                    value: "Owner"

                                                },
                                                {
                                                    label: "Loan officer",
                                                    value: "LoanOfficer"

                                                },
                                                {
                                                    label: "Loan processor",
                                                    value: "LoanProcessor"

                                                },
                                                {
                                                    label: "Price engine user",
                                                    value: "PriceEngineUser"

                                                },

                                            ]} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name="ID"
                                            label="Account Tier"

                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row> */}





                                <div style={{ display: 'flex', justifyContent: "end" }}>
                                    <Button loading={isLoading} htmlType="submit" type='primary' style={{ width: '150px' }}>Let’s go</Button>

                                </div>


                            </Form>
                        </Col>
                    </Row >
                    <div className='create-copyright'>© {new Date().getFullYear()} Zeitro. All rights reserved.</div>
                </div>

            </div>

        </>
    )
};

export default ActivateLOAccount;
