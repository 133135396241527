import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import "./HomePage.css"
import { AnthenticatedMenu } from "./Menu.js"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Helmet } from 'react-helmet'
import '../node_modules/font-awesome/css/font-awesome.min.css'
import Sidebar from './Sidebar/Sidebar'
import Profile from './Profile'
import Borrowers from './Borrowers'
import Borrower from './Borrower'
import Tasks from './Tasks/Tasks'
import LogoutPage from './LogoutPage'
import LOInput from './LOInput/LOInput'
import Notifications from './Notifications'
import ContactCustomer from './Tools/ContactCustomer/ContactCustomer'
import Affordability from './Tools/Afordability/Affordability';
import Login from './Tools/CustomerRegistration/Login'
import Management from './Management/Management';
import RateQuoter from "./Tools/MortgageCalculators/RateQuote"
import SubscribersList from './Tools/Subscription/SubscribersList';
import SubscriptionDashboard from './Tools/Subscription/SubscriptionDashboard';
import { ReportIssuesForm } from './Tools/MortgageCalculators/ReportIssues';
import LandingPageSetup from "./InitialSetup/LandingPageSetup";
import NewLandingPageSetup from "./InitialSetup/NewLandingPageSetup";
import NewEditLandingPageSetup from "./InitialSetup/NewEditLandingPageSetup";
import NewPreviewLandingPageSetup from "./InitialSetup/NewPreviewLandingPageSetup";
import WelcomePage from './WelcomePage';
import MortgageGPT from './MortgageGPT/MortgageGPT';
import IntervewBorrowerView from './InterviewBorrowerView';
import { checkAccess, checkAloneAccess, RouteWrapper } from './Auth';
import { UR_LoanOfficer, UR_LoanOfficerManager, UR_Owner, UR_LoanProcessor, UR_PriceEngineUser } from './State';
import * as com from "./Common"
import {
  Switch,
  Route
} from "react-router-dom";
import BorrowerForLoanProcessors from './BorrowerForLoanProcessors';
import BorrowersForLoanProcessors from './BorrowersForLoanProcessors/BorrowersForLoanProcessors';
import Interview1003View from './Interview1003View/Interview1003View';
import ProspectLoans from './Management/ProspectLoans';
import ActiveLoans from './Management/Borrowers';
import FundedLoans from './Management/FundedLoans';
import Pricing from './Billing/Pricing';
import Billing from './Billing/Billing';
import Subscribe from './Billing/Subscribe';
import Account from './Billing/Account';
import Pipeline from './Management/Borrowers';
import { Menu, Layout, Modal, Tooltip, Drawer, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import Setting from './setting';
import GMCCUniverseCalculator from './Tools/MortgageCalculators/GMCCUniverseCalculator';
import GMCCProgramEngine from './GMCCCalculator/GMCCProgramEngine';
import PlanModal from './planModal';
import { connect } from 'react-redux'
import {
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import eventBus from './Bus';
import GMCCDiamond from './GMCCCalculator/GMCCDiamond';
import GMCCCalculators from './Management/GMCCCalculators';
import CensusChecker from './GMCCCalculator/CensusChecker';
import MobileHomeLoanApplicationFormExtractor from './Documents/MobileHomeLoanApplicationFormExtractor';

const { Header, Content, Footer, Sider } = Layout;
const { Item: MenuItem } = Menu;
const { confirm } = Modal;
const mapStateToProps = (state) => {
  return {
    subscriptionReducer: state.subscriptionReducer.currentInfo
  }
}
// const subscription = useSelector(state => {

//   return state.subscriptionReducer.currentInfo
// })
class HomePage extends React.Component {
  state = {
    shrunk: false,
    collapsed: false,
    avatar: "/avatar.png",
    email: "",
    firstName: "",
    lastName: "",
    current: "dashboard",
    companyName: "",
    navDrawer: false,
    isModalOpen: false,

  }

  shrink = () => {
    this.setState({ shrunk: !this.state.shrunk })
  }
  listenResizeEvent = e => {
    //console.log("listenResizeEvent")
    if (window.innerWidth < 800) {
      if (!this.state.hidden) {
        this.setState({ hidden: true, shrunk: true })
        //console.log("small resize")
      }
    } else {
      if (this.state.hidden) {
        this.setState({ hidden: false, shrunk: false })
        //console.log("big resize")
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setMenuSelect()
    }
  }

  componentDidMount() {

    this.setMenuSelect()
    com.getReturnURLs()
    eventBus.addListener('updateProfile', this.getprofile)
    window.addEventListener('resize', this.listenResizeEvent)
    window.addEventListener("popstate", this.setMenuSelect.bind(this))
    this.listenResizeEvent()
    this.getprofile()
    this.getCompanyName()
  }
  componentWillUnmount() {
    eventBus.removeListener('updateProfile', this.getprofile)
    window.addEventListener('resize', this.listenResizeEvent)
    window.removeEventListener("popstate", this.setMenuSelect.bind(this))
  }
  setMenuSelect = () => {
    const urlObj = {
      'dashboard': 'welcome',
      'activeLoans': 'active-loans',
      'loans': 'loans',
      // 'leads' : '',
      'borrowers': 'borrowers',
      'rates': 'purchasecalculator',
      'gpt': 'mortgagegpt',
      'mySite': 'landingpage',
      'management': 'management',
      // 'search' : '',
      'settings': 'profile',
      "mobilehomeloan": "mobilehomeloan"
    }
    const currentKey = Object.keys(urlObj).find(key => window.location.pathname.includes(urlObj[key]))
    console.log(currentKey)
    if (currentKey) {
      this.setState({ current: currentKey, collapsed: currentKey !== 'dashboard' })
    }
  }
  getCompanyName = () => {
    fetch('/data/companycustomization', {
      headers: {
        'X-CustomerId': com.getCustomerId() || 'zeitro',
      }
    }).then(async response => {
      if (!response.ok) {
        return;
      }
      response.json().then(json => {
        console.log(json.CompanyName)
        this.setState({ companyName: json.CompanyName })
      })
    })
  }
  getprofile = () => {
    let token = window.sessionStorage.getItem("ZeitroA")
    fetch("/los/getprofile", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(response => {
      let d = new Date()
      //console.log(JSON.stringify(response))
      if (!response.ok) {
        console.log("Auth fetch error, at: " + d.toLocaleString())
        sessionStorage.removeItem("ZeitroA")
      } else {

        response.json().then(js => {
          //console.log(JSON.stringify(js))
          if (js.Status !== "OK") {

          } else {
            let loan = js.LoanOfficers[0]
            this.setState({ avatar: loan.Image === '' ? '/avatar.png' : loan.Image, email: loan.Email, firstName: loan.FirstName, lastName: loan.LastName })
          }
        })
      }
    }).catch(error => {
      let d = new Date()
      console.log("Revalidate catch error " + error + ", at: " + d.toLocaleString())
    })
  }
  getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    }
  }
  onClose = () => {
    this.setState({ navDrawer: false })
  }
  showDrawer = () => {
    this.setState({ navDrawer: !this.state.navDrawer })
  }
  toDashboard = () => {
    this.props.history.push('/app/welcome')
  }
  logout = (props) => {
    props.history.push('/app/logout')
  }
  callSidebar = () => {
    if (checkAloneAccess([UR_PriceEngineUser])) {
      return ""
    }
    const sidebaricon = (key) => {
      return this.state.current === key ? <img className='sidebar-icon' src={`/sidebaricons/${key}-active.svg`} /> : <img className='sidebar-icon' src={`/sidebaricons/${key}.svg`} />
    }
    const urlObj = {
      'dashboard': 'welcome',
      'activeLoans': 'active-loans',
      'loans': 'loans',
      // 'leads' : '',
      'borrowers': 'borrowers',
      'rates': 'purchasecalculator',
      'censuschecker': 'censuschecker',
      'specials': 'programengine',
      'gmcccelebrityrates': 'gmcccelebrityrates',
      'gmccuniverserates': 'gmccuniverserates',
      'gmccdiamond': 'gmccdiamond',
      'gmcccalculators': 'gmcccalculators',
      'gpt': 'mortgagegpt',
      'mySite': 'landingpage',
      'management': 'management',
      'settings': 'creditpullsetting',
      'mobilehomeloan': 'mobilehomeloan',
    }

    const onSidebarClick = (e) => {

      const status = com.getStatus(com.getLoId(), this.props.subscriptionReducer.loanOfficers)
      if (status === undefined) return
      if (e.key === 'gmcccalculators') {
        if ( status === 'free') {
          this.setState({ isModalOpen: true })
          return

        }

        }
        let url = `/app/${urlObj[e.key]}`
        this.setState({ collapsed: e.key !== 'dashboard', current: e.key })
        this.props.history.push(url)
      }
      const logout = (props) => {
        confirm({
          title: 'Are you sure you want to log out?',
          onOk() {
            props.history.push('/app/logout')
          },
          onCancel() {

          },
        })
      }

      const handleCollapse = () => {
        this.setState({ collapsed: !this.state.collapsed })
      }
      const toDashboard = () => {
        this.props.history.push('/app/welcome')
      }
      // return <div className="call-sidebar"><Col className="px-2 m-0 " style={{position:"sticky", top:"55px", height:"calc(100vh - 56px)"}}><Sidebar /></Col></div>
      return (
        <Spin spinning={false}>
          <div className='call-sidebar'>
            <div className='collapse-btn' onClick={handleCollapse}>{this.state.collapsed ? <RightOutlined /> : <LeftOutlined />}</div>
            <Sider className='main-sider' collapsible collapsed={this.state.collapsed} trigger={null}>
              {!this.state.collapsed && <div className='sidebar-logo'><div className='logo-box' onClick={toDashboard} style={{ fontSize: this.state.companyName.length > 14 ? 20 : 24 }}>{this.state.companyName}</div></div>}
              {/* <Menu onClick={onSidebarClick} defaultSelectedKeys={[this.state.current]} mode="inline" items={items} /> */}
              <Menu onClick={onSidebarClick} selectedKeys={[this.state.current]} mode="inline">
                <MenuItem key="dashboard" icon={sidebaricon('dashboard')}>Dashboard</MenuItem>
                <MenuItem key="activeLoans" icon={sidebaricon('activeLoans')}>Loans</MenuItem>
                {/* <MenuItem key="leads" icon={sidebaricon('leads')}>Leads</MenuItem> */}
                <MenuItem key="borrowers" icon={sidebaricon('borrowers')}>Borrowers</MenuItem>
                <MenuItem key="rates" icon={sidebaricon('rates')}>Rates</MenuItem>
                {/* {com.getCustomerId() === "gmcc" && checkAccess([UR_Owner]) && <MenuItem key="specials" icon={sidebaricon('rates')}>Programs Map</MenuItem>}
                {com.getCustomerId() === "gmcc" && <MenuItem key="gmcccelebrityrates" icon={sidebaricon('gmcccelebrityrates')}>Celebrity</MenuItem>}
                {com.getCustomerId() === "gmcc" && <MenuItem key="gmccuniverserates" icon={sidebaricon('gmccuniverserates')}>Universe</MenuItem>}
                {com.getCustomerId() === "gmcc" && <MenuItem key="gmccdiamond" icon={sidebaricon('gmccdiamond')}>Diamond</MenuItem>} */}
                {com.getCustomerId() === "changehomemortgage" && <MenuItem key="censuschecker" icon={sidebaricon('gmcccalculators')}>Census Checker</MenuItem>}
                {com.getCustomerId() === "gmcc" && <MenuItem key="gmcccalculators" icon={sidebaricon('gmcccalculators')}>GMCC Specials</MenuItem>}
                <MenuItem key="gpt" icon={sidebaricon('gpt')}>Guideline GPT</MenuItem>
                {com.getCustomerId() !== "yeeleecapital" && !checkAloneAccess([UR_LoanProcessor]) &&<MenuItem key="mySite" icon={sidebaricon('mySite')}>My site</MenuItem>}
                {checkAccess([UR_LoanOfficerManager, UR_Owner]) && <MenuItem key="management" icon={sidebaricon('management')}>Management</MenuItem>}
                {(com.getCustomerId() === "zeitro" || com.getCustomerId() === "serenityhomeloans") && <MenuItem key="mobilehomeloan" icon={sidebaricon('mobilehomeloan')}>MH.AI</MenuItem>}
                
                <div className='menu-line'></div>
                {/* <MenuItem key="search" icon={sidebaricon('search')}>Search</MenuItem> */}
                {/* <MenuItem key="settings" icon={sidebaricon('settings')}>Settings</MenuItem> */}
                <MenuItem key="settings" icon={sidebaricon('settings')}>Settings</MenuItem>
              </Menu>
              <div className={`sidebar-botttom ${this.state.collapsed ? 'sidebar-collapsed-style' : ''}`} >
                <img alt="avatar" className="header-img" src={this.state.avatar} />

                {this.state.collapsed && <div style={{ marginTop: 2 }} className='logout' onClick={() => logout(this.props)}>logout</div>}
                {
                  !this.state.collapsed && (
                    <div style={{ flex: 1, marginLeft: 10 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '3px' }}><div className='load-name'><Tooltip title={`${this.state.firstName} ${this.state.lastName}`}>{`${this.state.firstName} ${this.state.lastName}`}</Tooltip></div><div className='logout' onClick={() => logout(this.props)}>logout</div></div>
                      <div className='load-email'><Tooltip title={this.state.email}>{this.state.email}</Tooltip></div>
                    </div>
                  )
                }
              </div>
            </Sider>
          </div>
        </Spin>
      )
    }
    render = () => {
      return (
        <div id="myapp" className="py-0 mb-0">

          <Helmet>
            <title>{this.state.companyName} - mortgage platform </title>
          </Helmet>
          {/* <AnthenticatedMenu /> */}
          <div className='mobileMenu'>
            <div className='sidebar-logo'><div className='logo-box' onClick={this.toDashboard} style={{ fontSize: "15px" }}>{this.state.companyName}</div></div>
            <img src='/images/menu.svg' onClick={this.showDrawer} />
          </div>
          <Row id="pane" className="w-100 mx-0 px-0 pt-0 align-top d-flex backimage0">
            {this.callSidebar()}
            <Col id="home" className="my-0  text-wrap align-top home p-0 m-0" >
              <Switch>
                <Route exact path="/app/home">
                  <h1>Welcome back!</h1>
                </Route>

                <RouteWrapper
                  Component={WelcomePage}
                  roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                  exact
                  path="/app/welcome"
                />
                <RouteWrapper
                  Component={Pricing}
                  roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                  exact
                  path="/app/pricing"
                />
                <RouteWrapper
                  Component={Billing}
                  roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                  exact
                  path="/app/billing"
                />
                <RouteWrapper
                  Component={Subscribe}
                  roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                  exact
                  path="/app/billing/subscribe"
                />
                <RouteWrapper
                  Component={Account}
                  roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                  exact
                  path="/app/billing/account"
                />
                <RouteWrapper
                  Component={MortgageGPT}
                  roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                  exact
                  path="/app/mortgagegpt/:threadID?"
                />
                <RouteWrapper
                  Component={Tasks}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/tasks"
                />
                <RouteWrapper
                  Component={Pipeline}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/prospect-loans"
                />
                <RouteWrapper
                  Component={ActiveLoans}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_Owner, UR_LoanOfficer]}
                  exact
                  path="/app/active-loans"
                />
                <RouteWrapper
                  Component={FundedLoans}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_Owner, UR_LoanOfficer]}
                  exact
                  path="/app/funded-loans"
                />
                <RouteWrapper
                  Component={Management}
                  roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                  exact
                  path="/app/management"
                />
                <RouteWrapper
                  Component={MobileHomeLoanApplicationFormExtractor}
                  roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                  exact
                  path="/app/mobilehomeloan"
                />
                <RouteWrapper
                  Component={Borrowers}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/borrowers/:borrowerid?"
                />
                <RouteWrapper
                  Component={LOInput}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/loinput"
                />

                <RouteWrapper
                  Component={Borrower}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                  path="/app/borrower/:id"
                />

                <RouteWrapper
                  Component={BorrowerForLoanProcessors}
                  roles={[UR_LoanProcessor]}
                  path="/app/borrowerforlp/:id"
                />
                <RouteWrapper
                  Component={BorrowersForLoanProcessors}
                  roles={[UR_LoanProcessor]}
                  path="/app/borrowersforlp"
                />
                <RouteWrapper
                  Component={ContactCustomer}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/emailcustomer"
                />

                <RouteWrapper
                  Component={Login}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/customerregistration"
                />

                <RouteWrapper
                  Component={Affordability}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/affordability"
                />

                <RouteWrapper
                  Component={Profile}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/profile"
                />
                <RouteWrapper
                  Component={Setting}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/creditpullsetting"
                />

                <RouteWrapper
                  Component={RateQuoter}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/purchasecalculator"
                />

                <RouteWrapper
                  Component={CensusChecker}
                  roles={[UR_Owner, UR_LoanOfficer]}
                  exact
                  path="/app/censuschecker"
                />

                <RouteWrapper
                  Component={GMCCProgramEngine}
                  roles={[UR_Owner]}
                  exact
                  path="/app/programengine"
                />
                <RouteWrapper
                  Component={GMCCUniverseCalculator}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/gmccuniverserates"
                />
                <RouteWrapper
                  Component={RateQuoter}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/gmcccelebrityrates"
                />
                <RouteWrapper
                  Component={GMCCDiamond}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/gmccdiamond"
                />
                <RouteWrapper
                  Component={GMCCCalculators}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/gmcccalculators"
                />
                <RouteWrapper
                  Component={IntervewBorrowerView}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_PriceEngineUser, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/interviewborrower"
                />

                <RouteWrapper
                  Component={SubscriptionDashboard}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/distribution"
                />
                <RouteWrapper
                  Component={ReportIssuesForm}
                  roles={[UR_LoanOfficer, UR_LoanProcessor, UR_LoanOfficerManager, UR_Owner]}
                  exact
                  path="/app/feedback"
                />
                {/* <RouteWrapper
                Component={LandingPageSetup}
                roles={[UR_LoanOfficer, UR_Owner]}
                exact
                path="/app/landingpage"
              /> */}
                <RouteWrapper
                  Component={NewPreviewLandingPageSetup}
                  roles={[UR_LoanOfficer, UR_Owner, UR_LoanOfficerManager]}
                  exact
                  path="/app/landingpage"
                />
                <RouteWrapper
                  Component={LogoutPage}
                  roles={[UR_LoanOfficer, UR_LoanOfficerManager, UR_Owner, UR_LoanProcessor, UR_PriceEngineUser]}
                  exact
                  path="/app/logout"
                />

                <Route >
                  <h1>Error 404! Can't render this URL!</h1>
                </Route>

              </Switch>
            </Col>
          </Row>
          <Notifications />
          <Drawer
            title={
              <div className='d-flex align-items-center'>
                <img style={{ width: "30px", height: "30px", marginRight: "10px" }} alt="avatar" className="header-img" src={this.state.avatar} />
                <div style={{ marginRight: "5px" }}>{this.state.firstName + " " + this.state.lastName}</div>
                <div style={{ marginTop: 2, textDecoration: "underline", color: "#325CEB" }} className='logout' onClick={() => this.logout(this.props)}>logout</div>
              </div>
            }
            rootClassName="mobileMenu-Drawer"
            placement="top"
            onClose={this.onClose}
            open={this.state.navDrawer}>
            <div className='nav' onClick={() => { this.props.history.push('/app/welcome'); this.setState({ navDrawer: false }) }}>Dashboard</div>
            <div className='nav' onClick={() => { this.props.history.push('/app/active-loans'); this.setState({ navDrawer: false }) }}>Loans</div>
            <div className='nav' onClick={() => { this.props.history.push('/app/borrowers'); this.setState({ navDrawer: false }) }}>Borrowers</div>
            <div className='nav' onClick={() => { this.props.history.push('/app/purchasecalculator'); this.setState({ navDrawer: false }) }}>Rates</div>
            {/* {com.getCustomerId() === "gmcc" && checkAccess([UR_Owner]) && <div className='nav' onClick={() => { this.props.history.push('/app/programengine'); this.setState({ navDrawer: false }) }}>Program Map</div>}
            {com.getCustomerId() === "gmcc" && <div className='nav' onClick={() => { this.props.history.push('/app/gmcccelebrityrates'); this.setState({ navDrawer: false }) }}>Celebrity</div>}
            {com.getCustomerId() === "gmcc" && <div className='nav' onClick={() => { this.props.history.push('/app/gmccuniverserates'); this.setState({ navDrawer: false }) }}>Universe</div>}
            {com.getCustomerId() === "gmcc" && <div className='nav' onClick={() => { this.props.history.push('/app/gmccdiamond'); this.setState({ navDrawer: false }) }}>Diamond</div>} */}
            {com.getCustomerId() === "changehomemortgage" && <div className='nav' onClick={() => { this.props.history.push('/app/censuschecker'); this.setState({ navDrawer: false }) }}>Census Checker</div>}
            {com.getCustomerId() === "gmcc" && <div className='nav' onClick={() => { this.props.history.push('/app/gmcccalculators'); this.setState({ navDrawer: false }) }}>GMCC Specials</div>} 
            <div className='nav' onClick={() => { this.props.history.push('/app/mortgagegpt'); this.setState({ navDrawer: false }) }}>Guideline GPT</div>
            {com.getCustomerId() !== "yeeleecapital" && <div className='nav' onClick={() => { this.props.history.push('/app/landingpage'); this.setState({ navDrawer: false }) }}>My site</div>}
            {checkAccess([UR_LoanOfficerManager, UR_Owner]) && <div className='nav' onClick={() => { this.props.history.push('/app/management'); this.setState({ navDrawer: false }) }}>Management</div>}
            <div className='nav' onClick={() => { this.props.history.push('/app/profile'); this.setState({ navDrawer: false }) }}>Settings</div>
          </Drawer>
          <PlanModal isModalOpen={this.state.isModalOpen} closeModal={() => {

            this.setState({ isModalOpen: false })

          }}></PlanModal>
        </div>
      )
    }
  }
export default withRouter(connect(mapStateToProps)(HomePage))