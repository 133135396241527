import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Chat from './Chat';
import "./MortgageGPT.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function MortgageGPT() {

  return (
    <div className="">
      <Chat />
    </div>
  );
}

export default MortgageGPT;
