import React, {useEffect, useState} from "react";
import {message, Checkbox, Button, textarea, Spin} from "antd";
import { LikeOutlined, DislikeOutlined, CopyOutlined, CloseOutlined } from "@ant-design/icons";


export default function ChatFeedback({threadID, processMoreDetails, chatAnswer, messageID, isBotThinking}) {
  const [isLike, setIsLike] = useState(false);
  const [isDislike, setIsDislike] = useState(false);
  const [isNotHelpful, setIsNotHelpful] = useState(false);
  const [isNotTrue, setIsNotTrue] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const [loadingMoreDetails, setLoadingMoreDetails] = useState(false);

  const handleCopyClick = (textToCopy) => {
    console.log("copying text: ", textToCopy);
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        messageApi.success("Answer copied to clipboard.");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  const handleLikeClick = () => {
    setIsLike(true);
    setIsDislike(false);
    sendFeedback(true, false, "");
  };
  const handleDislikeClick = () => {
    setIsLike(false);
    setIsDislike(true);
    setShowFeedback(true);
  };
  const clearFeedback = () => {
    setIsLike(false);
    setIsDislike(false);
    setFeedbackMessage("");
    setIsNotHelpful(false);
    setIsNotTrue(false);
    setShowFeedback(false);
    setLoadingMoreDetails(false);
  }
  const sendFeedback = (isLike, isDislike, feedbackMessage) => {
    let body = {
      MessageID: messageID,
      Like: isLike,
      Dislike: isDislike,
      Feedback: feedbackMessage,
    };
    let token = sessionStorage.getItem("ZeitroA");
    fetch('/los/mortgagegpt/recordinteraction', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
      }
  }).then(
      response => {
          if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                  response.status);
              clearFeedback()
              return;
          }
          response.json().then(js => {
              clearFeedback()
              messageApi.success("Thanks for your feedback!");
          });
      }
  ).catch(function (err) {
      console.log('Fetch Error :-S', err);
      clearFeedback()
  });
  };
  const getMoreDetails = () => {
    let body = {
      ThreadID: threadID,
      MsgID: messageID,
    };
    setLoadingMoreDetails(true)
    let token = sessionStorage.getItem("ZeitroA");
    fetch('/los/mortgagegpt/moredetail', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
      }
  }).then(
      response => {
          if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                  response.status);
              setLoadingMoreDetails(false)
              return;
          }
          response.json().then(js => {
            processMoreDetails(js)
            setLoadingMoreDetails(false)
          });
      }
  ).catch(function (err) {
      console.log('Fetch Error :-S', err);
      clearFeedback()
  });
  };
  const handleClose = () => {
    setShowFeedback(false);
    sendFeedback(false, true, "");
  }
  const handleSubmitFeedback = () => {
    let msg = feedbackMessage.trim();
    if (isNotHelpful) {
      msg += "\nThis isn't helpful"
    }
    if (isNotTrue) {
      msg += "\nThis isn't true"
    }
    sendFeedback(false, true, msg)
  }
  if (messageID === "" || isBotThinking) {
    return ""
  }

  return (
    <div className="text-left">
        <div className="d-flex align-items-center">
        <div className="chatFeedBackButtons">
        {contextHolder}
        <div
            className="chatFeedBackButton"
            onClick={handleLikeClick}>
            <LikeOutlined />
        </div>
        <div
            className="chatFeedBackButton"
            onClick={handleDislikeClick}>
            <DislikeOutlined />
        </div>
        <div
            className="chatFeedBackButton"
            onClick={() => handleCopyClick(chatAnswer)}>
            <CopyOutlined />
        </div>
        </div>
        {/* <div className="chatFeedBackMoredetails"><Button type="secondary" loading={loadingMoreDetails} onClick={()=>getMoreDetails()}>More Details</Button></div> */}
        </div>
        {
          showFeedback && <div className="chatFeedbackInputWrap">
            <div className="d-flex justify-content-between">
              <div className="bold">Help us to improve it</div>
              <CloseOutlined className="rowLink" onClick={handleClose}/>
            </div>
            <div className="mt-2">
              <Checkbox onChange={(e)=>setIsNotHelpful(e.target.checked)}><div style={{fontSize:14}}>This isn't helpful</div></Checkbox>
              <Checkbox onChange={(e)=>setIsNotTrue(e.target.checked)}><div style={{fontSize:14}}>This isn't true</div></Checkbox>
            </div>
            <div>
              <textarea 
                className="chatFeedbackInput" 
                onChange={(e)=>setFeedbackMessage(e.target.value)}
                placeholder="What is the issue with the response? How could it be improved?" />
            </div>
            <div className="text-right">
              <Button type="outline" className="chatFeedbackSubmit" onClick={handleSubmitFeedback}>Submit feedback</Button>
            </div>
          </div>
        }
    </div>

  );
}
