import React, { Component, useState } from "react";
import { Button, message, Steps, Select, Checkbox, Row, Col, Form, Table, Tabs } from 'antd';
import "./CallReports.css";
import CallReportPreview from "./CallReportPreview";
import * as com from "../../Common"
import { initial, cloneDeep } from "lodash";
import {decode, Base64} from 'js-base64';
import { UR_LoanOfficer, UR_Owner,  } from "../../State";
import moment from 'moment';

class CallReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedYear: '2023',
            selectedQuarter: this.getQuarter(),
            selectedStates: ["ca","tx"],
            currentstep: 0,
            callreportdata: undefined,
            callreportdatatoSave: undefined,
            openingGeneratedReport: false,
            loanofficers: {},
        };
    }

    componentDidMount() {
        this.getloans();
        this.getAllReports();
    }

    // componentDidUpdate(prevProps,prevState) {
    //     if(this.state.currentstep===0 && prevState.currentstep !== this.state.currentstep) {
    //         this.getAllReports();
    //     }
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.callreportdata !== this.state.callreportdata) {
        }
      }

    getLoanofficers = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/loanofficerlist', {
          method: 'GET',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          }
        }).then(
          response => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                response.status);
              this.setState({ loading: false })
              return;
            }
            // Examine the text in the response
            response.json().then(js => {
    
              if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
              } else {
    
                let docs = js.LoanOfficers.filter(el => el.Roles.includes(UR_LoanOfficer));
                docs = docs.map(s => {
                  let lo = cloneDeep(this.state.loanofficers)
                  lo[s.ID] = s.FirstName + " " + s.LastName
                  this.setState({ loanofficers: lo })
    
                  return {
                    rand: Math.random(), id: s.ID, email: s.Email,
                    firstname: s.FirstName, lastname: s.LastName, phone: s.Phone, image: s.Image === "" ? "/avatar.png" : s.Image
                  }
                })
                this.setState({ loanofficers_full: docs })
              }
              let loans = this.state.Loans.map(s => {
                let lo = "Unassigned"
                if (this.state.loanofficers[s.lo_id])
                  lo = this.state.loanofficers[s.lo_id]
    
                return { ...s, loanofficer: lo }
              }
    
    
              )
              this.setState({ Loans: loans})
            });
          }
        )
          .catch(function (err) {
            console.log('Fetch Error :-S', err);
            this.setState({ loading: false })
    
          });
      }
    
    getAllReports = () => {
        let token=sessionStorage.getItem("ZeitroA")
        fetch('/los/getallcallreport', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
            }).then(
            response => {
                if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                    response.status);
                return;
                }
                response.json().then(js => {
                if (js.Status !== "OK") {
                    console.log("Error: " + js.Text)
                } else {
                    if (js.Records) {
                        let records = js.Records.map(s => {
                            return {
                                name: s.Name,
                                quarter: s.Quarter,
                                year: s.Year,
                                state: s.State,
                                generateddate: s.GeneratedDate,
                                data: JSON.parse(decode(s.Data)),
                            }
                        })
                        this.setState({Records: records})
                    }
                }
                });
            }
            )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }

    getloans = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/loansforcallreport', {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        }
        }).then(
        response => {
            if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
                response.status);
            return;
            }
            response.json().then(js => {

            if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
            } else {
                if (js.Loans) {
                    let docs = js.Loans.map(s => {
                        return {
                        firstname: s.FirstName,
                        lastname: s.LastName,
                        loanid: s.LoanID,
                        status: s.Status,
                        purpose:s.LoanPurpose,
                        price:s.LoanPrice,
                        started: s.Started,  
                        loanamount:s.LoanSize,
                        lo_id: s.LoanOfficerID,
                        address: s.PropertyAddressStreet,
                        county: s.PropertyAddressCounty,
                        state: s.PropertyAddressState,
                        mtype: s.TypeOfMortgage,
                        mortgageapplied: s.MortgageApplied,
                        amortization: s.Amortization,
                        loanterm: s.Term,
                        apr: s.APR,
                        baserate: s.BaseRate,
                        monthlypay: s.MonthlyPay,
                        lenderfee: s.LenderFee,
                        propertytype: s.PropertyType,
                        occupancy: s.Occupancy,
                        hascontract: s.HasContract,
                        unknownpurpose: s.UnknownPurpose,
                        }
                    })
                    this.setState({Loans: docs}, () => {
                        this.getLoanofficers();
                    })
                }
            }
            });
        }
        )
        .catch(function (err) {
            console.log('Fetch Error :-S', err);
        });
    }

    getYears = () => {
        let currentYear = new Date().getFullYear();
        let nextYear = currentYear + 1;
        let years = Array.from({length: 3}, (_, i) => nextYear - i);
        let yearsOptions = years.map((year) => {
            let yearString = year.toString();
            return <option value={yearString}>{yearString}</option>
        })
        return yearsOptions;
    }

    getQuarter = () => {
        let month = new Date().getMonth();
        switch (true) {
            case month < 3:
                return 'q1';
            case month < 6:
                return 'q2';
            case month < 9:
                return 'q3';
            default:
                return 'q4';
        }
    }

    viewGeneratedReport = (record) => {
        this.setState({
            openingGeneratedReport: true,
            callreportdata: this.state.Records.find((r) => r.name === record.name),
            currentstep: 1, 
        })
    }

    handelGoBack = () => {
        this.setState({currentstep: 0})
        if(this.state.openingGeneratedReport) {
            this.setState({openingGeneratedReport: false})
        }
    }

    handleSaveReport = () => {
        let body = {
            name: this.state.callreportdatatoSave.name,
            state: this.state.callreportdatatoSave.state,
            quarter: this.state.callreportdatatoSave.quarter,
            year: this.state.callreportdatatoSave.year,
            generateddate: this.state.callreportdatatoSave.generateddate,
            data: com.ascii2hex(JSON.stringify(this.state.callreportdatatoSave.data))
        }
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/savecallreport', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        },
        body: JSON.stringify(body)
        })
        .then(
        response => {
            if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
                response.status);
            return;
            }
            response.json().then(js => {

            if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
            } else {
                alert("Report Saved Successfully")
                this.setState({currentstep: 0}, () => {
                    this.getAllReports();
                })
                if(this.state.openingGeneratedReport) {
                    this.setState({openingGeneratedReport: false})
                }
            }
            });
        }
        )
        .catch(function (err) {
            console.log('Fetch Error :-S', err);
        });
    }

    createReport = (values) => {
        let initialdata = {
            // companyid: sessionStorage.getItem("ZeitroA"),
            name: "MCR_"+values.quarter.toUpperCase()+"_"+values.year,
            generateddate: new Date().toLocaleDateString('en-US'),
            state: values.states,
            quarter: values.quarter,
            year: values.year,
            data:{
                ca: {},
                tx: {}
            }
        }
        values.states.forEach((state) => {
            initialdata.data[state] = cloneDeep(com.callReportInitValues)
        })
        // // call api to create report, and initialize report state object, and navigate to view page
        this.setState({callreportdata: cloneDeep(initialdata), callreportdatatoSave: cloneDeep(initialdata), currentstep: 1})
    }

    updateCurrentStep = (step) => {
        this.setState({currentstep: step});
    }

    render() {

        const allReportCols = [
            {
                title: 'File Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Quarter',
                dataIndex: 'quarter',
                key: 'quarter',
                render: (text, record) => (
                    <span>{text.toUpperCase()}</span>
                )
            },
            {
                title: 'Year',
                dataIndex: 'year',
                key: 'year',
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                render: (text, record) => (
                    <span>{String(record.state).toUpperCase().replace(/{|}/g,"")}</span>
                )
            },
            {
                title: 'Generated Date',
                dataIndex: 'generateddate',
                key: 'generateddate',
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.generateddate).unix() - moment(b.generateddate).unix(),
                render: (text, record) => (
                    <span>{moment(record.generateddate).format("MM/DD/YYYY")}</span>
                )
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                render: (text, record) => (
                    <Button type="link" onClick={()=>this.viewGeneratedReport(record)}>View</Button>
                )
            },
        ]

        const handleCheckState = (checkedValues) => {
            this.setState({selectedStates: checkedValues});
        }

        const handleStepsChange = (current) => {
            this.setState({currentstep: current});
        }

        const stateOptions = [
            { label: 'California', value: "ca" },
            { label: 'Texas', value: "tx"}
        ]
        const quarterOptions = [
            { label: 'Q1', value: 'q1' },
            { label: 'Q2', value: 'q2' },
            { label: 'Q3', value: 'q3' },
            { label: 'Q4', value: 'q4' }
        ]
 
       
        const steps = [
            {
              title: 'Select Specification',
              content: 
                <div>
                    <Form 
                        labelCol={{ span: 6}}
                        wrapperCol={{ span: 12}}
                        labelAlign="left"
                        onFinish={this.createReport}
                        initialValues={{
                            year: this.state.selectedYear,
                            quarter: this.state.selectedQuarter,
                            states: this.state.selectedStates
                          }}
                    >
                        <Form.Item
                            label="Select Year"
                            name="year"
                            rules={[{ required: true, message: 'Please select year!' }]}
                        >    
                            <Select onChange={(value) => this.setState({selectedYear: value})}>
                                {this.getYears()}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Select Quarter"
                            name="quarter"
                            rules={[{ required: true, message: 'Please select quarter!' }]}
                        >  
                            <Select onChange={(value) => this.setState({selectedQuarter: value})} options={quarterOptions}/>
                        </Form.Item>
                        <Form.Item
                            className="text-left"
                            label="Select States"
                            name="states"
                            rules={[{ required: true, message: 'Please select states!' }]}
                            wrapperCol={{ span: 12}}
                        >      
                            <Checkbox.Group options={stateOptions} onChange={handleCheckState}/>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{ span: 24}}
                        >   
                            <Button type="primary" htmlType="submit">Generate Report</Button>
                        </Form.Item>
                    </Form>
                </div>
            },
            {
              title: 'View and Edit',
              content: 
                <CallReportPreview 
                    selectedStates={this.state.selectedStates}
                    selectedQuarter={this.state.selectedQuarter}
                    selectedYear={this.state.selectedYear} 
                    updateCurrentStep={this.updateCurrentStep}
                    reportdata={this.state.callreportdata}
                    openingGeneratedReport={this.state.openingGeneratedReport}
                    Loans={this.state.Loans}
                    callreportdatatoSave={this.state.callreportdatatoSave}
                    setCallReportDatatoSave={(data) => this.setState({callreportdatatoSave: data})}
                    loanofficers={this.state.loanofficers}
                />
                // <Tabs defaultActiveKey={this.state.selectedStates[0]} items={tabItemsByState()} />
            },
        ]

        const items = steps.map((item) => ({
            key: item.title.toLowerCase(),
            title: item.title,
        }));

        return (
            <div style={{padding:"20px 20px 0px 20px"}}>
                <div style={{border:"2px solid #ECECEC",borderRadius:"8px", padding:"20px 20px 20px 40px", marginBottom:"20px"}}>
                   <div className="text-left" style={{fontSize:"16px", fontWeight:"600",marginBottom:"15px"}}>Generate and Save your Reports</div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <Steps style={{width:"500px", marginBottom:"20px"}} current={this.state.currentstep}  items={items}></Steps>
                        {this.state.currentstep === 1 && <div>
                        <Button style={{width:"120px"}} type="primary" onClick={this.handelGoBack}>Go Back</Button>
                        {!this.state.openingGeneratedReport &&<Button style={{marginLeft:"10px",width:"120px"}} type="primary" onClick={this.handleSaveReport} >Save Report</Button>}
                        </div>}
                    </div>
                    <div>{steps[this.state.currentstep].content}</div>
                </div>
                {!this.state.currentstep && <div style={{border:"2px solid #ECECEC",borderRadius:"8px", padding:"20px 20px 20px 20px "}} className="reportsTable">
                    <div className="text-left" style={{fontSize:"16px", fontWeight:"600",marginLeft:"15px",marginBottom:"10px"}}>Generated Reports:</div>
                    <Table columns={allReportCols} dataSource={this.state.Records} bordered />
                </div>}
            </div>
        );
    } 
}

export default CallReports;