import React, { useEffect, useRef, useState } from "react";
import { Button as B, Container, Row, Col, Spinner} from "react-bootstrap";
import { SearchOutlined, CloseOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Input, Space, Table, Button, Skeleton, Popconfirm, Dropdown, Tag, Tooltip, Modal, Form, Select, message } from "antd";
import Highlighter from "react-highlight-words";
import { Link, Redirect, withRouter, useHistory } from "react-router-dom";
import { cloneDeep} from "lodash";
import "./Borrowers.css"
import { checkAccess } from "./Auth";
import { UR_LoanProcessor} from "./State";
import dayjs from 'dayjs';
import * as com from './Common'
import {CaretDownOutlined} from '@ant-design/icons';
import { UR_LoanOfficer, UR_Owner,  } from "./State";
import * as JSZip from "jszip";
import { saveAs } from 'file-saver';
const manualupdateStatuses = [
  "loansubmitted", "conditionallyapproved", "applicationwithdrawn", "applicationdenied",
  "indocs","fundsreleased"
]
const { confirm } = Modal;
const Borrowers = (props) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [loans, setLoans] = useState([]);
  const [allLoans, setAllLoans] = useState([]);
  const [borrowerData, setBorrowerData] = useState([]);
  const [selectedBorrower, setSelectedBorrower] = useState("");
  const [isPast, setIsPast] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalStatus, setIsModalStatus] = useState(1);
  const [isLP, setIsLP] = useState(null)
  const [newBorrowerLoanID, setNewBorrowerLoanID] = useState("")
  const searchInput = useRef(null);
  const [searchedText, setSearchedText] = useState("");
  const [isArchivedLoans, setIsArchivedLoans] = useState(false)
  const history = useHistory();
  const formInitial = {}
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
  };

  const onFinish = (val) => {
    signup(val)
  }
  const signup = (data) => {
    let token = sessionStorage.getItem("ZeitroA")
    let local = window.location.hostname === "localhost"
    let tosend =
        JSON.stringify({
            email: data.email.toLowerCase(), 
            firstName: data.firstname,
            lastName: data.lastname,
            purpose: data.purpose,
        })
    fetch('/los/signupborrowerbylo', {
        method: 'POST',
        body: tosend,
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + token,
        },
    }).then(
        response => {
            if (response.status !== 200) {
                if (!local)
                    window.grecaptcha.reset()
                return;
            }
            response.json().then(js => {
                if (js.Status !== "OK") {
                  if (js.Text.includes("Account validated")) {
                    alert("The email you've entered is already in our system. You have the option to initiate a new loan from the borrower's page, or alternatively, use a different email that hasn't been registered yet.")
                } else {
                    alert(js.Text)
                }
                } else {
                    setNewBorrowerLoanID(js.Token)
                    getLoans('reset')
                    setIsModalStatus(2)
                }
            });
        }
        ).catch((err) => {
            console.log('Fetch Error :', err);
            // this.setState({ message: "Our servers are experiencing problems. Please check later.", show: true })
            if (!local)
                window.grecaptcha.reset()

        })
    }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue:[searchedText],
      onFilter: (value, record) => {
        return(
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record.phone).toLowerCase().includes(value.toLowerCase()) ||
          String(record.lastTouch).toLowerCase().includes(value.toLowerCase())
          );
      },
      // ...getColumnSearchProps("name"),
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      render: (_, record) => (
        <a
          onClick={() => handleSelected(record.borrowerId)}
          style={{ fontWeight: 500, fontSize: "16px"}}
        >
          {record.name}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => (
        <span style={{ color:"#325CEB",fontSize:"16px", fontWeight:"400"}}>{record.email}</span>
      )
      // ...getColumnSearchProps("email"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Activity Date",
      dataIndex: "lastTouch",
      key: "lastTouch",
      render: (text) => <>{text ? dayjs(text).format('MM/DD/YYYY') : ''}</>,
      sorter: (a, b) => a.lastTouch - b.lastTouch,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      fixed: 'right',
      render: (_, record) => (
        <div className="d-flex justify-content-end">
          <a
            onClick={() => handleSelected(record.borrowerId)}
            style={{ color: "#325CEB", textDecoration: "underline", marginRight: 23, fontSize: 16, fontWeight: 500 }}
            >
            View
          </a> 
          {!isLP && (
            <Popconfirm
              title="Are you sure to delete this borrower?"
              description="All the related loan records and documents will be deleleted."
              onConfirm={()=>deleteBorrower(record.borrowerId)}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "#EB0000", textDecoration: "underline", fontSize: 16, fontWeight: 500 }}>
                {deleteLoading ? <Spinner animation="border" variant="danger" size="sm" /> : <img style={{height:"32px"}} src="/images/delete.png" />}
              </a>
            </Popconfirm>
          )}  
          </div>
      ),
    },
  ];
  const transform = (borrower) => {
    const getPurpose = () => {
      if (borrower.TypeOfMortgage==="hardmoneyloan") return "Hard Money Loan"
      if (borrower.LoanPurpose === "") {
        return "";
      }
      if (borrower.LoanPurpose === "purchase") {
        if (borrower.HasContract) {
          return "Purchase";
        } else {
          return "Pre-qualification";
        }
      } else if (borrower.LoanPurpose === "refinance") {
        return "Rate/Term refinance";
      } else {
        return "Cash out refinance";
      }
    };
    return {
      name: borrower.FirstName + " " + borrower.LastName,
      email: borrower.Email,
      address: borrower.Address,
      phone: borrower.Phone === "" ? "-" : borrower.Phone,
      lastTouch: new Date(borrower.LastTouch),
      purpose: getPurpose(),
      borrowerId: borrower.ID,
      loanId: borrower.LoanID,
      isCurrentLoan: borrower.IsCurrentLoan,
      status: borrower.Status,
      loanstatus: borrower.LoanStatus
    };
  };
  const processLoans = (loans) => {
    let data = [];
    let borrowerMap = {};
    for (let loan of loans) {
      if (loan.email in borrowerMap) {
        if (loan.lastTouch > borrowerMap[loan.email].lastTouch) {
          borrowerMap[loan.email] = loan;
        }
      } else {
        borrowerMap[loan.email] = loan;
      }
    }
    for (let bo in borrowerMap) {
      data.push(borrowerMap[bo]);
    }
    return data;
  };

  const getLoans = (type) => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/loanlist", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Past": isPast,
        "X-LP": isLP ? true : false,
      },
    })
      .then((response) => {
        if (type === 'reset') {
          setSelectedBorrower("")
        }
        
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text);
          } else {
            
            let allDoc = js.Loans.map((s) => transform(s));
            setAllLoans(allDoc)
            let doc = []
            if (isArchivedLoans) {
              doc = allDoc.filter(s => s.loanstatus === "archived")
            } else {
              doc = allDoc.filter(s => s.loanstatus !== "archived")
            }
            setLoans(doc)
            
            let data = processLoans(allDoc);
            setBorrowerData(data);
            if (typeof props.match.params["borrowerid"] !== "undefined") {
              setSelectedBorrower(props.match.params["borrowerid"])
            } 
            setLoading(false);
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (checkAccess(UR_LoanProcessor)) {
      setIsLP(true)
    } else {
      setIsLP(false)
    }
  }, []);

  useEffect(() => {
    getLoans('reset');
  }, [isLP]);

  const handleSelected = (v) => {
    setSelectedBorrower(v);
  };
  const handleStatus = (id, status) => {
    const updatedBorrowers = loans.map(borrower => {
      if (borrower.loanId === id) {
        return { ...borrower, status: status };
      }
      return borrower;
    })
    setLoans(updatedBorrowers)
  }
  const deleteBorrower = (borrowerID) => {
    setDeleteLoading(true)
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/deleteborrower/"+borrowerID, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          setDeleteLoading(false)
          alert(
            "Looks like there was a problem. Please try later."
          );
          return;
        } else {
          setDeleteLoading(false)
          alert(
            "The borrower has been deleted."
          );
          window.location.href = "/app/borrowers"
        }
      })
      .catch(function (err) {
        setDeleteLoading(false)
        console.log("Fetch Error :-S", err);
      })
  }
  const handleLoanList = (key) => {
    setIsArchivedLoans(key)
    if (key) {
      setLoans(allLoans.filter(s => s.loanstatus === "archived"))
    } else {
      setLoans(allLoans.filter(s => s.loanstatus !== "archived"))
    }
  }
  const createApplication = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    if (isModalStatus === 2) {
      history.push("/app/borrower/" + newBorrowerLoanID + "?handingoff=true&type=newaccount");
    }

    setIsModalOpen(false)
    setIsModalStatus(1)
    form.resetFields()
  }
  const handleArchived = (ids, type) => {
    setLoading(true)
    let token = sessionStorage.getItem("ZeitroA")
    let body = { LoanIDs: ids }
    let api = type === 'add' ? '/los/bulkachiveloans' : '/los/bulkunachiveloans'
    fetch(api, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(response => {
      setLoading(false)
      if (response.status !== 200) {
        return;
      }
      if (type === 'add') {
        message.success('This loan have been archived.')
      } else {
        message.success('This loan have been unarchived.')
      }
      getLoans()
    }).catch(function (err) {
      setLoading(false)
    })
  }
  const { Option } = Select;
  return selectedBorrower !== "" ? (
    <BorrowerDetail
      borrowerId={selectedBorrower}
      borrowers={borrowerData}
      loans={loans}
      allLoans={allLoans}
      handleSelected={handleSelected}
      handleStatus={handleStatus}
      handleLoanList={handleLoanList}
      handleArchived={handleArchived}
    />
  ) : (
    <div className="borrowers-wrap text-right">
      {loading ? (
        <Skeleton active />
      ) : (
        <>  
          <Modal footer={null} width={786} open={isModalOpen} onCancel={handleCancel}>
            <div className={isModalStatus === 1 ? 'create-application-content' : 'create-application-content max-width-400'}>{
              isModalStatus === 1 ? (
                <>
                  <div className="create-application-title">Create an account</div>
                  <div className="create-application-subtitle">on borrower’s behalf</div>
                  <Form
                      className="create-application-form"
                      layout="vertical"
                      form={form}
                      initialValues={formInitial}
                      onFinish={onFinish}
                      validateMessages={validateMessages}
                      size="large"
                      >
                      <Row>
                          <Col className="pl-0 pr-0" span={24}>
                          <Form.Item
                              name="firstname"
                              label="First name"
                              rules={[
                              {
                                  required: true,
                              },
                              ]}
                          >
                              <Input placeholder="Enter customer first name" />
                          </Form.Item>
                          </Col>
                      </Row>
                      <Row>
                          <Col className="pl-0 pr-0" span={24}>
                          <Form.Item
                              name="lastname"
                              label="Last name"
                              rules={[
                              {
                                  required: true,
                              },
                              ]}
                          >
                              <Input placeholder="Enter customer last name" />
                          </Form.Item>
                          </Col>
                      </Row>
                      <Row>
                          <Col className="pl-0 pr-0" span={24}>
                          <Form.Item
                              name="email"
                              label="Customer email"
                              rules={[
                              {
                                  required: true,
                                  type: "email",
                              },
                              ]}
                          >
                              <Input placeholder="Enter customer email address" />
                          </Form.Item>
                          </Col>
                      </Row>
                      <Row>
                          <Col className="pl-0 pr-0 text-left" span={24}>
                          <Form.Item
                            name="purpose"
                            label="Loan purpose"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select the loan purpose"
                            >
                              <Option value="prequal">Pre-qualification</Option>
                              <Option value="purchase">Purchase</Option>
                              <Option value="refinance">Refinance</Option>
                              <Option value="cashoutrefinance">Cash out refinance</Option>
                            </Select>
                          </Form.Item>
                          </Col>
                      </Row>
                      <Button
                          htmlType="submit"
                          type="primary"
                          className="create-application-btn"
                          block
                      >
                          Sign up
                      </Button>
                      <div className="create-application-tip">Use this account to prepopulate information on the basis of personal or phone interview. It is not exposed to the borrower until the hand off</div>
                  </Form>
                </>
              ) : (
                <div>
                  <img src="/images/create-success.svg"/>
                  <div className="success-text">Account created successfully</div>
                  <div className="create-application-tip text-center">The account for your customer has been successfully set up. You can proceed to fill out the information and pass it onto your borrower on the subsequent page.</div>
                  <div className="mt-3">
                    <Button type="primary" onClick={()=>handleCancel()}>Proceed to fill out the borrower information</Button>
                  </div>
                </div>
              )
            }
            </div>
          </Modal>
          <div className="top-bar borrowers-container">
            <div className="top-bar-left"><img src='/sidebaricons/borrowers.svg'/><span>Borrowers</span></div>
            {!isLP && 
              // <Link className="applicant-link" to="/app/customerregistration">
              //   <B
              //     variant="zeitro-primary"
              //     style={{ width: "126px" }}
              //     className="applicant-btn"
              //   >
              //     New applicant
              //   </B>
              // </Link>
              <div
                className="applicant-btn"
                onClick={createApplication}
              >
                Create applicant
              </div>
            }
          </div>
          <div className="borrowers-pane">
            <div className="d-flex justify-content-between operate-bar borrowers-container">
              <div className="title-4 borrower-num">
                  {borrowerData.length + " results"}
              </div>
              <Input style={{width: 216, maxWidth: '50%'}} size="large" onChange={e => {setSearchedText(e.target.value)}} placeholder="Search by any field" prefix={<SearchOutlined />} />

            </div>
            <div className="borrowers-data-tableWrap">
              <Table
                  className="borrowers-data-table"
                  columns={columns}
                  dataSource={borrowerData}
                  showSorterTooltip={false}
                  scroll={{
                    x: true,
                  }}
                  pagination={{
                    hideOnSinglePage: true,
                  }}
                />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const BorrowerDetail = ({ borrowerId, borrowers, loans, allLoans, handleSelected, handleStatus, handleLoanList, handleArchived }) => {
  // const borrowerid = "1a65525d-eef0-45a7-b796-4357c97617df";
  const [borrowerSearch, setBorrowerSearch] = useState("");
  const [currentBorrowers, setCurrentBorrowers] = useState(borrowers);
  const [currentBorrower, setCurrentBorrower] = useState({});
  const [activeTab, setActiveTab] = useState(0)
  const [isNewAppModalOpen, setIsNewAppModalOpen] = useState(false)
  const [isNewAppModalStatus, setIsNewAppModalStatus] = useState(1)
  const [newAppForm] = Form.useForm()
  const [firstnameCheck, setFirstnameCheck] = useState("")
  const [lastnameCheck, setLastnameCheck] = useState("")
  const [purposeCheck, setPurposeCheck] = useState("")
  const [newAppLoanID, setNewAppLoanID] = useState("")
  const [documents, setDocuments] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isArchivedLoans, setIsArchivedLoans] = useState("")
  const newAppFormInitial = {}

  const newAppValidateMessages = {
    required: "${label} is required!"
  };

  const borrowerSearchHandler = (e) => {
    let tmp = borrowers.filter(
      (c) =>
        c.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        c.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setBorrowerSearch(e.target.value);
    setCurrentBorrowers(cloneDeep(tmp));
  };
  const history = useHistory();

  useEffect(() => {
    for (let b of borrowers) {
      if (b.borrowerId === borrowerId) {
        setCurrentBorrower(b);
        getDocuments(b);
      }
    }
  }, [borrowerId]);

  const getDocuments = (borrower) => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/documents", {
      method: "POST",
      body: JSON.stringify(
        { 
          ID: borrower.borrowerId, 
          LoanID: borrower.loanId, 
          WhoseDocuments: "all" 
        }
      ),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text);
          } else {
            let docs = js.Docs.filter( d => d.Mime !== "application/xml").map(s => {
              let dt = new Date(s.Uploaded);
              let tmp = s.ExpiryDate.split(' ')[0]
              tmp = tmp.split('-')
              let expDate = s.ExpiryDate === '' ? '' : `${tmp[1]}/${tmp[2]}/${tmp[0]}`
              let result = {
                id: s.ID,
                name: s.FileName,
                forwhom: s.ForWhom,
                created: dt.toLocaleString("en-US"),
                expiryDate: expDate,
              }
              return result
            })
            setDocuments(docs);
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  }

  const documentsJSON = (document, index) => {
    return {
      key: index,
      filename: document.name,
      forwhom: document.forwhom,
      created: document.created,
      expiryDate: document.expiryDate,
      serialID: document.id
    }
  }

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    }
  }

  const fileNameFormat = (filename) => {
    return filename.substring(0, filename.lastIndexOf('.')) ? filename.substring(0, filename.lastIndexOf('.')) : filename
  }

  const downloadAll = (ids, type) => {
    let token = sessionStorage.getItem("ZeitroA") 
    fetch('/los/downloadalldocs', {
      method: 'POST',
      body: JSON.stringify({ BorrowerID: currentBorrower.borrowerId, DocumentIDs: ids }),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(async response => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      }
      await response.json().then(js => {
          let files = js.Documents;
          if (files.length === 1){
            let file = base64ToArrayBuffer(files[0].Content)
            const modifiedPdfBlob = new Blob([file], { type: 'application/pdf' })
            const downloadLink = document.createElement('a')
            downloadLink.href = URL.createObjectURL(modifiedPdfBlob)
            downloadLink.download = files[0].Name
            downloadLink.click()
          } else {
            let zip = new JSZip()
            let filesFolder = zip.folder(`${currentBorrower.name} Documents`)
            for (let i = 0; i < files.length; i++){
              let {Name, Content} = files[i];
              filesFolder.file(Name, Content, {base64: true})
            }
            zip.generateAsync({type:"blob"}).then(blob=> saveAs(blob, `${currentBorrower.name} documents.zip`))
          }
      })
    }).catch(() => {
      console.log('Fetch Error :-S')
    })
  }

  const deleteAll = (ids) => {
    let token = sessionStorage.getItem("ZeitroA")
    let body = { DocumentIDs: ids }
    fetch('/los/deletedocs', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache"
      }
    }).then(
        response => {
            if (response.status !== 200) {
                message.error('something went wrong please try later')
                return
            }
            // Examine the text in the response
            response.json().then(js => {

                if (js.Status !== "OK") {
                    message.error('something went wrong please try later')
                } else {
                    setSelectedRowKeys([])
                    getDocuments(currentBorrower)
                }
            })
        }
    ).catch((err) => {
        message.error('something went wrong please try later')
        this.setState({ loading: false })
    })
  }

  const base64ToArrayBuffer = (base64) => {
    const binaryString = atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes.buffer
  }

  let operateBtn = [
    {
      key: 'download',
      label: 'Download',
    },
    {
      key: 'delete',
      label: 'Delete',
    }
  ]

  const handleOperate = (key) => {
    let ids = selectedRowKeys
    switch (key) {
      case 'download':
        downloadAll(ids, 'download')
        break
      case 'delete':
        deleteAll(ids)
        break
    }
  }

  const docTableColumns = [
    {
      title: "Name",
      dataIndex: "filename",
      key: "filename",
      width: 350,
      fixed: 'left',
      sorter: (a, b) => {
          if (a.filename < b.filename) {
              return -1;
          }
          if (a.filename > b.filename) {
              return 1;
          }
          return 0;
      },
      render: (text, record) => <div className="d-flex align-items-center"><img src="/images/document-pdf.svg" /><div className="ml-2 d-flex align-items-center"><div>{fileNameFormat(text)}</div>.pdf</div></div>,
    },
    {
      title: 'Uploaded by',
      dataIndex: "forwhom",
      key: "forwhom",
      render: (text) => <span>{text === "lender"?"You":"Borrower"}</span>,
    },
    {
      title: 'Created date',
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => {
          if (a.created < b.created) {
              return -1;
          }
          if (a.created > b.created) {
              return 1;
          }
          return 0;
      },
      render: (text) => <span className="document-created">{dayjs(text).format('MM/DD/YYYY')}</span>,
    },
    {
      title: 'Expiry date',
      dataIndex: "expiryDate",
      key: "expiryDate",
      sorter: (a, b) => {
          if (a.expiryDate < b.expiryDate) {
              return -1;
          }
          if (a.expiryDate > b.expiryDate) {
              return 1;
          }
          return 0;
      },
      render: (text, record) => <span style={{ textDecorationLine: 'underline', cursor: 'pointer' }} >{text === '' ? 'N/A' : text}</span>,
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      render: (text, record) => (
          <Space size="middle">
              <img style={{cursor:"pointer"}} src="/images/document-download.svg" onClick={() => downloadAll([record.serialID],'download')} />
              <img style={{cursor:"pointer"}} src="/images/document-delete.svg" onClick={() => deleteAll([record.serialID])} />
          </Space>
      ),
    }
  ]

  const switchBorrower = (where,purpose, email) => {
    sessionStorage.setItem("edit", "false")
    sessionStorage.setItem("state", "{}");
    sessionStorage.setItem("originalstate", "{}");
    sessionStorage.setItem("loanid", where);
    sessionStorage.setItem("purpose", purpose);
    sessionStorage.setItem("email", email);
    sessionStorage.setItem("borrowermenu", "loanSummary");
    // window.location.href = "/app/borrower/" + where;
    history.push("/app/borrower/" + where);
    
  };
  const getBorrowerLoans = () => {
    // if (loans.length === 0) {
    //   return (
    //     <Col xl={4} md={4} className="noLoan-card">
    //       <img style={{width: '74px', height: 'auto', marginBottom: '17px'}} alt="package" src="/images/package-icon.svg" />
    //       <span style={{fontSize: '14px', color: '#000', marginBottom: '24px'}}>The borrower doesn’t have any loans.</span>
    //       <div className="borrower-btn">Add loan</div>
    //     </Col>
    //   )
    // }
    const getIcon = (purpose) => {
      if (purpose === "Purchase" || purpose === "Pre-qualification") {
        return "/images/purchase.svg"
      } else {
        return "/images/refinance.svg"
      }
    }

    const handleLoanToggle = (e, id) => {
      e.domEvent.stopPropagation()
      let type = e.key
      let ids = [id]
      if (type === 'add') {
        handleArchived(ids, type)
      } else {
        confirm({
          title: 'Do you want to remove this loan from the archived loans?',
          icon: <ExclamationCircleFilled />,
          onOk: () => {
            handleArchived(ids, type)
          },
          onCancel() {
            
          },
        })
      } 
    }
    const handleStatusChange = (loanID, newStatus) => {
      let token = sessionStorage.getItem("ZeitroA")
      let body = { LoanID: loanID, Key: "status", Value: newStatus, IsAdmin: checkAccess([UR_Owner]) ? true : false }
      fetch('/los/updatepipeline', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache"
        }
      }).then(response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          // this.setState({ showErrorModal: true})
          return;
        }
        handleStatus(loanID, newStatus)
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      })
    }
    let bLoans = loans.filter(
      (l) => l.borrowerId === currentBorrower.borrowerId
    );
    bLoans.sort((a, b) => {
      if (a.isCurrentLoan && !b.isCurrentLoan) {
        return -1; 
      } else if (!a.isCurrentLoan && b.isCurrentLoan) {
        return 1; 
      }
      return 0; 
    });
    const items = (loanid) => {
      return Object.entries(com.loanStatusMap).map(([key, val]) => {

        return {
          key: key,
          label: (
            <a onClick={() => handleStatusChange(loanid, key)}>
              {val}
            </a>
          ),
        }
        
      })
    }

    let loansList = bLoans.map((l, idx) => (
        <div
          className="text-left d-flex flex-column borrowerLoans-item hoverEffect"
          style={{
            paddingTop: currentBorrower.loanId === l.loanId ? 0 : "45px",
            borderRadius: 4,
            border: "1px solid #ECECEC",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          }}
          
        >
          {/* {l.isCurrentLoan && <div className="applicationLabel">Current application</div>} */}
          <div className="pointerEffect" onClick={(e) => switchBorrower(l.loanId, e)}>
            {currentBorrower.loanId === l.loanId && <div className="d-flex align-items-center currentAppLabel"><img className="currentAppIcon" src="/images/verified02.png" />Current application</div>}
            <div className="borrowerLoans-item-top">
              
              <div className="borrowerLoans-item-icon"><img src={getIcon(l.purpose)} /></div>
              <div style={{paddingTop: 3, flex: 1, width: 0}}>
                <div className="borrowerLoans-item-purpose">
                  <span>{l.purpose}</span>
                  <Dropdown
                      className="document-dropdown"
                      menu={{
                          items: loanDropdownItems,
                          onClick: (e) => handleLoanToggle(e, l.loanId)
                      }}
                      trigger={['click']}
                  >
                      <img style={{width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => e.stopPropagation()} className="document-more" src="/images/document-more.svg" />
                  </Dropdown>
                  {/* <div className="view-btn" onClick={() => switchBorrower(l.loanId)}>View</div> */}
                </div>
                <div className="borrowerLoans-item-address">{l.address === "" ? "-" : <Tooltip title={l.address} placement="top"><span>{l.address}</span></Tooltip>}</div>
              </div>
              
            </div>
            {/* <div className="borrowerLoans-item-loanAmount">
              <span>Loan amount:</span><span style={{fontWeight: 600}}>$500,000</span>
            </div> */}
            <div className="borrowerLoans-item-editTime">
              <span>Last edit:</span><span>{dayjs(l.lastTouch).format('MM/DD/YYYY, HH:mm')}</span>
            </div>
          </div>
          <div style={{paddingTop: 12}} className="border-top d-flex justify-content-between align-items-center">
            <div style={{color: '#6E6E70', fontSize: '16px', fontWeight: 500}}>Status:</div>
            <div className="loansStatus">
              <Tag
                bordered={false}
                color={com.mapStatusTagColor(l.status)}
                className="loansStatusTag" >
                {com.splitStatusByWords(l.status)}
              </Tag>
              <Dropdown
                  menu={{items: items(l.loanId)}}
                  placement="bottomLeft"
                  arrow
                >
                  <Button className="loanStatusSelect" >
                    <CaretDownOutlined style={{fontSize:11}}/>
                  </Button>
              </Dropdown>
            </div>
          </div>
        </div>
      ))
      
    loansList.push(
        <div
          className="text-left d-flex flex-column borrowerLoans-item hoverEffect pointerEffect"
          style={{
            borderRadius: 4,
            border: "1px solid #ECECEC",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            backgroundColor: "#FCFCFC",
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: "211px"
          }}
          onClick={() => setIsNewAppModalOpen(true)}
        >
          <div className="d-flex align-items-center">
            <img src="/images/addIconCircle.png" />
            <div className="createNewButton"
            >
              Create new application
            </div>
          </div>
        </div>
    )
    
    return loansList
  };

  const createNewApplication = (data) => {
      let token = sessionStorage.getItem("ZeitroA")
      let local = window.location.hostname === "localhost"
      let js = {
          email: currentBorrower.email.toLowerCase(),
          firstName: currentBorrower.name.split(' ')[0],
          lastName: currentBorrower.name.split(' ')[1],
          purpose: data.purpose
      }
      fetch('/los/createapplicationbylo', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        },
        body: JSON.stringify(js)
    }).then(
        response => {
            if (response.status !== 200) {
                alert("Looks like there was a problem, please try again later or contact Zeitro support.")
                return;
            }
            response.json().then(js => {
                if (js.Status !== "OK") {
                    console.log(js.Text)
                    alert("Looks like there was a problem, please try again later.")
                } else {
                    setNewAppLoanID(js.Token)
                    getBorrowerLoans()
                    setIsNewAppModalStatus(2)
              
                }

            });
        }
    ).catch((err) => {
        console.log('Fetch Error :', err);
        if (!local)
          window.grecaptcha.reset()
    });
  }


  const handleNewAppModalCancel = () => {
    if (isNewAppModalStatus === 2) {
      sessionStorage.setItem("edit", "false")
      sessionStorage.setItem("state", "{}");
      sessionStorage.setItem("originalstate", "{}");
      sessionStorage.setItem("loanid", newAppLoanID);
      sessionStorage.setItem("purpose", purposeCheck);
      sessionStorage.setItem("email", currentBorrower.email.toLowerCase());
      sessionStorage.setItem("borrowermenu", "loanSummary");
      history.push("/app/borrower/" + newAppLoanID + "?handingoff=true&type=newloan");
    }

    setIsNewAppModalOpen(false)
    setIsNewAppModalStatus(1)
    newAppForm.resetFields()
  }

  const onNewAppModalFinish = (values) => {
    createNewApplication(values)
  }
  const handleArchivedToggle = (e) => {
    const isArchived = e.key === 'archived'
    setIsArchivedLoans(isArchived)
    handleLoanList(isArchived)
  }
  const dropdownItems = isArchivedLoans ? [
    {
      label: 'All Loans',
      key: 'all',
    },
  ] : [
    {
      label: 'Archived Loans',
      key: 'archived',
    }
  ]
  const loanDropdownItems = isArchivedLoans ? [
    {
      label: 'Unarchive',
      key: 'remove',
    },
  ] : [
    {
      label: 'Archive',
      key: 'add',
    }
  ]
  return (
    <>
      <Modal footer={null} width={786} open={isNewAppModalOpen} onCancel={handleNewAppModalCancel}>
        <div className={isNewAppModalStatus === 1 ? 'create-application-content' : 'create-application-content max-width-400'}>{
          isNewAppModalStatus === 1 ? (
            <>
              <div className="create-application-title">Create new application</div>
              <div className="create-application-subtitle">for {currentBorrower.name}</div>
              <Form
                  className="create-application-form"
                  layout="vertical"
                  form={newAppForm}
                  initialValues={newAppFormInitial}
                  onFinish={onNewAppModalFinish}
                  validateMessages={newAppValidateMessages}
                  size="large"
                  >
                  <Row>
                      <Col className="pl-0 pr-0 text-left" span={24}>
                      <Form.Item
                        name="purpose"
                        label="Loan purpose"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select the loan purpose"
                        >
                          <Option value="prequal">Pre-qualification</Option>
                          <Option value="purchase">Purchase</Option>
                          <Option value="refinance">Refinance</Option>
                          <Option value="cashoutrefinance">Cash out refinance</Option>
                        </Select>
                      </Form.Item>
                      </Col>
                  </Row>
                  <Button
                      htmlType="submit"
                      type="primary"
                      className="create-application-btn"
                      block
                  >
                      Create application
                  </Button>
                  {/* <div className="create-application-tip">Use this account to prepopulate information on the basis of personal or phone interview. It is not exposed to the borrower until the hand off</div> */}
              </Form>
            </>
          ) : (
            <div>
              <img src="/images/create-success.svg"/>
              <div className="success-text">Application created successfully</div>
              <div className="create-application-tip text-center">The application for your borrower has been successfully set up. You can proceed to fill out the information and pass it onto your borrower on the subsequent page.</div>
              <div className="mt-3">
                <Button type="primary" onClick={()=>handleNewAppModalCancel()}>Proceed to fill out the application</Button>
              </div>
            </div>
          )
        }
        </div>
      </Modal>
      <div className="borrowerBar">
        <span onClick={() => handleSelected("")} style={{color: '#6E6E70', cursor: 'pointer'}}>Borrowers</span><img alt="arrow" src="/images/arrow-bar.svg" /><span style={{color: '#222'}}>{currentBorrower.name}</span>
      </div>
      <Row className="d-flex los-borrower">
        {
          activeTab === 0 && (
            <div className="borrowerDetails los-borrowerDetails">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                  <span className="title-2">{currentBorrower.name}</span>
                  {/* <div className="borrower-dti"><span>DTI</span><span>36%</span></div> */}
                </div>
                {/* <div className="borrower-btn">Contact</div> */}
              </div>
              <Row className="borrower-info-card">
                <Col xl={3} className="info-item">
                  <img alt="phone" src="/images/phone-icon.svg" />
                  <div>
                    <div className="info-item-label">Phone</div>
                    <div className="info-item-value text-left">{currentBorrower.phone}</div>
                  </div>
                </Col>
                <Col xl={4} className="info-item">
                  <img alt="phone" src="/images/email-icon.svg" />
                  <div>
                    <div className="info-item-label">Email</div>
                    <div className="info-item-value text-left">{currentBorrower.email}</div>
                  </div>
                </Col>
                <Col xl={5} className="info-item">
                  <img alt="phone" src="/images/location-icon.svg" />
                  <div>
                    <div className="info-item-label">Home address</div>
                    <div className="info-item-value text-left">{currentBorrower.address === "" ? "-" : currentBorrower.address}</div>
                  </div>
                </Col>
              </Row>
              <div style={{borderTop: '1px solid #ECECEC'}}>
                <div className="title-4 loans-bar">
                  <div>
                    <span style={{color: '#222', fontWeight: 500}}>{isArchivedLoans ? 'Archived Loans' : 'All Loans'}</span>
                    {
                      (allLoans.filter((l) => l.borrowerId === currentBorrower.borrowerId && l.loanstatus === "archived").length > 0 || isArchivedLoans) && (
                        <Dropdown
                            className="document-dropdown"
                            menu={{
                                items: dropdownItems,
                                onClick: (e) => handleArchivedToggle(e)
                            }}
                            trigger={['click']}
                        >
                            <img style={{width: 24, height: 24, cursor: 'pointer'}} className="document-more" src="/images/document-more.svg" />
                        </Dropdown>
                      )
                    }
                  </div>
                  {/* {
                    loans.length !== 0 && (
                      <div className="borrower-btn">Add loan</div>
                    )
                  } */}
                </div>
                <Row className="d-flex flex-wrap borrowerLoans-wrap">{getBorrowerLoans()}</Row>
              </div>
              {/* <div style={{borderTop: '1px solid #ECECEC', marginTop:"30px", marginBottom:"30px"}}>
                <div className="title-4 loans-bar">
                  <span style={{color: '#222', fontWeight: 500}}>Current application documents</span>
                </div>
                {
                  selectedRowKeys.length > 0 && (
                    <div className="operateBar">
                        <div className="documentTotal"><CloseOutlined onClick={() => setSelectedRowKeys([])} style={{ fontSize: 12, marginRight: 12, cursor: 'pointer' }} /> <span className="document-num">{selectedRowKeys.length} selected</span></div>
                        <div className="documentOperate">
                            {
                                operateBtn.map(item => {
                                    return (
                                      <div className="documentOperateBtn">
                                          <img src={`/images/document-${item.key}.svg`} onClick={() => handleOperate(item.key)} />
                                      </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                  )
                }
                <Table 
                  columns={docTableColumns}
                  dataSource={documents.map((doc, index) => documentsJSON(doc, index))}
                  rowKey={(record) => record.serialID}
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  locale={{emptyText: "No documents uploaded yet"}}
                  pagination={false}
                />
              </div> */}
            </div>
          )
        }
      </Row>
    </>
  );
};

export default withRouter(Borrowers);
