import React, {useState, useEffect} from "react";
import {withRouter, useLocation, useHistory} from "react-router-dom";
import {
  Elements,
  CardElement,
  PaymentElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {Redirect} from "react-router-dom";
import LoanOfficers from "../Management/LoanOfficers";
import {Spin, Skeleton, Button, InputNumber, Modal} from "antd";
import {loadStripe} from "@stripe/stripe-js";
import "./Billing.css";
import { getStripePublicKey } from "./Prices";

const stripePromise = loadStripe(
  getStripePublicKey()
);

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      iconColor: "#c4f0ff",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const StripePaymentForm = ({clientSecret, selectedPriceId, customer, paymentMethods, history}) => {
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [quantity, setQuantity] = useState(3);
  const [currentActiveNumberOfLos, setCurrentActiveNumberOfLos] = useState(1);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showManageLOModal, setShowManageLOModal] = useState(false);
  const [showAlertQuantityModal, setShowAlertQuantityModal] = useState(false);
  const [showSetNewCard, setShowSetNewCard] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    console.log("StripePaymentForm: ", clientSecret, selectedPriceId, customer);
    getActiveLOs()
  }, []);

  const handleSubmit = async (e) => {
    setLoadingSubscription(true);
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      // Handle the case where the card element isn't found
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    console.log(result);
    if (result.error) {
      // Handle errors
      console.error(result.error.message);
    } else {
      // Payment method successfully created, get the ID
      setPaymentMethodId(result.paymentMethod.id);

      stripe
        .confirmCardSetup(clientSecret, {
          payment_method: result.paymentMethod.id,
        })
        .then(function (result) {
          console.log(result);
          if (result.error) {
            console.log(result.error);
          } else {
            onSuccessfulSetup(result.setupIntent.payment_method);
          }
        });
    }
  };

  const onSuccessfulSetup = async (paymentMethodId) => {
    const subscriptionResponse = await fetch("/los/stripe/createsubscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priceId: selectedPriceId,
        customerId: customer.id,
        paymentMethodId: paymentMethodId,
        quantity: quantity,
      }),
    }).then((r) => r.json());

    console.log("Subscription response:", subscriptionResponse);
    setSubscriptionData(subscriptionResponse);
    setLoadingSubscription(false);
    alert("Subscription created successfully")
    history.push("/app/billing");
  };

  const onSubscribeUsingExistingCard = () => {
    setLoadingSubscription(true);
    const paymentMethodId = paymentMethods[0].id
    onSuccessfulSetup(paymentMethodId);
  }

  const getActiveLOs = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/loanofficerlist", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then((js) => {
          console.log(js);
          const n = js.LoanOfficers.filter((lo) => (!(lo.Roles.length===1 && lo.Roles[0]==="PriceEngineUser"))).length
          setQuantity(n);
          setCurrentActiveNumberOfLos(n);
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  }

  useEffect(() => {
    console.log("quantity: ", quantity);
    if (quantity!== null && quantity < currentActiveNumberOfLos) {
      setShowAlertQuantityModal(true)
    }
  }, [quantity, currentActiveNumberOfLos]);

  const displayManageLOModal = () => {
    const handleCancel = () => {
      getActiveLOs()
      setShowManageLOModal(false)
    }
    return <Modal 
            title="Manage loan officers"
            open={showManageLOModal}
            onCancel={handleCancel}
            width={1000}
            footer={[
              <Button key="back" onClick={handleCancel}>
                OK
              </Button>
            ]}
    
            >
      <LoanOfficers />
    </Modal>
  }

  const displayAlertQuantityModal = () => {
    const handleCancel = () => {
      setQuantity(currentActiveNumberOfLos)
      setShowAlertQuantityModal(false)
    }
    return <Modal 
            title="Invalid number of seats"
            open={showAlertQuantityModal}
            onCancel={handleCancel}
            footer={[]}
    
            >
      <div>You are trying to subscribe to a plan with less number of seats than the number of active loan officers in your account. Please manage your loan officers before subscribing to this plan, or choose a number more than {currentActiveNumberOfLos} </div>
      <div className="mt-3">
        <Button type="primary" className="mr-3" onClick={()=>{
          setShowAlertQuantityModal(false)
          setShowManageLOModal(true)
        }}>Manage loan officers</Button>
        <Button type="secondary" onClick={()=>{
          setQuantity(currentActiveNumberOfLos)
          setShowAlertQuantityModal(false)
        }}>Reset number of seats</Button>
      </div>
    </Modal>
  }


  return (
    <div className="p-3">
      {displayAlertQuantityModal()}
      {displayManageLOModal()}
      <div className="title-2">Selected Plan</div>
      {selectedPriceId}
      <div>
        <div className="">Number of seats(You currently have {currentActiveNumberOfLos} active LOs)</div>
        <div className="">
          <InputNumber value={quantity} onChange={(e)=>setQuantity(e)} />
        </div>
      </div>
      {
        (!paymentMethods || paymentMethods.length === 0 || showSetNewCard)  ? (
          <form onSubmit={handleSubmit}>
            <div>
              <label>Card Number</label>
              <CardNumberElement />
            </div>
            <div>
              <label>Expiration Date</label>
              <CardExpiryElement />
            </div>
            <div>
              <label>CVC</label>
              <CardCvcElement />
            </div>
            <Button
              htmlType="submit"
              disabled={!stripe}
              loading={loadingSubscription}>
              Subscribe
            </Button>
          </form>
            ) : (
              <div>
                <Button loading={loadingSubscription} onClick={()=>onSubscribeUsingExistingCard()}>Subscribe using existing card ending in {paymentMethods[0].card.last4}</Button>
                <Button onClick={()=>setShowSetNewCard(true)}>Subscribe using a new card</Button>
              </div>
            )
        }
    </div>
  );
};

const Subscribe = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state;

  const clientSecret = state?.clientSecret;
  const selectedPriceId = state?.selectedPriceId;
  const customer = state?.customer;
  const paymentMethods = state?.paymentMethods;

  useEffect(() => {
    console.log(paymentMethods);
  }, []);

  return (
    <Elements
      stripe={stripePromise}
      options={{clientSecret}}>
      <StripePaymentForm
        clientSecret={clientSecret}
        selectedPriceId={selectedPriceId}
        customer={customer}
        paymentMethods={paymentMethods}
        history={history}
      />
    </Elements>
  );
};

export default Subscribe;
