import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { PennyInput } from "../NumericalInput";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Multiselect from "multiselect-react-dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../Utils.css";
import "./Management.css"
import * as com from "../Common";
import { lender } from "../State";
import { toInteger } from "lodash";
import { message, Popconfirm, Collapse, Input, Radio, Divider, Table, Select } from 'antd';
import { InfoCircleTwoTone } from "@ant-design/icons";
import { tooltip } from "../Common/Tooltip";
import { DeleteTwoTone } from "@ant-design/icons";
import PlanModal from "../planModal";
import { connect } from 'react-redux'
const deepcopy = require("deepcopy");
const mapStateToProps = (state) => {
  return {
    subscriptionReducer: state.subscriptionReducer.currentInfo
  }
}
const emptyrule = {
  selectedRule: "",
  value: "",
  maxrule: "",
  minrule: "",
  minoperator: "less",
  maxoperator: "less",
  action: "",
  selectedEnum: [],
  operator: null,
  ref: null,
};
const emptypromotion = {
  selectedPromotion: "",
  value: "",
  maxrule: "",
  minrule: "",
  minoperator: "less",
  maxoperator: "less",
  message: "",
  selectedEnum: [],
  operator: null,
  ref: null,
};

const lendersLogoMap = {
  Flagstar: "/images/lendersLogo/Flagstar_logo.png",
  "Divine Mortgage": "/images/lendersLogo/Divine_logo.png",
  AmWest: "/images/lendersLogo/Amwest_logo.png",
  AmeriHome: "/images/lendersLogo/Amerihome_logo.png",
  Caliber: "/images/lendersLogo/Caliber_logo.png",
  Fairway: "/images/lendersLogo/Fairway_logo.png",
  LoanStore: "/images/lendersLogo/Loanstore_logo.png",
  "New Wave Lending": "/images/lendersLogo/NewWaveLending_logo.png",
  "PlanetHome": "/images/lendersLogo/PlanetHome_logo.png",
  Provident: "/images/lendersLogo/PF_logo.png",
  PennyMac: "/images/lendersLogo/PennyMac_logo.png",
  PRMG: "/images/lendersLogo/PRMG_logo.png",
  USBank: "/images/lendersLogo/USBank_logo.png",
  "Rocket Pro": "/images/lendersLogo/Rocket_logo.png",
  "Wholesale Mortgage Bankers": "/images/lendersLogo/WMB_logo.png",
  "GMCC Celebrities": "/images/lendersLogo/GMCC_logo.png",
  "53 Bank": "/images/lendersLogo/53bank_logo.png",
  ResiCentral: "/images/lendersLogo/Resicentral_logo.png",
  FNMA: "/images/lendersLogo/FNMA_logo.png",
  "Credit Human": "/images/lendersLogo/CreditHuman_logo.png",
  "The Money Source": "/images/lendersLogo/TheMoneySource_logo.png",
  "Triad": "/images/lendersLogo/Triad_logo.png",
  "Chenoa Fund": "/images/lendersLogo/ChenoaFund_logo.png",
  "Freedom Mortgage": "/images/lendersLogo/Freedom_logo.png",
  "JMAC": "/images/lendersLogo/JMAC_logo.png",
  "U.S. Bank": "/images/lendersLogo/USBank_logo.png",
}
class Lenders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      allenders: [],
      customerlenders: [],
      selectedid: null,
      validated: false,
      minimumClosingCostPercentage: "",
      maximumClosingCostPercentage: "",
      show: false,
      showcopy: false,
      showpromotion: false,
      mode: "add",
      showmodalvalidated: false,
      rules: [deepcopy(emptyrule)],
      promotions: [deepcopy(emptypromotion)],
      selectedEnum: [],
      modalcopyvalidated: false,
      modalpromotionvalidated: false,
      lendercache: {},
      showLendersNotInListModal: false,
      isMobile: window.innerWidth < 576,
      showEdit: false,
      editTarget: [],
      modalAddLenderValidated: false,
      selectedLenders: [],
      savedLenders: [],
      currentView: "tableview",
      expandedRowKeys: [],
      hoveredRowIndex: null,
      cardHovering: {},
      isNarrowScreen: window.innerWidth < 1400,
      loanLimits: {},
      isInputSearchChanged: false,
      searchKeywords: "",
    };
    this.state.rules[0].ref = React.createRef();
    this.state.promotions[0].ref = React.createRef();
    this.lendersid = {};
    // this.form = React.createRef();
    this.formRefs = {};
    this.modal = React.createRef();
    this.promotionmodal = React.createRef();
    this.modalcopy = React.createRef();
    this.addLenderModal = React.createRef();
  }

  getCustomerLenders = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/data/getcustomerlenders", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            alert("Error!");
          } else {
            this.setState({
              minimumClosingCostPercentage: js.Config.MinClosingCostPercent,
              maximumClosingCostPercentage: js.Config.MaxClosingCostPercent,
            });
            if (js.Lenders) {
              js.Lenders.forEach((x) => {
                if (!x.Config) {
                  x.Config = {}
                } else {
                  x.Config = JSON.parse(x.Config);
                }
              });
              // this.getPreviousSavedStaus(js.Lenders);
              this.resetLenderIDs(js.Lenders);
            }
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };
  getPreviousSavedStaus = (lenders) => {
    let prviousSavedLenders = deepcopy(this.state.savedLenders);
    lenders.forEach((x) => {
      if (x.Active) {
        prviousSavedLenders.push(x.name)
      }
    })
    this.setState({ savedLenders: prviousSavedLenders })
  }

  resetLenderIDs = (lenders) => {
    this.lendersid = [];
    lenders.forEach((x) => {
      if (x.Active) {
        this.lendersid[x.id] = true;
      }
    });
    // lenders = lenders.sort((x, y) => {
    //   return x.name[0] > y.name[0] ? 1 : -1;
    // });
    this.setState({ customerlenders: lenders });
    // if (lenders.length > 0 ) {
    //     this.setState({ selectedid: lenders[0].id })
    // }
  };

  getLoanLimits = () => {
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {

          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        this.setState({ loanLimits: res })
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getLenders = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/data/getalllenders", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            alert("Error!");
          } else {
            let lenders = js.Lenders.sort((x, y) => {
              return x.name[0] > y.name[0] ? 1 : -1;
            });
            this.setState({ allenders: lenders });
            this.getCustomerLenders();
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };
  componentDidMount() {
    this.getLenders();
    this.getLoanLimits();
  }
  componentDidUpdate(prevState) {
    if (!prevState.showAdd && this.state.showAdd) {
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.setAttribute('id', 'addlenderModal-backdrop');
      }
    }
  }
  sendLenders(name) {
    let token = sessionStorage.getItem("ZeitroA");
    let b = [];
    let lendersCopy = deepcopy(this.state.customerlenders)
    for (let l of lendersCopy) {
      if (l.Active) {
        b.push(l);
      }
    }
    // reset config to string
    b.forEach((x) => {
      if (x.Config.closeover !== null) {
        x.Config.closeover = com.safeParseFloat(x.Config.closeover).toString();
      }
      x.Config = JSON.stringify(x.Config);
      if (x.name === name) {
        x.Lastedit = new Date().toISOString();
      }
    });
    let body = {
      Status: "OK",
      Lenders: b,
      Config: {
        MinClosingCostPercent: this.state.minimumClosingCostPercentage,
        MaxClosingCostPercent: this.state.maximumClosingCostPercentage,
      },
    };
    fetch("/data/postlenders", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log("Looks like there was a problem: ", response);
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            alert("Error!");
          } else {
            alert("Lenders updated successfully!");
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  }
  deleteLender(id) {
    let token = sessionStorage.getItem("ZeitroA");
    let body = { ID: id };

    fetch("/data/deletelender", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            alert("Error!");
          } else {
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  }
  handleSubmit = (name) => (event) => {
    const form = this.formRefs[name].current;
    if (form.reportValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true });

      return false;
    }
    this.sendLenders(name);
    let targets = deepcopy(this.state.editTarget);
    targets = targets.filter((x) => x !== name);
    if (targets.length === 0) {
      this.setState({ showEdit: false, editTarget: targets })
    } else {
      this.setState({ editTarget: targets })
    }
    event.preventDefault();
    event.stopPropagation();
    return false;
  };
  handleTableFormSubmit = (record) => (event) => {
    const form = this.formRefs[record.name + "_table"].current;
    if (form.reportValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true });
      return false;
    }
    this.sendLenders(record.name);
    let targets = deepcopy(this.state.editTarget);
    let expandedRowKeys = deepcopy(this.state.expandedRowKeys);
    targets = targets.filter((x) => x !== record.name);
    expandedRowKeys = expandedRowKeys.filter((x) => x !== record.key);
    this.setState({ expandedRowKeys: expandedRowKeys })
    if (targets.length === 0) {
      this.setState({ showEdit: false, editTarget: targets })
    } else {
      this.setState({ editTarget: targets })
    }

    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  handleCardMouseEnter = (index) => {
    let hover = deepcopy(this.state.cardHovering);
    hover[index] = true;
    this.setState({ cardHovering: hover })
  }
  handleCardMouseLeave = (index) => {
    let hover = deepcopy(this.state.cardHovering);
    hover[index] = false;
    this.setState({ cardHovering: hover })
  }
  handleSearch = (value) => {
    setTimeout(() => {
      if (!this.state.isInputSearchChanged) {
        this.setState({ searchKeywords: value })
      }
    }, 100);
  }
  handleSearchChange = (e) => {
    this.setState({ isInputSearchChanged: true, searchKeywords: e.target.value.trim() })
    setTimeout(() => {
      this.setState({ isInputSearchChanged: false })
    }, 200)
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleSearch(event.target.value);
    }
  }

  render() {
    const { cardHovering } = this.state;

    let addLenders = () => {

      let handleSelectLenders = (name) => {
        if (selectedLenders.includes(name)) {
          selectedLenders = selectedLenders.filter((y) => y !== name);
        } else {
          selectedLenders.push(name);
        }
        this.setState({ selectedLenders: selectedLenders })
      }
      let selectedLenders = deepcopy(this.state.selectedLenders);
      let out = [];
      let lenders = [];
      this.state.allenders.forEach((x) => {
        if (undefined === this.lendersid[x.id]) {
          lenders.push(x);
        }
      });

      lenders = lenders.sort((x, y) => {
        return x.name[0] > y.name[0] ? 1 : -1;
      });

      lenders.forEach((x) => {
        out.push({
          name: x.name,
          children:
            <div className={selectedLenders.includes(x.name) ? "selectedLenders" : "unselectedLenders"} onClick={() => handleSelectLenders(x.name)}>
              <img className="lendersLogo" src={Object.keys(lendersLogoMap).includes(x.name) ? lendersLogoMap[x.name] : "/images/lendersLogo/DefaultLender_logo.png"} />
              {/* <img className="lendersLogo" src="/images/lendersLogo/DefaultLender_logo.png"/> */}
              <div style={{ fontWeight: "500", whiteSpace: "nowrap", fontSize: "16px", marginLeft: "10px" }}>{x.name}</div>
            </div>,
        });
      });
      let result = [];
      let noSearchResult = false;
      out.forEach((x) => {
        if (x.name.toLowerCase().includes(this.state.searchKeywords.toLowerCase())) {
          result.push(x.children)
        }
      });
      if (result.length === 0) {
        noSearchResult = true;
      }

      return <div>
        <Input.Search
          placeholder="Search lenders by name"
          style={{ height: "44px" }}
          value={this.state.searchKeywords}
          onSearch={this.handleSearch}
          onChange={this.handleSearchChange}
          onKeyPress={this.handleKeyPress}
        />
        <Row style={{ display: "flex", justifyContent: "right", marginBottom: "10px" }}>
          <div className="revertSearch" onClick={() => this.setState({ searchKeywords: "" })}>Clear Search</div>
        </Row>
        <hr />
        {noSearchResult ? (
          <div className="d-flex align-items-center justify-content-center mt-3">
            <div style={{ color: "#586570", fontSize: "18px", fontWeight: "500" }}>No search result.</div>
          </div>
        ) : (
          <Row style={{ display: "flex", gap: "20px" }}>
            {result}
          </Row>
        )}
      </div>;
    };
    let setSelectedLender = (e) => {
      this.setState({ selectedid: parseInt(e.target.value) });
    };

    let showLenders = (e) => {

      let convertEditTime = (time) => {
        let date = new Date(time);
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let year = date.getFullYear();
        return month + '/' + day + '/' + year;
      }

      const columns = [
        {
          title: "Lender name",
          dataIndex: "name",
          width: this.state.isNarrowScreen ? "200px" : "auto",
          defaultsortOrder: "descend",
          sorter: (a, b) => a.name.localeCompare(b.name),
          key: "name",
          className: "lendersNameCol",
          render: (text, record) => (
            <div className="managementLendersName">
              <img className="lendersLogo" src={Object.keys(lendersLogoMap).includes(record.name) ? lendersLogoMap[record.name] : "/images/lendersLogo/DefaultLender_logo.png"} />
              <div className="lenderNames">{record.name}</div>
              {this.state.expandedRowKeys.includes(record.key) && <span style={{ color: "#325CEB", marginLeft: "15px", fontWeight: "600" }}>Editing</span>}
            </div>
          ),
        },
        {
          title: "Comp %",
          dataIndex: "compensation",
          render: (text, record) => (
            <div>
              <div className="lenderText">{record.compensation}%</div>
            </div>
          ),
        },
        {
          title: "Lender origination fee",
          dataIndex: "lenderfees",
          render: (text, record) => (
            <div>
              {record.lenderfees.map((x) => {
                return <div className="lenderText">${x.fee} - {x.states.join(",")}</div>;
              })}
            </div>
          ),
        },
        {
          title: "Last updated",
          dataIndex: "lastedit",
          defaultsortOrder: "descend",
          sorter: (a, b) => new Date(a.lastedit) - new Date(b.lastedit),
          defaultsorted: true,
          render: (text, record) => (
            <div>
              <div className="lenderText">{convertEditTime(record.lastedit)}</div>
            </div>
          ),
        },
        {
          title: "Actions",
          dataIndex: "actions",
          width: "100px",
          render: (text, record, index) => (
            <div style={{ display: this.state.hoveredRowIndex === index ? "" : "none" }}>
              <img src="/images/deleteLenderIcon.png" style={{ width: "25px", height: "25px", cursor: "pointer" }} onClick={() => tableRemoveLender(record)} />
              <img src="/images/editLenderIcon.png" style={{ marginLeft: "15px", width: "25px", height: "25px", cursor: "pointer" }} onClick={() => handleExpand(record)} />
            </div>
          ),
        }
      ]

      let handleExpand = (record) => {
        let targets = deepcopy(this.state.editTarget);
        let expandedRowKeys = deepcopy(this.state.expandedRowKeys);
        if (expandedRowKeys.includes(record.key)) {
          expandedRowKeys = expandedRowKeys.filter((x) => x !== record.key)
        } else {
          expandedRowKeys.push(record.key)
          targets.push(lender.name);
        }
        this.setState({ showEdit: true, editTarget: targets, expandedRowKeys: expandedRowKeys })
      }

      let removeLender = (lender) => {
        if (
          window.confirm(
            "Are you sure you want to delete " +
            lender.name +
            "? The operation is irreversible."
          )
        ) {
          let lenders = deepcopy(this.state.customerlenders);
          lenders = lenders.filter((x) => x.id !== lender.id);
          let cac = deepcopy(this.state.lendercache);
          cac[lender.id] = lender;
          this.setState({ lendercache: cac });
          this.deleteLender(lender.id);
          this.resetLenderIDs(lenders);
        }
      };

      let tableRemoveLender = (lender) => {
        if (
          window.confirm(
            "Are you sure you want to delete " +
            lender.name +
            "? The operation is irreversible."
          )
        ) {
          let lenders = [];
          this.state.customerlenders.forEach((y) => {
            if (y.id === lender.id) {
              return;
            }
            lenders.push(y);
          });
          let cac = deepcopy(this.state.lendercache);
          cac[lender.id] = lender.lenderObj;
          this.setState({ lendercache: cac });
          this.deleteLender(lender.id);
          this.resetLenderIDs(lenders);
        }
      }

      let handleEditLender = (lender) => {
        let targets = deepcopy(this.state.editTarget);
        targets.push(lender.name);
        this.setState({ showEdit: true, editTarget: targets, })
      }

      const getStates = () => {
        let options = [];
        let keys = Object.keys(this.state.loanLimits);
        keys.forEach((x) => {
          options.push({ label: com.capitalize(x), value: com.convertStateToAbbr(x) });
        });
        return options;
      };

      let items = []
      let tableItems = []
      let i = 0
      for (let x of this.state.customerlenders) {
        if (x.Active === true) {
          let displayRelationship = (key, i) => {
            let rel = com.LenderRelationshhip[key];
            return (
              <option
                value={key}
                selected={key === x.Config.correspondentLender}
                key={"relationship+" + i}
              >
                {rel.name}
              </option>
            );
          };

          let onCompensation = (e) => {
            x.Config.compensation = e.target.value;
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onCompensationFlatFee = (e) => {
            x.Config.compensationFlatFee = parseFloat(e.target.value);
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onMinCompensation = (e) => {
            x.Config.minimumCompensation = e.target.value;
            if (x.Config.minimumCompensation === "")
              x.Config.minimumCompensation = null;
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onMaxCompensation = (e) => {
            x.Config.maximumCompensation = e.target.value;
            if (x.Config.maximumCompensation === "")
              x.Config.maximumCompensation = null;
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onCloseover = (e) => {
            x.Config.closeover = e.target.value;
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onMincost = (e) => {
            x.Config.minimumClosingCostPercentage = e.target.value;
            if (x.Config.minimumClosingCostPercentage === "")
              x.Config.minimumClosingCostPercentage = null;
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onMaxcost = (e) => {
            x.Config.maximumClosingCostPercentage = e.target.value;
            if (x.Config.maximumClosingCostPercentage === "")
              x.Config.maximumClosingCostPercentage = null;
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onCorrespondent = (e) => {
            x.Config.correspondentLender = e.target.value;
            this.setState({ customerlenders: this.state.customerlenders });
          };

          let showLenderFees = (y, i) => {
            let removeSlice = () => {
              x.Config.lenderfees.splice(i, 1);
              this.setState({ customerlenders: this.state.customerlenders });
            };
            let addSlice = () => {
              x.Config.lenderfees.push({
                fee: 0,
                states: [],
                mortgageTypes: [],
              });
              this.setState({ customerlenders: this.state.customerlenders });
            };
            let onFee = (e) => {
              y.fee = parseFloat(e.target.value);
              this.setState({ customerlenders: this.state.customerlenders });
            };
            let onStates = (value) => {
              y.states = value;
              this.setState({ customerlenders: this.state.customerlenders });
            };

            return (
              <>
                <Row
                  key={"card" + x.lender + i}
                  className=" m-2 p-0"
                >
                  <Col xs="auto" className="p-2 formInputFees">
                    <Form.Group controlId={"idfee" + x.lender + i}>
                      <Form.Label className="text-left">
                        Lender Origination Fee:
                      </Form.Label>

                      <InputGroup size="sm">
                        <PennyInput
                          size="sm"
                          name={"idfee" + x.lender + i}
                          required
                          defaultValue={parseFloat(y.fee)}
                          onChange={onFee}
                          type="text"
                          style={{ width: "5em" }}
                          pattern="^.+$"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col className="p-2 formInputStates">
                    <Form.Group controlId={"states" + i} className="text-left" style={{ display: "flex", flexDirection: "column" }}>
                      <Form.Label className="text-left" style={{ color: "white" }}>States:</Form.Label>
                      {/* <Form.Control
                        isValid={false}
                        size="sm"
                        required
                        name={"states" + i}
                        type="text"
                        value={y.states.join(",")}
                        onChange={onStates}
                        pattern="^[\w,]*$"
                        style={{height:"50px"}}
                        placeholder="State(s) of this lender origination fee (e.g. CA, TX)"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please provide states.
                      </Form.Control.Feedback> */}

                      <Select
                        mode="multiple"
                        allowClear
                        // defaultValue={["lockpoint", "adjustment", "compensation"]}
                        defaultValue={y.states}
                        onChange={onStates}
                        options={getStates()}
                        placeholder="Select states for this lender origination fee"
                        className="lenderFeeStates"
                      />

                    </Form.Group>
                  </Col>
                  <Col
                    xs="auto"
                    as="div"
                    className="text-right d-flex align-items-center px-0 py-0"
                  >
                    <img className="addFeeIcon" src="/images/addIconCircle.png" onClick={addSlice} />
                    {x.Config.lenderfees.length > 1 ? (
                      <img className="removeFeeIcon" src="/images/removeIconCircle.png" onClick={removeSlice} />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </>
            );
          };
          let displaySubRule = (rule) => {
            let displayValue = (key) => {
              switch (com.RuleSet[key].typ) {
                case "minmax":
                  return (
                    <span className="text-bold">
                      {com.operatorInout[rule[key].operator]} [{" "}
                      {rule[key].value[0]}{" "}
                      {com.operatorMap[rule[key].minoperator]}{" "}
                      {com.RuleSet[key].name}{" "}
                      {com.operatorMap[rule[key].maxoperator]}{" "}
                      {rule[key].value[1]} ]{" "}
                    </span>
                  );
                case "bool":
                  return (
                    <span className="text-bold">
                      {com.operatorBool[rule[key].operator]}{" "}
                    </span>
                  );

                case "value":
                  switch (com.RuleSet[key].values) {
                    case "bool":
                      return (
                        <span className="text-bold">{rule[key].operator} </span>
                      );
                    case "dollar":
                      return (
                        <span className="text-bold">
                          {" "}
                          {com.operatorMap[rule[key].operator]} $
                          {com.commaizeFloat(rule[key].value)}
                        </span>
                      );
                    default:
                      return (
                        <span className="text-bold">
                          {com.operatorMap[rule[key].operator]}{" "}
                          {rule[key].value}
                        </span>
                      );
                  }

                case "enum":
                  return (
                    <span>
                      {com.operatorEnum[rule[key].operator]}{" "}
                      <span className="text-bold">
                        {" "}
                        [
                        {rule[key].value
                          .map((x) => com.RuleSet[key].values[x])
                          .join(", ")}
                        ]{" "}
                      </span>
                    </span>
                  );
                case "boolean":
                  return "";
              }
              return rule[key];
            };
            let prepSubRule = (key) => {
              return (
                <Row key={"prepSubRule" + key}>
                  <Col xs="auto" className="text-nowrap">
                    <span className="text-bold">{com.RuleSet[key].name}</span>,
                    if {displayValue(key)}
                  </Col>
                </Row>
              );
            };
            let out = Object.keys(rule).map(prepSubRule);
            return out;
          };
          let displayEstablishedRule = (rules, j) => {
            let removeeme = () => {
              x.Config.rules.splice(j, 1);
              this.setState({ customerlenders: this.state.customerlenders });
            };
            let edit = () => {
              let staterules = [];

              if (x.Config.rules[j].adjustment != null) {
                this.setState({
                  action: "adjustment",
                  adjustment: x.Config.rules[j].adjustment,
                });
              }

              if (x.Config.rules[j].fail != null) {
                this.setState({ action: "fail", fail: x.Config.rules[j].fail });
              }

              x.Config.rules[j].rules.forEach((y) => {
                Object.keys(y).forEach((z) => {
                  let r = {
                    selectedRule: z,
                  };

                  switch (com.RuleSet[z].typ) {
                    case "minmax":
                      {
                        r.minrule = y[z].value[0];
                        r.maxrule = y[z].value[1];
                        r.maxoperator = y[z].maxoperator;
                        r.minoperator = y[z].minoperator;
                        r.operator = y[z].operator;
                      }
                      break;
                    case "enum":
                      {
                        r.selectedEnum = y[z].value.map((x) => {
                          return { id: x, name: com.RuleSet[z].values[x] };
                        });
                        r.operator = y[z].operator;
                      }
                      break;
                    case "value":
                      {
                        r.value = y[z].value;
                        r.operator = y[z].operator;
                      }
                      break;
                    case "bool":
                      {
                        r.value = true;
                        r.operator = y[z].operator;
                      }
                      break;
                  }
                  staterules.push(r);
                });
              });

              this.setState({ rules: staterules });
              this.setState({
                show: true,
                modalvalidated: false,
                mode: "edit",
                config: x.Config,
                ruleindex: j,
                conditionindex: x,
              });
            };
            return (
              <Col className="rulesWraper" md={12} key={"esrule" + j}>
                <div className="rulecard d-flex mb-2">
                  <div className="rulesHeader">
                    <div>
                      ADJUSTMENT
                    </div>
                  </div>
                  {rules.rules.map(displaySubRule)}

                  <Row>
                    <Col xs="auto">
                      {rules.adjustment != null ? (
                        <span className="text-bold">
                          Adjustment: {rules.adjustment}%
                        </span>
                      ) : (
                        ""
                      )}
                      {rules.fail != null ? (
                        <span
                          className="text-bold"
                          style={{ color: "#993333" }}
                        >
                          Fail qualification!
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="d-flex align-items-center text-right pr-1 ml-3" style={{ color: "#325CEB" }}>
                  <i
                    onClick={edit}
                    className="fas fahover fa-edit aligh-top mr-2"
                  ></i>
                  <i
                    onClick={removeeme}
                    className="far fahover fa-minus-square aligh-top"
                  ></i>
                </div>
              </Col>
            );
          };
          let displayEstablishedRules = () => {
            let rules = x.Config.rules;

            let rows = [];
            for (let i = 0; i < rules.length; i += 4) {
              let cols = [];
              let j = i;
              for (; j < i + 4 && j < rules.length; j++) {
                cols.push(displayEstablishedRule(rules[j], j));
              }
              if ((i + 1) % 4 !== 0) cols.push(<Col key={"cc" + j}></Col>);
              rows.push(<Row key={"displayEstablishedRules" + i}>{cols}</Row>);
            }
            return rows;
          };
          let displayEstablishedPromotion = (promotions, j) => {
            let removeeme = () => {
              x.Config.promotions.splice(j, 1);
              this.setState({ customerlenders: this.state.customerlenders });
            };
            let edit = () => {
              let statepromotions = [];

              if (x.Config.promotions[j].message != null) {
                this.setState({
                  message: x.Config.promotions[j].message,
                });
              }

              x.Config.promotions[j].conditions.forEach((y) => {
                Object.keys(y).forEach((z) => {
                  let r = {
                    selectedRule: z,
                  };

                  switch (com.RuleSet[z].typ) {
                    case "minmax":
                      {
                        r.minrule = y[z].value[0];
                        r.maxrule = y[z].value[1];
                        r.maxoperator = y[z].maxoperator;
                        r.minoperator = y[z].minoperator;
                        r.operator = y[z].operator;
                      }
                      break;
                    case "enum":
                      {
                        r.selectedEnum = y[z].value.map((x) => {
                          return { id: x, name: com.RuleSet[z].values[x] };
                        });
                        r.operator = y[z].operator;
                      }
                      break;
                    case "value":
                      {
                        r.value = y[z].value;
                        r.operator = y[z].operator;
                      }
                      break;
                    case "bool":
                      {
                        r.value = true;
                        r.operator = y[z].operator;
                      }
                      break;
                  }
                  statepromotions.push(r);
                });
              });

              this.setState({ promotions: statepromotions });
              this.setState({
                // showpromotion: true,
                modalpromotionvalidated: false,
                mode: "edit",
                config: x.Config,
                promotionindex: j,
                conditionindex: x,
              });
            };
            return (
              <Col className="rulesWraper" md={12} key={"esrule" + j}>
                <div className="rulecard d-flex  mb-2">
                  <div className="rulesHeader">
                    <div>
                      PROMOTION
                    </div>
                  </div>
                  {promotions.conditions.map(displaySubRule)}

                  <Row>
                    <Col xs="auto">
                      <b>Promotion message: </b>{promotions.message}
                    </Col>
                  </Row>
                </div>
                <div className="d-flex align-items-center text-right pr-1 ml-3" style={{ color: "#325CEB" }}>
                  <i
                    onClick={edit}
                    className="fas fahover fa-edit aligh-top mr-2"
                  ></i>
                  <i
                    onClick={removeeme}
                    className="far fahover fa-minus-square aligh-top"
                  ></i>
                </div>
              </Col>
            );
          };
          let displayEstablishedPromotions = () => {
            let promotions = x.Config.promotions;

            let rows = [];
            for (let i = 0; i < promotions.length; i += 4) {
              let cols = [];
              let j = i;
              for (; j < i + 4 && j < promotions.length; j++) {
                cols.push(displayEstablishedPromotion(promotions[j], j));
              }
              if ((i + 1) % 4 !== 0) cols.push(<Col key={"cc" + j}></Col>);
              rows.push(<Row key={"displayEstablishedPromotions" + i}>{cols}</Row>);
            }
            return rows;
          };
          // let handleEditLender = () => {
          //   let targets = deepcopy(this.state.editTarget);
          //   targets.push(x.name);
          //   this.setState({showEdit: true, editTarget: targets, })
          // }
          if (!this.formRefs[x.name + "_table"]) {
            this.formRefs[x.name + "_table"] = React.createRef();
          }
          tableItems.push(
            {
              id: x.id,
              lenderObj: x,
              name: x.name,
              key: "row_" + x.lender,
              compensation: x.Config.compensation,
              compminmax: { min: x.Config.minimumCompensation, max: x.Config.maximumCompensation },
              closingcostminmax: { min: x.Config.minimumClosingCostPercentage, max: x.Config.maximumClosingCostPercentage },
              lenderfees: x.Config.lenderfees,
              config: x.Config,
              lender: x.lender,
              lastedit: x.Lastedit,
              onCompensation: onCompensation,
              onCompensationFlatFee: onCompensationFlatFee,
              onMinCompensation: onMinCompensation,
              onMaxCompensation: onMaxCompensation,
              onMincost: onMincost,
              onMaxcost: onMaxcost,
              onCorrespondent: onCorrespondent,
              showLenderFees: showLenderFees,
              displayEstablishedRules: displayEstablishedRules,
              displayEstablishedPromotions: displayEstablishedPromotions,
              onCloseover: onCloseover,
              displayRelationship: displayRelationship,
              // actions: 0
            }
          )
          items.push(
            {
              name: x.name,
              key: "card_" + x.lender,
              lastedit: x.Lastedit,
              label: <div className="w-100">
                <div className={this.state.editTarget.includes(x.name) ? "cardStatus" : "cardStatusHide"} >
                  {this.state.editTarget.includes(x.name) && <span style={{ color: "#325CEB" }}>Editing</span>}
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="managementLendersName">
                    <img className="lendersLogo" src={Object.keys(lendersLogoMap).includes(x.name) ? lendersLogoMap[x.name] : "/images/lendersLogo/DefaultLender_logo.png"} />
                    {/* <img className="lendersLogo" src="/images/lendersLogo/DefaultLender_logo.png"/> */}
                    <div className="lenderCardNames">{x.name === "WholesaleMortgageBankers" ? "Wholesale Mortgage Bankers" : x.name}</div>
                  </div>
                  {cardHovering[i] && <div className="cardButtonsWrap">
                    <img src="/images/deleteLenderIcon.png" style={{ width: "25px", height: "25px", cursor: "pointer" }} onClick={() => removeLender(x)} />
                    <img src="/images/editLenderIcon.png" style={{ marginLeft: "15px", width: "25px", height: "25px", cursor: "pointer" }} onClick={() => handleEditLender(x)} />
                  </div>
                  }
                </div>
              </div>,
              children: <div className="lenderSettingForm" style={{ padding: "10px 30px 10px 30px" }}>
                <Row>
                  <Col md={3} className="formInputPercent" xs="auto">
                    <Form.Group controlId={"idcompensation" + x.lender}>
                      <Form.Label className="text-left">
                        Compensation Point:
                      </Form.Label>

                      <InputGroup size="sm">
                        <Form.Control
                          size="sm"
                          name={"compensation" + x.lender}
                          required
                          value={x.Config.compensation}
                          onChange={onCompensation}
                          type="text"
                          pattern="^.+$"
                        />

                        <InputGroup.Append>
                          <InputGroup.Text
                            className="pl-3"
                            style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                            size="sm"
                          >
                            %
                          </InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter compensation
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="formInputDollar" xs="auto">
                    <Form.Group controlId={"idcompensationflatfee" + x.lender}>
                      <Form.Label className="text-left">
                        Compensation Flat Fee:
                      </Form.Label>
                      <InputGroup size="sm">
                        <PennyInput
                          size="sm"
                          name={"compensationflatfee" + x.lender}
                          required
                          defaultValue={x.Config.compensationFlatFee}
                          onChange={onCompensationFlatFee}
                          type="text"
                          style={{ width: "5em" }}
                          pattern="^.+$"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="formInputDollar" xs="auto">
                    <Form.Group controlId="password">
                      <Form.Label className="text-left">
                        Min Compensation:
                      </Form.Label>
                      <InputGroup size="sm">
                        <PennyInput
                          size="sm"
                          name={"mincompensation" + x.lender}
                          defaultValue={x.Config.minimumCompensation}
                          onChange={onMinCompensation}
                          type="text"
                          style={{ width: "5em" }}
                          pattern="^.+$"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="formInputDollar" xs="auto">
                    <Form.Group controlId="password">
                      <Form.Label className="text-left">
                        Max Compensation:
                      </Form.Label>

                      <InputGroup size="sm">
                        <PennyInput
                          size="sm"
                          name={"maxcompensation" + x.lender}
                          defaultValue={x.Config.maximumCompensation}
                          onChange={onMaxCompensation}
                          type="text"
                          style={{ width: "5em" }}
                          pattern="^.+$"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={3} className="formInputPercent" xs="auto">
                    <Form.Group controlId="password">
                      <Form.Label className="text-left">
                        Min Closing Cost:
                      </Form.Label>

                      <InputGroup size="sm">
                        <Form.Control
                          size="sm"
                          name={"mincost" + x.lender}
                          //required
                          value={
                            x.Config.minimumClosingCostPercentage == null
                              ? ""
                              : x.Config.minimumClosingCostPercentage
                          }
                          onChange={onMincost}
                          type="text"
                          pattern="^[0...9-.]*$"
                          placeholder="(-)XXX or empty"
                        />

                        <InputGroup.Append>
                          <InputGroup.Text
                            className="pl-3"
                            style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                            size="sm"
                          >
                            %
                          </InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter Min Closing Cost Points
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="formInputPercent" xs="auto">
                    <Form.Group controlId="password">
                      <Form.Label className="text-left">
                        Max Closing Cost:
                      </Form.Label>

                      <InputGroup size="sm">
                        <Form.Control
                          size="sm"
                          name={"mincost" + x.lender}
                          //required

                          value={
                            x.Config.maximumClosingCostPercentage == null
                              ? ""
                              : x.Config.maximumClosingCostPercentage
                          }
                          onChange={onMaxcost}
                          type="text"
                          pattern="^[0...9-.]*$"
                          placeholder="XXX or empty"
                        />

                        <InputGroup.Append>
                          <InputGroup.Text
                            className="pl-3"
                            style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                            size="sm"
                          >
                            %
                          </InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter Max Closing Cost Points
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>


                {x.Config.lenderfees?.map(showLenderFees)}
                <Row>
                  <Col className="formInputRelation" md={6} xs="auto">
                    <Form.Group className="inputSection">
                      <Form.Label style={{ marginBottom: "10px" }}>
                        Relationship with the lender:
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        as="select"
                        onChange={onCorrespondent}
                        required
                      >
                        {" "}
                        {Object.keys(com.LenderRelationshhip).map(
                          displayRelationship
                        )}
                      </Form.Control>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  {(x.Config.correspondentLender ===
                    "NondedicatedCorrespondentLender" ||
                    x.Config.correspondentLender ===
                    "DedicatedCorrespondentLender" ||
                    x.Config.correspondentLender === "DirecttLender") && (
                      <Col className="formInputCloseOver" md={6} xs="auto">
                        <Form.Group controlId={"idcloseover" + x.lender}>
                          <Form.Label className="text-left">
                            {tooltip(
                              "Close Over Percentage",
                              <div>
                                Applied to lenders who want to add extra points to
                                ratesheets, leave empty when not applied.
                              </div>,
                              "auto",
                              ""
                            )}
                          </Form.Label>
                          <InputGroup size="sm">
                            <Form.Control
                              size="sm"
                              name={"closeover" + x.lender}
                              value={x.Config.closeover}
                              onChange={onCloseover}
                              type="text"
                              pattern="^.+$"
                            />

                            <InputGroup.Append>
                              <InputGroup.Text
                                className="pl-3"
                                style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                                size="sm"
                              >
                                %
                              </InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Please enter compensation
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    )}
                  <Col></Col>
                </Row>
                <hr />
                <Row className="promotionSection mb-3">
                  <Col md={4}>Promotions:</Col>
                  <Col md={4} className="text-right">
                    <span className="mt-3 mr-2 addPromotionButton"
                      onClick={(e) => {
                      //   const status = com.getStatus(com.getLoId(), this.props.subscriptionReducer.loanOfficers)
                     
                      //   if( status === undefined)return
                      //   if ((this.props.subscriptionReducer.plan === 'starter') || (this.props.subscriptionReducer.plan === 'business' && status === 'free')) {
                      //     this.setState({ isModalOpen: true })
                      //     return
                      // }
                        let promotions = [deepcopy(emptypromotion)];
                        this.state.promotions[0].ref = React.createRef();
                        this.setState({
                          config: x.Config,
                          promotions: promotions,
                          modalpromotionvalidated: false,
                          showpromotion: true,
                          mode: "add",
                        });
                      }}
                    >
                      Add Promotion
                    </span>
                  </Col>
                </Row>
                {x.Config.promotions != null ? (
                  <div>
                    {displayEstablishedPromotions()}
                  </div>
                ) : (
                  ""
                )}
                <Row className="promotionSection mb-3 mt-3">
                  <Col md={4}>Adjustments:</Col>
                  <Col md={4} className="text-right">
                    <span className="mt-3 mr-2 addPromotionButton"
                      onClick={(e) => {
                      //   const status = com.getStatus(com.getLoId(), this.props.subscriptionReducer.loanOfficers)
                      //   if( status === undefined)return
                      //   if ((this.props.subscriptionReducer.plan === 'starter') || (this.props.subscriptionReducer.plan === 'business' && status === 'free')) {
                      //     this.setState({ isModalOpen: true })
                      //     return
                      // }
                        let rules = [deepcopy(emptyrule)];
                        this.state.rules[0].ref = React.createRef();
                        this.setState({
                          config: x.Config,
                          rules: rules,
                          modalvalidated: false,
                          show: true,
                          mode: "add",
                        });
                      }}
                    >
                      Add Adjustment
                    </span>
                  </Col>
                </Row>
                {x.Config.rules != null ? (
                  <div>
                    {displayEstablishedRules()}
                  </div>
                ) : (
                  ""
                )}
              </div>
            },
          )
          i += 1
        }
      }
      let handleSaveLender = (name) => {
        let savedLenders = deepcopy(this.state.savedLenders);
        let customerlenders = deepcopy(this.state.customerlenders);
        customerlenders.forEach((x) => {
          if (x.name === name) {
            x.Lastedit = new Date().toISOString();
          }
        });
        savedLenders.push(name)
        this.setState({ savedLenders: savedLenders, customerlenders: customerlenders });
      }

      let handleCloseEdit = (name) => {
        let targets = deepcopy(this.state.editTarget);
        targets = targets.filter((x) => x !== name);
        if (targets.length === 0) {
          this.setState({ showEdit: false, editTarget: targets })
        } else {
          this.setState({ editTarget: targets })
        }
      }


      // return out;
      return this.state.currentView === "cardview" ? (
        <div className="managementLendersWrap">
          {/* <Collapse collapsible="icon" items={items} /> */}
          {this.state.showEdit && <div className="mb-4">
            {items.map((x) => {
              if (this.state.editTarget.includes(x.name)) {
                if (!this.formRefs[x.name]) {
                  this.formRefs[x.name] = React.createRef();
                }
                return (
                  <Form
                    className="mb-4"
                    key={x.name}
                    ref={this.formRefs[x.name]}
                    onSubmit={this.handleSubmit(x.name)}
                    noValidate
                    validated={this.state.validated}
                  >
                    <div style={{ border: "1px solid #DBDCDE", borderRadius: "8px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 20px 10px 20px", borderBottom: "1px solid #DBDCDE" }}>
                        <div className="d-flex justify-content-between align-items-center">
                          <img src={Object.keys(lendersLogoMap).includes(x.name) ? lendersLogoMap[x.name] : "/images/lendersLogo/DefaultLender_logo.png"} />
                          {/* <img className="lendersLogo" src="/images/lendersLogo/DefaultLender_logo.png"/> */}
                          <div className="lenderNames">{x.name}</div>
                          <div className="lenderTimeStamp"> · Last updated on {convertEditTime(x.lastedit)}</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="mr-4 closeEdit" onClick={() => handleCloseEdit(x.name)}>Close</span>
                          <Button type="submit" onClick={() => handleSaveLender(x.name)} className="inEditButtons">Save</Button>
                        </div>
                      </div>
                      {x.children}
                    </div>
                  </Form>
                )
              }
            })}
          </div>}
          <Row className="cardRowWrap">
            {items.map((x, index) => {
              if (x.name.toLowerCase().includes(this.state.searchKeywords.toLowerCase())) {
                return <div className="lendersCardWrap" key={index} onMouseEnter={() => this.handleCardMouseEnter(index)} onMouseLeave={() => this.handleCardMouseLeave(index)}>
                  {x.label}
                </div>
              }
            })}
          </Row>
        </div>
      ) : (
        <Table
          className="lendersTable"
          columns={columns}
          dataSource={tableItems.filter((x) => x.name.toLowerCase().includes(this.state.searchKeywords.toLowerCase()))}
          showSorterTooltip={false}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: () => {
                this.setState({ hoveredRowIndex: rowIndex })
              },
              onMouseLeave: () => {
                this.setState({ hoveredRowIndex: null })
              }
            }
          }}
          expandable={{
            expandedRowRender: record => <div style={{ display: "flex", margin: 0, border: "1px solid #DBDCDE", borderRadius: "8px" }}>
              <Form
                key={record.name + "_table"}
                ref={this.formRefs[record.name + "_table"]}
                onSubmit={this.handleTableFormSubmit(record)}
                noValidate
                validated={this.state.validated}
                className="w-100"
              >
                <div style={{ border: "1px solid #DBDCDE", borderRadius: "8px" }}>
                  <div style={{ padding: "10px 20px 10px 20px", borderBottom: "1px solid #DBDCDE", borderBottom: "1px solid #DBDCDE" }}>
                    <div className="d-flex justify-content-end align-items-center text-right" style={{ borderBottom: "1px solid #DBDCDE", marginBottom: "10px", padding: "5px 40px 10px" }}>
                      <div className="d-flex align-items-center">
                        <span className="mr-4 closeEdit" onClick={() => handleExpand(record)}>Close</span>
                        <Button type="submit" onClick={() => handleSaveLender(record.name)} className="inEditButtons">Save</Button>
                      </div>
                    </div>
                    <div className="lenderSettingForm" style={{ padding: "10px 30px 10px 30px" }}>
                      <Row>
                        <Col md={3} className="formInputPercent" xs="auto">
                          <Form.Group controlId={"idcompensation" + record.lender}>
                            <Form.Label className="text-left">
                              Compensation Point:
                            </Form.Label>

                            <InputGroup size="sm">
                              <Form.Control
                                size="sm"
                                name={"compensation" + record.lender}
                                required
                                value={record.config.compensation}
                                onChange={record.onCompensation}
                                type="text"
                                pattern="^.+$"
                              />

                              <InputGroup.Append>
                                <InputGroup.Text
                                  className="pl-3"
                                  style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                                  size="sm"
                                >
                                  %
                                </InputGroup.Text>
                              </InputGroup.Append>
                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                Please enter compensation
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md={3} className="formInputDollar" xs="auto">
                          <Form.Group controlId={"idcompensationflatfee" + record.lender}>
                            <Form.Label className="text-left">
                              Compensation Flat Fee:
                            </Form.Label>
                            <InputGroup size="sm">
                              <PennyInput
                                size="sm"
                                name={"compensationflatfee" + record.lender}
                                required
                                defaultValue={record.config.compensationFlatFee}
                                onChange={record.onCompensationFlatFee}
                                type="text"
                                style={{ width: "5em" }}
                                pattern="^.+$"
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md={3} className="formInputDollar" xs="auto">
                          <Form.Group controlId="password">
                            <Form.Label className="text-left">
                              Min Compensation:
                            </Form.Label>
                            <InputGroup size="sm">
                              <PennyInput
                                size="sm"
                                name={"mincompensation" + record.lender}
                                defaultValue={record.config.minimumCompensation}
                                onChange={record.onMinCompensation}
                                type="text"
                                style={{ width: "5em" }}
                                pattern="^.+$"
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md={3} className="formInputDollar" xs="auto">
                          <Form.Group controlId="password">
                            <Form.Label className="text-left">
                              Max Compensation:
                            </Form.Label>

                            <InputGroup size="sm">
                              <PennyInput
                                size="sm"
                                name={"maxcompensation" + record.lender}
                                defaultValue={record.config.maximumCompensation}
                                onChange={record.onMaxCompensation}
                                type="text"
                                style={{ width: "5em" }}
                                pattern="^.+$"
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3} className="formInputPercent" xs="auto">
                          <Form.Group controlId="password">
                            <Form.Label className="text-left">
                              Min Closing Cost:
                            </Form.Label>

                            <InputGroup size="sm">
                              <Form.Control
                                size="sm"
                                name={"mincost" + record.lender}
                                //required
                                value={
                                  record.config.minimumClosingCostPercentage == null
                                    ? ""
                                    : record.config.minimumClosingCostPercentage
                                }
                                onChange={record.onMincost}
                                type="text"
                                pattern="^[0...9-.]*$"
                                placeholder="(-)XXX or empty"
                              />

                              <InputGroup.Append>
                                <InputGroup.Text
                                  className="pl-3"
                                  style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                                  size="sm"
                                >
                                  %
                                </InputGroup.Text>
                              </InputGroup.Append>
                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                Please enter Min Closing Cost Points
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md={3} className="formInputPercent" xs="auto">
                          <Form.Group controlId="password">
                            <Form.Label className="text-left">
                              Max Closing Cost:
                            </Form.Label>

                            <InputGroup size="sm">
                              <Form.Control
                                size="sm"
                                name={"mincost" + record.lender}
                                //required

                                value={
                                  record.config.maximumClosingCostPercentage == null
                                    ? ""
                                    : record.config.maximumClosingCostPercentage
                                }
                                onChange={record.onMaxcost}
                                type="text"
                                pattern="^[0...9-.]*$"
                                placeholder="XXX or empty"
                              />

                              <InputGroup.Append>
                                <InputGroup.Text
                                  className="pl-3"
                                  style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                                  size="sm"
                                >
                                  %
                                </InputGroup.Text>
                              </InputGroup.Append>
                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                Please enter Max Closing Cost Points
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>


                      {record.config.lenderfees?.map(record.showLenderFees)}
                      <Row>
                        <Col className="formInputRelation" md={6} xs="auto">
                          <Form.Group className="inputSection">
                            <Form.Label style={{ marginBottom: "10px" }}>
                              Relationship with the lender:
                            </Form.Label>
                            <Form.Control
                              size="sm"
                              as="select"
                              onChange={record.onCorrespondent}
                              required
                            >
                              {" "}
                              {Object.keys(com.LenderRelationshhip).map(
                                record.displayRelationship
                              )}
                            </Form.Control>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        {(record.config.correspondentLender ===
                          "NondedicatedCorrespondentLender" ||
                          record.config.correspondentLender ===
                          "DedicatedCorrespondentLender" ||
                          record.config.correspondentLender === "DirecttLender") && (
                            <Col className="formInputCloseOver" md={6} xs="auto">
                              <Form.Group controlId={"idcloseover" + record.lender}>
                                <Form.Label className="text-left">
                                  {tooltip(
                                    "Close Over Percentage",
                                    <div>
                                      Applied to lenders who want to add extra points to
                                      ratesheets, leave empty when not applied.
                                    </div>,
                                    "auto",
                                    ""
                                  )}
                                </Form.Label>
                                <InputGroup size="sm">
                                  <Form.Control
                                    size="sm"
                                    name={"closeover" + record.lender}
                                    value={record.config.closeover}
                                    onChange={record.onCloseover}
                                    type="text"
                                    pattern="^.+$"
                                  />

                                  <InputGroup.Append>
                                    <InputGroup.Text
                                      className="pl-3"
                                      style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                                      size="sm"
                                    >
                                      %
                                    </InputGroup.Text>
                                  </InputGroup.Append>
                                  <Form.Control.Feedback>
                                    Looks good!
                                  </Form.Control.Feedback>
                                  <Form.Control.Feedback type="invalid">
                                    Please enter compensation
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          )}
                        <Col></Col>
                      </Row>
                      <hr />
                      <Row className="promotionSection mb-3">
                        <Col md={4}>Promotions:</Col>
                        <Col md={4} className="text-right">
                          <span className="mt-3 mr-2 addPromotionButton"
                            onClick={(e) => {
                            //   const status = com.getStatus(com.getLoId(), this.props.subscriptionReducer.loanOfficers)
                            //   if( status === undefined)return

                            //   if ((this.props.subscriptionReducer.plan === 'starter') || (this.props.subscriptionReducer.plan === 'business' && status === 'free')) {
                            //     this.setState({ isModalOpen: true })
                            //     return
                            // }
                              let promotions = [deepcopy(emptypromotion)];
                              this.state.promotions[0].ref = React.createRef();
                              this.setState({
                                config: record.config,
                                promotions: promotions,
                                modalpromotionvalidated: false,
                                showpromotion: true,
                                mode: "add",
                              });
                            }}
                          >
                            Add Promotion
                          </span>
                        </Col>
                      </Row>
                      {record.config.promotions != null ? (
                        <div>
                          {record.displayEstablishedPromotions()}
                        </div>
                      ) : (
                        ""
                      )}
                      <Row className="promotionSection mb-3 mt-3">
                        <Col md={4}>Adjustments:</Col>
                        <Col md={4} className="text-right">
                          <span className="mt-3 mr-2 addPromotionButton"
                            onClick={(e) => {
                            //   const status = com.getStatus(com.getLoId(), this.props.subscriptionReducer.loanOfficers)
                            //   if( status === undefined)return
                            //   if ((this.props.subscriptionReducer.plan === 'starter') || (this.props.subscriptionReducer.plan === 'business' && status === 'free')) {
                            //     this.setState({ isModalOpen: true })
                            //     return
                            // }
                              let rules = [deepcopy(emptyrule)];
                              this.state.rules[0].ref = React.createRef();
                              this.setState({
                                config: record.config,
                                rules: rules,
                                modalvalidated: false,
                                show: true,
                                mode: "add",
                              });
                            }}
                          >
                            Add Adjustment
                          </span>
                        </Col>
                      </Row>
                      {record.config.rules != null ? (
                        <div>
                          {record.displayEstablishedRules()}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                </div>
              </Form>
            </div>,
            expandedRowKeys: this.state.expandedRowKeys,
            showExpandColumn: false
          }}
        />
      )

    };
    let add = (e) => {
      // let out = [];
      let lenders = [];
      this.state.allenders.forEach((x) => {
        if (undefined === this.lendersid[x.id]) {
          lenders.push(x);
        }
      });

      let selectedLenders = [];
      let targets = deepcopy(this.state.editTarget);
      let expandedKeys = deepcopy(this.state.expandedRowKeys);
      lenders.forEach((x) => {
        if (this.state.selectedLenders.includes(x.name)) {
          selectedLenders.push(x);
          targets.push(x.name);
          expandedKeys.push("row_" + x.lender);
        }
      });


      //  no need for now
      // if (selectedLenders.length === 0) {
      //   selectedLenders.push(lenders[0]);
      //   targets.push(lenders[0].name);
      //   expandedKeys.push(lenders[0].name);
      //   console.log(this.state.selectedid);
      //   console.log(lenders);
      // }
      this.setState({ editTarget: targets, showEdit: true, expandedRowKeys: expandedKeys });
      let lendersOnTop = []
      let lendersCopy = deepcopy(this.state.customerlenders);
      let selectedLenderCopy = selectedLenders

      // use historical config and filter out newly added historical lenders from newly add list
      for (let y of selectedLenders) {
        for (let x of lendersCopy) {
          if (y.id === x.id && !x.Active) {
            x.Active = true;
            lendersOnTop.push(x)
            selectedLenderCopy = selectedLenderCopy.filter(lender => lender.id !== y.id)
          }
        }
      }
      // filter out newly added historical lenders
      lendersCopy = lendersCopy.filter(lender => !lendersOnTop.some(lenderOnTop => lenderOnTop.id === lender.id));

      // assign default config to newly added fresh lenders
      for (let x of selectedLenderCopy) {
        x.Config = {
          compensation: "1",
          compensationFlatFee: 0,
          closeover: null,
          lenderfees: [
            {
              fee: 0,
              states: [],
              mortgageTypes: [],
            },
          ],
          maximumClosingCostPercentage: null,
          minimumClosingCostPercentage: null,
        };
        if (this.state.lendercache[x.id] !== undefined) {
          x.Config = this.state.lendercache[x.id]["Config"];
        }
      }
      for (let x of selectedLenderCopy) {
        let l = {
          id: x.id,
          name: x.name,
          lender: x.lender,
          Config: x.Config,
          Active: true
        }
        lendersOnTop.push(l)
      }
      //keep newly added lenders on top
      lendersCopy = [...lendersOnTop, ...lendersCopy]

      this.resetLenderIDs(lendersCopy);
      this.setState({ customerlenders: lendersCopy });
    };
    let showRules = (key) => {
      return (
        <option value={key} key={key}>
          {com.RuleSet[key].name}
        </option>
      );
    };
    let displayRule = () => {
      let out = [];
      out.push(displayRuleBody());
      out.push(displayAction());
      return out;
    };

    let displayAction = () => {
      return (
        <>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label style={{ marginBottom: "0" }}>Action:</Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  required
                  type="text"
                  value={this.state.action}
                  onChange={(e) => {
                    this.setState({ action: e.target.value });
                  }}
                  style={{ backgroundColor: "#FCFCFC" }}
                >
                  <option value="">...</option>
                  <option value="adjustment">Set adjustment points</option>
                  <option value="fail">Fail qualification</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {this.state.action === "adjustment" ? (
            <Row>
              <Col>
                <Form.Group controlId="action">
                  <InputGroup size="sm">
                    <Form.Control
                      size="sm"
                      required
                      value={this.state.adjustment}
                      onChange={(e) =>
                        this.setState({ adjustment: e.target.value })
                      }
                      type="text"
                      style={{ width: "3em" }}
                      pattern="^[0...9-.]*$"
                      placeholder="XX.X"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text
                        className="pl-3"
                        style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                        size="sm"
                      >
                        %
                      </InputGroup.Text>
                    </InputGroup.Append>

                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter adjustment points
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      );
    };

    let displayRuleBody = (index) => {
      let selectedRule = this.state.rules[index].selectedRule;
      let maxrule = this.state.rules[index].maxrule;
      let minrule = this.state.rules[index].minrule;
      let value = this.state.rules[index].value;
      let values = this.state.rules[index].values;
      let typ = this.state.rules[index].typ;

      let enumValues = () => {
        let out = [];
        Object.keys(com.RuleSet[selectedRule].values).forEach((key) => {
          out.push(
            <option value={key}>{com.RuleSet[selectedRule].values[key]}</option>
          );
        });
        return out;
      };

      if (selectedRule === "" || undefined === selectedRule) return "";

      let displayFloat = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
                pattern="^[0...9-.]+$"
                placeholder="XXX.XX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };

      let displayInt = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
                pattern="^[0-9]+$"
                placeholder="XXX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };
      let displayString = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };
      let displayDollars = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <InputGroup.Prepend>

              </InputGroup.Prepend>
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "5em" }}
                pattern="^[0-9,.]+$"
                placeholder="XXX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };
      let setRuleValue = (token) => {
        return (e) => {
          this.state.rules[index][token] = e.target.value;
          this.setState({ rules: this.state.rules });
        };
      };
      let displayOperator = () => {
        if (undefined === selectedRule || "" === selectedRule) return "";

        let showOption = (op) => {
          switch (com.RuleSet[selectedRule].typ) {
            case "minmax":
              return <option value={op}>{com.operatorInout[op]}</option>;
            case "enum":
              return <option value={op}>{com.operatorEnum[op]}</option>;
            case "bool":
              return <option value={op}>{com.operatorBool[op]}</option>;
            case "value":
            default:
              return <option value={op}>{com.operatorMap[op]}</option>;
          }
        };
        return (
          <Col
            xs="auto"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                required
                type="text"
                value={this.state.rules[index]["operator"]}
                onChange={(e) => {
                  this.state.rules[index]["operator"] = e.target.value;
                  this.setState({ rules: this.state.rules });
                }}
              >
                <option value="">...</option>
                {com.RuleSet[selectedRule].operators != null
                  ? com.RuleSet[selectedRule].operators.map(showOption)
                  : ""}
              </Form.Control>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Enter comparison
              </Form.Control.Feedback>{" "}
            </Form.Group>
          </Col>
        );
      };
      let getOptions = () => {
        let r = Object.keys(com.RuleSet[selectedRule].values).map((x) => {
          return { name: com.RuleSet[selectedRule].values[x], id: x };
        });

        return r;
      };
      let onSelect = (e) => {
        this.state.rules[index]["selectedEnum"] = e;
        this.setState({ rules: this.state.rules });
      };
      let selectLeftOperator = (e) => {
        this.state.rules[index]["minoperator"] = e.target.value;
        this.setState({ rules: this.state.rules });
      };
      let selecRightOperator = (e) => {
        this.state.rules[index]["maxoperator"] = e.target.value;
        this.setState({ rules: this.state.rules });
      };

      let displayRightCondition = () => {
        return (
          <Col xs="auto">
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                //defaultValue={this.props.firsttime}
                type="select"
                style={{ width: "5em" }}
                required
                value={this.state.rules[index]["maxoperator"]}
                onChange={selecRightOperator}
              >
                <option key="defrule" value="">
                  ...
                </option>
                <option key="less" value="less">
                  &lt;
                </option>
                <option key="lessorequal" value="lessorequal">
                  &lt;=
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
        );
      };
      let displayLeftCondition = () => {
        return (
          <Col xs="auto">
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                //defaultValue={this.props.firsttime}
                type="select"
                style={{ width: "5em" }}
                required
                value={this.state.rules[index]["minoperator"]}
                onChange={selectLeftOperator}
              >
                <option key="defrule" value="">
                  ...
                </option>
                <option key="less" value="less">
                  &lt;
                </option>
                <option key="lessorequal" value="lessorequal">
                  &lt;=
                </option>
              </Form.Control>
            </Form.Group>{" "}
          </Col>
        );
      };

      switch (com.RuleSet[selectedRule].typ) {
        case "minmax":
          return (
            <Row>
              {displayOperator()}
              <Col>
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                    "minimumrange",
                    minrule,
                    setRuleValue("minrule"),
                    "Enter Min"
                  )
                  : com.RuleSet[selectedRule].values === "int"
                    ? displayInt(
                      "minimumrange",
                      minrule,
                      setRuleValue("minrule"),
                      "Enter Min"
                    )
                    : com.RuleSet[selectedRule].values === "dollar"
                      ? displayDollars(
                        "minimumrange",
                        minrule,
                        setRuleValue("minrule"),
                        "Enter Min"
                      )
                      : ""}
              </Col>
              {displayLeftCondition()}
              <Col className="text-center">
                <span className="mx-2 text-nowrap font-weight-bold">
                  {com.RuleSet[selectedRule].short !== undefined
                    ? com.RuleSet[selectedRule].short
                    : com.RuleSet[selectedRule].name}
                </span>
              </Col>
              {displayRightCondition()}
              <Col>
                {" "}
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                    "maximumrange",
                    maxrule,
                    setRuleValue("maxrule"),
                    "Enter Max"
                  )
                  : com.RuleSet[selectedRule].values === "int"
                    ? displayInt(
                      "maximumrange",
                      maxrule,
                      setRuleValue("maxrule"),
                      "Enter Max"
                    )
                    : com.RuleSet[selectedRule].values === "dollar"
                      ? displayDollars(
                        "maximumrange",
                        maxrule,
                        setRuleValue("maxrule"),
                        "Enter Max"
                      )
                      : ""}
              </Col>
            </Row>
          );
        case "enum":
          return (
            <Row>
              {displayOperator()}
              <Col>
                <Multiselect
                  ref={this.state.rules[index].ref}
                  options={getOptions()}
                  selectedValues={this.state.rules[index]["selectedEnum"]}
                  displayValue="name"
                  onSelect={onSelect}
                  onRemove={onSelect}
                />
              </Col>
            </Row>
          );
          break;
        case "value":
          return (
            <Row>
              {displayOperator()}
              <Col>
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                    "value",
                    value,
                    setRuleValue("value"),
                    "Please enter value"
                  )
                  : com.RuleSet[selectedRule].values === "int"
                    ? displayInt(
                      "value",
                      value,
                      setRuleValue("value"),
                      "Please enter value"
                    )
                    : com.RuleSet[selectedRule].values === "string"
                      ? displayString(
                        "value",
                        value,
                        setRuleValue("value"),
                        "Please enter value"
                      )
                      : com.RuleSet[selectedRule].values === "dollar"
                        ? displayDollars(
                          "value",
                          value,
                          setRuleValue("value"),
                          "Please enter value"
                        )
                        : ""}
              </Col>
            </Row>
          );
        case "bool":
          return (
            <Row>
              {displayOperator()}
              <Col></Col>
              <Col></Col>
            </Row>
          );
        default:
          return selectedRule;
      }
    };
    let displayPromotionBody = (index) => {
      let selectedRule = this.state.promotions[index].selectedPromotion;
      let maxrule = this.state.promotions[index].maxrule;
      let minrule = this.state.promotions[index].minrule;
      let value = this.state.promotions[index].value;
      let values = this.state.promotions[index].values;
      let typ = this.state.promotions[index].typ;

      let enumValues = () => {
        let out = [];
        Object.keys(com.RuleSet[selectedRule].values).forEach((key) => {
          out.push(
            <option value={key}>{com.RuleSet[selectedRule].values[key]}</option>
          );
        });
        return out;
      };

      if (selectedRule === "" || undefined === selectedRule) return "";

      let displayFloat = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
                pattern="^[0...9-.]+$"
                placeholder="XXX.XX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };

      let displayInt = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
                pattern="^[0-9]+$"
                placeholder="XXX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };
      let displayString = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };
      let displayDollars = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text style={{ fontSize: "0.9em" }} size="sm">
                  $
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "5em" }}
                pattern="^[0-9,.]+$"
                placeholder="XXX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };
      let setRuleValue = (token) => {
        return (e) => {
          this.state.promotions[index][token] = e.target.value;
          this.setState({ promotions: this.state.promotions });
        };
      };
      let displayOperator = () => {
        if (undefined === selectedRule || "" === selectedRule) return "";

        let showOption = (op) => {
          switch (com.RuleSet[selectedRule].typ) {
            case "minmax":
              return <option value={op}>{com.operatorInout[op]}</option>;
            case "enum":
              return <option value={op}>{com.operatorEnum[op]}</option>;
            case "bool":
              return <option value={op}>{com.operatorBool[op]}</option>;
            case "value":
            default:
              return <option value={op}>{com.operatorMap[op]}</option>;
          }
        };
        return (
          <Col
            xs="auto"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                required
                type="text"
                value={this.state.promotions[index]["operator"]}
                onChange={(e) => {
                  this.state.promotions[index]["operator"] = e.target.value;
                  this.setState({ promotions: this.state.promotions });
                }}
              >
                <option value="">...</option>
                {com.RuleSet[selectedRule].operators != null
                  ? com.RuleSet[selectedRule].operators.map(showOption)
                  : ""}
              </Form.Control>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Enter comparison
              </Form.Control.Feedback>{" "}
            </Form.Group>
          </Col>
        );
      };
      let getOptions = () => {
        let r = Object.keys(com.RuleSet[selectedRule].values).map((x) => {
          return { name: com.RuleSet[selectedRule].values[x], id: x };
        });

        return r;
      };
      let onSelect = (e) => {
        this.state.promotions[index]["selectedEnum"] = e;
        this.setState({ promotions: this.state.promotions });
      };
      let selectLeftOperator = (e) => {
        this.state.promotions[index]["minoperator"] = e.target.value;
        this.setState({ promotions: this.state.promotions });
      };
      let selecRightOperator = (e) => {
        this.state.promotions[index]["maxoperator"] = e.target.value;
        this.setState({ promotions: this.state.promotions });
      };

      let displayRightCondition = () => {
        return (
          <Col xs="auto">
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                //defaultValue={this.props.firsttime}
                type="select"
                style={{ width: "5em" }}
                required
                value={this.state.promotions[index]["maxoperator"]}
                onChange={selecRightOperator}
              >
                <option key="defrule" value="">
                  ...
                </option>
                <option key="less" value="less">
                  &lt;
                </option>
                <option key="lessorequal" value="lessorequal">
                  &lt;=
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
        );
      };
      let displayLeftCondition = () => {
        return (
          <Col xs="auto">
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                //defaultValue={this.props.firsttime}
                type="select"
                style={{ width: "5em" }}
                required
                value={this.state.promotions[index]["minoperator"]}
                onChange={selectLeftOperator}
              >
                <option key="defrule" value="">
                  ...
                </option>
                <option key="less" value="less">
                  &lt;
                </option>
                <option key="lessorequal" value="lessorequal">
                  &lt;=
                </option>
              </Form.Control>
            </Form.Group>{" "}
          </Col>
        );
      };

      switch (com.RuleSet[selectedRule].typ) {
        case "minmax":
          return (
            <Row>
              {displayOperator()}
              <Col>
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                    "minimumrange",
                    minrule,
                    setRuleValue("minrule"),
                    "Enter Min"
                  )
                  : com.RuleSet[selectedRule].values === "int"
                    ? displayInt(
                      "minimumrange",
                      minrule,
                      setRuleValue("minrule"),
                      "Enter Min"
                    )
                    : com.RuleSet[selectedRule].values === "dollar"
                      ? displayDollars(
                        "minimumrange",
                        minrule,
                        setRuleValue("minrule"),
                        "Enter Min"
                      )
                      : ""}
              </Col>
              {displayLeftCondition()}
              <Col className="text-center">
                <span className="mx-2 text-nowrap font-weight-bold">
                  {com.RuleSet[selectedRule].short !== undefined
                    ? com.RuleSet[selectedRule].short
                    : com.RuleSet[selectedRule].name}
                </span>
              </Col>
              {displayRightCondition()}
              <Col>
                {" "}
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                    "maximumrange",
                    maxrule,
                    setRuleValue("maxrule"),
                    "Enter Max"
                  )
                  : com.RuleSet[selectedRule].values === "int"
                    ? displayInt(
                      "maximumrange",
                      maxrule,
                      setRuleValue("maxrule"),
                      "Enter Max"
                    )
                    : com.RuleSet[selectedRule].values === "dollar"
                      ? displayDollars(
                        "maximumrange",
                        maxrule,
                        setRuleValue("maxrule"),
                        "Enter Max"
                      )
                      : ""}
              </Col>
            </Row>
          );
        case "enum":
          return (
            <Row>
              {displayOperator()}
              <Col>
                <Multiselect
                  ref={this.state.promotions[index].ref}
                  options={getOptions()}
                  selectedValues={this.state.promotions[index]["selectedEnum"]}
                  displayValue="name"
                  onSelect={onSelect}
                  onRemove={onSelect}
                />
              </Col>
            </Row>
          );
          break;
        case "value":
          return (
            <Row>
              {displayOperator()}
              <Col>
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                    "value",
                    value,
                    setRuleValue("value"),
                    "Please enter value"
                  )
                  : com.RuleSet[selectedRule].values === "int"
                    ? displayInt(
                      "value",
                      value,
                      setRuleValue("value"),
                      "Please enter value"
                    )
                    : com.RuleSet[selectedRule].values === "string"
                      ? displayString(
                        "value",
                        value,
                        setRuleValue("value"),
                        "Please enter value"
                      )
                      : com.RuleSet[selectedRule].values === "dollar"
                        ? displayDollars(
                          "value",
                          value,
                          setRuleValue("value"),
                          "Please enter value"
                        )
                        : ""}
              </Col>
            </Row>
          );
        case "bool":
          return (
            <Row>
              {displayOperator()}
              <Col></Col>
              <Col></Col>
            </Row>
          );
        default:
          return selectedRule;
      }
    };
    let modalSubmit = (event) => {
      if (this.modal.current.reportValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ modalvalidated: true });

        return false;
      }
      event.preventDefault();
      event.stopPropagation();

      let tst = {};
      switch (this.state.action) {
        case "adjustment": {
          tst.adjustment = this.state.adjustment;
          break;
        }
        case "fail": {
          tst.fail = true;
          break;
        }
        default: {
          alert("should not see this!");
        }
      }
      tst.rules = [];

      this.state.rules.forEach((x) => {
        // set the config
        //create rule

        let rule = {};
        switch (com.RuleSet[x.selectedRule].typ) {
          case "minmax":
            rule[x.selectedRule] = {};
            rule[x.selectedRule].value = [x.minrule, x.maxrule];
            rule[x.selectedRule].minoperator = x.minoperator;
            rule[x.selectedRule].maxoperator = x.maxoperator;
            rule[x.selectedRule].operator = x.operator;
            break;
          case "enum":
            rule[x.selectedRule] = {};
            rule[x.selectedRule].value = x.selectedEnum.map((x) => x.id);
            rule[x.selectedRule].operator = x.operator;
            break;
          case "value":
            rule[x.selectedRule] = {};
            rule[x.selectedRule].value = x.value;
            rule[x.selectedRule].operator = x.operator;
            break;
          case "bool":
            rule[x.selectedRule] = {};
            rule[x.selectedRule].value = true;
            rule[x.selectedRule].operator = x.operator;
            break;
        }

        tst.rules.push(rule);
      });

      if (this.state.mode === "edit") {
        this.state.config.rules[this.state.ruleindex] = tst;
        this.state.rule = tst;
      } else {
        if (this.state.config.rules == null) this.state.config.rules = [];

        this.state.config.rules.push(tst);
      }

      this.setState({
        show: false,
        customerlenders: this.state.customerlenders,
      });
      return false;
    };
    let displayPromotionBox = (rule, i) => {
      let selectPromotion = (e) => {
        this.state.promotions[i].selectedPromotion = e.target.value;
        this.setState({ promotions: this.state.promotions });
        if (this.state.promotions[i].ref == null) {
          this.state.promotions[i].ref = React.createRef();
        }

        if (null != this.state.promotions[i].ref.current) {
          setTimeout((e) => {
            if (this.state.promotions[i].ref.current != null)
              this.state.promotions[i].ref.current.resetSelectedValues();

          }, 200);
        }
      };
      let remove = () => {
        this.state.promotions.splice(i, 1);
        this.setState({ promotions: this.state.promotions });
      };

      return (
        <div className="rulecard mb-2" key={"displayRuleBox" + i}>
          {this.state.promotions.length > 1 ? (
            <Row>
              <Col className="text-right pt-0 pr-1">
                <i
                  className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
                  onClick={remove}
                ></i>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <Form.Group>
                <Form.Label style={{ marginBottom: "10px" }}>
                  Condition:
                </Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  //defaultValue={this.props.firsttime}
                  type="select"
                  required
                  value={rule.selectedPromotion}
                  onChange={selectPromotion}
                >
                  <option key="defrule" value="">
                    Please select...
                  </option>
                  {Object.keys(com.RuleSet).map(showRules)}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {this.state.promotions[i].selectedPromotion !== "" ? displayPromotionBody(i) : ""}
        </div>
      );
    };
    let addMorePromotion = () => {
      let newpromotion = deepcopy(emptypromotion);
      newpromotion.ref = React.createRef();
      this.state.promotions.push(newpromotion);
      this.setState({ promotions: this.state.promotions });
    };
    let displayRuleBox = (rule, i) => {
      let selectRule = (e) => {
        this.state.rules[i].selectedRule = e.target.value;
        this.setState({ rules: this.state.rules });
        if (this.state.rules[i].ref == null) {
          this.state.rules[i].ref = React.createRef();
        }

        if (null != this.state.rules[i].ref.current) {
          setTimeout((e) => {
            if (this.state.rules[i].ref.current != null)
              this.state.rules[i].ref.current.resetSelectedValues();

          }, 200);
        }
      };
      let remove = () => {
        this.state.rules.splice(i, 1);
        this.setState({ rules: this.state.rules });
      };

      return (
        <div className="rulecard mb-2" key={"displayRuleBox" + i}>
          {this.state.rules.length > 1 ? (
            <Row>
              <Col className="text-right pt-0 pr-1">
                <i
                  className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
                  onClick={remove}
                ></i>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <Form.Group>
                <Form.Label style={{ marginBottom: "10px" }}>
                  Condition:
                </Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  //defaultValue={this.props.firsttime}
                  type="select"
                  required
                  value={rule.selectedRule}
                  onChange={selectRule}
                >
                  <option key="defrule" value="">
                    Please seclect...
                  </option>
                  {Object.keys(com.RuleSet).map(showRules)}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {this.state.rules[i].selectedRule !== "" ? displayRuleBody(i) : ""}
        </div>
      );
    };
    let addMore = () => {
      let newrule = deepcopy(emptyrule);
      newrule.ref = React.createRef();
      this.state.rules.push(newrule);
      this.setState({ rules: this.state.rules });
    };
    let copyOptions = () => {
      let out = [];
      this.state.customerlenders.forEach((x) => {
        if (x.id === this.state.lenderid) return;
        out.push(<option value={x.id}> {x.name} </option>);
      });
      return out;
    };
    let modalCopySubmit = (event) => {
      if (this.modalcopy.current.reportValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ modalcopyvalidated: true });

        return false;
      }
      event.preventDefault();
      event.stopPropagation();

      this.state.customerlenders.forEach((x) => {
        if (x.id.toString() === this.state.targetcopy) {
          //alert(this.state.targetcopy)
          x.Config.rules = deepcopy(this.state.config.rules);
          x.Config.promotions = deepcopy(this.state.config.promotions);
        }
      });

      this.setState({
        showcopy: false,
        customerlenders: this.state.customerlenders,
      });
      return false;
    };
    let modalPromotionSubmit = (event) => {
      if (this.promotionmodal.current.reportValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ modalpromotionvalidated: true });

        return false;
      }
      event.preventDefault();
      event.stopPropagation();

      let tst = {};
      tst.conditions = [];
      tst.message = this.state.message;

      this.state.promotions.forEach((x) => {
        // set the config
        //create rule
        let rule = {};
        switch (com.RuleSet[x.selectedPromotion].typ) {
          case "minmax":
            rule[x.selectedPromotion] = {};
            rule[x.selectedPromotion].value = [x.minrule, x.maxrule];
            rule[x.selectedPromotion].minoperator = x.minoperator;
            rule[x.selectedPromotion].maxoperator = x.maxoperator;
            rule[x.selectedPromotion].operator = x.operator;
            break;
          case "enum":
            rule[x.selectedPromotion] = {};
            rule[x.selectedPromotion].value = x.selectedEnum.map((x) => x.id);
            rule[x.selectedPromotion].operator = x.operator;
            break;
          case "value":
            rule[x.selectedPromotion] = {};
            rule[x.selectedPromotion].value = x.value;
            rule[x.selectedPromotion].operator = x.operator;
            break;
          case "bool":
            rule[x.selectedPromotion] = {};
            rule[x.selectedPromotion].value = true;
            rule[x.selectedPromotion].operator = x.operator;
            break;
        }

        tst.conditions.push(rule);
      });

      if (this.state.mode === "edit") {
        this.state.config.promotions[this.state.promotionindex] = tst;

      } else {
        if (this.state.config.promotions == null) this.state.config.promotions = [];

        this.state.config.promotions.push(tst);
      }

      this.setState({
        showpromotion: false,
        customerlenders: this.state.customerlenders,
      });
      return false;
    };


    const sendMsgToSlack = () => {

      let token = sessionStorage.getItem("ZeitroA");

      fetch("/los/addlenderrequest", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
      }).then((response) => {
        if (response.status !== 200) {
          console.log(
            "/los/addlenderrequest: Looks like there was a problem. Status Code: " +
            response.status
          );
          return;
        }
      });
    };

    let modalAddLenderSubmit = (event) => {
      if (this.addLenderModal.current.reportValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ modalAddLenderValidated: true });

        return false;
      }
      event.preventDefault();
      event.stopPropagation();
      add()
      this.setState({
        showAdd: false,
      });
      return false
    }

    return (
      <div className="text-left lendersPane">
        <div className="mt-3 lendersHeadingWrap" style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="title-2">Lenders:</div>
          <div className="addLenderBtnOutline" onClick={() => {

            // if (this.props.subscriptionReducer.plan === 'starter') {
            //   this.setState({ isModalOpen: true })
            //   return
            // }

            this.setState({ showAdd: true, searchKeywords: "", selectedLenders: [] })

          }}>
            Add lender
          </div>
        </div>
        <div className="mt-3 lendersViewWrap">
          <div style={{ width: "60%" }}>
            <Input.Search
              placeholder="Search lenders by name"
              value={this.state.searchKeywords}
              onSearch={(value) => this.handleSearch(value)}
              onChange={(e) => this.handleSearchChange(e)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              style={{ height: "44px" }}
              allowClear
            />
          </div>
          <div>
            <Radio.Group
              onChange={(e) => this.setState({ currentView: e.target.value })}
            >
              <Radio.Button value="tableview" style={{ height: "44px", padding: "5px 15px 15px 15px" }}><img src="/images/tableViewIcon.png" /></Radio.Button>
              <Radio.Button value="cardview" style={{ height: "44px", padding: "5px 15px 15px 15px" }}><img src="/images/cardViewIcon.png" /></Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <hr style={{ borderTop: "1px solid #DBDCDE" }} />
        <Modal
          dialogClassName="addAdjustmentDialog"
          show={this.state.show}
          onHide={(e) => {
            this.setState({ show: false });
          }}
          className="addAdjustmentModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.mode === "edit" ? (
                <>Edit an adjustment</>
              ) : (
                <>Add an adjustment</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Form
            ref={this.modal}
            onSubmit={modalSubmit}
            noValidate
            validated={this.state.modalvalidated}
            className="addAdjustmentModalForm"
          >
            <Modal.Body>
              <div className="d-flex h-100">
                <div className="conditionSection">
                  {this.state.rules.map(displayRuleBox)}
                  <hr />
                  <Row>
                    <Col className="text-left">
                      <Button onClick={addMore} variant="link" className="pl-0">
                        Add condition
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="actionSection">
                  {this.state.selectedRule !== "" ? displayAction() : ""}
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer className="text-center d-block">
              <Row className="text-center">
                <Col className="d-flex align-items-center">
                  <div
                    className="adjustmentCancelBtn"
                    onClick={(e) => {
                      this.setState({ show: false });
                    }}
                  >
                    Cancel
                  </div>
                </Col>
                <Col className="d-flex align-items-center text-right justify-content-end">
                  <Button type="submit" className="adjustmentSubmitBtn">
                    {this.state.mode === "edit" ? "Update" : "Add"}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName="modal-50w"
          show={this.state.showcopy}
          onHide={(e) => {
            this.setState({ showcopy: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Copy adjustment rules from {this.state.lendername}
            </Modal.Title>
          </Modal.Header>
          <Form
            ref={this.modalcopy}
            onSubmit={modalCopySubmit}
            noValidate
            validated={this.state.modalcopyvalidated}
          >
            <Modal.Body>
              <Form.Group>
                <Form.Label style={{ marginBottom: "0" }}>To:</Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  required
                  type="text"
                  //value={this.state.action}

                  onChange={(e) => {
                    this.setState({ targetcopy: e.target.value });
                  }}
                >
                  <option value="">...</option>
                  {copyOptions()}
                </Form.Control>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer className="text-center d-block">
              <Row className="text-center">
                <Col>
                  <Button
                    onClick={(e) => {
                      this.setState({ showcopy: false });
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button variant="success" type="submit">
                    Copy
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName="addPromotionDialog"
          show={this.state.showpromotion}
          onHide={(e) => {
            this.setState({ showpromotion: false });
          }}
          className="addPromotionModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.mode === "edit" ? (
                <>Edit promotion</>
              ) : (
                <>Add promotion</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Form
            ref={this.promotionmodal}
            onSubmit={modalPromotionSubmit}
            noValidate
            validated={this.state.modalpromotionvalidated}
            className="addPromotionModalForm"
          >
            <Modal.Body>
              <div className="d-flex h-100">
                <div className="conditionSection">
                  {this.state.promotions.map(displayPromotionBox)}
                  <hr />
                  <Row>
                    <Col className="text-left">
                      <Button onClick={addMorePromotion} variant="link" className="pl-0">
                        Add more
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="actionSection">
                  {this.state.selectedRule !== "" ? <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label style={{ marginBottom: "10px" }}>Promotion message:</Form.Label>
                        <Form.Control
                          size="sm"
                          required
                          type="text"
                          value={this.state.message}
                          onChange={(e) => {
                            this.setState({ message: e.target.value });
                          }}
                          placeholder="Please enter message here"
                          style={{ minHeight: "120px", backgroundColor: "#FCFCFC", border: "1px solid #DBDCDE", borderRadius: "4px" }}
                        >
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row> : ""}
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer className="text-center d-block">
              <Row className="text-center">
                <Col className="d-flex align-items-center">
                  <div
                    className="adjustmentCancelBtn"
                    onClick={(e) => {
                      this.setState({ showpromotion: false });
                    }}
                  >
                    Cancel
                  </div>
                </Col>
                <Col className="d-flex align-items-center text-right justify-content-end">
                  <Button type="submit" className="adjustmentSubmitBtn">
                    {this.state.mode === "edit" ? "Update" : "Add"}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName="addLenderDialog"
          className="addLenderModal"
          show={this.state.showAdd}
          onHide={(e) => {
            this.setState({ showAdd: false });
          }}
          onExited={() => this.setState({ searchKeywords: "" })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Select your lenders
            </Modal.Title>
          </Modal.Header>
          <Form
            className="addLenderModalForm"
            ref={this.addLenderModal}
            onSubmit={modalAddLenderSubmit}
            noValidate
            validated={this.state.modalAddLenderValidated}
          >
            <Modal.Body>
              {addLenders()}
            </Modal.Body>

            <Modal.Footer className="text-center d-block">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <Popconfirm
                    id="addLenderRequest"
                    title="Would you like to send a request to add lenders to the Zeitro team?"
                    description="After submitting your request, Zeitro will reach out to you with instructions on how to add additional lenders to the system. We may require your assistance in obtaining the necessary information for these lenders."
                    onConfirm={() => {
                      sendMsgToSlack()
                      message.success("Your request has been sent, and the Zeitro team will contact you soon.")
                    }}
                    onCancel={() => { }}
                    okText="Send request"
                    placement="top"
                    overlayStyle={{ width: 400 }}
                    cancelText="Cancel"
                    icon={<InfoCircleTwoTone />}
                  ><Button variant="link" style={{ textDecoration: "underline" }}>Can't find your lender? </Button></Popconfirm>
                </div>

                <Button className="addLenderButton" type="submit">
                  Add
                </Button>

              </div>
            </Modal.Footer>
          </Form>
        </Modal>
        <PlanModal isModalOpen={this.state.isModalOpen} closeModal={() => {

          this.setState({ isModalOpen: false })

        }} ></PlanModal>
        {showLenders()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Lenders);
