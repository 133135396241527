import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Carousel,
  Spin,
  Modal,
  Checkbox
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import * as com from "./Common.js";
import EmailVerification from "./Application/EmailVerification.js";
import { RecaptchaV3, RecaptchaV3SiteKey } from "./Recaptcha.js";
import { Redirect, withRouter } from "react-router-dom";
import {
  UR_LoanOfficerManager,
  UR_LoanProcessor,
  UR_Owner,
  UR_PriceEngineUser,
} from "./State.js";
import { checkAccessByToken } from "./Auth.js";
import "./LoginPage.css";
import cookie from 'react-cookies'

function Login(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [customerid, setCustomerid] = useState("");
  const [redirect, setRedirect] = useState("");
  const [remember, setRemember] = useState(false)
  const [companyName, setCompanyName] = useState("");
  const [status, setStatus] = useState(2) // 1 customerId  2 Continue 3 Sign in 4 Reset your password 5 success
  const [showChooseCompany, setShowChooseCompany] = useState(false)
  const [customerIDs, setCustomerIDs] = useState([])
  const formInitial = {};
  useEffect(() => {
    if (cookie.load("remember")) {
      let cache = cookie.load("remember")
      setRemember(true)
      form.setFieldsValue({
          email: cache.username,
          password: cache.password
      })
    }
  }, [])

  const getcustomeridsbyemail = () => {
    let tosend = JSON.stringify({
      Email: form.getFieldValue('email').toLowerCase(),
    })
    setLoading(true)
    fetch("/los/getcustomeridsbyemail", {
      method: "POST",
      body: tosend,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false)
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          setLoading(false)
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
            setMsg(js.Text)
          } else {
            const filteredCustomerIDs = js.CustomerIDs.filter(customer => customer.CustomerID !== "individualcustomers");
            if (filteredCustomerIDs.length === 1 ) {
              setStatus(prev => prev + 1)
              setCustomerid(filteredCustomerIDs[0].CustomerID)
              localStorage.setItem("customerid", filteredCustomerIDs[0].CustomerID)
              setCompanyName(filteredCustomerIDs[0].CompanyName)
            } else {
              setCustomerIDs(filteredCustomerIDs)
              setShowChooseCompany(true)
            }
          }
        })
      })
      .catch((err) => {
        setLoading(false)
        console.log("Fetch Error :-S", err)
      });
  }

  const onFinish = (val) => {
    if (status === 2) {
      getcustomeridsbyemail()
    } else if (status === 3) {
      setLoading(true)
      window.grecaptcha.ready(() => {
        setLoading(false)
        window.grecaptcha
          .execute(RecaptchaV3SiteKey, { action: "submit" })
          .then((token) => {
            login(val, token)
          })
      })
    } else if (status === 5) {
      setStatus(3)
    }
  };

  const checkEmail = () => {
    let tosend = JSON.stringify({
      email: form.getFieldValue('email').toLowerCase(),
      customerid,
    });
    setLoading(true);
    fetch("/los/checksigninemail", {
      method: "POST",
      body: tosend,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false);
          console.log("response: ")
          response.text().then((errorText) => {
            let title = 'An internal error has occurred';
            let content = 'Please try again later or contact contact@zeitro.com for assistance.'
            console.log('errorText: ' + errorText)
            // check if customerid does not exist in database
            if (errorText.includes('relation') && errorText.includes('does not exist')) {
                title = 'Invalid Company ID';
                content = 'The Company ID you entered is invalid. Please check your Company ID and try again.';
            }
            Modal.info({
              title: title,
              content: content,
            })
            return
          });
          return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            Modal.info({
              title: 'The email has not been registered yet',
              content: 'Please check your email or contact your company administrator for assistance.',
            })
          } else {
            setShowEmailVerification(true);
          }
          setLoading(false);
        });
      })
      .catch(function (err) {
        setLoading(false);
        console.log("Fetch Error :-S", err);
      });
  };
  const handleClose = () => {
    setShow(false);
  };
  const login = (val, token) => {
    let tosend = JSON.stringify({
      username: val.email.toLowerCase(),
      password: val.password,
      token: token,
      customerid,
    });
    setLoading(true);
    fetch("/los/signin", {
      method: "POST",
      body: tosend,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false);
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          //console.log("js: " + js)
          //console.log("js: " + JSON.stringify(js))
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text);
            setMsg(js.Text);
            setShow(true);
            sessionStorage.removeItem("ZeitroA");
            sessionStorage.removeItem("state");
            setLoading(false);
          } else {
            if (remember) {
              let inFifteenMinutes = new Date(new Date().getTime() + 72 * 3600 * 1000)
              cookie.save("remember", {username: val.email.toLowerCase(), password: val.password}, { expires: inFifteenMinutes })
            } else {
              cookie.remove("remember");
            }
            sessionStorage.setItem("ZeitroA", js.Token);
            // now seed the local state!
            //console.log(" state: " + js.State)

            sessionStorage.setItem("state", js.State);
            sessionStorage.setItem("originalstate", js.State);

            setLoading(false);
            if (
              checkAccessByToken(js.Token, [UR_LoanOfficerManager, UR_Owner])
            ) {
              window.location.href = "/app/welcome";
            } else if (checkAccessByToken(js.Token, [UR_LoanProcessor])) {
              window.location.href = "/app/borrowers";
            } else if (checkAccessByToken(js.Token, [UR_PriceEngineUser])) {
              window.location.href = "/app/purchasecalculator";
            } else {
              window.location.href = "/app/welcome";
            }

            //console.log(js)
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };

  const resetCustomerId = (e) => {
    setStatus(2)
    setShowEmailVerification(false)
    setCustomerid('')
    setCompanyName('')
    localStorage.removeItem("customerid")
    setRedirect(<Redirect to="/" />)
  }
  const handleValuesChange = (changedValues, allValues) => {
    
  }
  const back = (type) => {
    setShowEmailVerification(false)
    setStatus(type + 1)
  }
  const pageHeader = () => {
    if (status === 2) {
      return (
        <>
          <div className="login-title">Sign in</div>
          <div className="login-subtitle mt-2 title-5">
            to work on loan applications
          </div>      
        </>
      )
    } else {
      return (
        <div style={{marginBottom: 24}}>
          <img src="/images/password-check.png" style={{ width: 69, height: 69, marginBottom: 11 }} />
          <div className="login-title">{status === 3 ? 'Enter your password' : status === 4 ? 'Reset your password' : 'You are all set!'}</div>
          {status === 5 && <div className="login-title-tips">Your password has been successfully reset,click the following to continue your access.</div>}
        </div>
      )
    }
  }
  const onChangeRemember = (e) => {
    setRemember(e.target.checked)
  }

  const toSignup = () => {
    setRedirect(<Redirect to="/signup" />);
  }
  const chooseCompany = (item) => {
    setShowChooseCompany(false)
    setCompanyName(item.CompanyName)
    document.title = `${item.CompanyName} - mortgage platform`
    setCustomerid(item.CustomerID)
    localStorage.setItem("customerid", item.CustomerID)
    setStatus(prev => prev + 1)
  }
  return (
    <>
      <div className="sign-up-box">
        {redirect}
        <div className="create-account">
          <div className="sign-up-logo">
            <span className="company-name">{companyName}</span> 
          </div>
          <div className="mobile-nav">
            <span className="company-name">{companyName}</span>
          </div>
          {showEmailVerification ? (
            <div className="create-centerBox">
              <EmailVerification
                showBack
                fromSignIn
                customerid={customerid}
                email={form.getFieldValue("email").toLowerCase()}
                back={(type) => back(type)}
              />
            </div>
          ) : (
            <div className="create-centerBox">
              {pageHeader()}

              <Form
                className="create-account-form"
                layout="vertical"
                form={form}
                initialValues={formInitial}
                onFinish={onFinish}
                onValuesChange={handleValuesChange}
                size="large"
              >
                {
                  [2,3,4].includes(status) && (
                    <Row>
                        <Col span={24}>
                        <Form.Item
                            name="email"
                            label="Email"
                            style={{marginBottom: status !== 2 ? 0 : 20}}
                            rules={[
                            {
                                required: true,
                                type: "email",
                                message: 'Please enter a valid email address.',
                            },
                            ]}
                        >
                            
                            {status === 2 && <Input placeholder="Your email" />}
                            {(status === 3 || status === 4) && <div className="enterPwd-email"><span>{form.getFieldValue('email')}</span>{status === 3 && <span onClick={() => setStatus(2)}>Edit</span>}</div>}
                        </Form.Item>
                        </Col>
                    </Row>
                  )
                }
                { status === 3 && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Password"
                        name="password"
                        style={{marginBottom: 6}}
                        rules={[
                          {
                            required: true,
                            message: "Enter your password!",
                          },
                        ]}
                      >
                        <Input.Password placeholder="Your password" />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {
                  <Row>
                    <Col span={24}>
                      <RecaptchaV3 />
                    </Col>
                  </Row>
                }
                {
                  status === 3 && (
                    <Row className="login-operate">
                      <Checkbox checked={remember} onChange={onChangeRemember}><span className="remember-pwd">Remember me</span></Checkbox>
                      <span className="forget-pwd" onClick={()=>{}}>Forgot password?</span>
                    </Row>
                  )
                }
                <Spin spinning={loading}>
                  <Button
                    htmlType="submit"
                    className="create-btn sign-zeitro"
                    block
                  >
                    {status === 2 ? "Continue" : "Sign in"}
                  </Button>
                </Spin>
              </Form>
              {companyName !== '' && (
                <div className="bottom-link">
                  {/* {
                    status === 2 && (
                      <div onClick={resetCustomerId}>
                        <a variant="link">
                          Not working for{" "}
                          {com.capitalize(companyName)}?
                        </a>
                      </div>
                    )
                  } */}
                  {status === 3 && <div>or<span onClick={checkEmail}>Sign in with one-time code</span></div>}
                </div>
              )}
            </div>
          )}

          {
            status === 3 ? (
              <div className="create-copyright-box create-centerBox">
                {/* {
                    [3,4].includes(status) && (
                      <div className="footer-link">
                      <div onClick={resetCustomerId}>
                        <a variant="link">
                          Not working for{" "}
                          {com.capitalize(companyName)}?
                        </a>
                      </div>
                      </div>
                    )
                  } */}
                <div className="create-copyright">© {new Date().getFullYear()} Zeitro. All rights reserved.</div>
              </div>
            ) : (
              <div className="create-copyright">© {new Date().getFullYear()} Zeitro. All rights reserved.</div>
            )
          }
        </div>
      </div>
      <Modal
        open={show}
        title="Authentication Failed"
        cancelText="Close"
        onCancel={handleClose}
        footer={(_, { CancelBtn }) => (
          <>
            <CancelBtn />
          </>
        )}
      >
        <div>{msg}</div>
      </Modal>
      <Modal
        className="chooseCompany-modal"
        title={customerIDs.length === 0 ? 'Email Address Not Found' : 'Join the following orginizations'}
        open={showChooseCompany}
        footer={null} 
        onCancel={()=>{
            // this.editEmail('')
            setShowChooseCompany(false)
          }
        }>
          {
            customerIDs.length === 0 ? (
              <div>{form.getFieldValue('email')} is not registered in our system. If you would like to create a new account, please <span style={{color: '#325CEB', cursor: 'pointer'}} onClick={toSignup}>click here to sign up</span>.</div>
            ) : (
              <>
                <div className="ft-500">{form.getFieldValue('email')} is associated with accounts at the following orginizations, please select one to log in.</div>
                <div className="chooseCompany-list">
                  {
                    customerIDs.map((item, index) =>(
                      <div key={index} className="chooseCompany" onClick={() => chooseCompany(item)}>
                        <div>{item.CompanyName}</div>
                        <RightOutlined />
                      </div>
                    ))
                  }
                </div>
              </>
            )
          }
      </Modal>
    </>
  );
}
export default withRouter(Login);
