import { Button, Form, Input, Popconfirm, Table, Select } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./Interview1003View.css";
import * as act from "../Store/actions";
import * as com from "../Common.js";
import * as st from "../State.js";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    assetsandliabilities: state.application.assetsandliabilities,
    application: state.application,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateJointly: (event) => {
    dispatch(act.UpdateJointly(event.target.checked));
  },
  clearBorrowerBankAccounts: (index, who) => {
    dispatch(act.ClearBorrowerBankAccounts(index, who));
  },
  addBorrowerBankAccount: (i, who) => {
    dispatch(act.AddBorrowerBankAccount(i, who));
  },
  bulkAddBorrowerBankAccounts: (t, who) => {
    dispatch(act.BulkAddBorrowerBankAccounts(t, who));
  },
  bulkAddBorrowerOtherAssets: (t, who) => {
    dispatch(act.BulkAddBorrowerOtherAssets(t, who));
  },
  bulkAddBorrowerLoans: (t, who) => {
    dispatch(act.BulkAddBorrowerLoans(t, who));
  },
  bulkAddNewMortgageLoan: (t) => {
    dispatch(act.BulkAddNewMortgageLoan(t));
  },
  bulkAddBorrowerGifts: (t) => {
    dispatch(act.BulkAddBorrowerGifts(t));
  },
  bulkAddAlimonys: (t, who) => {
    dispatch(act.BulkAddAlimonys(t, who));
  },
  removeBorrowerBankAccount: (index, who) => {
    dispatch(act.RemoveBorrowerBankAccount(index, who));
  },
  changeOwnedProperty: (t, i, v) => {
    dispatch(act.ChangeOwnedProperty(t, i, v));
  },
  changeMainProperty: (value, verb) => {
    dispatch(act.ChangeMainProperty(value, verb))
  },
});

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  type,
  options,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (type === "operation") {
    return <td {...restProps}>{childNode}</td>
  }
  try {
    if (editing) {
      let ele = <Input ref={inputRef} onPressEnter={save} onBlur={save} />;
      if (type === "inputdollar") {
        ele = (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} prefix="$" />
        );
      } else if (type === "inputdollarmonth") {
        ele = (
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            suffix="mo"
            prefix="$"
          />
        );
      } else if (type === "select") {
        ele = (
          <Select
            ref={inputRef}
            onBlur={save}
            onChange={save}
            options={options}
          />
        );
      }
      childNode = (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {ele}
        </Form.Item>
      );
    } else {
      let text = record[dataIndex].toString().split(/(?=[A-Z][a-z])/).join(' ')
      if (type === "inputdollarmonth" || type === "inputdollar") {
        text = "$ " + com.commaize(text) 
      }
      childNode = (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {text}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  } catch (error) {
    return <td {...restProps}>No data</td>;
  }

};

const IncomeSourcesTable = (props) => {
  const [dataSource, setDataSource] = useState(
    props.assetsandliabilities.accounts === null
      ? []
      : props.assetsandliabilities.accounts.map((a, i) => ({
          key: a.key,
          accounttype: a.accounttype,
          institution: a.bankname,
          accountnumber: a.account,
          balance: a.balance,
        }))
  );
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    sendData(newData)
  };
  const defaultColumns = [
    {
      title: "ACCOUNT TYPE",
      dataIndex: "accounttype",
      //   width: "30%",
      type: "select",
      options: com.bankaccounttypes.map((c) => {
        return {
          value: c[0],
          label: c[1],
        };
      }),
    },
    {
      title: "FINANCIAL INSTITUTION",
      dataIndex: "institution",
      type: "input",
    },
    {
      title: "ACCOUNT NUMBER",
      dataIndex: "accountnumber",
      type: "input",
    },
    {
      title: "CASH/MARKET VALUE",
      dataIndex: "balance",
      type: "inputdollar",
    },
    {
      title: "Delete",
      dataIndex: "operation",
      fixed: 'right',
      width: 80,
      type: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <MinusCircleOutlined  style={{fontSize:16, color:"#325CEB", cursor:"pointer"}}/>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    const newData = {
      key: Math.random().toString(),
      accounttype: "CheckingAccount",
      institution: "",
      accountnumber: "",
      balance: "",
    };
    setDataSource([...dataSource, newData]);
  };
  const sendData = (newData) => {
    let tosend = newData.map((d) => {
      return {
        key: d.key,
        bankname: d.institution,
        account: d.accountnumber,
        accounttype: d.accounttype,
        balance: d.balance,
        attribution: null,
        isretirement: false,
      };
    });
    props.bulkAddBorrowerBankAccounts(tosend, "borrower");
  }

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    sendData(newData)
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        type: col.type,
        options: col.options,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div className="incomeSourcesTable">
      <Table
        scroll={{
          x: true,
        }}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <Button onClick={handleAdd} type="form1003AddRow" className="mt-3">
        <img
          src="/images/bluePlusInCircleMark.png"
          style={{ width: 15, height: 15 }}
          className="mr-2"
        />
        Add income source
      </Button>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(IncomeSourcesTable);

const OtherAssets = (props) => {
  const [dataSource, setDataSource] = useState(
    props.assetsandliabilities.otherassets === null
      ? []
      : props.assetsandliabilities.otherassets.map((a, i) => ({
          key: a.key,
          assettype: a.assettype,
          value: a.value,
        }))
  );
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    sendData(newData)
  };
  const defaultColumns = [
    {
      title: "ASSET OR CREDIT TYPE",
      dataIndex: "assettype",
      type: "select",
      options: com.otherassetsandcredits.map((c) => {
        return {
          value: c[0],
          label: c[1],
        };
      }),
    },
    {
      title: "CASH/MARKET VALUE",
      dataIndex: "value",
      type: "inputdollar",
    },
    {
      title: "Delete",
      fixed: 'right',
      width: 80,
      dataIndex: "operation",
      type: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <MinusCircleOutlined  style={{fontSize:16, color:"#325CEB", cursor:"pointer"}}/>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    const newData = {
      key: Math.random().toString(),
      assettype: "",
      value: "",
      description: "",
      attribution: "",
    };
    setDataSource([...dataSource, newData]);
  };
  const sendData = (newData) => {
    let tosend = newData.map((d) => {
      return {
        key: d.key,
        assettype: d.assettype,
        value: d.value,
        attribution: null,
        description: "",
      };
    });
    props.bulkAddBorrowerOtherAssets(tosend, "borrower");
  }
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    sendData(newData)
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        type: col.type,
        options: col.options,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div className="incomeSourcesTable">
      <Table
        scroll={{
          x: true,
        }}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <Button onClick={handleAdd} type="form1003AddRow" className="mt-3">
        <img
          src="/images/bluePlusInCircleMark.png"
          style={{ width: 15, height: 15 }}
          className="mr-2"
        />
        Add asssets or credits
      </Button>
    </div>
  );
};
export const OtherAssetsTable = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(OtherAssets);

const Liabilities = (props) => {
  const [dataSource, setDataSource] = useState(
    props.assetsandliabilities.loans === null
      ? []
      : props.assetsandliabilities.loans
  );
  const [count, setCount] = useState(
    props.assetsandliabilities.loans === null
      ? 0
      : props.assetsandliabilities.loans.length
  );
  const sendData = (newData) => {
    props.bulkAddBorrowerLoans(newData, "borrower");
  }
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    sendData(newData)
  };
  const defaultColumns = [
    {
      title: "ACCOUNT TYPE",
      dataIndex: "creditliabilityaccounttype",
      type: "select",
      options: [
        "Revolving",
        "CreditLine",
        "Installment",
        "Mortgage",
        "Open",
        "Other",
      ].map((c) => {
        return {
          value: c,
          label: c.split(/(?=[A-Z])/).join(" "),
        };
      }),
    },
    {
      title: "CREDITOR NAME",
      dataIndex: "name",
      type: "input",
    },
    {
      title: "ACCOUNT NUMBER",
      dataIndex: "accountnumber",
      type: "input",
    },
    {
      title: "UNPAID BALANCE",
      dataIndex: "remainingbalance",
      type: "inputdollar",
    },
    {
      title: "MONTHLY PAYMENT",
      dataIndex: "monthlypayment",
      type: "inputdollarmonth",
    },
    {
      title: "Delete",
      fixed: 'right',
      width: 80,
      dataIndex: "operation",
      type: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <MinusCircleOutlined  style={{fontSize:16, color:"#325CEB", cursor:"pointer"}}/>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    let newData = new st.loan();
    newData.key = Math.random().toString();
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    sendData(newData)
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        type: col.type,
        options: col.options,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div className="incomeSourcesTable">
      <Table
        scroll={{
          x: true,
        }}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <Button onClick={handleAdd} type="form1003AddRow" className="mt-3">
        <img
          src="/images/bluePlusInCircleMark.png"
          style={{ width: 15, height: 15 }}
          className="mr-2"
        />
        Add liability
      </Button>
    </div>
  );
};
export const LiabilitiesTable = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(Liabilities);

const OtherLiabilities = (props) => {
  console.log(props.who)
  console.log(props.application.coborrower.finances.alimony)
  const [dataSource, setDataSource] = useState(
    props.who === "borrower"?
    (props.application.borrower.finances.alimony === null
      ? []
      : props.application.borrower.finances.alimony)
    :
    (props.application.coborrower.finances.alimony === null
      ? []
      : props.application.coborrower.finances.alimony)
  );

  useEffect(() => {
    setDataSource(
      props.who === "borrower"
        ? props.application.borrower.finances.alimony === null
          ? []
          : props.application.borrower.finances.alimony
        : props.application.coborrower.finances.alimony === null
        ? []
        : props.application.coborrower.finances.alimony
    );
  }, [props.who]);

  const sendData = (newData) => {
    props.bulkAddAlimonys(newData, props.who);
  }
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    sendData(newData)
  };
  const defaultColumns = [
    {
      title: "TYPE",
      dataIndex: "alimonyorsupport",
      type: "select",
      options: [
        ["alimony", "Alimony"],
        ["childsupport", "Child support"],
        ["maintenancepayments", "Maintenance payments"],
        ["jobrelatedexpenses", "Job related expenses"],
        ["other", "Other"],
      ].map((c) => {
        return {
          value: c[0],
          label: c[1],
        };
      }),
    },
    {
      title: "MONTHLY PAYMENT",
      dataIndex: "amount",
      type: "inputdollar",
    },
    {
      title: "Delete",
      fixed: 'right',
      width: 80,
      dataIndex: "operation",
      type: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <MinusCircleOutlined  style={{fontSize:16, color:"#325CEB", cursor:"pointer"}}/>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    let newData = new st.alimony().toJson();
    newData = JSON.parse(newData)
    newData.key = Math.random().toString();
    setDataSource([...dataSource, newData]);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    sendData(newData)
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        type: col.type,
        options: col.options,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div className="incomeSourcesTable">
      <Table
        scroll={{
          x: true,
        }}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <Button onClick={handleAdd} type="form1003AddRow" className="mt-3">
        <img
          src="/images/bluePlusInCircleMark.png"
          style={{ width: 15, height: 15 }}
          className="mr-2"
        />
        Add other liability
      </Button>
    </div>
  );
};
export const OtherLiabilitiesTable = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(OtherLiabilities);

const Property = (props) => {
  const [dataSource, setDataSource] = useState(
    props.assetsandliabilities.loans === null
      ? []
      : props.assetsandliabilities.loans
  );

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    sendData(newData)
  };
  const defaultColumns = [
    {
      title: "ASSET TYPE",
      dataIndex: "creditliabilityaccounttype",
      type: "select",
      options: [
        "Revolving",
        "CreditLine",
        "Installment",
        "Mortgage",
        "Open",
        "Other",
      ].map((c) => {
        return {
          value: c,
          label: c.split(/(?=[A-Z])/).join(" "),
        };
      }),
    },
    {
      title: "UNPAID BALANCE",
      dataIndex: "remainingbalance",
      type: "inputdollar",
    },
    {
      title: "Delete",
      fixed: 'right',
      width: 80,
      dataIndex: "operation",
      type: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <MinusCircleOutlined  style={{fontSize:16, color:"#325CEB", cursor:"pointer"}}/>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    let newData = new st.loan();
    newData.key = Math.random().toString();
    setDataSource([...dataSource, newData]);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    sendData(newData)
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        type: col.type,
        options: col.options,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const sendData = (newData) => {
    props.bulkAddBorrowerLoans(newData, "borrower");
  }
  return (
    <div className="incomeSourcesTable">
      <Table
        scroll={{
          x: true,
        }}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <Button onClick={handleAdd} type="form1003AddRow" className="mt-3">
        <img
          src="/images/bluePlusInCircleMark.png"
          style={{ width: 15, height: 15 }}
          className="mr-2"
        />
        Add liability
      </Button>
    </div>
  );
};
export const PropertyTable = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(Property);

const PropertyMortgages = (props) => {
  const otherLoans = props.assetsandliabilities.newloans===null ? [] : props.assetsandliabilities.newloans
  const [dataSource, setDataSource] = useState(
    props.assetsandliabilities.newloans === null
      ? []
      : props.assetsandliabilities.newloans
  );

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    sendData(newData)
  };
  const defaultColumns = [
    {
      title: "CREDITOR NAME",
      dataIndex: "creditor",
      type: "input",
    },
    {
      title: "LIEN TYPE",
      dataIndex: "lientype",
      type: "select",
      options: [
        {
          value: "firstlien",
          label: "First lien",
        },
        {
          value: "subordinatelien",
          label: "Subordinate lien",
        },
      ],
    },
    {
      title: "MONTHLY AMOUNT",
      dataIndex: "monthlyamount",
      type: "inputdollarmonth",
    },
    {
      title: "LOAN AMOUNT",
      dataIndex: "loanamount",
      type: "inputdollar",
    },
    {
      title: "CREIDT LIMIT",
      dataIndex: "creditlimit",
      type: "inputdollar",
    },
    {
      title: "Delete",
      fixed: 'right',
      width: 80,
      dataIndex: "operation",
      type: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <MinusCircleOutlined  style={{fontSize:16, color:"#325CEB", cursor:"pointer"}}/>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    let newData = new st.newmortgage().toJson();
    newData = JSON.parse(newData)
    newData.key = Math.random().toString();
    newData.lientype = "firstlien"
    setDataSource([...dataSource, newData]);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    sendData(newData)
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        type: col.type,
        options: col.options,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const sendData = (newData) => {
    props.bulkAddNewMortgageLoan(newData);
  }
  return (
    <div className="incomeSourcesTable">
      <Table
        scroll={{
          x: true,
        }}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <Button onClick={handleAdd} type="form1003AddRow" className="mt-3">
        <img
          src="/images/bluePlusInCircleMark.png"
          style={{ width: 15, height: 15 }}
          className="mr-2"
        />
        Add mortgage loan
      </Button>
    </div>
  );
};
export const PropertyMortgagesTable = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(PropertyMortgages);


const OwnedPropertyLoans = (props) => {
  const [dataSource, setDataSource] = useState(props.isSubjectProperty ? props.application.property.loans : props.application.assetsandliabilities.ownedproperties[props.i].loans);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    sendData(newData)
  };
  const defaultColumns = [
    {
      title: "TYPE",
      width:"30%",
      dataIndex: "creditloantype",
      type: "select",
      options: [
        {
          value: "ConventionalRealEstateMortgage",
          label: "Conventional",
        },
        {
          value: "FHARealEstateMortgage",
          label: "FHA",
        },
        {
          value: "VeteransAdministrationLoan",
          label: "VA",
        },
        {
          value: "Other",
          label: "Other",
        },
      ],
    },
    {
      title: "CREDITOR NAME",
      dataIndex: "name",
      type: "input",
    },
    {
      title: "ACCOUNT NUMBER",
      dataIndex: "accountnumber",
      type: "input",
    },
    {
      title: "MONTHLY PAYMENT",
      dataIndex: "monthlypayment",
      type: "inputdollarmonth",
    },
    {
      title: "UNPAID BALANCE",
      dataIndex: "remainingbalance",
      type: "inputdollar",
    },
    {
      title: "DELETE",
      fixed: 'right',
      width: 80,
      dataIndex: "operation",
      type: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <MinusCircleOutlined  style={{fontSize:16, color:"#325CEB", cursor:"pointer"}}/>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    let newData = new st.loan().toJson();
    newData = JSON.parse(newData)
    newData.creditloantype = "ConventionalRealEstateMortgage"
    newData.key = Math.random().toString();
    setDataSource([...dataSource, newData]);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    sendData(newData)
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        type: col.type,
        options: col.options,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const sendData = (newData) => {
    if (props.isSubjectProperty) {
      props.changeMainProperty(newData,"loans")
    } else {
      props.changeOwnedProperty(newData,props.i,"loans")
    }
  }
  return (
    <div className="incomeSourcesTable">
      {dataSource.length > 0 && (
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ x: 1000 }}
        />
      )}
      <Button onClick={handleAdd} type="form1003AddRow" >
        <img
          src="/images/bluePlusInCircleMark.png"
          style={{ width: 15, height: 15 }}
          className="mr-2"
        />
        Add mortgage loans on this property
      </Button>
    </div>
  );
};
export const OwnedPropertyLoansTable = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(OwnedPropertyLoans);

const Gifts = (props) => {
  const [dataSource, setDataSource] = useState(
    props.assetsandliabilities.gifts === null
      ? []
      : props.assetsandliabilities.gifts.map((a, i) => ({
          assettype: a.assettype,
          deposited: a.deposited,
          sourcedescription: a.sourcedescription,
          source: a.source,
          value: a.value,
          key: a.key,
          attribution: a.attribution,
        }))
  );
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    sendData(newData)
  };
  const defaultColumns = [
    {
      title: "ASSET OR CREDIT TYPE",
      dataIndex: "assettype",
      type: "select",
      options: com.otherassetsandcredits.map((c) => {
        return {
          value: c[0],
          label: c[1],
        };
      }),
    },
    {
      title: "DEPOSITED/NOT",
      dataIndex: "deposited",
      type: "select",
      options: [
        {
          value: true,
          label: "Deposited"
        },
        {
          value: false,
          label: "Not deposited"
        },
      ]
    },
    {
      title: "SOURCE",
      dataIndex: "source",
      type: "input",
    },
    {
      title: "CASH/MARKET VALUE",
      dataIndex: "value",
      type: "inputdollar",
    },
    {
      title: "Delete",
      fixed: 'right',
      width: 80,
      dataIndex: "operation",
      type: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <MinusCircleOutlined  style={{fontSize:16, color:"#325CEB", cursor:"pointer"}}/>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    const newData = {
      assettype: "",
      deposited: true,
      sourcedescription: "",
      source: "",
      value: "",
      key: Math.random().toString(),
      attribution: null,
    };
    setDataSource([...dataSource, newData]);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    sendData(newData)
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        type: col.type,
        options: col.options,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const sendData = (newData) => {
    props.bulkAddBorrowerGifts(newData, "borrower");
  }
  return (
    <div className="incomeSourcesTable">
      <Table
        scroll={{
          x: true,
        }}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <Button onClick={handleAdd} type="form1003AddRow" className="mt-3">
        <img
          src="/images/bluePlusInCircleMark.png"
          style={{ width: 15, height: 15 }}
          className="mr-2"
        />
        Add asssets or credits
      </Button>
    </div>
  );
};
export const GiftsTable = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(Gifts);