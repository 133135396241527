import React, { useState, useEffect, useRef } from 'react';
import "./WelcomePage.css"
import { Col, Row } from 'react-bootstrap';
import { checkAccess } from './Auth';
import { UR_LoanOfficer, UR_LoanOfficerManager, UR_Owner, UR_LoanProcessor, UR_PriceEngineUser } from './State';
import { Skeleton, Select, Collapse, Spin } from 'antd';
import {useSelector,useDispatch} from 'react-redux'

import { commaize, monthNames, dateToStringMMDDYYYY, isHardMoneyLoanCustomer } from './Common';
import { Link, useHistory } from "react-router-dom";
import { VictoryArea, VictoryLine, VictoryGroup, VictoryLabel, VictoryClipContainer } from 'victory';
import smartlookClient from 'smartlook-client'
import Swiper from 'react-id-swiper';

import dayjs from 'dayjs';

function WelcomePage(props) {
  const dispatch = useDispatch()
  const [companyName, setCompanyName] = useState("")
  const [profile, setProfile] = useState(null)
  const [notifications, setNotifications] = useState(null)
  const [plotsData, setPlotsData] = useState({})
  const [allLoan, setAllLoan] = useState([])
  const [loans, setLoans] = useState([])
  const [isEnd, setIsEnd] = useState(false)
  const [pageLoading, setpageLoading] = useState(false)
  const [publishModule, setPublishModule] = useState([])
  const [selectTime, setSelectTime] = useState('month')
  const [loading, setLoading] = useState(false)
  const [isLP, setIsLP] = useState(null)
  const [rateSelection, setRateSelection] = useState([])
  const [borrowerTasks, setBorrowerTasks] = useState([])
  const [setupGuideStatus, setSetupGuideStatus] = useState({
    exploreLoans: false,
    tryPricingEngine: false,
    tryMortgageGPT: false,
    tryAffordabilityCalculator: false,
    hideGuide: false,
  })
  const [guideStatusDone, setGuideStatusDone] = useState(false)
  const [currentLOProfile, setCurrentLOProfile] = useState(null)
  const [featuresList, setFeaturesList] = useState([
    // {
    //     id: 1,
    //     key: 'addLender',
    //     status: 0,
    //     name: 'Add a lender',
    //     operateText: 'Get started',
    //     link: 'management?select=lender'
    // },
    // {
    //     id: 2,
    //     key: 'addBorrower',
    //     status: 0,
    //     name: 'Add a borrower',
    //     operateText: 'Get started',
    //     link: 'borrowers'
    // },
    {
      id: 3,
      key: 'completeProfile',
      status: 0,
      name: 'Complete your profile ',
      operateText: 'Get started',
      link: 'profile'
    },
    // {
    //     id: 4,
    //     key: 'exploreLoans',
    //     status: 0,
    //     name: 'Explore your loans',
    //     operateText: 'Get started',
    //     link: 'loans'
    // },
    {
      id: 5,
      key: 'tryPricingEngine',
      status: 0,
      name: 'Try Pricing Engine',
      operateText: 'Try now',
      link: 'purchasecalculator'
    },
    {
      id: 6,
      key: 'tryMortgageGPT',
      status: 0,
      name: 'Try Guideline',
      operateText: 'Try now',
      link: 'mortgagegpt'
    },
    {
      id: 7,
      key: 'tryAffordabilityCalculator',
      status: 0,
      name: 'Try Affordability Calculator',
      operateText: 'Try now',
      link: 'affordability'
    },
    {
      id: 8,
      key: 'setUpWebsite',
      status: 0,
      name: 'Set up your Website',
      operateText: 'Get started',
      link: 'landingpage'
    }
  ])
  const swiperRef = useRef(null);
  const [currentIndex, updateCurrentIndex] = useState(0);
  const [link, setLink] = useState('');
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const isMobile = window.innerWidth < 576;
  const history = useHistory();
  const ishardmoneyloancustomer = isHardMoneyLoanCustomer()
  useEffect(() => {
   
    // getInfo()
    console.log(props);
    getLoInfo()
    reload()
    getCustomerLenders()
    getProfile()
    if (checkAccess(UR_LoanProcessor)) {
      setIsLP(true)
    } else {
      setIsLP(false)
    }
    getLoans()
    console.log(ishardmoneyloancustomer)
  }, []);
  useEffect(() => {
    filterLoan(selectTime)
  }, [allLoan])
  useEffect(() => {
    if (guideStatusDone) {
      updateProfile()
      if (link !== '') {
        history.push(link)
      }
      updateCurrentIndex(0)
      setIsEnd(false)
    }
  }, [guideStatusDone, link, setupGuideStatus.hideGuide])
  useEffect(() => {
    setFeaturesList(prevList => {
      return prevList.map(item => {
        if (item.id === 3 && completeProfile()) {
          return { ...item, status: 1 }
        }
        if (item.id === 8 && isPublish()) {
          return { ...item, status: 1 }
        }
        return item
      })
    })
  }, [publishModule])
  const params = {
    on: { slideChange: index => { updateCurrentIndex(index.realIndex); setIsEnd(index.isEnd) }, },
    slidesPerView: 4,
    centeredSlides: false,
    spaceBetween: 12,
    breakpoints: {
      1400: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 1,
      },
      300: {
        slidesPerView: 1,
      }
    }
  }
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  }

  const divs = [];
  for (let i = 0; i < 100; i++) {
    divs.push(<div key={i}>This is div {i + 1}</div>);
  }
  const filterLoan = (type) => {
    const currentDate = new Date()
    const selectDate = new Date()
    if (type === 'week') {
      selectDate.setDate(selectDate.getDate() - 7)
    } else {
      selectDate.setDate(selectDate.getDate() - 30)
    }
    console.log(selectDate)
    const sortDate = (a, b) => {
      const dateA = new Date(a.started)
      const dateB = new Date(b.started)
      return dateB - dateA
    }
    const filteredData = allLoan.sort(sortDate).filter(item => {
      const startedDate = new Date(item.started)
      return startedDate >= selectDate && startedDate <= currentDate
    })
    setLoans(filteredData)
  }
  const getPurpose = (purpose, hascontract, unknownpurpose) => {
    if (purpose === "refinance") return "Rate/Term Refinance"
    if (purpose === "cashoutrefinance") return "Cash-out Refinance"
    if (purpose === "purchase") {
      if (unknownpurpose) return "TBD"
      if (!hascontract) return "Pre-qualification"
      return "Purchase"
    }
    return "TBD"
  }
  const getIcon = (purpose) => {
    if (purpose === "Purchase" || purpose === "Pre-qualification") {
      return "/images/purchase.svg"
    } else {
      return "/images/refinance.svg"
    }
  }
  const isPublish = () => {
    return publishModule.includes(1) && publishModule.includes(2) && publishModule.includes(3);
  }
  const completeProfile = () => {
    return publishModule.includes(4);
  }
  const processLoans = (loans) => {
    let data = [];
    let borrowerMap = {};
    for (let loan of loans) {
      if (loan.email in borrowerMap) {
        if (loan.lastTouch > borrowerMap[loan.email].lastTouch) {
          borrowerMap[loan.email] = loan;
        }
      } else {
        borrowerMap[loan.email] = loan;
      }
    }
    for (let bo in borrowerMap) {
      data.push(borrowerMap[bo]);
    }
    return data;
  }
  const getLoans = () => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch("/los/loanlist", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Past": false,
        "X-LP": isLP ? true : false,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          )
          return
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
          } else {
            let docs = js.Loans
            let data = processLoans(docs)
            setFeaturesList(prevList => {
              return prevList.map(item => {
                if (item.id === 2) {
                  return { ...item, status: data.length > 0 ? 1 : 0 }
                }
                return item
              })
            })
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err)
      })
  }
  const getProfile = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/los/getprofilesetup", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(response => {
      if (!response.ok) {
        console.log(response)
      } else {
        response.json().then(js => {

          let lo = js
          setCurrentLOProfile(lo)
          let su = lo.LandingPageSetUp
          let basicInfo = {
            file: lo.Image === '' ? [] : [
              {
                url: lo.Image
              }
            ],
            firstname: lo.FirstName,
            lastname: lo.LastName,
            nmls: lo.NMLS,
            states: typeof su.States === "undefined" ? [] : su.States,
            tagsList: typeof su.TagsList === "undefined" ? [] : su.TagsList,
            popularTags: ['I speak Mandarin', 'Top 10 Loan offier', "Bay Area for 10+ years"]
          }
          let aboutInfo = {
            introduction: lo.Introduction,
            phone: lo.Phone,
            email: lo.Email,
            companyWebsite: su.CompanyWebsite === undefined ? '' : su.CompanyWebsite,
            google: su.Google === undefined ? '' : su.Google,
            yelp: su.Yelp === undefined ? '' : su.Yelp,
            linkedin: su.Linkedin === undefined ? '' : su.Linkedin,
            twitter: su.Twitter === undefined ? "" : su.Twitter,
          }
          if (Object.keys(basicInfo).length !== 0 && basicInfo.file.length > 0 && basicInfo.firstname !== '' && basicInfo.lastname !== '' && !publishModule.includes(1)) {
            setPublishModule(prev => [...prev, 1])
          }
          if (Object.keys(aboutInfo).length !== 0 && aboutInfo.introduction !== '' && aboutInfo.phone !== '' && aboutInfo.email !== '' && !publishModule.includes(2)) {
            setPublishModule(prev => [...prev, 2])
          }
          if (Object.keys(aboutInfo).length !== 0 && lo.Image !== '' && basicInfo.firstname !== '' && basicInfo.lastname !== '' && aboutInfo.phone !== '' && aboutInfo.email !== '' && basicInfo.nmls !== '' && !publishModule.includes(4)) {
            setPublishModule(prev => [...prev, 4])
          }
          if (su.SetupGuideStatus && typeof su.SetupGuideStatus !== "underfined") {
            setSetupGuideStatus(su.SetupGuideStatus)
            setFeaturesList(prevList => {
              return prevList.map(item => {
                const { key } = item
                if (su.SetupGuideStatus.hasOwnProperty(key)) {
                  return { ...item, status: su.SetupGuideStatus[key] ? 1 : 0 }
                }
                return item
              })
            })
          }
        })
      }
    }).catch(error => {
      console.log("Revalidate catch error " + error)
    })
  }
  const updateProfile = () => {
    let tosend = { ...currentLOProfile }
    tosend.LandingPageSetUp.SetupGuideStatus = setupGuideStatus

    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/updateprofilesetup', {
      method: 'POST',
      body: JSON.stringify(tosend),
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      },
    }).then(
      response => {
        if (response.status !== 200) {
          console('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        getProfile()
      }
    ).catch(function (err) {
      console.log('Fetch Error :-S', err);
    });
  }
  const reload = () => {
    setLoading(true)
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/loansforloanofficer', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          setLoading(false)
          return;
        }
        response.json().then(js => {

          if (js.Status !== "OK") {
            setLoading(false)
            console.log("Error: " + js.Text)
          } else {
            if (js.Borrowers) {
              let docs = js.Borrowers.map(s => {
                return {
                  rand: Math.random(), id: s.ID, loan_id: s.LoanID, firstname: s.FirstName,
                  lastname: s.LastName,
                  email: s.Email, status: s.Status, started: s.Started, phone: s.ContactPhone,
                  los_id: s.LoanOfficerID,
                  purpose: getPurpose(s.LoanPurpose, s.HasContract, s.UnknownPurpose),
                  hascontract: s.HasContract,
                  unknownpurpose: s.UnknownPurpose,
                  address: s.PropertyAddressStreet,
                  county: s.PropertyAddressCounty,
                  state: s.PropertyAddressState,
                }
              })
              let rateSelection = docs.filter(item => item.status === 'productselected')
              setRateSelection(rateSelection)
              let borrowerTasks = docs.filter(item => item.status === 'loansubmitted')
              setBorrowerTasks(borrowerTasks)
              setAllLoan(docs)
            }
            setLoading(false)
          }
        });
      }
    )
      .catch(function (err) {
        setLoading(false)
        console.log('Fetch Error :-S', err);
      });
  }
  const getCustomerLenders = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/data/getcustomerlenders", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          )
          return
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log(js)
          } else {
            if (js.Lenders !== null) {
              const hasLenders = js.Lenders.some(item => item.Active)
              if (hasLenders && !publishModule.includes(3)) {
                setPublishModule(prev => [...prev, 3])
              }
              setFeaturesList(prevList => {
                return prevList.map(item => {
                  if (item.id === 1) {
                    return { ...item, status: hasLenders ? 1 : 0 }
                  }
                  return item
                })
              })
            }
          }
        })
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err)
      })
  }
  const getLoInfo = () => {
    let token = window.sessionStorage.getItem("ZeitroA");
    fetch("/los/getloanofficernotificationsandprofile", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(response => {
      let d = new Date()
      //console.log(JSON.stringify(response))
      if (!response.ok) {
        console.log("Auth fetch error, at: " + d.toLocaleString())
        // sessionStorage.removeItem("ZeitroA")
      } else {
        response.json().then(js => {
          setCompanyName(js.CompanyName)
          setProfile(js.Profile)
          setNotifications(js.Notifications)
          setPlotsData(js.Performance)
          smartlookClient.init('abc8514fc7e47edf16ab4fd8e6bc6b653296e642')
          smartlookClient.record({
            emails: true,
            forms: true,
            numbers: true,
            ips: true,
          })
          smartlookClient.identify(js.Profile.ID, {
            name: js.Profile.FirstName + " " + js.Profile.LastName,
            email: js.Profile.Email,
          })
        })
      }
    }).catch(error => {
      let d = new Date()
      console.log("Revalidate catch error " + error + ", at: " + d.toLocaleString())
    })

  }

  if (profile === null) {
    return <div className='d-flex flex-column align-items-center justify-content-center vh-100 px-5'>
      <Skeleton active className='mb-5' />
      <Skeleton active className='mb-5' />
      <Skeleton active />
    </div>
  }

  const handleTime = (value) => {
    setSelectTime(value)
    filterLoan(value)
  }
  const switchLoan = (loanid) => {
    sessionStorage.setItem("edit", "false")
    console.log(sessionStorage.getItem("edit"))
    sessionStorage.setItem("state", "{}");
    sessionStorage.setItem("originalstate", "{}")
    sessionStorage.setItem("borrowermenu", "loanSummary");
    history.push("/app/borrower/" + loanid)
  }
  const getLoanList = () => {
    if (loans.length === 0) {
      return (
        <div className='noApplication'>
          <div>
            <div style={{ fontSize: 16, color: '#101820', fontWeight: 600, lineHeight: '20px', marginBottom: 8 }}>No application added</div>
            <div style={{ fontSize: 14, color: '#6E6E70', lineHeight: '20px' }}>Add borrower’s information to explore recent loans.</div>
          </div>
          <img src='/images/no-loan-icon.svg' />
        </div>
      )
    } else {
      return (
        <div className='loan-list'>
          {
            loans.map(item => (
              <div className='loan-item' key={item.loan_id}>
                <div className='loan-icon'><img src={getIcon(item.purpose)} /></div>
                <div className='loan-info'>
                  <div className='loan-info-top'><div><span style={{ fontWeight: 600 }}>{item.purpose}</span> | <span style={{ fontWeight: 400 }}>{`${item.firstname} ${item.lastname}`}</span></div><div className='loans-view-btn' onClick={() => switchLoan(item.loan_id)}>View</div></div>
                  <div className='loan-info-bottom'><div>{item.email} {item.phone !== '' && <span>·</span>} {item.phone}</div><div style={{ color: '#6E6E70' }}>{dayjs(item.started).format('MM/DD/YYYY')}</div></div>
                </div>
              </div>
            ))
          }
        </div>
      )
    }
  }
  const panelStyle = {
    // border: 'none',
  }
  const handleGuide = () => {
    setGuideStatusDone(true)
    setSetupGuideStatus(prevState => ({
      ...prevState,
      hideGuide: !prevState.hideGuide
    }))
    setLink('')
  }
  const handleFeatures = (item) => {

    smartlookClient.track("Guide_ClicksinGeneral", {
      label: "General Guide Clicks",
      category: "Clicks",
    })

    switch (item.link) {
      case 'profile':
        smartlookClient.track("Guide_Clicks_Profile", {
          label: "profile clicks",
          category: "Clicks",
        })
        break
      case 'purchasecalculator':
        smartlookClient.track("Guide_Clicks_PriceEngine", {
          label: "price engine clicks",
          category: "Clicks",
        })
        break
      case 'mortgagegpt':
        smartlookClient.track("Guide_Clicks_Guideline", {
          label: "guideline clicks",
          category: "Clicks",
        })
        break
      case 'affordability':
        smartlookClient.track("Guide_Clicks_Affordability", {
          label: "affordability clicks",
          category: "Clicks",
        })
        break
      case 'landingpage':
        smartlookClient.track("Guide_Clicks_LandingPage", {
          label: "landing page clicks",
          category: "Clicks",
        })
        break
      default:
        break
    }
    setGuideStatusDone(true)
    if ([4, 5, 6, 7].includes(item.id) && !setupGuideStatus[item.key]) {
      setSetupGuideStatus(prevState => ({
        ...prevState,
        [item.key]: true
      }))
    }
    setLink(item.link)
  }
  return (
    <div className='welcomPageWrap'>
      <Spin spinning={loading}>
        <div className='welcomePageDashboard'>
          <div style={{ paddingLeft: 15 }} className='welcomeMessage'>Hi,<span style={{ marginLeft: 8 }}>{profile !== null ? profile.FirstName : ""}</span></div>
          {!ishardmoneyloancustomer && <div className='systemFeatures'>
            <div className='systemFeatures-top'>
              <div className='systemFeatures-top-text'>
                <div>Explore our system features</div>
                <div>Complete setting up your account and explore our features</div>
              </div>
              <div className='systemFeatures-top-btn' onClick={handleGuide}>{!setupGuideStatus.hideGuide ? 'Hide' : 'Display'} setup guide</div>
            </div>
            {
              !setupGuideStatus.hideGuide && (
                <div className='systemFeatures-list'>
                  <Swiper ref={swiperRef} {...params} style={{ marginTop: 20 }}>
                    {
                      featuresList.map((item, index) => (
                        <div className='systemFeatures-item' key={index}>
                          <div className='features-item-left'>
                            <div>
                              <div className='status text-wrap'>{item.status === 0 ? <span className='status-mark incomplete'></span> : <img className='status-mark complete' src='/images/navSignFinished.png' />}<span className={`status-text ${item.status === 0 ? 'incomplete' : 'complete'}`}>{item.status === 0 ? 'Incomplete' : 'Complete'}</span></div>
                              <div className='features-name'>{item.name}</div>
                            </div>
                            <div className='features-operate' onClick={() => handleFeatures(item)}>{item.operateText}</div>
                          </div>
                          <div className='features-item-right'>
                            <img className='features-img' src={`/images/systemFeatures/features-${index + 1}.svg`} />
                          </div>
                        </div>
                      ))
                    }
                  </Swiper>
                  {currentIndex !== 0 && <div className='prev-btn-box swiper-btn'><img src='/images/systemFeatures/swiper-btn.svg' onClick={goPrev} /></div>}
                  {!isEnd && <div className='next-btn-box swiper-btn'><img src='/images/systemFeatures/swiper-btn.svg' onClick={goNext} /></div>}
                </div>
              )
            }
          </div>}
          <Row>
            <Col lg={8}>
              <div className='recentLoans'>
                <span style={{ fontSize: '18px', color: '#000', fontWeight: 500, lineHeight: 'normal' }}>My recent loans</span>
                <Select
                  suffixIcon={<img src='/images/select-arrow.svg' />}
                  value={selectTime}
                  style={{
                    width: 130,
                    height: 38,
                    color: '#000',
                    fontSize: 14
                  }}
                  onChange={handleTime}
                  options={[
                    {
                      value: 'month',
                      label: 'Last 30 days',
                    },
                    {
                      value: 'week',
                      label: 'Last 7 days',
                    }
                  ]}
                />
              </div>
              {getLoanList()}
            </Col>
            <Col lg={4}>
              <div className='overview'>
                <div className='overview-title'>Overview</div>
                <div className='TCD-wrap'>
                  <div className='rate-selection'>
                    <Collapse
                      ghost
                      className='dashboard-collapse inside processing-collapse'
                      collapsible="icon"
                      expandIconPosition='end'
                      expandIcon={({ isActive }) => isActive && rateSelection.length > 0 ? <img className='down' src='/images/systemFeatures/swiper-btn.svg' /> : !isActive && rateSelection.length > 0 ? <img className='up' src='/images/systemFeatures/swiper-btn.svg' /> : null}
                      items={[
                        {
                          key: '1',
                          label: (
                            <div className='dashboard-card-header'>
                              <div className='header-title'>{ishardmoneyloancustomer ? 'Target Closing date' : 'Rate selection'}</div>
                              <div><span className='num'>{rateSelection.length}</span><span style={{ color: ishardmoneyloancustomer ? '#08979C' : '' }} className='unit'>{ishardmoneyloancustomer ? 'processing' : 'Borrowers'}</span></div>
                              <div className='sub-title'>{ishardmoneyloancustomer ? 'are running late' : 'need rate recommendations'}</div>
                            </div>
                          ),
                          children: (
                            <div className='dashboard-card-content'>
                              {
                                rateSelection.map(item => (
                                  <div className='content-item' key={item.LoanID}><span className='name'>{`${item.firstname} ${item.lastname}`}</span></div>
                                ))
                              }
                            </div>
                          ),
                          style: panelStyle,
                        },
                      ]}
                    />
                  </div>
                  {
                    ishardmoneyloancustomer && (
                      <>
                        <div className='divider'></div>
                        <div className='borrower-tasks'>
                          <Collapse
                            ghost
                            className='dashboard-collapse inside underwriting-collapse'
                            collapsible="icon"
                            expandIconPosition='end'
                            expandIcon={({ isActive }) => isActive && borrowerTasks.length > 0 ? <img className='down' src='/images/systemFeatures/swiper-btn.svg' /> : !isActive && borrowerTasks.length > 0 ? <img className='up' src='/images/systemFeatures/swiper-btn.svg' /> : null}
                            items={[
                              {
                                key: '1',
                                label: (
                                  <div className='dashboard-card-header'>
                                    <div><span className='num'>{borrowerTasks.length}</span><span style={{ color: '#325CEB' }} className='unit'>underwriting</span></div>
                                    <div className='sub-title'>are running late</div>
                                  </div>
                                ),
                                children: (
                                  <div className='dashboard-card-content'>
                                    {
                                      borrowerTasks.map(item => (
                                        <div className='content-item' key={item.LoanID}><span className='name'>{`${item.firstname} ${item.lastname}`}</span></div>
                                      ))
                                    }
                                  </div>
                                ),
                                style: panelStyle,
                              },
                            ]}
                          />
                        </div>
                      </>
                    )
                  }
                </div>
                {
                  ishardmoneyloancustomer && (
                    <div style={{ marginBottom: 18 }} className='borrower-tasks'>
                      <Collapse
                        ghost
                        className='dashboard-collapse'
                        collapsible="icon"
                        expandIconPosition='end'
                        expandIcon={({ isActive }) => isActive && borrowerTasks.length > 0 ? <img className='down' src='/images/systemFeatures/swiper-btn.svg' /> : !isActive && borrowerTasks.length > 0 ? <img className='up' src='/images/systemFeatures/swiper-btn.svg' /> : null}
                        items={[
                          {
                            key: '1',
                            label: (
                              <div className='dashboard-card-header'>
                                <div className='header-title'>To Do</div>
                                <div><span className='num'>{borrowerTasks.length}</span><span className='unit'>Comments</span></div>
                                <div className='sub-title'>are addressed to me</div>
                              </div>
                            ),
                            children: (
                              <div className='dashboard-card-content'>
                                {
                                  borrowerTasks.map(item => (
                                    <div className='content-item' key={item.LoanID}><span className='name'>{`${item.firstname} ${item.lastname}`}</span></div>
                                  ))
                                }
                              </div>
                            ),
                            style: panelStyle,
                          },
                        ]}
                      />
                    </div>
                  )
                }
                <div className='borrower-tasks'>
                  <Collapse
                    ghost
                    className='dashboard-collapse'
                    collapsible="icon"
                    expandIconPosition='end'
                    expandIcon={({ isActive }) => isActive && borrowerTasks.length > 0 ? <img className='down' src='/images/systemFeatures/swiper-btn.svg' /> : !isActive && borrowerTasks.length > 0 ? <img className='up' src='/images/systemFeatures/swiper-btn.svg' /> : null}
                    items={[
                      {
                        key: '1',
                        label: (
                          <div className='dashboard-card-header'>
                            <div className='header-title'>Borrower task progress</div>
                            <div><span className='num'>{borrowerTasks.length}</span><span className='unit'>Borrowers</span></div>
                            <div className='sub-title'>with pending tasks</div>
                          </div>
                        ),
                        children: (
                          <div className='dashboard-card-content'>
                            {
                              borrowerTasks.map(item => (
                                <div className='content-item' key={item.LoanID}><span className='name'>{`${item.firstname} ${item.lastname}`}</span></div>
                              ))
                            }
                          </div>
                        ),
                        style: panelStyle,
                      },
                    ]}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
}

export default WelcomePage

