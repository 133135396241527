import React, { useState, useEffect, useRef } from 'react'
import { useImmer } from "use-immer"
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Popover } from 'antd'
import './index.css'
import { SearchOutlined, CheckOutlined, FormOutlined } from '@ant-design/icons'
import "bootstrap/dist/css/bootstrap.min.css"
import { capitalize, divideStringByCapitalLetter, getLoInfo, getUserId, splitByCapitalLetter } from '../../Common'
import { checkAccess } from '../../Auth'
import { UR_Owner } from '../../State'
import { useSelector } from 'react-redux'

function SendSuccessComponent(props) {
    const { closeModal } = props

    return (

        <div style={{ textAlign: "center" }}>
            <div>
                <img src='/images/checkEmail2.png'></img>
            </div>
            <div style={{ marginTop: "20px", fontSize: '36px', fontWeight: 600, color: "#222222" }}>
                Invitation sent successfully

            </div>
            <div style={{ marginTop: "20px", fontSize: '16px', color: "#6E6E70" }}>
                We will let you know once the user join you on Zeitro.

            </div>
            <div style={{ marginTop: "50px" }}>

                <Button style={{ height: '44px', width: "200px" }} onClick={() => {
                    closeModal(1)



                }}>Done</Button>
                <Button onClick={() => {
                    closeModal(2)



                }} type='primary' style={{ marginLeft: "20px", height: '44px', minWidth: "200px" }}>Invite next user</Button>
            </div>

        </div>

    )
}



export default function UserManagement(props) {
    const { showUser, toLoans } = props

    const [form] = Form.useForm()
    const [support] = Form.useForm()
    const [dataSource, setDataSource] = useImmer([])
    const [isModalOpen, setIsModalOpen] = useImmer(false)
    const [addLoading, setAddLoading] = useImmer(false)
    const [isDisableModalOpen, setIsDisableModalOpen] = useImmer(false)
    const [disablledLoading, setDisablledLoading] = useImmer(false)
    const [isTooltipModalOpen, setIsTooltipModalOpen] = useImmer(false)
    const [currentUser, setCurrentUser] = useImmer({})
    const [loading, setLoading] = useImmer(false)
    const [searchedText, setSearchedText] = useImmer('')
    const [showRoleList, setShowRoleList] = useImmer(false)
    const [inviteOrEdit, setInviteOrEdit] = useImmer(true)
    const [isSameNMLS, setIsSameNMLS] = useImmer(false)
    const [isSameEmail, setIsSameEmail] = useImmer(false)
    const [sendSuccess, setSendSuccess] = useImmer(false)
    const [isConfirm, setSsConfirm] = useImmer(false)
    const [currentId, setCurrentId] = useImmer('')
    const [currentData, setCurrentData] = useImmer({})
    const [currentInfo, setCurrentInfo] = useState({})
    const [isSupportModalOpen, setSupportModalOpen] = useState(false)
    const [isUpgradeLOSuccessModalOpen, setIsUpgradeLOSuccessModalOpen] = useState(false)
    const [messageReceivedModalOpen, setMessageReceivedModalOpen] = useState(false)

    const { TextArea } = Input
    const subscription = useSelector(state => {

        return state.subscriptionReducer.currentInfo
    })
    useEffect(async () => {
        setIsModalOpen(showUser)
        getList()
        // setLoading(true)

        // let token = sessionStorage.getItem("ZeitroA")

        // const response = await fetch('/los/getcurrentsubscription', {
        //     method: 'get',
        //     headers: {
        //         Authorization: "Bearer " + token,
        //         Cache: "no-cache"
        //     }
        // })
        const { customerID, loanOfficers, plan, stripeCustomer, recurringInterval } = subscription

        setCurrentInfo({
            customerID,
            loanOfficers,
            plan,
            stripeCustomer,
            recurringInterval
        })
        // setLoading(false)

    }, [])

    const RoleDataSource = [
        {
            title: (<div className='UserSource'>Assign loans</div>),
            Executive: 'Assign loans to LOs & Processors',
            Admin: "Assign loans to LOs & Processors ",
            Manager: "Assign loans to LOs & Processors",
            Loan: 'Assign loans to Processors',
            Processor: "-"

        },
        {
            title: (<div className='UserSource'>View and edit loans</div>),
            Executive: 'Full access to all loans in company',
            Admin: "Full access to all loans in company",
            Manager: "Limited access* to all loans in company",
            Loan: 'Access to loans assigned to the user',
            Processor: "Access to loans assigned to the user"

        },
        {
            title: (<div className='UserSource'> Create and view borrowers</div>),
            Executive: 'Create and view all borrowers in company',
            Admin: "Create and view all borrowers in company",
            Manager: "Create and view all borrowers in company",
            Loan: `Create and view the user's borrowers`,
            Processor: "View the user's borrowers"

        },
        {
            title: (<div className='UserSource'>Use Guideline GPT</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: true

        },
        {
            title: (<div className='UserSource'>Update my site</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Use pricing engine</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Set up personal landing page</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Turn on/off automatic prequal letter generation for borrower application</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Turn on/off soft credit pull feature for borrower application</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View own usage of features with usage based billing</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: true,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View all users' usage of features with usage based billing</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View employee accounts with partial information (name, NMLS, status)</div>),
            Executive: true,
            Admin: true,
            Manager: true,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View all employee accounts and related account info</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View and update the lender setup</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Create and view call reports</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View and update communication set-up</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Set up credit report</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Set up public site integration</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Adjust company customization settings</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Create/disable employee accounts</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Manage employee's access permissions (excluding creating Admin roles)</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>View and manage disabled accounts</div>),
            Executive: true,
            Admin: true,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Creating General Admin/Executive Admin</div>),
            Executive: true,
            Admin: false,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Update subscription payment methods</div>),
            Executive: true,
            Admin: false,
            Manager: false,
            Loan: false,
            Processor: false

        },
        {
            title: (<div className='UserSource'>Cancel all Zeitro accounts</div>),
            Executive: true,
            Admin: false,
            Manager: false,
            Loan: false,
            Processor: false

        },
    ]
    const RoleColumns = [
        {
            title: '',
            dataIndex: 'title',
            width: 80,
            ellipsis: true,
            key: 'title',
        },
        {
            title: (<>
                <div className='tableHeader_one'>Owner</div>
                <div className='tableHeader_two'>Paid seat</div>
            </>
            ),
            dataIndex: 'Executive',
            align: 'center',
            key: 'Executive',
            width: 150,
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#6E6E70" }}>{text}</span> : text ? <CheckOutlined style={{ color: "#1F2890", fontSize: '18px', fontWeight: '600' }} /> : <></>}</>,

        },
        // {
        //     align: 'center',
        //     title: (<>
        //         <div className='tableHeader_one'>Admin</div>
        //         <div className='tableHeader_two'>Paid seat</div>
        //     </>
        //     ),
        //     width: 150,
        //     dataIndex: 'Admin',
        //     key: 'Admin',
        //     render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#6E6E70" }}>{text}</span> : text ? <CheckOutlined style={{ color: "#1F2890", fontSize: '18px', fontWeight: '600' }} /> : <></>}</>,

        // },
        {
            title: (<>
                <div className='tableHeader_one'>Manager </div>
                <div className='tableHeader_two'>Paid seat</div>
            </>
            ),
            align: 'center',
            width: 150,
            dataIndex: 'Manager',
            key: 'Manager',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#6E6E70" }}>{text}</span> : text ? <CheckOutlined style={{ color: "#1F2890", fontSize: '18px', fontWeight: '600' }} /> : <></>}</>,

        },
        {
            title: (<>
                <div className='tableHeader_one'>Loan Officer</div>
                <div className='tableHeader_two'>Paid seat</div>
            </>
            ),
            align: 'center',
            width: 150,
            dataIndex: 'Loan',
            key: 'Loan',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#6E6E70" }}>{text}</span> : text ? <CheckOutlined style={{ color: "#1F2890", fontSize: '18px', fontWeight: '600' }} /> : <></>}</>,

        },
        {
            title: (<>
                <div className='tableHeader_one'>Processor</div>
                <div className='tableHeader_two'>Free</div>
            </>
            ),
            align: 'center',
            width: 150,
            dataIndex: 'Processor',
            key: 'Processor',
            render: (text) => <>{typeof text === 'string' ? <span style={{ color: "#6E6E70" }}>{text}</span> : text ? <CheckOutlined style={{ color: "#1F2890", fontSize: '18px', fontWeight: '600' }} /> : <></>}</>,

        },

    ]
    let columns = [
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
            fixed: 'left',
            width: 120,
            render: (text) => <>{text}</>,
            filteredValue: [searchedText],
            onFilter: (value, record) => {
                return (
                    String(record.Email).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.FullName).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.Nmls).toLowerCase().includes(value.toLowerCase())

                )
            },
            className: "loansFixedColumn",
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.Email.localeCompare(b.Email),
        },
        {
            title: 'Full Name',
            dataIndex: 'FullName',
            key: 'FullName',
            width: 120,
            fixed: 'left',
            sorter: (a, b) => {
                if (a.Email < b.Email) {
                    return -1
                }
                if (a.Email > b.Email) {
                    return 1
                }
                return 0
            },
            render: (text) => <>{text}</>,
            className: "loansFixedColumn"
        },
        {
            title: 'Role',
            dataIndex: 'Roles',
            key: 'Roles',
            width: 100,
            render: (text) => <div title={text?.join(',')} style={{
                width: "150px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }}>{text?.map(splitByCapitalLetter).join(',')}</div>,
            className: "loansFixedColumn"
        },
        {
            title: 'NMLS #',
            dataIndex: 'Nmls',
            key: 'Nmls',
            width: 120,
            render: (text) => <>{text}</>,
            sorter: (a, b) => {
                if (a.Nmls < b.Nmls) {
                    return -1
                }
                if (a.Nmls > b.Nmls) {
                    return 1
                }
                return 0
            },
            className: "loansFixedColumn",
        },
        {
            title: 'Account Tier',
            dataIndex: 'AccountTier',
            width: 120,
            key: 'AccountTier',
            render: (text) => <>{capitalize(text)}</>,
            className: "loansFixedColumn",
            sorter: (a, b) => a.AccountTier.localeCompare(b.AccountTier),
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: "loansFixedColumn",
            width: 120,
            render: (text, _) => <>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', width: '100%' }} className='Status'>
                    {getStatus(text)==undefined?<div style={{width:60}}></div>:getStatus(text)}
                    {

                        _.Status === 'disabled' ? <>
                            <div></div>
                            <div></div></> : <><FormOutlined style={{ fontSize: '18px' }} onClick={() => {
                                setSendSuccess(false)
                                const data = {
                                    ...Object.fromEntries(
                                        Object.entries(_).map(([key, value]) => [key.toLowerCase(), value])
                                    ),
                                    roles: _.Roles,
                                }

                                setCurrentId(_.ID)
                                setCurrentData(data)
                                form.setFieldsValue(data)
                                setIsModalOpen(true)
                                setIsSameNMLS(false)
                                setIsSameEmail(false)
                                setInviteOrEdit(false)

                            }} />



                            <Popover
                                overlayClassName='Popover'
                                style={{ marginLeft: '10px', }}
                                content={<a onClick={() => {
                                    setIsDisableModalOpen(true)
                                    setCurrentUser(_)
                                }}>
                                    <div >Disable</div>


                                </a>}
                                placement="bottomLeft"
                                trigger="click"

                            >
                                {_.Roles.includes('Owner') ? <div style={{ minWidth: 15 }}></div> : <div style={{ fontSize: "18px", cursor: "pointer" }}>···</div>}
                            </Popover>


                        </>

                    }

                </div>


            </>,
        },
    ]
    const getStatus = (item) => {
        console.log(item, '636363')
        const statusDom = {
            active: <Tag color="success">Active</Tag>,
            disabled: <Tag color="default">Disabled</Tag>,
            invited: <Tag color="warning">Invited</Tag>,
          
        }
        return statusDom[item]


    }

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }

    const recurringInterval = {
        free: '',
        monthly: "This user will be removed from your billing cycle.",
        annual: "This user will be removed from your billing cycle, and you will be receiving a prorated refund for the remaining months on this user's subscription. "
    }
    const Tooltip = {
        free: 'You will no longer be charged for this user. ',
        monthly: "You will no longer be charged for this user. You will receive a prorated refund for the remaining months of your annual billing cycle for this user. ",
        annual: "You will no longer be charged for this user. You will receive a prorated refund for the remaining months of your annual billing cycle for this user."
    }
    return (
        <>
            <div className='ml-4'>
                <div className='text-left mb-3'>
                    <div className='User_title mt-4 mr-5'>User  Management
                        <Tag color='#D6E2FB' style={{ fontSize: "16px", color: '#1F3988', height: '27px', lineHeight: '24px', marginLeft: '10px', textAlign: "center" }}>{!currentInfo.plan ? 'Starter' : capitalize(currentInfo.plan)}</Tag>
                    </div>
                    <div className='title-5'>Invite your team members to explore Zeitro</div>
                </div>
                <div className='User_search'>
                    <Button style={{ width: '160px', height: "44px" }} type='primary' onClick={() => {

                        setIsModalOpen(true)
                        setInviteOrEdit(true)
                        setSendSuccess(false)
                        setCurrentData({})
                        form.resetFields()
                    }}>Add user</Button>

                    <div style={{ width: '50%', marginLeft: "10px" }} className='user_Input'>
                        <Input
                            addonBefore={<SearchOutlined />}
                            placeholder="Search by email, name or nmls"



                            onChange={(e) => {
                                setSearchedText(e.target.value)

                            }}

                        />

                    </div>
                </div>
                <div>
                    <Table
                        loading={loading}
                        className="allUsersTable"
                        columns={columns}
                        dataSource={dataSource}
                        showSorterTooltip={false}
                        scroll={{
                            x: true
                        }}
                    />
                </div>
                <Modal
                    wrapClassName='modal_warp'
                    classNames={{
                        content: 'modal_content',
                    }} width={1000} open={showRoleList} onCancel={() => {
                        setShowRoleList(false)
                    }
                    } onOk={() => {
                        setShowRoleList(false)

                    }}>
                    <div className='role_modal' id='role_modal'>
                        <div className='User_modal_title'>Access Permission for Role</div>
                        <div style={{ fontSize: "16px", color: "#6E6E70", marginTop: "10px", textAlign: "center" }}>Zeitro offers five user roles, each inheriting all permissions from lower roles.  </div>
                        {/* <div style={{ fontSize: "18px", color: "#325CEB", marginTop: "10px", textAlign: "center", cursor: 'pointer' }}
                            onClick={() => {
                                const targetDiv = document.getElementById('role_modal');
                                targetDiv.scrollTop = targetDiv.scrollHeight;

                            }}
                        >View Table for Full Details</div> */}

                        {/* <div style={{ marginTop: "10px", marginBottom: 10, textAlign: "center" }}>
                            <img src='/images/permissions-table.svg' />
                        </div> */}
                        <div style={{ textAlign: 'center', color: "#6E6E70" }}


                        >The table below summarizes the access permissions for each role.</div>
                        <Table
                            columns={RoleColumns}
                            dataSource={RoleDataSource}
                            className="allRolesTable"
                            showSorterTooltip={false}
                            pagination={false}
                            scroll={{
                                x: true,
                                y: 400
                            }}
                        ></Table>
                        {/* <div style={{ fontSize: "18px", color: "#325CEB", marginTop: "10px", textAlign: "center", cursor: 'pointer' }}
                            onClick={() => {
                                const targetDiv = document.getElementById('role_modal');
                                targetDiv.scrollTop = 0;

                            }}
                        >Back to top</div> */}
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>For more questions, please <Button type='link' onClick={() => setSupportModalOpen(true)}>contact Zeitro.</Button></div>
                    </div>
                </Modal>
                <Modal

                    wrapClassName='modal_warp'
                    classNames={{
                        content: 'modal_content',
                    }} width={800} style={{ top: 0 }} open={isModalOpen} footer={null} keyboard maskClosable onCancel={() => { setIsModalOpen(false) }}>
                    {

                        sendSuccess ? <div className='User_modal'> <SendSuccessComponent closeModal={(e) => {
                            if (e === 1) {
                                form.resetFields()
                                setIsModalOpen(false)
                            } else {
                                form.resetFields()
                                setSendSuccess(false)

                            }


                        }}></SendSuccessComponent></div> : <div className='User_modal'>

                            <div>
                                {
                                    inviteOrEdit ?
                                        <div>
                                            <div className='User_modal_title'>Invite users on your team</div>
                                            <div style={{ fontSize: "16px", color: "#6E6E70", marginTop: "10px", textAlign: "center" }}>Send an invitation to members on your team via email.</div>
                                        </div>
                                        : <div className='User_modal_title'>Edit user's information</div>
                                }
                                <Form
                                    onFinish={onFinish}
                                    form={form}
                                    style={{ marginTop: '20px' }}
                                    layout='vertical'
                                    validateMessages={validateMessages}
                                >
                                    <Row>
                                        <Col span={11}>
                                            <Form.Item

                                                name="firstname"
                                                label="First name"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Enter first name" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11} push={2}>
                                            <Form.Item
                                                name="lastname"
                                                label="Last name"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Enter last name" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                name="email"
                                                hidden={!inviteOrEdit && currentData.roles && currentData.roles.includes(UR_Owner)}
                                                label="Email address"
                                                rules={[
                                                    {
                                                        required: true,
                                                        type: 'email',

                                                    },
                                                ]}
                                            >
                                                <Input status={isSameEmail ? 'error' : null} placeholder="Enter loan officer email address" />

                                            </Form.Item>
                                            {isSameEmail && <div style={{ color: 'red', marginTop: -20 }}>The email address is already in use. Please use a different one.</div>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} >
                                            <Form.Item
                                                hidden={currentData.roles && currentData.roles.includes(UR_Owner)}
                                                name="roles"
                                                label={<><span>Role</span><Button type='link' onClick={() => { setShowRoleList(true) }}>Access permissions for each role?</Button></>}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Select Role" options={[
                                                    {
                                                        label: "Loan officer",
                                                        value: "LoanOfficer"

                                                    },
                                                    {
                                                        label: "Account owner",
                                                        value: "Owner"

                                                    },
                                                    {
                                                        label: "Loan processor",
                                                        value: "LoanProcessor"

                                                    },
                                                    {
                                                        label: "Price engine user",
                                                        value: "PriceEngineUser"

                                                    }

                                                ]} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                name="nmls"
                                                label={<> <span>NMLS number</span><span style={{ color: "#6E6E70", marginLeft: "10px" }}>*Required for loan officer role</span></>}

                                            >
                                                <Input status={isSameNMLS ? 'error' : null} placeholder="Enter loan officer NMLS number" />
                                            </Form.Item>
                                            {isSameNMLS && <div style={{ color: 'red', marginTop: -20 }}>Each NMLS number is allowed to be associated with only one Zeitro account. Please verify the accuracy of NMLS numbers provided.</div>}

                                        </Col>
                                    </Row>

                                    {

                                        (currentInfo.plan === "enterprise") && <Row>
                                            <Col span={24} >
                                                <Form.Item
                                                    name="accounttier">
                                                    <Radio.Group>
                                                        <Radio value="free"> Free user </Radio>
                                                        <Radio value="paid"> Paid user </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            
                                        </Row>
                                    }

                                    {

                                        (!inviteOrEdit && currentInfo.plan === "business") && <Row>
                                            {currentData.accounttier === 'paid' ? <></> : <Col span={24} >
                                                <Form.Item
                                                    name="accounttier">
                                                    <Radio.Group>
                                                        <Radio value="free"> Free user </Radio>
                                                        <Popover
                                                            style={{ marginLeft: '10px', }}
                                                            content={
                                                                <div>You will start getting charged once this user creates their account.</div>
                                                            }
                                                            placement="right"
                                                            trigger="hover"

                                                        >
                                                            <Radio value="paid"> Paid user </Radio>
                                                        </Popover>

                                                    </Radio.Group>

                                                </Form.Item>
                                            </Col>
                                            }
                                        </Row>
                                    }

                                    <Button loading={isConfirm} type='primary' htmlType="submit" style={{ width: '100%', height: '44px' }}>{inviteOrEdit ? 'Send invite' : 'Confirm'}</Button>
                                </Form>
                            </div>

                        </div>

                    }


                </Modal >

                <Modal width={800} wrapClassName='modal_warp'
                    classNames={{
                        content: 'modal_content',
                        body: 'modal_body'
                    }} open={isDisableModalOpen} footer={null} cancelText='Cancel' onCancel={() => setIsDisableModalOpen(false)}>
                    <div className='User_modal'>
                        <div style={{ textAlign: 'center', fontSize: '16px' }}>

                            <div style={{ fontSize: '36px', fontWeight: 'bold', height: '60px', lineHeight: '60px' }}>
                                Disable account?
                            </div>
                            <div style={{ marginTop: '20px' }}>Are you sure you want to disable <span style={{ color: '#1F3988' }}>{currentUser.FirstName + currentUser.LastName}`s</span> account?</div>
                            <div style={{ marginTop: '20px' }} > <span style={{ fontWeight: 'bold' }}>This action is irreversible</span> and should only be done if this user is leaving your team permanently.</div>
                            <div style={{ marginTop: '20px' }}>Once disabled, the account will become inactive and no longer accessible to the user, but their loan data will be securely archived and accessible to you. You can also reassign their loans to other active users.</div>
                            <div style={{ marginTop: '20px' }}>{
                                recurringInterval[currentInfo.recurringInterval === '' ? 'free' : currentInfo.recurringInterval]

                            }</div>




                            <div style={{ marginTop: '40px' }}>
                                <Button style={{ height: "44px", width: '200px' }} onClick={() => {

                                    setIsDisableModalOpen(false)

                                }}>Cancel</Button>
                                <Button loading={disablledLoading} type='primary' style={{ marginLeft: '20px', height: "44px", width: '200px' }}
                                    onClick={async () => {
                                        setDisablledLoading(true)
                                        let token = sessionStorage.getItem("ZeitroA")
                                        const response = await fetch('/los/disablealoanofficer', {

                                            method: 'POST',
                                            headers: {
                                                Authorization: "Bearer " + token,
                                                Cache: "no-cache"
                                            },
                                            body: JSON.stringify({
                                                loanOfficerID: currentUser.ID
                                            })

                                        })
                                        console.log(response.status)
                                        const { status } = response

                                        // const a = await response.json()

                                        if (status !== 200) {
                                            setDisablledLoading(false)
                                            alert('something went wrong, please try later')
                                            return
                                        }
                                        getList()
                                        setIsDisableModalOpen(false)
                                        setIsTooltipModalOpen(true)
                                        setDisablledLoading(false)

                                    }}
                                >Confirm</Button>

                            </div>
                        </div>
                    </div>
                </Modal >
                <Modal open={isTooltipModalOpen} footer={null} onCancel={() => {


                    setIsTooltipModalOpen(false)
                }}>

                    <div style={{ textAlign: "center" }}>
                        <div style={{ marginTop: '20px' }}> <span style={{ color: '#1F3988' }}>{currentUser.FirstName + currentUser.LastName}`s</span> account has been disabled. </div>
                        <div style={{ marginTop: '20px' }}> {

                            Tooltip[currentInfo.recurringInterval === '' ? 'free' : currentInfo.recurringInterval]
                        } </div>
                        <div style={{ marginTop: '20px' }}>
                            To reassign the loan officer's loans to other active users, please go to<span style={{ fontWeight: 'bold' }}> Management {'>'} Loans.
                            </span>  </div>

                    </div>
                    <div style={{ margin: '40px auto', display: "flex", justifyContent: 'center' }}>
                        <Button style={{ height: "44px", width: '200px' }} onClick={() => {

                            toLoans()

                        }}>Reassign loans</Button>
                        <Button type='primary' style={{ marginLeft: '20px', height: "44px", width: '200px' }}
                            onClick={() => {
                                setIsTooltipModalOpen(false)

                            }}
                        >OK</Button>

                    </div>

                </Modal>

                <Modal wrapClassName='modal_warp'
                    width={800}
                    classNames={{
                        content: 'modal_content',
                        body: 'modal_body'
                    }} open={isSupportModalOpen} footer={null} onCancel={() => {
                        setSupportModalOpen(false)
                    }}>
                    <div className='User_modal' >
                        <div>
                            <div className='User_modal_title'>How can we help?</div>
                            <div className='title-5 text-center'>For inquiries regarding billing, payments, product feedback, or any issues encountered while using Zeitro, we're here to assist you promptly. </div>

                            <Form
                                onFinish={onSupportFinish}
                                form={support}
                                style={{ marginTop: '20px', }}
                                layout='vertical'
                                validateMessages={validateMessages}

                            >
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name="message"
                                            label="Message"
                                        >
                                            <TextArea placeholder="Please leave us a message..." rows={4} style={{ height: '200px' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className='title-5 text-center'>Alternatively, you could also email us at contact@zeitro.com </div>

                                <div style={{ textAlign: 'center', marginBottom: '40px', marginTop: '60px', }}>
                                    <Button style={{ width: '40%', height: '44px' }} onClick={() => {
                                        setSupportModalOpen(false)

                                    }}>Cancel</Button>
                                    <Button style={{ width: '40%', marginLeft: '10px', height: '44px' }} type='primary' htmlType="submit">Send Message</Button>

                                </div>
                            </Form>
                        </div>
                    </div>


                </Modal>
                <Modal open={messageReceivedModalOpen} footer={null} onCancel={() => {
                    setMessageReceivedModalOpen(false)
                }}>
                    <div className='text-center p-5'>
                        <div><img src='/images/emailSent.svg' /></div>
                        <div className='User_modal_title my-3'>We've received your message</div>
                        <div className='title-5 text-center'>Thank you for reaching out. </div>
                        <div className='title-5 text-center'>We will get back to you via email as soon as possible.</div>
                        <Button type='primary' size='large' className='mt-4' onClick={() => setMessageReceivedModalOpen(false)}>Done</Button>
                    </div>
                </Modal>
                <Modal open={isUpgradeLOSuccessModalOpen} footer={null} closable={false}>
                    <div className='text-center p-5'>
                        <img className='my-4' src='/images/congratulation.png' style={{ height: 80 }} />
                        <div>You have upgraded the loan officer to a Paid User Tier.</div>
                        <div>Your billing amount has been updated accordingly.</div>
                        <Button type='primary' size='large' style={{ width: 200 }} className='mt-4' onClick={() => {
                            setIsUpgradeLOSuccessModalOpen(false)
                            getList()
                        }}>OK</Button>
                    </div>
                </Modal>
            </div >

        </>
    )
    async function getList() {
        try {
            let token = sessionStorage.getItem("ZeitroA")
            setLoading(true)
            const response = await fetch('/los/loanofficerlist', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    'X-Image-Free': 'true'
                }
            })
            const { LoanOfficers } = await response.json()
            let docs = LoanOfficers.map(s => {
                return {
                    ...s,
                    FullName: s.FirstName + " " + s.LastName
                }
            }).sort((a, b) => {

                const aValue = a.Status === 'disabled'
                const bValue = b.Status === 'disabled'

                if (aValue === bValue) return 0 // 如果两者value属性都是（或都不是）'disable'，保持原有顺序

                // 将value为'disable'的元素排在后面
                return aValue ? 1 : -1

            })
            setDataSource([...docs])
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }


    }
    function onSupportFinish(val) {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/customersupporttoslack', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify({
                Message: val.message
            })
        }).then(async response => {
            if (!response.ok) {
                return
            }
        })
        setSupportModalOpen(false)
        setMessageReceivedModalOpen(true)
    }
    async function onFinish(val) {

        setSsConfirm(true)
        let token = sessionStorage.getItem("ZeitroA")
        let roles = val.roles
        if (typeof roles === 'string') {
            roles = [roles]
        }

        let tosend = {
            email: val.email.toLowerCase(),
            firstname: val.firstname,
            lastname: val.lastname,
            password: val.password,
            phone: val.phone,
            nmls: val.nmls,
            roles: roles,
            accounttier: val.accounttier,
        }
        if (inviteOrEdit) {
            try {

                const response = await fetch('/los/createloaccount', {
                    method: 'POST',
                    headers: {

                        Cache: "no-cache",
                        'Content-Type': 'application/json;charset=utf-8',
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(tosend)
                })
                if (response.ok === false) {
                    alert('Something went wrong, please try later.')
                    return
                }

                const { Text, Status } = await response.json()
                if (Status === 'Error') {
                    Text.includes('NMLS') ? setIsSameNMLS(true) : setIsSameNMLS(false)
                    Text.includes('Email already exists') ? setIsSameEmail(true) : setIsSameEmail(false)
                    setInviteOrEdit(true)

                } else {
                    form.resetFields()
                    getList()

                    setSendSuccess(true)
                }
            } catch (error) {
                console.log(error)
            }
        } else {

            try {

                const response = await fetch('/los/updatelo', {
                    method: 'POST',
                    headers: {

                        Cache: "no-cache",
                        'Content-Type': 'application/json;charset=utf-8',
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({ ...tosend, id: currentId })
                })

                if (response.status !== 200) {
                    alert('Something went wrong, please try later')
                    setAddLoading(false)
                    return
                }

                const { Text, Status } = await response.json()

                if (Status === 'Error') {
                    Text.includes('NMLS') ? setIsSameNMLS(true) : setIsSameNMLS(false)
                    Text.includes('loanofficers_email_key') ? setIsSameEmail(true) : setIsSameEmail(false)


                } else {
                    form.resetFields()
                    getList()

                    if (currentInfo.plan !== "enterprise") {

                        if (val.accounttier === 'paid') {
                            setUpgrade(val.id, token)
                        }
                        getList()
                    }
                    setIsModalOpen(false)
                }
            } catch (error) {
                console.log(error)
            }

            setAddLoading(false)
        }
        setSsConfirm(false)



    }
    //
    //合并两个数组


    async function setUpgrade() {
        const token = sessionStorage.getItem("ZeitroA")


        if (!token || !currentId) {
            console.error("Token or currentId is missing or invalid.")
            return
        }

        try {

            const response = await fetch('/los/upgradealoanofficer', {
                method: 'post',
                headers: {
                    Authorization: "Bearer " + token,
                    'Cache-Control': 'no-cache',
                },
                body: JSON.stringify({ loanOfficerID: currentId })
            })

            if (response.ok) {
                setIsUpgradeLOSuccessModalOpen(true)
            } else {

                console.error('Request failed with status ' + response.status)
            }

            // 优化3: 使用 const 声明且因未使用响应数据 a，所以这里移除该赋值操作
            // const a = await response.json();
        } catch (error) {
            // 异常处理优化: 错误处理和用户反馈
            console.error('Fetch error: ', error)
            // 可以在这里添加更多的错误处理逻辑，如用户提示等
        }
    }

}