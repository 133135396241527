import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import EmailSetup from './EmailSetup';
import SMSSetup from './SMSSetup';

const CommunicationSetup = () => {
  return (
    <Tabs mountOnEnter={true} unmountOnExit="true">
      <Tab eventKey="emailsetup" title="Email Setup">
        <EmailSetup />
      </Tab>
      <Tab eventKey="smssetup" title="SMS Setup">
        <SMSSetup />
      </Tab>
    </Tabs>
  );
}

export default CommunicationSetup;