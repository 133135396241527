import React, {  useEffect, useState } from "react";
import { Button, Input, Space, Table, Skeleton, Tabs, Form, Tag, Dropdown } from 'antd';
import * as com from '../../Common';
import { clone, cloneDeep, set} from "lodash";
import { report } from "process";
import {CaretDownOutlined} from '@ant-design/icons';

const quarterMap={
    q1: [1,2,3],
    q2: [4,5,6],
    q3: [7,8,9],
    q4: [10,11,12],
}
const notreflectedRows = [
    "AC030","AC060","AC062","AC064","AC110","AC120","AC130","AC310","AC400",
    "AC500","AC510","AC520","AC700","AC710","AC720","AC800","AC810","AC920",
    "AC930","AC940","AC1000"
]
const boldrows=[
    "AC066","AC090","AC190","AC290","AC390",
    "AC590","AC790","AC890","AC910","AC990"
]
const rowsLabel= {
    applicationdata: [
        "AC010","AC020","AC030","AC040","AC050","AC060","AC062",
        "AC064","AC065","AC066","AC070","AC080","AC090"
    ],
    closedloandata: [
        'AC100','AC110','AC120','AC130','AC190','AC200','AC210',
        'AC220','AC290','AC300','AC310','AC320','AC390','AC400',
        'AC500','AC510','AC520','AC590','AC600','AC610','AC620',
        'AC700','AC710','AC720','AC790','AC800','AC810','AC890',
        'AC900','AC910','AC920','AC930','AC940','AC990','AC1000'
    ],
    revenuedata: [
        'AC1100'
    ],
    mlodata: []
}

const closedLoanTotals = [
    "AC190","AC290","AC390","AC590","AC790","AC890","AC910","AC990"
]

const currentLoanStatuses = [
    'interviewstarted','interviewfinished', 'documentationrequested',
    'loansubmitted', 'conditionallyapproved', 'applicationwithdrawn', 'applicationdenied',
    "pendingrateselection", "readyforsubmit", "indocs", "fundsreleased"
  ]
  const manualupdateStatuses = [
    "loansubmitted", "conditionallyapproved", "applicationwithdrawn", "applicationdenied",
    "indocs","fundsreleased"
  ]

const CallReportTable = (props) => {

    const [form] = Form.useForm();

    const handleApplicationDataChange = (event, record,columnkey) => {
        let tempdata = cloneDeep(props.datatoSave);
        tempdata.data[props.selectedState].applicationdata[com.callReportFieldIndex.applicationdata[record.datapoint]][columnkey] = parseInt(event.target.value)
        props.setDatatoSave(tempdata)
        props.setCallReportDatatoSave(tempdata)
    }
    const handleClosedLoanDataChange = (event, record,columnkey) => {
        let tempdata = cloneDeep(props.datatoSave);
        tempdata.data[props.selectedState].closedloandata[com.callReportFieldIndex.closedloandata[record.datapoint]][columnkey] = parseInt(event.target.value)
        props.setDatatoSave(tempdata)
        props.setCallReportDatatoSave(tempdata)

    }
    const handleRevenueDataChange = (event, record,columnkey) => {
        let tempdata = cloneDeep(props.datatoSave);
        tempdata.data[props.selectedState].revenuedata[com.callReportFieldIndex.revenuedata[record.datapoint]][columnkey] = parseInt(event.target.value)
        props.setDatatoSave(tempdata)
        props.setCallReportDatatoSave(tempdata)

    }
    const handleMLODataChange = (event, record,columnkey) => {
        let tempdata = cloneDeep(props.datatoSave);
        tempdata.data[props.selectedState].mlodata[props.loMap[record.parameters]][columnkey] = parseInt(event.target.value)
        props.setDatatoSave(tempdata)
        props.setCallReportDatatoSave(tempdata)

    }
    const applicationColumns = [
        {
          title: 'Data Points',
          dataIndex: 'datapoint',
          key: 'datapoint',
          render: (text, record) => (
            <div className={boldrows.includes(record.datapoint)?"boldParameters":""}>{notreflectedRows.includes(record.datapoint)?`*${record.datapoint}`:` ${record.datapoint}`}</div>
        )
        },
        {
            title: 'Parameters',
            dataIndex: 'parameters',
            key: 'parameters',
            render: (text, record) => (
                <div className={boldrows.includes(record.datapoint)?"boldParameters":""}>{record.parameters}</div>
            )
          },
        {
          title: 'Brokered',
          children: [
            {
              title: 'Amount',
              dataIndex: 'brokeredamount',
              key: 'brokeredamount',
              render: (text, record) => (
                (record.datapoint!=="AC066" && record.datapoint!=="AC090" && !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                    $
                    <Input
                        style={{marginLeft:"5px"}}
                        value={com.commaize(record.brokeredamount)}
                        onChange={event => handleApplicationDataChange(event, record, "brokeredamount")}
                    />
                </div>
                ):(
                    <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{com.commaize(record.brokeredamount)}</div>
                    </div>
                )
              )
            },
            {
              title: 'Count',
              dataIndex: 'brokeredcount',
              key: 'brokeredcount',
              render: (text, record) => (
                (record.datapoint!=="AC066" && record.datapoint!=="AC090"&& !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                    $
                    <Input
                        style={{marginLeft:"5px"}}
                        value={record.brokeredcount}
                        onChange={event => handleApplicationDataChange(event, record, "brokeredcount")}
                    />
                </div>
                ):(
                    <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{record.brokeredcount}</div>
                    </div>
                )
              )
            },
            {
                title: 'Average',
                dataIndex: 'brokeredaverage',
                key: 'brokeredaverage',
                render: (text, record) => (
                    (record.datapoint!=="AC066" && record.datapoint!=="AC090"&& !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.brokeredaverage)}
                            onChange={event => handleApplicationDataChange(event, record, "brokeredaverage")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}>$</div>
                            <div>{com.commaize(record.brokeredaverage)}</div>
                        </div>
                    )
                  )
              },
          ],
        },
        {
          title: 'Third Party',
          children: [
            {
              title: 'Amount',
              dataIndex: 'thirdpartyamount',
              key: 'thirdpartyamount',
              render: (text, record) => (
                (record.datapoint!=="AC066" && record.datapoint!=="AC090"&& !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                    $
                    <Input
                        style={{marginLeft:"5px"}}
                        value={com.commaize(record.thirdpartyamount)}
                        onChange={event => handleApplicationDataChange(event, record, "thirdpartyamount")}
                    />
                </div>
                ):(
                    <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{com.commaize(record.thirdpartyamount)}</div>
                    </div>
                )
              )
            },
            {
              title: 'Count',
              dataIndex: 'thirdpartycount',
              key: 'thirdpartycount',
              render: (text, record) => (
                (record.datapoint!=="AC066" && record.datapoint!=="AC090"&& !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                    $
                    <Input
                        style={{marginLeft:"5px"}}
                        value={record.thirdpartycount}
                        onChange={event => handleApplicationDataChange(event, record, "thirdpartycount")}
                    />
                </div>
                ):(
                    <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{record.thirdpartycount}</div>
                    </div>
                )
              )
            },
            {
                title: 'Average',
                dataIndex: 'thirdpartyaverage',
                key: 'thirdpartyaverage',
                render: (text, record) => (
                    (record.datapoint!=="AC066" && record.datapoint!=="AC090"&& !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.thirdpartyaverage)}
                            onChange={event => handleApplicationDataChange(event, record, "thirdpartyaverage")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}>$</div>
                            <div>{com.commaize(record.thirdpartyaverage)}</div>
                        </div>
                    )
                  )
              },
          ],
        },
      ];
    const closedloanColumns = [
        {
            title: 'Data Points',
            dataIndex: 'datapoint',
            key: 'datapoint',
            render: (text, record) => (
                <div className={boldrows.includes(record.datapoint)?"boldParameters":""}>{notreflectedRows.includes(record.datapoint)?`*${record.datapoint}`:` ${record.datapoint}`}</div>
            )

          },
          {
              title: 'Parameters',
              dataIndex: 'parameters',
              key: 'parameters',
              render: (text, record) => (
                <div className={boldrows.includes(record.datapoint)?"boldParameters":""}>{record.parameters}</div>
            )

            },
          {
            title: 'Brokered',
            children: [
              {
                title: 'Amount',
                dataIndex: 'brokeredamount',
                key: 'brokeredamount',
                render: (text, record) => (
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.brokeredamount)}
                            onChange={event => handleClosedLoanDataChange(event, record, "brokeredamount")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}>$</div>
                            <div>{com.commaize(record.brokeredamount)}</div>
                        </div>
                    )
                )
              },
              {
                title: 'Count',
                dataIndex: 'brokeredcount',
                key: 'brokeredcount',
                render: (text, record) => (
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={record.brokeredcount}
                            onChange={event => handleClosedLoanDataChange(event, record, "brokeredcount")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}>$</div>
                            <div>{record.brokeredcount}</div>
                        </div>
                    )
                )
              },
              {
                  title: 'Average',
                  dataIndex: 'brokeredaverage',
                  key: 'brokeredaverage',
                  render: (text, record) => (
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.brokeredaverage)}
                            onChange={event => handleClosedLoanDataChange(event, record, "brokeredaverage")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}>$</div>
                            <div>{com.commaize(record.brokeredaverage)}</div>
                        </div>
                    )
                )
                },
            ],
          },
          {
            title: 'Non Delegated Correspondent',
            children: [
              {
                title: 'Amount',
                dataIndex: 'NDCamount',
                key: 'NDCamount',
                render: (text, record) => (
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.NDCamount)}
                            onChange={event => handleClosedLoanDataChange(event, record, "NDCamount")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}>$</div>
                            <div>{com.commaize(record.NDCamount)}</div>
                        </div>
                    )
                )
              },
              {
                title: 'Count',
                dataIndex: 'NDCcount',
                key: 'NDCcount',
                render: (text, record) => (
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={record.NDCcount}
                            onChange={event => handleClosedLoanDataChange(event, record, "NDCcount")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}>$</div>
                            <div>{record.NDCcount}</div>
                        </div>
                    )
                )
              },
              {
                  title: 'Average',
                  dataIndex: 'NDCaverage',
                  key: 'NDCaverage',
                  render: (text, record) => (
                    (!closedLoanTotals.includes(record.datapoint)&& !props.openingGeneratedReport)?(<div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.NDCaverage)}
                            onChange={event => handleClosedLoanDataChange(event, record, "NDCaverage")}
                        />
                    </div>
                    ):(
                        <div className={boldrows.includes(record.datapoint)?"boldParameters":""} style={{display:"flex"}}>
                            <div style={{marginRight:"15px"}}>$</div>
                            <div>{com.commaize(record.NDCaverage)}</div>
                        </div>
                    )
                )
                },
            ],
          },
    ]
    const revenueColumns = [
        {
            title: 'Data Points',
            dataIndex: 'datapoint',
            key: 'datapoint',
          },
          {
              title: 'Parameters',
              dataIndex: 'parameters',
              key: 'parameters',
            },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => (
                (!props.openingGeneratedReport)?(
                    <div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.amount)}
                            onChange={event => handleRevenueDataChange(event, record, "amount")}
                        />
                    </div>
                ):(
                    <div style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{com.commaize(record.amount)}</div>
                    </div>
                )
            )
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            render: (text, record) => (
                (!props.openingGeneratedReport)?(
                    <div style={{display:'flex', alignItems:"center"}}>
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={record.count}
                            onChange={event => handleRevenueDataChange(event, record, "count")}
                        />
                    </div>
                ):(
                    <div style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{record.count}</div>
                    </div>
                )
            )
        },
        {
            title: 'Average',
            dataIndex: 'average',
            key: 'average',
            render: (text, record) => (
                (!props.openingGeneratedReport)?(
                    <div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.average)}
                            onChange={event => handleRevenueDataChange(event, record, "average")}
                        />
                    </div>
                ):(
                    <div style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{com.commaize(record.average)}</div>
                    </div>
                )
            )
        },
    ]
    const mloColumns = [
        {
            title: 'Data Points',
            dataIndex: 'datapoint',
            key: 'datapoint',
          },
          {
              title: 'Parameters',
              dataIndex: 'parameters',
              key: 'parameters',
            },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => (
                (!props.openingGeneratedReport)?(
                    <div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.amount)}
                            onChange={event => handleMLODataChange(event, record, "amount")}
                        />
                    </div>
                 ):(
                    <div style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{com.commaize(record.amount)}</div>
                    </div>
                )
            )
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            render: (text, record) => (
                (!props.openingGeneratedReport)?(
                    <div style={{display:'flex', alignItems:"center"}}>
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={record.count}
                            onChange={event => handleMLODataChange(event, record, "count")}
                        />
                    </div>
                ):(
                    <div style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{record.count}</div>
                    </div>
                )
            )
        },
        {
            title: 'Average',
            dataIndex: 'average',
            key: 'average',
            render: (text, record) => (
                (!props.openingGeneratedReport)?(
                    <div style={{display:'flex', alignItems:"center"}}>
                        $
                        <Input
                            // defaultValue={record.brokeredamount}
                            style={{marginLeft:"5px"}}
                            value={com.commaize(record.average)}
                            onChange={event => handleMLODataChange(event, record, "average")}
                        />
                    </div>
                ):(
                    <div style={{display:"flex"}}>
                        <div style={{marginRight:"15px"}}>$</div>
                        <div>{com.commaize(record.average)}</div>
                    </div>
                )
            )
        },
    ]
    const types = 
        {
            applicationdata: "Application Data",
            closedloandata: "Closed Loan Data",
            revenuedata: "Revenue Data",
            mlodata: "MLO Data"
        }
    
    const columnsMap = {
        applicationdata: applicationColumns,
        closedloandata: closedloanColumns,
        revenuedata: revenueColumns,
        mlodata: mloColumns,
    }

    const getFilteredData = (selectedState,Loans) => {
        let tempLoans = cloneDeep(Loans)
        let printloans = tempLoans.map(LoansJSON).filter(s => {
            return(
                s.state.includes(com.RuleSet.State.values[selectedState.toUpperCase()]) &&
                quarterMap[props.selectedQuarter].includes(new Date(s.appintake).getMonth()+1) &&
                new Date(s.appintake).getFullYear() === parseInt(props.selectedYear)
            )
        })
        return Loans.map(LoansJSON).filter(s => {
            return(
                s.state.includes(com.RuleSet.State.values[selectedState.toUpperCase()]) &&
                quarterMap[props.selectedQuarter].includes(new Date(s.appintake).getMonth()+1) &&
                new Date(s.appintake).getFullYear() === parseInt(props.selectedYear)
            )
        })
    }

    const LoansJSON = (document, index) => {
        let dt = new Date(com.timeGoToJS(document.started))
        return {
            address: document.address,
            state: document.state,
            loanid: document.loanid,
            countystate: document.county+", "+ com.convertStateToAbbr(document.state),
            name: document.firstname + " " + document.lastname,
            loanofficer: document.loanofficer,
            status: document.status,
            appintake: dt.toLocaleDateString(),
            // loid: document.lo_id,
            loanamount: document.loanamount,
            loanprice: document.price,
            loantype: document.mtype,
            mortgageapplied: document.mortgageapplied,
            amortization: document.amortization,
            loanterm: document.loanterm,
            apr: document.apr,
            baserate: document.baserate,
            monthlypay: document.monthlypay,
            lenderfee: document.lenderfee,
            propertytype: document.propertytype,
            occupancy: document.occupancy,
            purpose: getPurpose(document.purpose, document.hascontract, document.unknownpurpose),
        }
    }

    const getPurpose = (purpose, hascontract, unknownpurpose) => {
        if (purpose === "refinance") return "Rate/Term Refinance"
        if (purpose === "cashoutrefinance") return "Cash-out Refinance"
        if (purpose === "purchase") {
          if (unknownpurpose) return "TBD"
          if (!hascontract) return "Pre-qualification"
          return "Purchase"
        }
        return "TBD"
    }


    const tabItemsByDataType = (state, types) => {
        // setDatatoSave(props.reportTableData)
        let items = Object.keys(types).map((type) => {
            return {
                key: type,
                label: types[type],
                children: 
                    <>
                        <div className="text-left" style={{marginLeft:"5px",marginBottom:"5px"}}>* = currently not reflected by our system</div>
                        <Table
                            style={{border:"1px solid #ECECEC",borderRadius:"8px"}}
                            className="callreportTable"
                            columns={columnsMap[type]}
                            dataSource={props.datatoSave.data[state][type]}
                            pagination={false}
                            showSorterTooltip={false}
                            scroll={{ x:1000}}
                            rowClassName={(record) => boldrows.includes(record.datapoint)?"totalrowsStyle":""}
                        />
                    </>
                   
            }
        })
        items.push({
            key: "Loans Pipeline",
            label: `Loans in ${props.selectedQuarter.toUpperCase()} ${props.selectedYear} (${state.toUpperCase()})`,
            children:
                <CallReportPipeline data={getFilteredData(props.selectedState,props.allloans)} currentSelectedState={state} {...props} />
        })

        return items;
    }

    return(
        <>
            <Tabs style={{borderBottom:"1px solid #ECECEC"}} tabPosition="left" defaultActiveKey={types[0]} items={tabItemsByDataType(props.selectedState, types)}/>
        </>
    )
}

const CallReportPipeline = (props) => {

    const [editingCell, setEditingCell] = useState({ loanID: null, columnKey: null });

    const columns=[
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 100,
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            width: 100,
        },
        {
            title: 'App Intake',
            dataIndex: 'appintake',
            key: 'appintake',
            width: 100,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 180,
            render: (text, record) => {
                const items = Object.entries(com.loanStatusMap).map(([key, val]) => {
                  if (manualupdateStatuses.includes(key)) {
                    return {
                      key: key,
                      label: (
                        <a onClick={() => handleStatusChange(record.loanid, key)}>
                          {val}
                        </a>
                      ),
                    }
                  }
              })
      
                return <div className="loansStatus">
                  <Tag
                    bordered={false}
                    color={com.mapStatusTagColor(record.status)}
                    className="loansStatusTag" >
                    {com.splitStatusByWords(record.status)}
                    
                  </Tag>
                  <Dropdown
                      menu={{items,}}
                      placement="bottomLeft"
                      arrow
                    >
                      <Button className="loanStatusSelect" onClick={() => toggleEdit(record.loanid, 'status')} >
                        <CaretDownOutlined style={{fontSize:11}}/>
                      </Button>
                  </Dropdown>
                </div>
              },
        },
        {
            title: 'Loan Purpose',
            dataIndex: 'purpose',
            key: 'purpose',
            width: 150,
        },
        {
            title: 'Loan Price',
            dataIndex: 'loanprice',
            key: 'loanprice',
            width: 100,
        },
        {
            title: 'Loan Amount',
            dataIndex: 'loanamount',
            key: 'loanamount',
            width: 100,
        },
        {
            title: 'Mortgage Type',
            dataIndex: 'mortgageapplied',
            key: 'mortgageapplied',
            width: 150,
        },
        {
            title: 'Product Type',
            dataIndex: 'loantype',
            key: 'loantype',
            width: 100,
        },
        {
            title: 'Amortization',
            dataIndex: 'amortization',
            key: 'amortization',
            width: 100,
        },
        {
            title: 'Loan Term',
            dataIndex: 'loanterm',
            key: 'loanterm',
            width: 100,
        },
        {
            title: 'APR',
            dataIndex: 'apr',
            key: 'apr',
            width: 100,
        },
        {
            title: 'Base Rate',
            dataIndex: 'baserate',
            key: 'baserate',
            width: 100,
        },
        {
            title: 'Monthly Payment',
            dataIndex: 'monthlypay',
            key: 'monthlypay',
            width: 100,
        },
        {
            title: 'Lender Fee',
            dataIndex: 'lenderfee',
            key: 'lenderfee',
            width: 100,
        },
        {
            title: 'Property Address',
            dataIndex: 'address',
            key: 'address',
            width: 150,
        },
        {
            title: 'Property Type',
            dataIndex: 'propertytype',
            key: 'propertytype',
            width: 150,
        },
        {
            title: 'Occupancy',
            dataIndex: 'occupancy',
            key: 'occupancy',
            width: 150,
        },
        {
            title: 'Loan Officer',
            dataIndex: 'loanofficer',
            key: 'loanofficer',
            width: 150,
        },
      
    ]

    const toggleEdit = (loanID, columnKey) => {
        setEditingCell(prevState => ({
          loanID: prevState.loanID === loanID && prevState.columnKey === columnKey ? null : loanID,
          columnKey: prevState.columnKey === columnKey && prevState.loanID === loanID ? null : columnKey
        }));
      };

    const handleStatusChange = (loanID, newStatus) => {
        // this.setState({ loadingStatus: true })
        let token = sessionStorage.getItem("ZeitroA")
        let body = { LoanID: loanID, Key: "status", Value: newStatus, IsAdmin: true}
        fetch('/los/updatepipeline', {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          }
        }).then(response => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
              response.status);
            // this.setState({ showErrorModal: true, loadingStatus: false })
            return;
          }
        //   this.toggleEdit(null, null);
          const updatedloans = props.allloans.map(loan => {
            if (loan.loanid === loanID) {
              return { ...loan, status: newStatus };
            }
            return loan;
          });
          props.setAllloans(updatedloans);
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        })
      }

    return(
        <div style={{marginTop:"20px",width:"100%"}}>
            {/* <div className="text-left" style={{fontSize:"16px", fontWeight:"600",marginBottom:"15px"}}>Loans at {props.selectedQuarter.toUpperCase()} {props.selectedYear} in {props.currentSelectedState.toUpperCase()}</div> */}
            <Table
                style={{border:"1px solid #ECECEC",borderRadius:"8px",width:"100%"}}
                className="callreportPipeline"
                columns={columns}
                dataSource={props.data}
                pagination={true}
                showSorterTooltip={false}
                scroll={{  y: 800 }}
            />
        </div>
    )
}


const CallReportPreview= (props) => {
    const [datatoSave, setDatatoSave] = useState(cloneDeep(props.reportdata));
    const [loading, setLoading] = useState(false);
    const [loNameMap, setLoNameMap] = useState({});
    const [allloans, setAllloans] = useState(props.Loans);
    useEffect(() => {
        const result = cloneDeep(props.reportdata);
        if(!props.openingGeneratedReport){
            props.selectedStates.forEach((state) => {
                let data_afterApplication = applicationDataCalculation(state, datatoSave, allloans);
                let data_afterClosedLoan = closedloanDataCalculation(state, data_afterApplication, allloans);
                let data_afterRevenue = revenueDataCalculation(state, data_afterClosedLoan, allloans);
                let resultdata = mloDataCalculation(state, data_afterRevenue, allloans);
                result.data[state] = resultdata.data[state];
            });          
        }
        props.setCallReportDatatoSave(result)
        setDatatoSave(result)
    }, [allloans]);

    useEffect(() => {
    },[datatoSave])


    const tabItemsByState = () => {
        let items=props.selectedStates.map((state) => {
            return {
                key: state,
                label: com.RuleSet.State.values[state.toUpperCase()],
                children: 
                    <>
                        <CallReportTable 
                            {...props}
                            selectedQuarter={props.selectedQuarter} 
                            selectedYear={props.selectedYear} 
                            loMap={loNameMap} 
                            allloans={allloans}
                            setAllloans={setAllloans}
                            datatoSave={datatoSave} 
                            setDatatoSave={setDatatoSave} 
                            selectedState={state} 
                            setCallReportDatatoSave={props.setCallReportDatatoSave} 
                        />
                    </>,
            }
        })
        return items;
    }


    const calculateGrossRevenue = (term,monthlypay) => {
        let revenue = 0
        if (term !== "" && monthlypay !== ""){
            revenue = parseFloat(monthlypay)* parseFloat(term) * 12
        }
        return revenue
    }
    const applicationDataCalculation =(state,initialdata,allloans)=>{
        let tempdata = cloneDeep(initialdata);

        rowsLabel.applicationdata.forEach(row => {
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredamount = 0
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredcount = 0
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredaverage = 0
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyamount = 0
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartycount = 0
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyaverage = 0

        })

        allloans.forEach(s => {
            if (s.state === com.RuleSet.State.values[state.toUpperCase()]) {
                if (s.loanamount===""){
                    s.loanamount=0
                }
                //application in process at the beginning
                if (s.status !== "applicationwithdrawn" && 
                s.status !== "fundsreleased" && 
                s.status !== "applicationabandoned" && 
                s.status !== "applicationrejected" && 
                s.status !== "borrowerwithdrew" && 
                s.status !== "loancancelled" && 
                s.status !== "loandenied" &&
                new Date(com.timeGoToJS(s.started)) < new Date(props.selectedYear, quarterMap[props.selectedQuarter][0]-1, 1)) {
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC010']].brokeredcount += 1
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC010']].brokeredamount += parseInt(s.loanamount)
                }
                //application received
                if (quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC020']].brokeredcount += 1
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC020']].brokeredamount += parseInt(s.loanamount)
                }
                //application withdrawn
                if (s.status === "applicationwithdrawn" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC050']].brokeredcount += 1
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC050']].brokeredamount += parseInt(s.loanamount)
                }
                //application denied
                if (s.status === "applicationdenied" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC040']].brokeredcount += 1
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC040']].brokeredamount += parseInt(s.loanamount)
                }

                //loans closed and funded
                if (s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC070']].brokeredcount += 1
                    tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC070']].brokeredamount += parseInt(s.loanamount)
                }
            }
        })
        //net changes
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC065']].brokeredcount += tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC020']].brokeredcount-tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC050']].brokeredcount-tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC040']].brokeredcount
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC065']].brokeredamount += tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC020']].brokeredamount-tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC050']].brokeredamount-tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC040']].brokeredamount
        //total application pipeline
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC066']].brokeredcount = tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC010']].brokeredcount + tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC065']].brokeredcount
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC066']].brokeredamount = tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC010']].brokeredamount + tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC065']].brokeredamount
        //applications at the end of period
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC080']].brokeredcount = tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC066']].brokeredcount - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC070']].brokeredcount
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC080']].brokeredamount = tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC066']].brokeredamount - tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC070']].brokeredamount
        //total application pipeline result
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC090']].brokeredcount = tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC080']].brokeredcount
        tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC090']].brokeredamount = tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata['AC080']].brokeredamount

        rowsLabel.applicationdata.forEach(row => {
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredaverage= Math.round(tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredamount/tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredcount)
            tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyaverage= Math.round(tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyamount/tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartycount)
            if(tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredamount===0){
                tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].brokeredaverage=0
            }
            if(tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyamount===0){
                tempdata.data[state].applicationdata[com.callReportFieldIndex.applicationdata[row]].thirdpartyaverage=0
            }
        })
        
        return tempdata
    }
    const closedloanDataCalculation =(state,initialdata,allloans)=>{
        let tempdata = cloneDeep(initialdata);

        rowsLabel.closedloandata.forEach(row => {
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredamount = 0
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredcount = 0
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredaverage = 0
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCamount = 0
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCcount = 0
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCaverage = 0

        })

        allloans.forEach(s => {
            if (s.state === com.RuleSet.State.values[state.toUpperCase()]) {
                if (s.loanamount===""){
                    s.loanamount=0
                }
                //conventional
                if (s.mortgageapplied === "conventional" && s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear) ) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC100']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC100']].brokeredamount += parseInt(s.loanamount)
                }
                if ((s.propertytype === "singlefamilydetached"|| s.propertytype==="singlefamilyattached") && s.status === "fundsreleased" && 
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC200']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC200']].brokeredamount += parseInt(s.loanamount)
                }
                if (s.propertytype === "manufacturedhome" && s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC210']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC210']].brokeredamount += parseInt(s.loanamount)
                }
                if (s.propertytype === "twotofourfamily" && s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC220']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC220']].brokeredamount += parseInt(s.loanamount)
                }
                if (s.purpose === "Purchase" && s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC300']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC300']].brokeredamount += parseInt(s.loanamount)
                }
                if (s.purpose === "Rate/Term Refinance" && s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC320']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC320']].brokeredamount += parseInt(s.loanamount)
                }
                if (s.status === "fundsreleased" && quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC900']].brokeredcount += 1
                    tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC900']].brokeredamount += parseInt(s.loanamount)
                }
            }
        })

        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC190']].brokeredamount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC100']].brokeredamount
        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC190']].brokeredcount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC100']].brokeredcount
        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC290']].brokeredamount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC200']].brokeredamount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC210']].brokeredamount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC220']].brokeredamount
        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC290']].brokeredcount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC200']].brokeredcount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC210']].brokeredcount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC220']].brokeredcount
        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC390']].brokeredamount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC300']].brokeredamount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC320']].brokeredamount
        tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC390']].brokeredcount= tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC300']].brokeredcount + tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata['AC320']].brokeredcount  
      
        rowsLabel.closedloandata.forEach(row => {
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredaverage= Math.round(tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredamount/tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredcount)
            tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCaverage= Math.round(tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].thirdpartyamount/tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].thirdpartycount)
            if(tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredamount===0){
                tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].brokeredaverage=0
            }
            if(tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCamount===0){
                tempdata.data[state].closedloandata[com.callReportFieldIndex.closedloandata[row]].NDCaverage=0
            }
        })

        return tempdata
    }
    const revenueDataCalculation =(state,initialdata,allloans)=>{
        let tempdata = cloneDeep(initialdata);
        let revenue = 0
        tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].amount = 0
        tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].count = 0
        tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].average = 0
        allloans.forEach(s => {
            if (s.state === com.RuleSet.State.values[state.toUpperCase()]) {
                if (s.status === "fundsreleased" &&
                quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)) {
                    revenue = calculateGrossRevenue(s.loanterm,s.monthlypay)
                    tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].amount += revenue
                    tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].count += 1
                }
            }
        })
        tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].average = Math.round(tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].amount/tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].count)
        if(tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].amount===0){
            tempdata.data[state].revenuedata[com.callReportFieldIndex.revenuedata['AC1100']].average=0
        }
        return tempdata
    }
    const mloDataCalculation =(state,initialdata,allloans)=>{
        let tempdata = cloneDeep(initialdata);
        let index=0
        Object.values(props.loanofficers).forEach(loanofficer => {
            loNameMap[loanofficer] = index
            index += 1
        })
        loNameMap["Unassigned"] = index
        setLoNameMap(loNameMap)
        Object.entries(loNameMap).forEach(([key, value]) => {
            tempdata.data[state].mlodata.push(
                {
                    datapoint: `MLO${value + 1}`,
                    parameters: key,
                    amount: 0,
                    count: 0,
                    average: 0,
                }
            )
        })

        Object.keys(loNameMap).forEach(lo => {
            let index = loNameMap[lo];
            tempdata.data[state].mlodata[index].amount = 0
            tempdata.data[state].mlodata[index].count = 0
            tempdata.data[state].mlodata[index].average = 0
        });

        allloans.forEach(s => {
            if (s.state === com.RuleSet.State.values[state.toUpperCase()]) {
                if (quarterMap[props.selectedQuarter].includes(new Date(com.timeGoToJS(s.started)).getMonth()+1) &&
                new Date(com.timeGoToJS(s.started)).getFullYear() === parseInt(props.selectedYear)){
                    tempdata.data[state].mlodata[loNameMap[s.loanofficer]].amount += parseInt(s.loanamount)
                    tempdata.data[state].mlodata[loNameMap[s.loanofficer]].count += 1
                }
            }

        })
        Object.keys(loNameMap).forEach(lo => {
            let index = loNameMap[lo];
            tempdata.data[state].mlodata[index].average = Math.round(tempdata.data[state].mlodata[index].amount / tempdata.data[state].mlodata[index].count);
            if(tempdata.data[state].mlodata[index].amount === 0){
                tempdata.data[state].mlodata[index].average = 0;
            }
        });
        return tempdata
    }
    
    
    return(
        <div>
            <Tabs defaultActiveKey={props.selectedStates[0]} items={tabItemsByState()}/>
        </div>
    )
}

export default CallReportPreview;