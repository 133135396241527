import React, { useState, useEffect, useRef } from 'react';
import { useImmer } from "use-immer";
import { Form, Input, Button, Col, Row, Modal, Space, Popover } from 'antd';
import CodeVerification from "./Common/components/CodeVerification";
import NewEmailVerification from './Application/NewEmailVerification';
import "./signup.css";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { QuestionCircleOutlined } from '@ant-design/icons';


function PersonalInformation(props) {
    const { signData, back } = props
    console.log(signData, props);
    const [errorInput, setError] = useState(false)
    const [flag, setFlag] = useImmer(true)
    const [form] = Form.useForm();
    const onFinish = async (val) => {

        try {
            const response = await fetch('/los/createnewcustomer', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(val)
            })
            const { Token, Text } = await response.json()


            if (Token !== '') {
                setError(false)
                sessionStorage.setItem("ZeitroA", Token)
                let path = "/app/welcome"
                window.location.href = path
            } else {
                if (Text.includes('customers_id_key')) {
                    setError(true)
                    return
                }
                alert("Something went wrong. Please try again later.")
                back()
            }

        } catch (error) {
            console.log(error);
        }
        console.log(val)

    }
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    return (
        <>
            <Row >
                <Col span={24}>
                    <div className='Welcome'>Welcome aboard!</div>
                    <div className='inter'>We prioritize the security and trust of our users. Link your Zeitro account to your NMLS ID, so that borrowers trust us, and trust you. </div>
                    <Form
                        className=''
                        style={{ marginTop: '20px' }}
                        layout='vertical'
                        form={form}

                        onFinish={onFinish}
                        validateMessages={validateMessages}
                        size="large"
                    >
                        <Row>
                            <Col span={11}>
                                <Form.Item
                                    name="FirstName"
                                    label="First Name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your first name" />
                                </Form.Item>
                            </Col>
                            <Col span={11} push={2}>
                                <Form.Item
                                    name="LastName"
                                    label="Last Name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your last name" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="Password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Enter your Password" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="IndividualNMLS"
                                    label=" NMLS ID"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your Individual NMLS ID" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="Role"
                                    label="Your role"
                                 
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="tier"
                                    label="Account Tier"
                                
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>





                        <div style={{ display: 'flex', justifyContent: "end" }}>
                            <Button htmlType="submit" type='primary' style={{ width: '150px' }}>Let’s go</Button>

                        </div>


                    </Form>
                </Col>
            </Row >

        </>
    )

}
export default function SignUpPage() {
    const [form] = Form.useForm();
    const formInitial = {

    }
    const [showPersonalInformation, setShowPersonalInformation] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [signData, setSignData] = useImmer({})

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    const onFinish = (val) => {
        setSignData(val)
        setShowPersonalInformation('email')


    }
    const exportToPDF = async () => {

        const element = document.getElementById('pdf');

        element.style.fontSize = '10px';
        element.style.transform = 'scale(0.9, 1)';
        
        const pdf = new jsPDF('p', 'pt', 'a4')

        const scale = window.devicePixelRatio > 1 ? window.devicePixelRatio : 2;
        let width = element.offsetWidth;
        let height = element.offsetHeight;
        const canvas = document.createElement('canvas');
        canvas.width = width * scale;
        canvas.height = height * scale;

        const pdfCanvas = await html2canvas(element, {
            useCORS: true,
            scale: scale,
            width,
            height,
            x: 0,
            y: 0,
        });
        const imgDataUrl = pdfCanvas.toDataURL();
        if (height > 14400) {
            const ratio = 14400 / height;
            height = 14400;
            width = width * ratio;
        }
        height = height * pdf.internal.pageSize.getWidth() / width;
        width = pdf.internal.pageSize.getWidth();

        pdf.addImage(imgDataUrl, 'png', 10, 10, width * 0.9, height * 1)
        await pdf.save("Terms and Conditions", { returnPromise: true });

    }


    return (
        <>
            <div className="sign-up-box">
                <div className='create-account'>
                    <div className='sign-up-logo'><img style={{ width: '100px' }} src='/images/logo.svg' /></div>
                    <div className='mobile-nav'>
                        <div className='mobile-logo'><img src='/images/mobile-logo.svg' /></div>
                        <div className='mobile-menu'><img src='/images/menu.svg' /></div>
                    </div>
                    <PersonalInformation signData={signData} back={() => {

                        setShowPersonalInformation('')
                    }} />
                    <div className='create-copyright'>© {new Date().getFullYear()} Zeitro. All rights reserved.</div>
                </div>
                <Modal width={800} open={isModalOpen} okText='I agree' cancelText='Export as PDF' onOk={() => setIsModalOpen(false)} onCancel={() => {
                    exportToPDF()

                    // setIsModalOpen(false)
                }}>
                    <div id='pdf'>

                        <h4>Terms and Conditions</h4>
                        <h5>Blah - the following is generated  by ChatGPT as a placeholder.</h5>
                        <p>This agreement (the "Agreement") is between Zeitro, Inc., a corporation incorporated under the laws of the United States, and you ("User"), as a user of the loan origination software provided by Zeitro, Inc. By accessing or using our loan origination software, you agree to be bound by this Agreement.</p>
                        <p>1. Services Provided
                            Zeitro, Inc. provides loan origination software ("the Software") for use by loan officers and loan broker companies to facilitate loan processing and management.</p>
                        <p>
                            2. User Responsibilities
                            User agrees to use the Software in compliance with all applicable laws, regulations, and industry standards. User is responsible for maintaining the confidentiality of their login credentials.
                        </p>
                        <p>
                            3. Data Privacy and Security
                            Zeitro, Inc. will collect, process, and store personal and financial information as necessary to provide the services outlined in this Agreement. User acknowledges and agrees to the terms of Zeitro, Inc.'s Privacy Policy.
                        </p>
                        <p>
                            4. Intellectual Property
                            The Software and all intellectual property rights therein are owned by Zeitro, Inc. User does not acquire any ownership rights by using the Software.
                        </p>
                        <p>
                            5. Financial Liability
                            Zeitro, Inc. shall not be liable for any damages arising out of or in connection with the use of the Software. In no event shall Zeitro, Inc.'s total liability exceed the amount paid by User for the Software during the twelve (12) months preceding the claim.
                        </p>
                        <p>
                            6. Termination
                            Either party may terminate this Agreement at any time for any reason. Upon termination, User's access to the Software will be suspended.
                            By using the Software, User acknowledges that they have read, understood, and agree to be bound by this Agreement.
                        </p>
                        <p>
                            7. Governing Law and Jurisdiction
                            This Agreement shall be governed by the laws of the United States. Any disputes shall be subject to the exclusive jurisdiction of the courts of the United States.
                            Zeitro, Inc. reserves the right to update or modify this Agreement at any time without prior notice. Continued use of the Software after any such changes shall constitute acceptance of the modified Agreement.
                        </p>

                    </div>
                </Modal>
            </div>

        </>
    )
}